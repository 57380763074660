import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import * as XLSX from 'xlsx';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-van-stockverify-rep',
  templateUrl: './van-stockverify-rep.component.html',
  styleUrls: ['./van-stockverify-rep.component.css']
})
export class VanStockverifyRepComponent implements OnInit {

  fileName = 'van_stock_verification_report.xlsx';
  periodTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'tm', name: 'This Month' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];

  sortType = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' },
  ];
  userType: any;
  branch_all: any;
  users: any;
  vans: any;
  products: any[];
  curpage: number;
  lastpage: number;
  pgstart: boolean;
  pgend: boolean;
  pageLoadingImg: boolean;
  repDate: any;
  from: any;
  summary: any;
  tot_items: any;
  tot_req_qty: any;
  total_vrfy_qty: any;
  exportLoader: boolean;
  expsummary: any;
  exptot_items: any;
  exptot_req_qty: any;
  exptotal_vrfy_qty: any;
  branch_display_name: any;
  branch_address:any;
  branch_display_code: any;
  cur_lang: any;
  PrdNAme: any;
  constructor(private apiService: ApiService, private coreService: CoreService,private modalService: NgbModal,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.cur_lang = sessionStorage.getItem("baseLang");
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    let searchval=new FormData();

    this.pageLoadingImg = true;
    this.apiService.vanStockverifyRep(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
        
      this.summary = res.data.data;
      this.tot_items=res.total.tot_prods;
      this.tot_req_qty=res.total.tot_req_qty;
      this.total_vrfy_qty=res.total.total_vrfy_qty;

        this.curpage = res.data.current_page;
        this.lastpage = res.data.last_page;
        this.repDate = res.Date;
        this.from = res.data.from;
        this.pgEnd(this.curpage, this.lastpage);
        this.pgStart(this.curpage);
        
  
  
      });

    this.userType = this.coreService.getUserData('user_type');
    this.searchUser('');
    this.searchVans('');
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');
  }
  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all']).subscribe((res: string) => {    
     
    
      this.periodTypes = [
        
        { id: 't', name: res['Common.today'] },
        { id: 'ld', name: res['Common.last_day'] },
        { id: 'lw', name: res['Common.last_week'] },
        { id: 'lm', name: res['Common.last_maonth'] },
        { id: 'ly', name: res['Common.last_yr'] },
        { id: 'c', name: res['Common.cust_date'] },
        { id: '', name: res['Common.all'] }
            
          ];
    });

  


  }
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
    });
  }
  searchUser(search: string) {
    let searchval = new FormData();
    searchval.append("usr_name", search);
    this.apiService.getUserByName(searchval).subscribe((res) => {
      this.users = res['data'];
    });
  }

  searchVans(search: string) {
    const searchval = new FormData();
    searchval.append('van_name', search);
    this.apiService.searchVans(searchval).subscribe((res) => {
      this.vans = res['data'];
    });

  }

    // search product
    selectProd(name: string) {

      const searchval = new FormData();
      searchval.append('prod_name', name);
      if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
        searchval.append('alias_search', '1');
      }
      this.apiService.getProdByName(searchval).subscribe((res) => {
        this.products = res.data;
      });
    }

    pageNext(formdata: { value: any; }, pageno: any) {

      this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }

      this.apiService.vanStockverifyRep(formdata.value, pageno).subscribe((res: any) => {
        this.pageLoadingImg = false;
          
        this.summary = res.data.data;
        this.tot_items=res.total.tot_prods;
        this.tot_req_qty=res.total.tot_req_qty;
        this.total_vrfy_qty=res.total.total_vrfy_qty;

          this.curpage = res.data.current_page;
          this.lastpage = res.data.last_page;
          this.repDate = res.Date;
          this.from = res.data.from;
    
    
          this.pgEnd(this.curpage, this.lastpage);
          this.pgStart(this.curpage);
          
    
    
        });
    }

    
  pgEnd(curr, end) {
    if (curr == end) {
      this.pgend = true;
    } else {
      this.pgend = false;
    }

  }

  pgStart(curr) {
    if (curr == 1) {
      this.pgstart = true;
    } else {
      this.pgstart = false;
    }
  }

  exportEXCL(formdata: { value: any; }, pageno = 1) {
    formdata.value.export = 'export';
   
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
   
    this.exportLoader = true;

    this.apiService.vanStockverifyRep(formdata.value, pageno).subscribe((res: any) => {
      
        
      this.expsummary = res.data.data;
      this.exptot_items=res.total.tot_prods;
      this.exptot_req_qty=res.total.tot_req_qty;
      this.exptotal_vrfy_qty=res.total.total_vrfy_qty;

        this.curpage = res.data.current_page;
        this.lastpage = res.data.last_page;
        this.repDate = res.Date;
        this.from = res.data.from;

        
        setTimeout(() => {
          this.exportexcel();
        }, 3000);
  
      });
  }

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }


  // pdf download
  generatePdf(formdata: { value: any; }) {
    this.exportLoader = true;

    formdata.value.export = 'export';
   
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
   

    this.apiService.vanStockverifyRep(formdata.value, 1).subscribe((res: any) => {
      
        
      this.expsummary = res.data.data;
      this.exptot_items=res.total.tot_prods;
      this.exptot_req_qty=res.total.tot_req_qty;
      this.exptotal_vrfy_qty=res.total.total_vrfy_qty;

        this.curpage = res.data.current_page;
        this.lastpage = res.data.last_page;
        this.repDate = res.Date;
        this.from = res.data.from;

        const heads = [
          this.translate.instant('Common.prd_name'),
          this.translate.instant('Purchase.refno'),
          this.translate.instant('HOME.verification_date'),
          this.translate.instant('HOME.required_qty'),
          this.translate.instant('HOME.verified_qty'),
          this.translate.instant('HOME.missing_qty'),
          this.translate.instant('HOME.excess_qty')
        ];
    
          const lblXPos = 10;
          const headerHeight = 15;
          const valueXPos = 55;
          const data = [];
    
          this.expsummary.forEach(item=>{
            if(this.translate.currentLang == "Arabic"){this.PrdNAme =  item.prd_alias}else{this.PrdNAme =  item.prd_name}
            const arr=[
              this.PrdNAme,
              item.van_stkvrfy_ref_id,
              item.van_stkvrfy_date,
              this.currencyPipe.transform(item.van_stkvrfy_req_qty, '', '', '1.1-1'),
              this.currencyPipe.transform(item.van_stkvrfy_existing_qty, '', '', '1.1-1')
            ]

            if(item.van_stkvrfy_add_missing){
              arr.splice(5,0, item.van_stkvrfy_existing_qty - item.van_stkvrfy_req_qty)
            } else {
              arr.splice(5,0, ' ')
            }

            if(item.van_stkvrfy_add_excess){
              arr.splice(6,0, item.van_stkvrfy_existing_qty - item.van_stkvrfy_req_qty)
            } else {
              arr.splice(6,0, ' ')
            }

            data.push(arr)
          })
      
          let doc = this.excelService.getPdfObj();
    
          var addressLines = this.branch_address.split('\r\n');
          var addressText = addressLines.join(', ');
    
          doc.setFont('Amiri');
          doc.setFontSize(12);
          doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
    
          doc.setFont('Amiri');
          doc.setFontSize(9);
          doc.text(addressText, 71, headerHeight + 2);
    
          if(this.repDate){
            doc.setFontSize(10);
            doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
            doc.setFontSize(10);
            doc.text(':  ' + this.repDate.date1 + '   to   ' + this.repDate.date2  ,  valueXPos, headerHeight + 10);
          }
    
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
          doc.setFontSize(10);
          doc.text(':  ' + `${this.translate.instant('HOME.vanstock_verification_inv_based')}`,  valueXPos, headerHeight + 15);

          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Table.totl_item')}`, lblXPos, headerHeight + 20);
          doc.setFontSize(10);
          doc.text(':  ' + this.tot_items ,  valueXPos, headerHeight + 20);

          doc.setFontSize(10);
          doc.text(`${this.translate.instant('HOME.total_required_qty')}`, lblXPos, headerHeight + 25);
          doc.setFontSize(10);
          doc.text(':  ' + this.currencyPipe.transform(this.tot_req_qty, '', '', '1.1-1'),  valueXPos, headerHeight + 25);

          doc.setFontSize(10);
          doc.text(`${this.translate.instant('HOME.total_verified_qty')}`, lblXPos, headerHeight + 30);
          doc.setFontSize(10);
          doc.text(':  ' + this.currencyPipe.transform(this.total_vrfy_qty, '', '', '1.1-1'),  valueXPos, headerHeight + 30);
          
          doc = this.excelService.addTableToPdf(doc, headerHeight + 35, lblXPos, heads, data, null);
         
          doc.save(`Van Stock Verification Invoiced Based.pdf`);
          this.exportLoader = false;
      });



    
  }


}
