import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
@Component({
  selector: 'app-sales-order-settings',
  templateUrl: './sales-order-settings.component.html',
  styleUrls: ['./sales-order-settings.component.css']
})
export class SalesOrderSettingsComponent implements OnInit {

  footer_display = [
    { id: 0, name: 'Hide' },
    { id: 1, name: 'Show' },
  ];
  settings: any;
  so_footer_display: any;
  pageLoadingImg: boolean;
  types: { id: number; name: any; }[];
  
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService) { }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.getSalesSetting();
  }


  getSalesSetting() {

    this.apiService.getSalesSetting().subscribe((res) => {
      if (res['data']) {
        this.settings = res['data'];
        this.so_footer_display = this.settings.sales_order_footer_display;
       

      } else { 
       

      }
    });

  }

  update(formdata: { value: any; }) {
    this.pageLoadingImg = true;

    this.apiService.updateSalesOrderSettings(formdata.value).subscribe((res) => {
      this.pageLoadingImg = false;
      if (res.data) {
        this.coreService.showMessage('Setting Saved');

      }
    }, (error) => {
      this.pageLoadingImg = false;
    });
  }
  langChange(){
    this.translate.get(['HOME.hide', 'HOME.show',
  ]).subscribe((res: string) => {    
     

      this.footer_display = [
        { id: 0, name: res['HOME.hide']},
        { id: 1, name: res['HOME.show'] },
        
      ];
    
    });

}
}
