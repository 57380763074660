import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import * as XLSX from 'xlsx'; 
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-van-sale-rep',
  templateUrl: './van-sale-rep.component.html',
  styleUrls: ['./van-sale-rep.component.css']
})
export class VanSaleRepComponent implements OnInit {

  fileName= 'van_sale_report.xlsx';
  filterTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'tm', name: 'This Month' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];

  rept_type = [
    // { id: 1, name: 'Transfer & Returns' },
    { id: 2, name: 'Sales' },
    { id: 3, name: 'Sales Return' },
  ];
  searchProducts: any[];
  subcatagories: any;
  categories: any;
  godown_all: any;
  vans: any;
  values: any;
  pageLoadingImg: boolean;
  curpage: number;
  lastpage: number;
  pgstart: boolean;
  pgend: boolean;
  van_sale: any;
  van_list: any;
  total_sale: any;
  total_prdct: any;
  exportLoader: boolean;
  expvan_sale: any;
  expvan_list: any;
  exptotal_sale: any;
  exptotal_prdct: any;
  branch_display_name: any;
  branch_display_code: any;
  reportType: any;
  expreportType: any;
  cmpny: any;
  byderived: boolean;
  userType: any;
  branch_all: any;
  cur_lang: any;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService) { }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.cmpny = this.coreService.getUserData('cmpny');
    this.userType = this.coreService.getUserData('user_type');
    if(this.cmpny=='AQE'){
      this.byderived=true;
    }
    this.cur_lang = sessionStorage.getItem("baseLang");
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    let searchval = new FormData();

    if(this.cmpny=='AQE'){
      searchval.append("byderived", '1');
    }
    this.pageLoadingImg = true;
    this.apiService.vanSalereport(searchval,1).subscribe((res) => {
      this.pageLoadingImg = false;             
      this.van_sale=res.data;
      this.van_list=res.van_list;
      this.total_sale=res.total_transfer_qty;
      this.reportType=res.report_type;
      this.total_prdct=res.total_products;
      this.curpage = res.current_page;  
      this.lastpage= res.last_page; 
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);      
    });
    
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');
  }
  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','Common.sales','Common.sales_return']).subscribe((res: string) => {    
     
      this.rept_type = [
        // { id: 1, name: 'Transfer & Returns' },
        { id: 2, name:  res['Common.sales'] },
        { id: 3, name:  res['Common.sales_return'] },
      ];
      this.filterTypes = [
        
        { id: 't', name: res['Common.today'] },
        { id: 'ld', name: res['Common.last_day'] },
        { id: 'lw', name: res['Common.last_week'] },
        { id: 'lm', name: res['Common.last_maonth'] },
        { id: 'ly', name: res['Common.last_yr'] },
        { id: 'c', name: res['Common.cust_date'] },
        { id: '', name: res['Common.all'] }
            
          ];
    });

  


  }
  pageNext(formdata: { value: any; }, pageno:any)
  {  
   
  this.pageLoadingImg = true;

 
  if (formdata.value.period_type === 'c') {
    formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
    formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
  }
  this.apiService.vanSalereport(formdata.value,pageno).subscribe((res) => {
    this.pageLoadingImg = false;             
    this.van_sale=res.data;
    this.van_list=res.van_list;
    this.reportType=res.report_type;
    this.total_sale=res.total_transfer_qty;
    this.total_prdct=res.total_products;
    this.curpage = res.current_page;  
    this.lastpage= res.last_page; 
    this.pgEnd(this.curpage, this.lastpage);
    this.pgStart(this.curpage);      
  });
  }


  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

   // search product
 selectProd(name: string) {

  const searchval = new FormData();
  searchval.append('prod_name', name);
  if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
    searchval.append('alias_search', '1');
  }

  this.apiService.getProdByName(searchval).subscribe((res) => {
    this.searchProducts = res.data;
  });
}

 //form search  for category
 searchCat(search: string) {
  let searchval = new FormData();
  searchval.append("cat_name", search);
  this.apiService.getCatbyName(searchval).subscribe((res) => {
    this.categories = res['data'];     
  });  
}

selectSubCats(catid: number) {
  this.apiService.getAllSubCatbyId(catid).subscribe((res: any) => {
    this.subcatagories = res['data'];
    
  });
}

searchGdwn(search: string) {
  let searchval = new FormData();
  searchval.append("gd_name", search);
  this.apiService.getGodownByName(searchval).subscribe((res) => {
    this.godown_all = res['data'];
    this.godown_all.push({ "gd_id": 0, 'gd_name': 'SHOP' });
  });

}

searchVans(search: string) {
  const searchval = new FormData();
  searchval.append('van_name', search);
  this.apiService.searchVans(searchval).subscribe((res) => {
    this.vans = res['data'];
  });

}

      //form search
      searchUser(search: string)
      {
       let searchval = new FormData();      
            searchval.append("usr_name", search);    
             this.apiService.getUserByName(searchval).subscribe((res) => {
              this.values = res['data'];                  
             });
      }


      exportexcel(): void 
  {
     /* table id is passed over here */   
   
      let element = document.getElementById('export-group');
     
      
      const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.fileName);
     this.exportLoader = false;
    
  }


  exportEXCL(formdata: { value: any; }){
    this.exportLoader = true;
   
    formdata.value.export = 'export';
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.apiService.vanSalereport(formdata.value,1).subscribe((res) => {
              
      this.expvan_sale=res.data;
      this.expvan_list=res.van_list;
      this.expreportType=res.report_type;
      this.exptotal_sale=res.total_transfer_qty;
      this.exptotal_prdct=res.total_products;
      setTimeout(() => {
        this.exportexcel();
      }, 3000);
      
    });
  }

  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
    });
  }
}
