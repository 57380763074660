import { Component, OnInit } from '@angular/core';
import { CoreService } from 'src/app/service/core.service';

@Component({
  selector: 'app-rep-ext-transfer',
  templateUrl: './rep-ext-transfer.component.html',
  styleUrls: ['./rep-ext-transfer.component.css']
})
export class RepExtTransferComponent implements OnInit {
  up: any;
  constructor(private coreService: CoreService) { }

  ngOnInit() {
    this.up = this.coreService.getUserPrivilage();
  }

}
