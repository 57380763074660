import { Component,ViewChild} from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';
import { Stockedit} from '../../../model/stockedit.model';
import { Unit } from '../../../model/unit.model';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { NgSelectComponent } from '@ng-select/ng-select';


@Component({
  selector: 'app-godown-transfer',
  templateUrl: './godown-transfer.component.html',
  styleUrls: ['./godown-transfer.component.css']
})
export class GodownTransferComponent{
  @ViewChild('selprod', { static: false }) selprod: NgSelectComponent;
  selecTed: any = { prd_id: null,prd_name: null,message:null,rec_unit_rates:null,
  upt_unit_rates:null,unit_rates:null,units_rates:null,error:null,gdstock:null,branch_id:null,
  prd_units:null,manufacture_date:null,expiry_date:null,batch_code:null,cmp_stock_id:null,purchase_rate:null};
 
  stkprd_all : string[];
  rec_unit_rates:any[];
  sel_unit_rates :any[];
  godown_sel:any[]; 
  godown_notsel:any[];
  shppqty:number;
  resultobj: any = {};
  error='';

  godown_all:any[];
  keepFocus:boolean;
  trans_stocks =[];
  unit_all:string[];
  prod_unit_id:any;
  errunit:false;
  errqty:false;
  unit_qty:any;
  prod_unit_name:string;
  prod_id:any;
  prod_name:any;
  branch_stock_id:any;
  gsl_stock_id:any;
  changeBox :boolean;
  resDup:any;
   slno = 0;  
   resultunit:number;
   resultQty:number;
   printError=[];   
  unitsel:any;
  stkprdsel:any;
  printTrans=[];
  fromid:any;
  toid:any;
  pageLoadingImg:boolean;

  errObjArr={unitqtyErr:null,unitErr:null,
    prodErr:null,dtErr:null};
   errObjTran={fromErr:null,toErr:null,
      sameErr:null};   
      godowndate: Date;
  gwnTransNo:any;
  printPreview: any;
  branch_name: any;
  branch_display_name: any;
  branch_display_code: any;
  branch_address: any;
  img_url: any;
  branch_phone: any;
  branch_mob: any;
  branch_tin: any;
  branch_reg_no: any;
  base_url: string;
  img: string;
  gwns:any;
  gwnstranlist: any;
  curpage: any;
  lastpage: any;
  slnum: number;
  pgend: boolean;
  pgstart: boolean;
  up: any;
  godown_transfer_without_stock = 1;
  alias: any;
  prod_alias: any;
  currentTimeString: any;
  constructor(private apiService: ApiService,private coreService: CoreService,private translate: TranslateService) { }
  gd_from: any ;
  gd_to: any ;
  gsl_from: any ;
  gsl_to: any ;
  gt_notes: any ;
  from_qty = 0;  
  to_qty = 0;
  show_from_stock = false;  
  from_stock_loading = false;  
  show_to_stock = false;  
  to_stock_loading = false;  
  prod_base_qty = 0;
  gdFromReadOnly = false;
  gdToReadOnly = false;
  cur_lang: string;
  ngOnInit() { 
    this.cur_lang = sessionStorage.getItem("baseLang");
this.aliasChecked()
  this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
      this.aliasChecked()
    })
    this.getGodown()
    this.getSalesSetting();
   this.errunit = false; 
   $('#addbase-btn').hide();
   this.up = this.coreService.getUserPrivilage();

   this.branch_display_name = this.coreService.getUserData('branch_disp_name');
   this.branch_name= this.coreService.getUserData('branch_name');
   this.branch_display_code= this.coreService.getUserData('branch_code');
   this.branch_address= this.coreService.getUserData('branch_address');
   this.img_url= this.coreService.getUserData('img_url');
   this.branch_phone= this.coreService.getUserData('branch_phone');
   this.branch_mob= this.coreService.getUserData('branch_mob');
   this.branch_tin= this.coreService.getUserData('branch_tin');
   this.branch_reg_no= this.coreService.getUserData('branch_reg_no');
this.base_url = location.origin;


this.getGodownList('');
this.searchGdwn1('');
this.searchGdwn2('');

  }
  aliasChecked(){
    if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
     
      this.alias='alias_checked';
    }else{
      
      this.alias=null;
    }
  }

  getSalesSetting() {
    this.apiService.getSalesSetting().subscribe((res) => {
      if (res['data']) {
        this.godown_transfer_without_stock = res['data'].godown_transfer_without_stock;
      } else {
        this.godown_transfer_without_stock=0;
      }
    });

  }

  ngAfterViewInit() {

    this.getTranList(1);
   
  }

  transferprintPreview(data){
   this.printPreview = [];
    this.printPreview.gsl_from = data.gdtrn_from;
    this.printPreview.gsl_to = data.gdtrn_to;
    this.printPreview.gt_notes = data.gdtrn_notes;
    this.printPreview.godown_ord_number = data.gdtrn_inv_id;
    this.printPreview.tranfer_date = data.gdtrn_date;
    this.printPreview.tranfer_time = data.gdtrn_time;
    this.printPreview.trans_stocks = data.transfers;
    this.printPreview.gsl_to = data.gdtrn_to;

    setTimeout(function () { $("#openModalButton").click(); }, 100);
    // document.getElementById("openModalButton").click();



    // console.log(this.printPreview);

  }

  getTranList(pagno){
   
        
    this.apiService.listGwnTranfer(pagno).subscribe((res) => {
     this.gwnstranlist = res['data'];  
     this.curpage = res['current_page'];
     this.lastpage = res['last_page'];
       this.pgEnd(this.curpage, this.lastpage);
     this.pgStart(this.curpage);
     this.slnum = 0;
          
    });
  }


  getGodownList(search: string)
  {
       let searchval = new FormData();      
        
         this.apiService.getGodownList(searchval).subscribe((res) => {
          this.gwns = res['data'];  
          // console.log(this.gwns);
               
         });
  }
 

     //product form search 
    searchStkPrd(search: string)
     {
          let searchval = new FormData();      
           searchval.append("keyword", search);   
           searchval.append('stk_stat','1'); 
           if(this.alias){
            searchval.append('alias_search', '1');
           }
            this.apiService.getStockbyName(searchval).subscribe((res) => {
             this.stkprd_all = res['data'];  
                  
            });
     }


    //form search select
  selectStockPrd(res)
  {
    if(res){ 
      // this.unitsel = "Select Unit";  
      this.selecTed = res;
      this.sel_unit_rates = this.selecTed.prd_units; 
      this.unitsel = this.selecTed.prd_default_unit_id?this.selecTed.prd_default_unit_id:this.selecTed.prd_base_unit_id;
      this.selectUnit(this.unitsel);
      $('#addbase-btn').show();
      $('#unit_qty').focus();  
      // console.log(res);
      this.getFromGodownStock();
      this.getToGodownStock();
    }
  }

  getFromGodownStock(){
    this.show_from_stock = true;  
    this.from_stock_loading = true;  
    const searchval = new FormData();
    searchval.append('prd_id', this.stkprdsel.prd_id);
    searchval.append('gd_id', this.gsl_from);
    this.apiService.getProductStock(searchval).subscribe((res) => {
      this.from_qty = (res.data) ? (res.data.stock_qty || 0) : 0;
      this.from_stock_loading = false;
    });
  }
  getToGodownStock(){
    this.show_to_stock = true;  
    this.to_stock_loading = true; 
    const searchval = new FormData();
    searchval.append('prd_id', this.stkprdsel.prd_id);
    searchval.append('gd_id', this.gsl_to);
    this.apiService.getProductStock(searchval).subscribe((res) => {
      this.to_qty = (res.data) ? (res.data.stock_qty || 0) : 0;
      this.to_stock_loading = false;
    });
  }

   //form search unit
searchUnit(search: string)
{
 let searchval = new FormData();      
      searchval.append("unit_name", search);    
       this.apiService.getUnitByName(searchval).subscribe((res) => {
        this.unit_all = res['data'];                     
       });
      
}

//form search select
selectUnit(res)
{ 
  if(res){
    this.prod_unit_id = res;  
    // alert(this.sel_unit_rates.find(x => x.sur_unit_id === this.prod_unit_id).unit_name);
    this.prod_unit_name = this.sel_unit_rates.find(x => x.sur_unit_id === this.prod_unit_id).unit_name;//res.unit_name;   
    this.prod_base_qty = this.sel_unit_rates.find(x => x.sur_unit_id === this.prod_unit_id).unit_base_qty;//res.unit_name;   
  } else {
    this.prod_base_qty = 0;
  }
  
}

selectQty(evenVal)
{
  if(evenVal){
    this.errorDisp('qty',false); 
    }else{
    this.errorDisp('qty',true);
    }
}


addToList(res)
  {
  this.unit_qty = $('#unit_qty').val();
  this.prod_id = $('#prod_id').val();
  this.prod_name = $('#prod_name').val(); 
  this.branch_stock_id = $('#branch_stock_id').val();
  this.gsl_stock_id = $('#gsl_stock_id').val();
  this.validateForm();
  if(this.printError.length <= 0)
  {  
    // filter array
    var prod_id_inp = this.prod_id;
    var filterObj = this.trans_stocks.filter(function(e) {     
      return e.prod_id == prod_id_inp;
    });
   
      // check duplicates
    this.resDup = this.coreService.checkDuplicateValueGdUnit(filterObj,this.prod_unit_id,this.prod_id);
    if(typeof this.resDup == 'undefined') { 
      // check godown transfer without stock
      if(!this.godown_transfer_without_stock){
        let avlQty = this.from_qty || 0;
        let addReqQty = (this.prod_base_qty * this.unit_qty);
        var listItemQty = 0;
        filterObj.forEach(element => {
          listItemQty += element.qty_in_base;
        });
        if(avlQty < (addReqQty + listItemQty)){
          this.printError.push({'repeatErr': 'Godown Transfer Without stock Not allowed, Only ' + avlQty + ' Quantity (Base unit) Available'});
          return false;
        }
        this.gdFromReadOnly = true;
        this.gdToReadOnly = true;
        
      }

     this.slno = this.slno+1; 
      this.trans_stocks.push({ "sl_no":this.slno,"prod_id": this.prod_id,"prod_name":this.prod_name,"prd_alias":this.selecTed.prd_alias,"branch_stock_id": this.branch_stock_id,
      "gsl_stock_id": this.gsl_stock_id,"prod_unit_id":this.prod_unit_id,"prod_unit_name": this.prod_unit_name,
      "prod_qty": this.unit_qty,"qty_in_base": this.prod_base_qty * this.unit_qty});  
        this.reFreshAll()  
      $('#gdtran-btn').show(); 
      this.selprod.focus()
    }else {
      this.printError.push({'repeatErr': 'Already exists , Pleae remove from the table'});  
    }
  }
 
  
  }

 // delete unit from table
 deleteUnit(data:any)
  {
var type = data.sl_no,
i;
for (i = this.trans_stocks.length - 1; i >= 0; --i) {
if (this.trans_stocks[i].sl_no == type) {
  this.trans_stocks.splice(i, 1);
}
}
if(this.trans_stocks.length==0)
  $('#gdtran-btn').hide();
  this.gdFromReadOnly = false;
  this.gdToReadOnly = false;
}

  searchGdwn1(search: string)
{
 let searchval = new FormData();      
      searchval.append("gd_name", search);    
       this.apiService.getGodownByName(searchval).subscribe((res) => {
        this.godown_all = res['data'];  
        this.godown_all.push({ "gd_id": 0, 'gd_name': 'Shop' });           
       });

}

searchGdwn2(search: string)
{
 let searchval = new FormData();      
      searchval.append("gd_name", search);    
       this.apiService.getGodownByName(searchval).subscribe((res) => {
        this.godown_all = res['data']; 
        this.godown_all.push({ "gd_id": 0, 'gd_name': 'Shop' });             
       });

}

selectFrom(fromid:number){
  this.fromid = fromid;
  if(this.stkprdsel && this.stkprdsel.prd_id){
    this.getFromGodownStock();
  }
}

selectTo(toid:number){
  this.toid = toid;
  if(this.stkprdsel && this.stkprdsel.prd_id){
    this.getToGodownStock();
  }
}

// add product to tranfer list
addProdGdn(formgroup: { value: any; }) { 
 this.validateTransfer();
  // intial
 formgroup.value.gsl_vchr_type = 0;
 formgroup.value.trans_type = 'G-G'; 
 formgroup.value.trans_stocks = this.trans_stocks;  
 const currentTime = new Date();

 const hours = currentTime.getHours().toString().padStart(2, '0');
 const minutes = currentTime.getMinutes().toString().padStart(2, '0');
 const seconds = currentTime.getSeconds().toString().padStart(2, '0');  
 const currentTimeString = `${hours}:${minutes}:${seconds}`;
 formgroup.value.tranfer_time = currentTimeString;  
//  console.log(currentTimeString);
if(this.printTrans.length<=0)
{

    if(confirm("Are you sure?")){
      this.pageLoadingImg =true;
      this.printPreview = formgroup.value;
      // console.log(this.printPreview);
      formgroup.value.tranfer_date = (formgroup.value.tranfer_date) ?
        this.apiService.formatDate(formgroup.value.tranfer_date) : formgroup.value.tranfer_date;

this.apiService.transferGodown(formgroup.value,).subscribe((res: Stockedit) => {  
  this.pageLoadingImg =false;

  if (res.error != null) {                  
    this.resultobj = res.error;     
    this.resultobj = this.coreService.getValidation(this.resultobj);     
  }
  else {
    this.resultobj={};
    this.coreService.updatefunct(formgroup,this.resultobj,res.message);  
    // document.getElementById("openModalButton").click();
    setTimeout(function () { $("#previewbtn").click(); }, 100);

    this.godown_all = []; 
    this.trans_stocks = [];
    this.stkprd_all = [];    
    $('#gdtran-btn').hide();
    $('#addbase-btn').hide();   
    this.unit_all =[];
    this.fromid = null;
    this.toid = null;
    this.reFreshAll();
    this.getGodown();
    this.slno = 0;
 
  }     

});
    }
}
//end if
}

errorDisp(type,bln)
{
 if(type=='unit') 
 {
  if(bln){
    $('#error_unit').html('Required');
    }else{
     $('#error_unit').html('');
    }
}
else if(type=='unitrepeat'){
  if(bln){
  $('#error_unit').html('Unit already exists in table ,Please remove to edit');
  }
  else{
    $('#error_unit').html('');  
  }

}
else if(type=='gd'){
  if(bln){
  $('#error_godown').html('Godown from and to are same');
  }
  else{
    $('#error_godown').html('');  
  }

}
else if(type=='gdreq'){
  if(bln){
  $('#error_godown').html('Godown from and to required');
  }
  else{
    $('#error_godown').html('');  
  }

}
else
{
  if(bln){
    $('#error_unitqty').html('Required positive value');
    }else{
     $('#error_unitqty').html('');
    }
}
}

changeSel()
{

  if(this.changeBox)
      this.changeBox = false;
      else
      this.changeBox = true;
}


validateForm() 
{  
  this.printError = []; 
  if($('#unit_qty').val() == "" || $('#unit_qty').val() == undefined ) {  
    this.errObjArr.unitqtyErr = "t"; 
    this.printError.push({'fieldErr': 'Required'});  
  }
  else
  {
    this.errObjArr.unitqtyErr = "f";   
  }
  if(this.unitsel == undefined || this.unitsel == null)
  { 
   this.errObjArr.unitErr = "t"; 
   this.printError.push({'fieldErr': 'Required'}); 
  }
  else{
    this.errObjArr.unitErr = "f"; 
  }

  if(this.stkprdsel == undefined || this.stkprdsel == null || this.stkprdsel == 'Select Product')
  {
   this.errObjArr.prodErr = "t"; 
   this.printError.push({'fieldErr': 'Required'});  
  }
  else{
    this.errObjArr.prodErr = "f"; 
  }

}


validateTransfer() 
{
  
  this.printTrans = []; 
  if(this.fromid == null || this.fromid == undefined ) {  
    this.printTrans.push({'tranErrFrom': 'Required'});
    this.errObjTran.fromErr = "t"; 
  }else
  {
    this.errObjTran.fromErr = "f"; 
  }

  if (this.godowndate == undefined) {
    this.errObjArr.dtErr = "t";
    this.printTrans.push({ 'fieldErr': 'Required' });
  }
  else {
    // if (this.godowndate > new Date()) {
    //   this.errObjArr.dtErr = "t";
    //   this.printTrans.push({ 'fieldErr': 'Required' });
    // } else {
      this.errObjArr.dtErr = "f";
    // }
  }

  if(this.toid == null || this.toid == undefined ) {  
    this.printTrans.push({'tranErrTo': 'Required'});
    this.errObjTran.toErr = "t"; 
  }else
  {
    this.errObjTran.toErr = "f"; 
  }


  if(this.toid == this.fromid ) {  
    this.errorDisp('gd',true);
    this.printTrans.push({'transErrSame': 'Same'});
    this.errObjTran.sameErr = "t"; 
  }
    else
    {this.errorDisp('gd',false);
    this.errObjTran.sameErr = "f"; 
  
  }

//  console.log(this.printTrans);
}


getGodown() {
  let searchval = new FormData();
  this.apiService.get_godown_tran_num(searchval).subscribe((res) => {
   
    this.gwnTransNo = res['data']['gd_num'];
    this.godowndate = new Date();

});
}

pgEnd(curr, end) {
  if (curr == end)
    this.pgend = true;
  else
    this.pgend = false;

}

pgStart(curr) {
  if (curr == 1)
    this.pgstart = true;
  else
    this.pgstart = false;
}


reFreshAll()
{

 $('#unit_qty').val('');
 this.stkprdsel = "Select Product";
 this.unitsel = null;
 $('#prod_name').val('');
 $('#prod_alias').val('');
}

}
