import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sales-ret-preprint-temp1',
  templateUrl: './sales-ret-preprint-temp1.component.html',
  styleUrls: ['./sales-ret-preprint-temp1.component.css']
})
export class SalesRetPreprintTemp1Component implements OnInit {

  @Input()salesRetPreview;
  @Input()print_style;
  @Input()qr_inv;
  @Input()savedTemplate;
  @Input()is_prew_description;
  @Input()payTypes;
  @Input()cmpny;
  @Input()previewLoader;
  @Input()logoImgUrl;
  @Input()footImgUrl;
  @Input()tempImgUrl;
  @Input()sales_print_name;
  @Input()hide_item_discount;
  @Input()hide_vat;
  @Input()sales_settings;

  noPage = [];
  noRowPerPage = 16;
  ttlPage = 1;
  data = [];
  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes) {
    if (changes['salesRetPreview'] && this.salesRetPreview) {
      this.data = this.salesRetPreview.items;
      this.ttlPage = Math.ceil((this.salesRetPreview.items.length+4) /this.noRowPerPage);
      this.noPage = this.createRange(this.ttlPage);
      console.log(this.ttlPage)
      console.log(this.noPage)
      console.log(this.data)
    }
  }
  createRange(number,start=0){
    let noPage = [];
    for(var i = start; i < number; i++){
       noPage.push(i);
    }
    return noPage;
  }

  startPrint(){

    $('#printButton').click();
  }

  
}
