import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { Repcatagory } from '../../../../../model/report/repcatagory.model';
import { TranslateService } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-rep-catagory',
  templateUrl: './rep-catagory.component.html',
  styleUrls: ['./rep-catagory.component.css']
})
export class RepCatagoryComponent implements OnInit {
  selecTed: any = { cat_id: null,cat_name: null};
  rep_cats:any[];
  pageLoadingImg: Boolean
  up: any;

  branch_display_name: any;
  branch_display_code: any;
  branch_address:any;

  constructor(private apiService: ApiService,private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }

  ngOnInit() {

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');

    this.pageLoadingImg = true;
    this.readCatRep();
    this.up = this.coreService.getUserPrivilage();
  }

  readCatRep()
 {
  let searchval = new FormData();   
 this.apiService.readCatReport(searchval).subscribe((res) => {
   this.pageLoadingImg =false;
  this.rep_cats = res['data'];
 
});
}

// pdf download
generatePdf() {
  
  const heads = [
    this.translate.instant('HOME.CATAGORY'),
    this.translate.instant('HOME.SUB CATAGORY')
  ];

    const lblXPos = 10;
    const headerHeight = 15;
    const valueXPos = 55;
    const data = [];

    this.rep_cats.forEach(item=>{
      item.sub_cat.forEach((item1,i)=>{
        const arr=[
          item1.subcat_name
        ]

        if(i == 0){
          arr.splice(0,0,  item.cat_name)
        } else {
          arr.splice(0,0,  ' ')
        }

        data.push(arr)
      })
    })

    let doc = this.excelService.getPdfObj();

    var addressLines = this.branch_address.split('\r\n');
    var addressText = addressLines.join(', ');

    doc.setFont('Amiri');
    doc.setFontSize(12);
    doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);

    doc.setFont('Amiri');
    doc.setFontSize(9);
    doc.text(addressText, 71, headerHeight + 2);

    doc.setFontSize(10);
    doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 10);
    doc.setFontSize(10);
    doc.text(':  ' + `${this.translate.instant('HOME.product_categories')}`,  valueXPos, headerHeight + 10);

    
    doc = this.excelService.addTableToPdf(doc, headerHeight + 15, lblXPos, heads, data, null);

   
    doc.save(`Product Categories.pdf`);


  
}

}
