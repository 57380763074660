import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { Repcatagory } from '../../../../../model/report/repcatagory.model';
import { TranslateService } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-rep-man-summary',
  templateUrl: './rep-man-summary.component.html',
  styleUrls: ['./rep-man-summary.component.css']
})
export class RepManSummaryComponent implements OnInit {
  res_data:any[];
  curpage:number;
  lastpage:number;
  pgstart:boolean;
  pgend:boolean;
  pageLoadingImg:boolean;
  up: any;

  branch_display_name: any;
  branch_display_code: any;
  branch_address:any;

  constructor(private apiService: ApiService,private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }

  ngOnInit() { 

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');

    this.pageLoadingImg = true; 
    this.pageNext(1);
    this.up = this.coreService.getUserPrivilage();
  }

  pageNext(pageno)
  {
   let searchval = new FormData();   
  this.apiService.ManSumPage(searchval,pageno).subscribe((res) => {
    this.pageLoadingImg =false;
   this.res_data = res['data']['data'];
   this.curpage = res['data']['current_page'];  
   this.lastpage= res['data']['last_page'];
 
   this.pgEnd(this.curpage,this.lastpage); 
   this.pgStart(this.curpage);
 });
 }

 pgEnd(curr,end)
{
if(curr==end)
this.pgend = true;
else
this.pgend = false;

}

pgStart(curr)
{
if(curr==1)
this.pgstart = true;
else
this.pgstart = false;
}


// pdf download
generatePdf() {
  
  const heads = [
    this.translate.instant('HOME.NAME'),
    this.translate.instant('Table.code'),
    this.translate.instant('Table.Website'),
    this.translate.instant('Table.product_count')
  ];

    const lblXPos = 10;
    const headerHeight = 15;
    const valueXPos = 55;
    const data = [];

    this.res_data.forEach(item=>{
      data.push([
        item.manftr_comp_name,
        item.manftr_comp_code,
        item.manftr_comp_website,
        this.currencyPipe.transform(item.product_count, '', '', '1.1-1')
      ])
    })

    let doc = this.excelService.getPdfObj();

    var addressLines = this.branch_address.split('\r\n');
    var addressText = addressLines.join(', ');

    doc.setFont('Amiri');
    doc.setFontSize(12);
    doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);

    doc.setFont('Amiri');
    doc.setFontSize(9);
    doc.text(addressText, 71, headerHeight + 2);

    doc.setFontSize(10);
    doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 10);
    doc.setFontSize(10);
    doc.text(':  ' + `${this.translate.instant('HOME.manufature_summary')}`,  valueXPos, headerHeight + 10);

    
    doc = this.excelService.addTableToPdf(doc, headerHeight + 15, lblXPos, heads, data, null);

   
    doc.save(`Manufacture Summary.pdf`);


  
}

}
