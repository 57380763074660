import { Component, OnInit } from '@angular/core';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import * as XLSX from 'xlsx'; 
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-van-itemwise-rep',
  templateUrl: './van-itemwise-rep.component.html',
  styleUrls: ['./van-itemwise-rep.component.css']
})
export class VanItemwiseRepComponent implements OnInit {
  fileName= 'van_itemWise_report.xlsx';
  vans: any;
  periodTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'tm', name: 'This Month' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];
  pageLoadingImg: boolean;
  curpage: number;
  lastpage: number;
  pgend: boolean;
  pgstart: boolean;
  prds: any[];
  selecTed: any=null;
  prd_id: any;
  period_type: string;
  itemwise: any;
  repDate: any;
  exportLoader: boolean;
  branch_display_name: any;
  branch_display_code: any;
  branch_address:any;
  cur_lang: any;
  PrdNAme: any;
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe,
    private datePipe: DatePipe) { }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.cur_lang = sessionStorage.getItem("baseLang");
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    this.searchVans('');
    this.getVanProducts('');
    this.period_type = 't';
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');
  }
  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all',]).subscribe((res: string) => {    
     
     
      this.periodTypes = [
        
        { id: 't', name: res['Common.today'] },
        { id: 'ld', name: res['Common.last_day'] },
        { id: 'lw', name: res['Common.last_week'] },
        { id: 'lm', name: res['Common.last_maonth'] },
        { id: 'ly', name: res['Common.last_yr'] },
        { id: 'c', name: res['Common.cust_date'] },
        { id: '', name: res['Common.all'] }
            
          ];
    });

  


  }
  selectPrdct(res){
    this.pageLoadingImg = true;
    this.prd_id=res.prd_id;
    this.selecTed = null
    const searchval = new FormData();
    
    searchval.append('prd_id', res.prd_id);
    searchval.append('period_type', 't');
    

    this.apiService.vanItemtWiseTracking(searchval,1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.itemwise = res.data;
      this.repDate=res.Date;
    
 
 
        this.pgEnd(this.curpage, this.lastpage);
        this.pgStart(this.curpage);
        
 
     
     });
  }


  pageNext(formdata: { value: any; }){

    this.pageLoadingImg = true;
    formdata.value.prd_id=this.prd_id;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.apiService.vanItemtWiseTracking(formdata.value,1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.itemwise = res.data;
      this.repDate=res.Date;
 
    
 
 
        this.pgEnd(this.curpage, this.lastpage);
        this.pgStart(this.curpage);
        
 
     
     });
  }

  pgEnd(curr, end) {
    if (curr == end) {
      this.pgend = true;
    } else {
      this.pgend = false;
    }

  }

  pgStart(curr) {
    if (curr == 1) {
      this.pgstart = true;
    } else {
      this.pgstart = false;
    }
  }


  getVanProducts(key:any) {   
   
    let searchval = new FormData();
  if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      searchval.append('alias_search', '1');
    }
      searchval.append("search", key);
    
    this.apiService.getVanAllProducts(searchval).subscribe((res) => {
      this.prds = res['data'];
      this.selecTed = null;
    });

  }

  searchVans(search: string) {
    const searchval = new FormData();
    searchval.append('van_name', search);
    this.apiService.searchVans(searchval).subscribe((res) => {
      this.vans = res['data'];
    });

  }


  exportexcel(): void 
  {
    this.exportLoader = true;
     /* table id is passed over here */   
     let element = document.getElementById('export-group'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.fileName);
     this.exportLoader = false;
    
  }


  // pdf download
  generatePdf() {
  
    const heads = [
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      ''
    ];

      const lblXPos = 10;
      const headerHeight = 15;
      const valueXPos = 55;
      const data = [];

      this.itemwise.forEach(item=>{
        if(this.translate.currentLang == "Arabic"){this.PrdNAme =  item.prd_alias}else{this.PrdNAme =  item.prd_name}

        data.push([
          this.PrdNAme,
          item.van_name,
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          ''
        ])

        data.push([
          this.translate.instant('Table.date'),
          this.translate.instant('HOME.UNIT'),
          this.translate.instant('HOME.opening'),
          this.translate.instant('Van.Van_Transfer'),
          this.translate.instant('Van.Van_Return'),
          this.translate.instant('HOME.SALES'),
          this.translate.instant('HOME.sales_return'),
          this.translate.instant('HOME.excess'),
          this.translate.instant('HOME.missing'),
          this.translate.instant('HOME.available_stock')
        ])

        item.dates.forEach(item1=>{
          data.push([
            this.datePipe.transform(item1.date, 'dd/MM/yyyy'),
            item.unit,
            item1.opening,
            item1.tranfs,
            item1.tranfsRet,
            item1.sales,
            item1.salesRet,
            item1.excess,
            item1.missing,
            item1.avialble
          ])
        })

        data.push([
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          this.translate.instant('Purchase.Total_Qty'),
          item.ttl_avlble
        ])
      })
  
      let doc = this.excelService.getPdfObj();

      var addressLines = this.branch_address.split('\r\n');
      var addressText = addressLines.join(', ');

      doc.setFont('Amiri');
      doc.setFontSize(12);
      doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);

      doc.setFont('Amiri');
      doc.setFontSize(9);
      doc.text(addressText, 71, headerHeight + 2);

      if(this.repDate){
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
        doc.setFontSize(10);
        doc.text(':  ' + this.repDate.date1 + '   to   ' + this.repDate.date2  ,  valueXPos, headerHeight + 10);
      }

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
      doc.setFontSize(10);
      doc.text(':  ' + `${this.translate.instant('HOME.van_itemwise_trackng')}`,  valueXPos, headerHeight + 15);

      
      doc = this.excelService.addTableToPdf(doc, headerHeight + 20, lblXPos, heads, data, null);
  
     
      doc.save(`Production Summary.pdf`);


    
  }


}
