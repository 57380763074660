import { Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/service/api.service';
import { ExcelService } from '../../../../../service/excel.service';
import { CoreService } from 'src/app/service/core.service';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-van-collection-rep',
  templateUrl: './van-collection-rep.component.html',
  styleUrls: ['./van-collection-rep.component.css']
})
export class VanCollectionRepComponent implements OnInit {
  VanList: any;
  branch_display_name: any;
  branch_name: any;
  country_dec: number=2;
  pageLoadingImg: boolean;
  exportLoader: boolean;
  branch_display_code: any;
  branch_address:any;
  fileName = 'van_collection.xlsx';
  filter_types = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'tm', name: 'This Month' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];
  filter_paytype = [
    { id: 0, name: 'Cash' },
    { id: 2, name: 'Bank' },
    { id: 3, name: 'Multi' }

  ];
  repData: any;
  pageFrom: any;
  curpage: any;
  lastpage: any;
  pgend: boolean;
  pgstart: boolean;
  van_cash_ledger: any;
  van_id: any;
  ExelrepData: any;
  userType = '';
  repDate: any;
  vanName: any;
  customer: any;
  ExelrepDate: any;
  total_amount: any;
  total_cash: any;
  total_bank: any;
  constructor(private apiService: ApiService,private translate:TranslateService,private excelService: ExcelService,private coreService: CoreService,private datePipe: DatePipe) {  }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
        });

        this.branch_display_name = this.coreService.getUserData('branch_disp_name');
        this.branch_name= this.coreService.getUserData('branch_name');
        this.branch_display_code= this.coreService.getUserData('branch_code');
        this.branch_address = this.coreService.getUserData('branch_address');
        this.userType = this.coreService.getUserData('user_type');
        this.country_dec=this.coreService.setDecimalLength();


        


  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','Common.this_month']).subscribe((res: string) => {    
     
      
      this.filter_types = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'tm', name: res['Common.this_month'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
    });
  }

  searchVans(search: string) {
    const searchval = new FormData();
    searchval.append('van_name', search);
    this.apiService.searchVans(searchval).subscribe((res) => {
      this.VanList = res['data'];
    });
    $('.resetfitr').click();
  }

  selectVan(van) {
    if (van) {
       this.pageLoadingImg = true;
      this.van_cash_ledger=van.van_cash_ledger_id;
       this.van_id=van.van_id;
      let searchval = new FormData();
       searchval.append('van_ledger_id', van.van_cash_ledger_id);
      searchval.append('van_id', van.van_id);
      this.apiService.vanCollectionRep(searchval,1).subscribe((res:any) => {
        this.repDate = res.Date;
        this.repData = res.data.data;
        this.vanName=res.data.vanName;
        console.log('this.vanName');
        console.log(this.vanName);
        
        this.total_amount=res.data.totalVchOut;
        this.total_cash=res.data.totalVchOutForcash;
        this.total_bank=res.data.totalVchOutForbank;
         this.pageFrom = res.data.from;
         this.curpage = res.data.current_page;
         this.lastpage = res.data.last_page;
         this.pgEnd(this.curpage, this.lastpage);
         this.pgStart(this.curpage)
         this.pageLoadingImg = false;
  
      });

    }
  }

  searchCustomer(search: string) {
    const searchval = new FormData();
    searchval.append('cust_name', search);
    this.apiService.searchCustomer(searchval).subscribe((res) => {
      this.customer = res['data'];
    });

  }

  
  filterSearch(formdata: { value: any; },van){
     this.pageLoadingImg = true;
      formdata.value.van_id=this.van_id;
      formdata.value.van_ledger_id=  this.van_cash_ledger;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.apiService.vanCollectionRep(formdata.value,1).subscribe((res:any) => {
      this.repData = res.data.data;
      this.repDate = res.Date;
      this.vanName=res.data.vanName
      this.total_amount=res.data.totalVchOut;
      this.total_cash=res.data.totalVchOutForcash;
      this.total_bank=res.data.totalVchOutForbank;
      this.pageFrom = res.data.from;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.pageLoadingImg = false;
    });
  }


  pgEnd(curr, end) {
    if (curr == end) {
      this.pgend = true;
    }
    else {
      this.pgend = false;
    }

  }

  pgStart(curr) {
    if (curr == 1) {
      this.pgstart = true;
    }
    else {
      this.pgstart = false;
    }
  }



  pageNext(formdata: { value: any; },van, pageno: any) {
    this.pageLoadingImg = true;
    formdata.value.van_id=this.van_id;
    formdata.value.van_ledger_id=this.van_cash_ledger;
    this.apiService.vanCollectionRep(formdata.value,pageno).subscribe((res:any) => {
      this.repData = res.data.data;
      this.repDate = res.Date;
      this.vanName=res.data.vanName
      this.total_amount=res.data.totalVchOut;
      this.total_cash=res.data.totalVchOutForcash;
      this.total_bank=res.data.totalVchOutForbank;
      console.log(' this.repData');
      console.log( this.repData);
      this.pageFrom = res.data.from;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.pageLoadingImg = false;
    });

  }

  export(formdata: { value: any;}) {
     this.exportLoader = true;
    
    formdata.value.export= 'export';
    formdata.value.van_id=this.van_id;
    formdata.value.van_ledger_id=this.van_cash_ledger;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.apiService.vanCollectionRep(formdata.value,1).subscribe((res:any) => {
      formdata.value.export='';
      this.ExelrepData=res.data.data;
      this.ExelrepDate = res.data.Date;
      this.vanName=res.data.vanName
      this.total_amount=res.data.totalVchOut;
      this.total_cash=res.data.totalVchOutForcash;
      this.total_bank=res.data.totalVchOutForbank;

      setTimeout(() => {
        this.exportexcel();
      }, 3000);
    });
  }

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
     this.exportLoader = false;

  }

  generatePdf(formdata: { value: any; }) {
    this.exportLoader = true;
    formdata.value.export= 'export';
    formdata.value.van_id=this.van_id;
    formdata.value.van_ledger_id=this.van_cash_ledger;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.apiService.vanCollectionRep(formdata.value,1).subscribe((res:any) => {
      formdata.value.export='';
      this.ExelrepData=res.data.data;
      this.ExelrepDate = res.Date;
      this.vanName=res.data.vanName
      this.total_amount=res.data.totalVchOut;
      this.total_cash=res.data.totalVchOutForcash;
      this.total_bank=res.data.totalVchOutForbank;
      console.log(' this.ExelrepData');
      console.log( this.ExelrepData);
      
      // this.pageLoadingImg = false;



      const heads = [
        this.translate.instant('Table.sl_no'),
        this.translate.instant('Table.date'),
        this.translate.instant('Common.ref_no'),
        this.translate.instant('Common.branch_ref_no'),
        this.translate.instant('Common.Vocher_type'),
        this.translate.instant('Common.Pay_Type'),
        this.translate.instant('Common.received_account'),
        this.translate.instant('Common.customer_name'),
        this.translate.instant('Table.amnt'),
        this.translate.instant('Common.notes')
      ];

      const lblXPos = 10;
      const headerHeight = 15;
      const valueXPos = 55;
      const data = [];

      this.ExelrepData.forEach((item,i)=>{
        data.push([
          i+1,                 
          this.datePipe.transform(item.date, 'dd/MM/yyyy'),
          item.ref_no,
          item.branch_ref_no,
          item.vchtype_name,
          item.pay_type,
          (this.translate.currentLang == 'Arabic') ? item.ledger_name_arabic : item.ledger_name,
          (this.translate.currentLang == 'Arabic') ? item.customer_name_ar : item.customer_name,
          (item.vch_out).toFixed(this.country_dec),
          item.notes
        ])
      })

      let doc = this.excelService.getPdfObj();
  
      var addressLines = this.branch_address.split('\r\n');
      var addressText = addressLines.join(', ');

      doc.setFont('Amiri');
      doc.setFontSize(12);
      doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);

      doc.setFont('Amiri');
      doc.setFontSize(9);
      doc.text(addressText, 71, headerHeight + 2);

      if(this.vanName){
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Van.name')}`, lblXPos, headerHeight + 10);
        doc.setFontSize(10);
        doc.text(':  ' + this.vanName,  valueXPos, headerHeight + 10);
        }
  

      if(this.ExelrepDate){
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 15);
        doc.setFontSize(10);
        doc.text(':  ' + this.ExelrepDate.date1 + '   to   ' + this.ExelrepDate.date2  ,  valueXPos, headerHeight + 15);
      }
      if(this.total_bank){
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.ttl_bank_amount')}`, lblXPos, headerHeight + 20);
        doc.setFontSize(10);
        doc.text(':  ' + (this.total_bank).toFixed(this.country_dec),  valueXPos, headerHeight + 20);
      }
      if(this.total_cash){
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.ttl_cash_amount')}`, lblXPos, headerHeight + 25);
        doc.setFontSize(10);
        doc.text(':  ' + (this.total_cash).toFixed(this.country_dec),  valueXPos, headerHeight + 25);
      }
      if(this.total_amount){
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Table.total_amount')}`, lblXPos, headerHeight + 30);
        doc.setFontSize(10);
        doc.text(':  ' + (this.total_amount).toFixed(this.country_dec),  valueXPos, headerHeight + 30);
      }
 

      doc = this.excelService.addTableToPdf(doc, headerHeight + 35, lblXPos, heads, data, null);
      doc.save(`van collection Report.pdf`);
  
       this.exportLoader = false;


    });


  }
  print(formdata: { value: any; }){

    this.exportLoader = true;
    
    formdata.value.export= 'export';
    formdata.value.van_id=this.van_id;
    formdata.value.van_ledger_id=this.van_cash_ledger;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.apiService.vanCollectionRep(formdata.value,1).subscribe((res:any) => {
      formdata.value.export='';
      this.ExelrepData=res.data.data;
      this.ExelrepDate = res.data.Date;
      this.vanName=res.data.vanName
      this.total_amount=res.data.totalVchOut;
      this.total_cash=res.data.totalVchOutForcash;
      this.total_bank=res.data.totalVchOutForbank;

      setTimeout(() => {
        $('#btn1').click();
        this.exportLoader = false;    
      }, 2000);
    });





  }





}
