import { Component, OnInit } from '@angular/core';

import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { Manufacture } from '../../../../../model/manufacture.model';
import { TranslateService } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-manf-details',
  templateUrl: './manf-details.component.html',
  styleUrls: ['./manf-details.component.css']
})
export class ManfDetailsComponent implements OnInit {

  manftr_comp_name: any;
  manftr_comp_code: any ;
  manftr_comp_address: any ;
  manftr_comp_phone: any ;
  manftr_comp_mobile: any ;
  manftr_comp_fax: any ;
  manftr_comp_website: any ;
  manftr_comp_contact_person: any ;
  created_at: any ;
  manftr_comp_notes: any ;
  product: any ;
  manufctr: any ;
  elseBlocknoouterwrap: any ;
  selecTed: any = {
    id: null,
    error: null,
    message: null,
    manftr_comp_flags: null,

  };
  manufDet: [];
  products: any[];
  up: any;

  branch_display_name: any;
  branch_display_code: any;
  branch_address:any;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe,private datePipe: DatePipe) { }

  ngOnInit() {

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');

    this.up = this.coreService.getUserPrivilage();
    $("#report-wrapper").hide();
  }

  //Manufacture search 
  searchMAnuf(search: string) {

   
    $("#report-wrapper").hide();
    let searchval = new FormData();
    searchval.append("keyword", search);
    this.apiService.getManfbyName(searchval).subscribe((res) => {
      this.manufDet = res['data'];
      this.selecTed = null;

    });
  }

  //form search select
  selectStockPrd(mid: string) {
   
    if (mid) {
      $("#report-wrapper").show();
      let searchval = new FormData();
      searchval.append('mnfct_id', mid);

      this.apiService.manfDetailReport(searchval).subscribe((res) => {
        this.selecTed = res['data'];
        this.products = res['data']['product'];
    
      });


    }
  }


// pdf download
generatePdf() {
  
  const heads = [
    this.translate.instant('Common.prd_name')
  ];

    const lblXPos = 10;
    const headerHeight = 15;
    const valueXPos = 55;
    const data = [];

    this.products.forEach(item=>{
      item.category.forEach(item1=>{
        data.push([
          item1.name
        ])
      })

      item.items.forEach(item2=>{
        data.push([
          item2.prd_name
        ])
      })
    })

    let doc = this.excelService.getPdfObj();

    var addressLines = this.branch_address.split('\r\n');
    var addressText = addressLines.join(', ');

    doc.setFont('Amiri');
    doc.setFontSize(12);
    doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);

    doc.setFont('Amiri');
    doc.setFontSize(9);
    doc.text(addressText, 71, headerHeight + 2);

    doc.setFontSize(10);
    doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 10);
    doc.setFontSize(10);
    doc.text(':  ' + `${this.translate.instant('HOME.manufacture_details')}`,  valueXPos, headerHeight + 10);

    if(this.selecTed.manftr_comp_name){
      doc.setFontSize(10);
      doc.text(`${this.translate.instant('HOME.company_name')}`, lblXPos, headerHeight + 15);
      doc.setFontSize(10);
      doc.text(':  ' + this.selecTed.manftr_comp_name,  valueXPos, headerHeight + 15);
    } else {
      doc.setFontSize(10);
      doc.text(`${this.translate.instant('HOME.company_name')}`, lblXPos, headerHeight + 15);
      doc.setFontSize(10);
      doc.text(':  ' ,  valueXPos, headerHeight + 15);
    }

    if(this.selecTed.manftr_comp_code){
      doc.setFontSize(10);
      doc.text(`${this.translate.instant('HOME.company_code')}`, lblXPos, headerHeight + 20);
      doc.setFontSize(10);
      doc.text(':  ' + this.selecTed.manftr_comp_code,  valueXPos, headerHeight + 20);
    } else {
      doc.setFontSize(10);
      doc.text(`${this.translate.instant('HOME.company_code')}`, lblXPos, headerHeight + 20);
      doc.setFontSize(10);
      doc.text(':  ',  valueXPos, headerHeight + 20);
    }

    if(this.selecTed.manftr_comp_address){
      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Table.address')}`, lblXPos, headerHeight + 25);
      doc.setFontSize(10);
      doc.text(':  ' + this.selecTed.manftr_comp_address,  valueXPos, headerHeight + 25);
    } else {
      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Table.address')}`, lblXPos, headerHeight + 25);
      doc.setFontSize(10);
      doc.text(':  ',  valueXPos, headerHeight + 25);
    }

    if(this.selecTed.manftr_comp_phone){
      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Van.phone')}`, lblXPos, headerHeight + 30);
      doc.setFontSize(10);
      doc.text(':  ' + this.selecTed.manftr_comp_phone,  valueXPos, headerHeight + 30);
    } else {
      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Van.phone')}`, lblXPos, headerHeight + 30);
      doc.setFontSize(10);
      doc.text(':  ',  valueXPos, headerHeight + 30);
    }

    if(this.selecTed.manftr_comp_mobile){
      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Table.mob')}`, lblXPos, headerHeight + 35);
      doc.setFontSize(10);
      doc.text(':  ' + this.selecTed.manftr_comp_mobile,  valueXPos, headerHeight + 35);
    } else {
      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Table.mob')}`, lblXPos, headerHeight + 35);
      doc.setFontSize(10);
      doc.text(':  ' ,  valueXPos, headerHeight + 35);
    }

    if(this.selecTed.manftr_comp_fax){
      doc.setFontSize(10);
      doc.text(`${this.translate.instant('HOME.fax')}`, lblXPos, headerHeight + 40);
      doc.setFontSize(10);
      doc.text(':  ' + this.selecTed.manftr_comp_fax,  valueXPos, headerHeight + 40);
    } else {
      doc.setFontSize(10);
      doc.text(`${this.translate.instant('HOME.fax')}`, lblXPos, headerHeight + 40);
      doc.setFontSize(10);
      doc.text(':  ' ,  valueXPos, headerHeight + 40);
    }

    if(this.selecTed.manftr_comp_website){
      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Table.Website')}`, lblXPos, headerHeight + 45);
      doc.setFontSize(10);
      doc.text(':  ' + this.selecTed.manftr_comp_website,  valueXPos, headerHeight + 45);
    } else {
      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Table.Website')}`, lblXPos, headerHeight + 45);
      doc.setFontSize(10);
      doc.text(':  ' ,  valueXPos, headerHeight + 45);
    }

    if(this.selecTed.manftr_comp_contact_person){
      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Table.contact_person')}`, lblXPos, headerHeight + 50);
      doc.setFontSize(10);
      doc.text(':  ' + this.selecTed.manftr_comp_contact_person,  valueXPos, headerHeight + 50);
    } else {
      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Table.contact_person')}`, lblXPos, headerHeight + 50);
      doc.setFontSize(10);
      doc.text(':  ',  valueXPos, headerHeight + 50);
    }

    doc.setFontSize(10);
    doc.text(`${this.translate.instant('Common.date_added')}`, lblXPos, headerHeight + 55);
    doc.setFontSize(10);
    doc.text(':  ' + this.datePipe.transform(this.selecTed.created_at, 'dd/MM/yyyy'),  valueXPos, headerHeight + 55);

    if(this.selecTed.manftr_comp_notes){
      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Common.notes')}`, lblXPos, headerHeight + 60);
      doc.setFontSize(10);
      doc.text(':  ' + this.selecTed.manftr_comp_notes,  valueXPos, headerHeight + 60);
    } else {
      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Common.notes')}`, lblXPos, headerHeight + 60);
      doc.setFontSize(10);
      doc.text(':  ',  valueXPos, headerHeight + 60);
    }

    doc.setFontSize(14);
    doc.text(`${this.translate.instant('Table.items')}`, lblXPos, headerHeight + 68);
    
    doc = this.excelService.addTableToPdf(doc, headerHeight + 70, lblXPos, heads, data, null);

   
    doc.save(`Manufacture Details.pdf`);


  
}



}
