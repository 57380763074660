import { Component, OnInit, Input } from '@angular/core';
import { ViewportScroller } from "@angular/common";
import { ApiService } from '../../../../service/api.service';
import { CoreService } from '../../../../service/core.service';
import { Stockedit } from '../../../../model/stockedit.model';
import { Branch } from '../../../../model/branch.model';

@Component({
  selector: 'app-stock-rates',
  templateUrl: './stock-rates.component.html',
  styleUrls: ['./stock-rates.component.css']
})
export class StockRatesComponent implements OnInit {
  @Input() name: string;
  selecTed: any = {
    prd_id: null, prd_name: null, message: null, rec_unit_rates: null,
    upt_unit_rates: null, unit_rates: null, units_rates: null, error: null, gdstock: null, branch_id: null, prd_units: null,
    manufacture_date: null, expiry_date: null, batch_code: null, cmp_stock_id: null, purchase_rate: null
  };



  stkprd_all: string[];
  rec_unit_rates: any[];
  branches: [];
  allbranches: any[];
  branchids: number[];
  data_unit_rates: any[]
  counter: string;
  // resultobj: any {};
  resultobj: any = {};
  error = '';
  stkprdsel: any;
  pageLoadingImg: boolean;
  branch_units: any;
  skIp: any;
  taKe: any;
  toTal: any;
  counT: any;
  selBranch=[];
  branch_name: any;
  up: any;
  prd_tax:any;
  chk_all:any;
  cmpny:any;
  is_rate_2: boolean;
  barcode:any;
  sel_urates:any;
  usr_hide_purch_cost: any;
  country_dec: string;
  display_qty: JQuery<HTMLElement>;
  batches = [];
  prod_alias: any;
  cur_lang: string;
  Add_Barcodes: any;
  unit_id: any;
  additional_barcodes: any;
  generalSearch_type: any;
  g_settings: any;
  gdstocksdata: any;
  ErrCL:any;

  constructor(private apiService: ApiService, private coreService: CoreService,private scroller: ViewportScroller) { }

  ngOnInit() {
    this.getGeneralSetting();
    this.cur_lang = sessionStorage.getItem("baseLang");
    this.aliasChecked()
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
      this.aliasChecked()
      
      
    })

    this.is_rate_2 = false;
    this.cmpny = this.coreService.getUserData('cmpny');
    this.getAllBranches();
    this.up = this.coreService.getUserPrivilage();
   this.usr_hide_purch_cost = this.coreService.getUserData('usr_hide_purch_cost');
   this.country_dec=this.coreService.setDecimalLength();

  }

  aliasChecked(){
    if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      this.prod_alias = 'alias_true';
      
    }else{
      this.prod_alias =null;
      
    }
  }

  //form search
  searchStkPrd(search: string) {
    let searchval = new FormData();
    searchval.append("keyword", search);
    if(this.prod_alias){
      searchval.append('alias_search', '1');
    }
    this.apiService.getQtybyNameNew(searchval).subscribe((res) => {
      this.stkprd_all = res['data'];
      // this.batches = this.stkprd_all.prd_batch;

      //console.log(res['data']);


    });
  }

  searchStkPrdBarcode(search: string) {
    if(!search){
      $("#br_err").text("Enter Barcode");
      return false;
    }else{
      $("#br_err").text("");
    }
    let searchval = new FormData();
    searchval.append("barcode", search);
    this.apiService.getQtybyBarcodeNew(searchval).subscribe((res) => {
      
      this.stkprd_all = [];
      var units_notsel = [];
      var sel_unit_rates = [];
      this.branch_units = [];
      this.sel_urates = [];
      if (typeof res['data'].prd_id !== 'undefined') {
        this.getBranches(res['data'].prd_id);
        this.getBatches(res['data'].prd_id);
        this.selecTed = res['data'];
        console.log("false");
        this.sel_urates = res['data'].units_rates;
        sel_unit_rates = this.selecTed.units_rates;  
        this.Add_Barcodes = this.selecTed.additional_barcodes;
        this.branchids = this.selecTed.branch_id;
        this.prd_tax = this.selecTed.prd_tax;
        $('#chk_all').prop('checked',false);
        $('#error_in').html('');
        $('#editstockform').show();
       

      }else{
        $("#br_err").text("Barcode not found");
        $('#editstockform').hide();
      }
    });
  }

  //form search select
  selectStockPrd(res: Stockedit) { 
      //  this.availablegdStock(res.prd_id)
    this.getBranches(res.prd_id);
    this.getBatches(res.prd_id);
    this.stkprd_all = [];
    var units_notsel = [];
    var sel_unit_rates = [];
    this.branch_units = [];
    if (res) {
      this.selecTed = res;
      this.sel_urates = this.selecTed.units_rates;
      this.Add_Barcodes = this.selecTed.additional_barcodes;
      

      //console.log(this.sel_urates);
      sel_unit_rates = this.selecTed.units_rates;         
      this.branchids = this.selecTed.branch_id;
      this.prd_tax = this.selecTed.prd_tax;
      this.display_qty=this.selecTed.bs_rate2_display_qty;
      $('#chk_all').prop('checked',false);
      $('#error_in').html('');
      $('#editstockform').show();
      

    }
  }
  availablegdStock(prdid){
    let searchval = new FormData();
    searchval.append("prd_id", prdid);
    this.apiService.getGodownWiseStock(searchval).subscribe((res) => {
      this.gdstocksdata= res['data']['data'];
      
    });
  }


  Additionalbarcodes(res){

    if(res){
      this.unit_id = res;  
      const filteredBarcodes = this.Add_Barcodes.filter(item => item.brc_unit_id === this.unit_id);
      this.additional_barcodes = filteredBarcodes.map(item => item.brc_ean_barcode);
    } 
    

  }

  updateStock(form: { value: Stockedit; }) {
    this.pageLoadingImg = true;
    var upt_unit_rates = [];
    var upt_all_unit_rates = [];
    var units_rates = [];
    var units_eans = [];

    let errObjArr = {};
    let validaterr=false;
    var errorobj = false;
    var confrm=true;
    
    $('input[name="unitratechk"]:checked').each(function () {


      upt_unit_rates.push({ "unit_id": $(this).val() });
      upt_all_unit_rates.push({ "unit_id": $(this).val() });
    });
  

    $('input[name="unitratechk"]:not(:checked)').each(function () {
      upt_all_unit_rates.push({ "unit_id": $(this).val() });
    });

    $('input.edit_unit_rate').each(function (index1) {
      var attr_id = this.getAttribute("id");
      var unit_rt = $(this).val();
      var unit_rt2 = $('input.edit_unit_rate2').eq(index1).val();
      var unit_base_qty11 = $('input.unit_base_qty11').eq(index1).val();
      var unit_type11 = $('input.unit_type11').eq(index1).val();
      var unit_ean = $('input.edit_unit_ean').eq(index1).val();

      
      
    // price validation on update rate
      if((Number(unit_rt))<(form.value.purchase_rate*Number(unit_base_qty11))){
     
       if(upt_unit_rates.some(unit => unit.unit_id === attr_id)){
        errObjArr[index1] = 'Sales Rate is Less Than Purch.Rate'; 
        validaterr=true;
       }     
        
      }

      $.each(upt_unit_rates, function (index, value) {

        if (value.unit_id == attr_id) {
          if (Number(unit_rt) <= 0) {
            errorobj = true;
          }
          units_rates.push({ "unit_id": attr_id, 'unit_rate': unit_rt, 'unit_rate2': unit_rt2, 'unit_ean': unit_ean});
        }
      });

      $.each(upt_all_unit_rates, function (index, value) {

        if (value.unit_id == attr_id) {
          units_eans.push({ "unit_id": attr_id, 'unit_ean': unit_ean});
        }
      });

    });
    

  this.ErrCL=errObjArr;



// return
    //form.value.stkprdsel=undefined;
    form.value.unit_rates = units_rates;
    form.value.units_eans = units_eans;
    if (errorobj) {
      $('#error_in').html('Please enter  value greater than zero');
      this.pageLoadingImg = false;
    }
    else {
      $('#error_in').html('');
      this.pageLoadingImg = false;
    }
    if(validaterr){
       confrm = confirm("Rate is Less Than Purch.Rate");
    }
  
    if (!errorobj && confrm) {
      this.pageLoadingImg=true;
      this.apiService.updateStock(form.value).subscribe((res: Stockedit) => {
        this.pageLoadingImg = false;
        if (res.error != null) {
          this.resultobj = res.error;
          if (typeof res.sh_rr !== 'undefined') {
            $('#error_in').html(res.error);
            document.getElementById("edit-group").scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "nearest"
            });
          }else{
            this.resultobj = this.coreService.getValidation(this.resultobj);
          }
        }
        else {
          this.barcode='';
          $("#br_err").text("");
          this.selecTed.bs_stock_quantity_shop='';
          this.selecTed.bs_stock_quantity_gd='';
          this.resultobj = {};
          this.coreService.createfunct(form, this.resultobj, 'Updated');
          this.stkprd_all = [];
          $('#error_in').html('');
          $('#editstockform').hide();
          this.stkprdsel = "Select Product";
          document.getElementById("topmain_div").scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
          });
        }

      });
    }
  }

  getBranches(prd_id) {
    let searchval = new FormData();
    searchval.append("prd_id", prd_id);
    this.apiService.getBranchesWithUnits(searchval).subscribe((branch: Branch) => {
      this.branches = branch['data'];
    });
  }


  getBatches(prd_id) {
    let searchval = new FormData();
    searchval.append("prd_id", prd_id);
    this.apiService.getBatches(searchval).subscribe((batch: Branch) => {
      this.batches = batch['data'];
    });
  }

  onChange(event) {
    this.counter = "Number Only";
  }

  isNumberKey(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }


  isNumberKey1(e) {
    //if the letter is not digit then display error and don't type anything
    if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
      //display error message
      $("#errmsg").html("Digits Only").show().fadeOut("slow");
      return false;
    }
  }


  updateQty(formdata) {
    // this.pageLoadingImg = true;    
    this.apiService.updateQty(formdata.value).subscribe((res: any) => {
      this.pageLoadingImg = false;

      if (res.error != null) {
        console.log("error");
      }
      else {
        this.coreService.createfunct(formdata, "nil", "Updated");
      }

    });
  }


  selectBatch(batchId, prd_id) {
   // console.log(batchId);
    let inpVal = new FormData();
    inpVal.append("batchId", batchId);
    inpVal.append("prd_id", prd_id);
    this.apiService.batchUnitRate(inpVal).subscribe((res: any) => {
      this.pageLoadingImg = false;

      if (res.error != null) {
        console.log("error");
      }
      else {
      //  this.branch_units = res['data'];
      // console.log("hiiiii");
      // console.log(res['data'][0]['units_rates']);
      this.sel_urates = res['data'][0]['units_rates'];
      

      // console.log('rate11');
      // console.log(this.sel_urates);
      
      }

    });
  }

  selectStockPrdBranch(branchid, prd_id) {

   // console.log(branchid);
    let inpVal = new FormData();
    inpVal.append("branchid", branchid);
    inpVal.append("prd_id", prd_id);
    this.apiService.selBranchviseQty(inpVal).subscribe((res: any) => {
      this.pageLoadingImg = false;

      if (res.error != null) {
        console.log("error");
      }
      else {
        this.branch_units = res['data'];
      }

    });

  }

  useBranchData(data) {
    const found = this.selecTed.units_rates.some(el => el.unit_name === data.unit_name);
    var existingArray = this.selecTed.units_rates.filter((x => x.unit_name === data.unit_name));
    this.selecTed.units_rates.find(x => x.unit_name === existingArray[0].unit_name).sur_unit_rate = data.unit_rate;
  }

  useBranchDataAll() {
    console.log(this.branch_units);
    console.log(this.selecTed.units_rates);
    for (var i = 0; i < this.branch_units.length; i++) {
      if (this.selecTed.units_rates.find(x => x.unit_name === this.branch_units[i].unit_name)) { this.selecTed.units_rates.find(x => x.unit_name === this.branch_units[i].unit_name).sur_unit_rate = this.branch_units[i].unit_rate; }

    }
  }


  getAllBranches() {
    this.apiService.getStockBranches().subscribe((datas: Branch[]) => {
      this.allbranches = datas['data'];
    });

  }

  useBranchAllData(data,skip,flag,total) {
    let inpVal = new FormData();
    inpVal.append("branchid", data.branch_id);
    inpVal.append("skip", skip);
    inpVal.append("flag", flag);
    inpVal.append("total", total);

    this.pageLoadingImg = true;
    $('.list_branch').hide();    
    this.apiService.selBranchviseUnits(inpVal).subscribe((res: any) => {

      if (res.error != null) {
        console.log("error");
      }
      else {      
        this.toTal = res['data']['total'];
        this.counT = res['data']['insert']; 
        console.log(res);
       this.pageLoadingImg = false; 
        $('.list_data').hide();
        $('.list_cont').show(); 
     if(res['data']['flag']=='E')    
      {
        $('.list_data').show();
        $('.list_cont').hide();
        $('.sel-branch').hide();
        $('.list_branch').show();    
      this.coreService.createfunct(data, "nil", "Updated");
      }
        }
      

    });
  }


  useBranchAllDatSel(data)
  {
   this.selBranch = data;
   $('.sel-branch').show();
 
  }
  showCalculateMdl(id: number) {
    $("#sp_inc_tax").val('');
    $("#sp").val('');
    $("#mrp_id").val(id);
    $("#calculateMdl").show();
  }
  closeCalculateMdl(){
    $("#sp_inc_tax").val('');
    $("#sp").val('');
    $("#sp_tax").val('');
    $("#calculateMdl").hide();
  }
  calculateTax(keyword){
    if(!isNaN(keyword)){
      let t = keyword- ((keyword * 100) / (this.prd_tax + 100));
      let p = keyword-t;
      $("#sp").val(p);
      $("#sp_tax").val(t);
    }else{
      $("#sp").val('');
      $("#sp_tax").val('');
    }
  }
  showCalculatedTax(){
    let mrp_id = $("#mrp_id").val();
    let sp = $("#sp").val();
    $(".stk_mrp_"+mrp_id).val(sp);
    $("#calculateMdl").hide();
  }
  inc_tax(keyword,id){
    if(!isNaN(keyword)){
      let t = keyword- ((keyword * 100) / (this.prd_tax + 100));
      let p = keyword-t;
      $(".stk_mrp2_"+id).val(p);
    }
  }
  exl_tax(keyword,id){
    if(!isNaN(keyword)){
      keyword = Number (keyword);
      let t = keyword+ (keyword * this.prd_tax) / 100;
      $(".stk_mrp2_inp2_"+id).val(t);
    }
  }

  inc_tax1(keyword,id){
    if(!isNaN(keyword)){
      let t = keyword- ((keyword * 100) / (this.prd_tax + 100));
      let p = keyword-t;
      $(".stk_mrp3_"+id).val(p);
    }
  }
  exl_tax1(keyword,id){
    if(!isNaN(keyword)){
      keyword = Number (keyword);
      let t = keyword+ (keyword * this.prd_tax) / 100;
      $(".stk_mrp3_inp2_"+id).val(t);
    }
  }
  CheckAllOptions() {
   $('.chk_bxs').prop('checked', this.chk_all);
  }
  
  toggleEditable(event) {
    if ( event.target.checked ) {
      $('.chk_bxs').prop('checked', true);
   }else{
    $('.chk_bxs').prop('checked', false);
   }
  }

  getGeneralSetting() {

    this.apiService.getGeneralSetting().subscribe((res) => {
  
      // console.log(res['data']);
      this.g_settings = res['data'];
      this.generalSearch_type = this.g_settings.g_s_search_type;
      console.log(this.generalSearch_type);
      
    });
  
  }
}
