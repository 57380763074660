import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';

@Component({
  selector: 'app-parent-product-invoice-with-header-footer',
  templateUrl: './parent-product-invoice-with-header-footer.component.html',
  styleUrls: ['./parent-product-invoice-with-header-footer.component.css']
})
export class ParentProductInvoiceWithHeaderFooterComponent implements OnInit {
  checkIsExciseEnble: boolean;
  country_dec: any;

  @Input()saleQtnData1;
  @Input()print_style;
  @Input()qr_inv;
  @Input()savedTemplate;
  @Input()is_prew_description;
  @Input()payTypes;
  @Input()cmpny;
  @Input()previewLoader1;
  @Input()logoImgUrl;
  @Input()tempImgUrl;
  @Input()footImgUrl;
  @Input()sales_print_name;
  @Input()hide_item_discount;
  @Input()hide_vat;
  @Input()sales_settings;
  @Input()water_mark;

  headerheightInmm = 34.4;

  // 130px means 130 / 96 * 25.4 =  31.75
  /// 73.5mm (278px) maximum height allowed 
  footerheightInmm = 31.75;

  pagelabelBottomInmm = 26.46; // 100px
  pagelabelLeftInmm = 92.60;
  dispalyPageNo = false;
  pageLabel = "Page [page_no] of [page_total_no]";
 
  is_prew_description1: any;
  qr_inv1: any;
 


  constructor(private apiService: ApiService, private coreService: CoreService) { }

  ngOnInit() {

    console.log("higggggggggggggg");
    console.log(this.saleQtnData1);

    this.country_dec=this.coreService.setDecimalLength();
    this.apiService.getClientSettingBykey({ key: "excise_duty_enable" }).subscribe((res) => {
      if (res["data"]) {
        this.checkIsExciseEnble = res["data"]["cs_value"] ? true : false;
        
        // let searchval=new FormData;
        // this.apiService.getAllExcsTaxCategory(searchval).subscribe((res) => {
        //   this.excstaxCategories = res.data;
        // });

      }
    });
  }


  ngOnChanges(changes) {
    if (changes['saleQtnData1'] && this.saleQtnData1) {

      this.headerheightInmm=this.savedTemplate.header_height;
    this.footerheightInmm=this.savedTemplate.footer_height;
    this.pagelabelBottomInmm=this.savedTemplate.botom_h;
    this.pagelabelLeftInmm=this.savedTemplate.left_h;
    if(this.savedTemplate.page_no_display){
      this.dispalyPageNo=true;
      this.pageLabel=this.savedTemplate.page_format;
    }

    }
  }

  startParentPrint(){

    console.log("test main");

    if(this.dispalyPageNo){
      const contentHeight = document.getElementById("printContentArea1").scrollHeight;
      var dpi = this.getDPI();
      var contentHeightinmm = (contentHeight/dpi * 25.4) - this.headerheightInmm - this.footerheightInmm;
      var correctionpx = 0;
      var correctionpxinmm = (correctionpx/dpi * 25.4);
      // 297 mm A4 height 
      var singlePageAvlSpaceInmm = 297 - this.headerheightInmm - this.footerheightInmm - correctionpxinmm;

      var totalPage = Math.ceil(contentHeightinmm / singlePageAvlSpaceInmm);

      // var totalPage = Math.ceil(document.getElementById("printContentArea1").scrollHeight / 1123);
      // console.log(' dpi ' + dpi);
      // console.log(' content height in px : ' + contentHeight);
      // console.log(' content height in mm: ' + contentHeightinmm);
      // console.log(' page avialabe height in mm: ' + singlePageAvlSpaceInmm);
      // console.log(' total page : ' + totalPage);

      // $('.ttlpage').html('' +totalPage);
      for (var i = 1; i <= totalPage; i++) {
        var pageNumberDiv = document.createElement("div");
        var pageNodisplay = this.pageLabel.replace("[page_no]", "" + i).replace("[page_total_no]", "" + totalPage)
        var pageNumber = document.createTextNode(pageNodisplay);
        pageNumberDiv.style.position = "absolute";
        pageNumberDiv.style.color = "#000";
        //297mm A4 pageheight; 0,5px unknown needed necessary correction value; additional wanted 40px margin from bottom(own element height included)
        pageNumberDiv.style.top = "calc((" + i + " * (297mm - 0.5px)) - " + this.pagelabelBottomInmm + "mm)"; 
        pageNumberDiv.style.height = "16px";
        pageNumberDiv.appendChild(pageNumber);
        // if(i != 1){
          $('#parent-print-sales').append(pageNumberDiv);
        // }
        pageNumberDiv.style.left = "calc(100% - (" + pageNumberDiv.offsetWidth + "px + " + this.pagelabelLeftInmm + "mm))";
      }
    }

    $('#printButton1').click();
  }

  getDPI(){  
    var div = document.createElement('div');
      div.style.display = 'block';
      div.style.height = '100mm';
      div.setAttribute("id","my_mm");
      document.body.appendChild(div);
      var px = $('#my_mm').height();
    return Math.round(px/3.93901);
}

}
