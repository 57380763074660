import { NgIf } from '@angular/common';
import { Component, Input, OnInit,EventEmitter, Output } from '@angular/core';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import { log } from 'util';

@Component({
  selector: 'app-additional-charges-popup',
  templateUrl: './additional-charges-popup.component.html',
  styleUrls: ['./additional-charges-popup.component.css']
})
export class AdditionalChargesPopupComponent implements OnInit {
  @Input()servicetypes;
  @Input()edit_service_additional_charges;
  @Input()EditServiceAdditionaltotalAmount;
  @Input()isEdit;
  @Input()additional_charges;
  @Input()additional_total_amount;
  @Input()doneMessage;
  @Output() additionalServiceCharges = new EventEmitter();
 
  amount: number;
  slno = 0;
  country_dec: string;
  ledger_name: any;
  ledger_id: any;
  service_type: any;
  // additional_charges:any=[]; 
  printError = []; 
  errObjArr = {
    srvcidErr: null, amtErr: null,
  };
  add_chrg_name: any;
  src_id: any;
  additional_serv_type=[];
  deleteServiceType: any[];
  allServiceType: any[];
  // doneMessage=false;
  
  constructor(private apiService:ApiService , private coreService:CoreService , private translate: TranslateService) { }

  ngOnInit() {
    // this.additional_total_amount=0;
console.log(this.additional_charges);

    this.doneMessage =false;
    this.country_dec = this.coreService.setDecimalLength();

    
    
  }
  ngOnChanges() {

    // this.additional_serv_type=this.servicetypes;  
if (this.edit_service_additional_charges !=undefined && this.isEdit) {
  console.log('hiii');
  
   this.additional_charges=this.edit_service_additional_charges
   this.additional_total_amount=this.EditServiceAdditionaltotalAmount;
   this.additional_charges.forEach(element => {
   this.servicetypes = this.servicetypes.filter(x => x.src_id != element.src_id);
});
console.log('this.additional_charges');
console.log(this.additional_charges);

}


  else{
    if(!this.doneMessage){
      this.additional_total_amount =0;
    }
  }
  
 
  }

  getServiceAmount(data){
    if (data) {
      this.src_id=data.src_id;
      this.amount= data.src_type_amount;
      this.add_chrg_name=data.src_type_name;
      this.ledger_id=data.src_type_ledger_id;
      // this.ledger_name=data.ledger_name; 
    }
  }

  addServiceType(){
    this.printError = [];

    if (this.service_type== undefined|| this.service_type== null) {
      this.errObjArr.srvcidErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });
    } 
    else {
      this.errObjArr.srvcidErr = "f";
    }
    if (this.amount == null) {
      this.errObjArr.amtErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });
    }  else if (this.amount <=0) {
      this.errObjArr.amtErr = "t";
      this.printError.push({ 'fieldErr': 'invalid amount' });
    }
    else {
      this.errObjArr.amtErr = "f";
    }

    if (this.printError.length <= 0) {
   this.slno = this.additional_charges.length + 1;
     this.additional_charges.push({
       "sl_no": this.slno, "src_id": this.src_id,"additional_amount":  this.amount, "additional_ledger_id": this.ledger_id,"additional_chrg_name": this.add_chrg_name,
     });

     this.additional_total_amount=0;
     this.additional_charges.forEach(element => {
       this.additional_total_amount =  Number(this.additional_total_amount)+Number(element.additional_amount);
   });
   this.servicetypes = this.servicetypes.filter(x => x.src_id != this.src_id);
   this.src_id=null;
   this.amount= null;
   this.add_chrg_name=null;
   this.ledger_id=null;
   this.ledger_name=null;
   this.service_type=null; 
    }
  
  }

  deleterow(data) {
    this.apiService.getAllServiceTypesDatas().subscribe((res) => {
      this.additional_serv_type = res.data;
      this.src_id =data.src_id;

      
      var i;
        for (i = this.additional_charges.length - 1; i >= 0; --i) {
          if (this.additional_charges[i].src_id ==this.src_id) {
            this.additional_charges.splice(i, 1);
          }
        }
        this.additional_total_amount=0;
        this.additional_charges.forEach(element => {
          this.additional_total_amount =  Number(this.additional_total_amount)+Number(element.additional_amount);
          //  this.serv_id =element.src_id;
      });
     
      this.deleteServiceType = this.additional_serv_type.filter(x => x.src_id == this.src_id);
      this.servicetypes = [
        ...this.servicetypes,
        { 'src_id': this.deleteServiceType[0]['src_id'], 'src_type_amount': this.deleteServiceType[0]['src_type_amount'],'src_type_ledger_id': this.deleteServiceType[0]['src_type_ledger_id'],
      'src_type_name': this.deleteServiceType[0]['src_type_name'] },
      ];

      this.additionalServiceCharges.emit({ additionalCharges: this.additional_charges, ServiceAdditionaltotalAmount: this.additional_total_amount });
    });
    
   


       }
       submitSection(){
        
 
        
         $('.custPopCloseBtn').click();
         this.doneMessage=true;
      
         this.additionalServiceCharges.emit({ additionalCharges: this.additional_charges, ServiceAdditionaltotalAmount: this.additional_total_amount} );
         
         this.src_id=null;
         this.amount= null;
         this.add_chrg_name=null;
         this.ledger_id=null;
         this.ledger_name=null;
         this.service_type=null; 


       }

      //  clearAndClose(){
      //   console.log('this.additional_charges111111');
      //   console.log(this.additional_charges);
        
      //    $('.custPopCloseBtn').click();
      
      //   this.additional_charges=[];
      //   this.service_type=null;
      //   this.amount= null;
      //   this.additional_total_amount=0;
      //    this.additionalServiceCharges.emit({ additionalCharges: this.additional_charges, ServiceAdditionaltotalAmount: this.additional_total_amount });
      //   this.getAdditonalCharge();
      //  }
      //  getAdditonalCharge() {
      //   this.apiService.getAllServiceTypesDatas().subscribe((res) => {
      //     this.servicetypes = res.data;
         
      //   });
      // }
    

      close(){
  
        this.src_id=null;
        this.amount= null;
        this.add_chrg_name=null;
        this.ledger_id=null;
        this.ledger_name=null;
        this.service_type=null; 


      }
 
}


  

