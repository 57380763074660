import { Component, OnInit,ElementRef,ViewChild } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import * as XLSX from 'xlsx';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-stock-unit-report',
  templateUrl: './stock-unit-report.component.html',
  styleUrls: ['./stock-unit-report.component.css']
})
export class StockUnitReportComponent implements OnInit {
  @ViewChild('exportall', { static: true }) reportContent: ElementRef;
  @ViewChild('printBtn',{static: true}) printBtn: ElementRef<HTMLElement>;
  fileName= 'Stock_unit_list.xlsx';
  search: any;
  prds:any;
  prdCat: any[];
  categories: any;
  cat_id: any;
  prd_id: string;
  pageLoadingImg: boolean;
  pgend: boolean;
  pgstart: boolean;
  slnum: number;
  prduntdtls: any;
  curpage: number;
  lastpage: number;
  prd_ean: string;
  barcode: string;
  err_msg: any;
  brcd_ean_err: any;
  brcd_err: any;
  total_prds: any;
  exportLoader: boolean;
  branch_display_name: any;
  branch_display_code: any;
  ExptotalProducts: any;
  Expprduntdtls: any;
  derived_unt_ean: any;
  derived_unt: any;
  unit_id: string;
  remark: any;
  Expremark:any;
  datePipe: any;
  currencyPipe: any;
  excelService: any;
  repDate: any;
  PrintpageLoadingImg: any;
  Printprduntdtls: any;
  Printcurpage: any;
  Printlastpage: any;
  Printtotal_prds: any;
  Printremark: any;
  from: any;
  cur_lang: string;

  constructor(private apiService: ApiService,private coreService: CoreService,private translate: TranslateService,) { }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.cur_lang = sessionStorage.getItem("baseLang");
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    const searchval = new FormData();
    this.apiService.stockUnitDetails(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.prduntdtls=res['data']['data'];
      this.curpage=Number(res['data'].current_page);
      this.lastpage=Number(res['data'].last_page);
      this.total_prds=res['data'].total;
      this.remark=res['data'].remark;
      this.from=res['data'].from;
       this.pgEnd(this.curpage, this.lastpage);
       this.pgStart(this.curpage);
      this.slnum = 0;


    });

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');
  }
  langChange(){
    this.translate.get(['Common.Last_Purchase_Rate','Common.Avg_Purchase_rate']).subscribe((res: string) => {

      this.filter_prate = [
        { id: 0, name:res['Common.Last_Purchase_Rate'] },
        { id: 1, name:res['Common.Avg_Purchase_rate'] }
      ];
 
   
    });

  }
  stockUntDetail(formdata: { value: any; }, pageno: any) {
    this.pageLoadingImg = true;

  this.apiService.stockUnitDetails(formdata.value, pageno).subscribe((res: any) => {
     this.pageLoadingImg = false;
     this.prduntdtls=res['data']['data'];
     this.curpage=Number(res['data'].current_page);
     this.lastpage=Number(res['data'].last_page);
     this.total_prds=res['data'].total;
     this.remark=res['data'].remark;
     this.from=res['data'].from;

    //console.log(this.curpage);


       this.pgEnd(this.curpage, this.lastpage);
       this.pgStart(this.curpage);
       this.slnum = 0;

// console.log(this.prduntdtls);

    });
  }

   // search product
   selectProd(name: string) {

    let searchval = new FormData();
    if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      searchval.append('alias_search', '1');
    }
    searchval.append("prod_name", name);
    searchval.append("flag[]", "1");
    this.apiService.getProdByName(searchval).subscribe((res) => {
      this.prds = res['data'];
    });
  }

  listUnit(prd_id){
    this.prd_id != null;
     if(this.prd_id){
      this.unit_id ="";
     }
    this.apiService.getUnitByPrdId(prd_id).subscribe((res) => {
      this.search = res['data'];
    });
  }

  filter_prate = [
    { id: 0, name: 'Last Purchase Rate' },
    { id: 1, name: 'Avg. Purchase rate' }
  ];

  pgEnd(curr, end) {
    if (curr == end) {
      this.pgend = true;
    } else {
      this.pgend = false;
    }

  }

  pgStart(curr) {
    if (curr == 1) {
      this.pgstart = true;
    } else {
      this.pgstart = false;
    }
  }

  exportexcel(): void
  {
     /* table id is passed over here */
     let element = document.getElementById('export-group');
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.fileName);
     this.exportLoader = false;

  }

  exportEXCL(Expformdata: { value: any; }){
    this.exportLoader = true;

      // debugger;
      Expformdata.value.export = 'export';

      this.apiService.stockUnitDetails(Expformdata.value, 1).subscribe((res: any) => {
          this.Expprduntdtls=res['data']['data'];
          this.ExptotalProducts =res['data'].total;
          this.Expremark = res['data'].remark;



      setTimeout(() => {
        this.exportexcel();
    }, 3000);

    Expformdata.value.export = '';


    });
    }


    saveAndPrint(Expformdata: { value: any; }) {

      // debugger;
      Expformdata.value.export = 'export';


        this.apiService.stockUnitDetails(Expformdata.value, 1).subscribe((res: any) => {

          this.PrintpageLoadingImg = false;
          this.Printprduntdtls=res['data']['data'];
          this.Printcurpage=Number(res['data'].current_page);
          this.Printlastpage=Number(res['data'].last_page);
          this.Printtotal_prds=res['data'].total;
          this.Printremark=res['data'].remark;
          this.pgEnd(this.Printcurpage, this.Printlastpage);
          this.pgStart(this.Printcurpage);
          this.slnum = 0;

        setTimeout(() => {
          this.exportLoader = false;
          let el: HTMLElement = this.printBtn.nativeElement;
          el.click();
      }, 2000);
        Expformdata.value.export = '';


      });



    }


}


