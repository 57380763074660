import { Component, OnInit, Inject } from '@angular/core';

import { Purchase } from '../../../../model/purchase';
import { ApiService } from '../../../../service/api.service';
import { CoreService } from '../../../../service/core.service';
import { DatePipe } from '@angular/common';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';

// NOTE MODAL IMPORT 
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
// END 

@Component({
  selector: 'app-purchase-void',
  templateUrl: './purchase-void.component.html',
  styleUrls: ['./purchase-void.component.css'],

})
export class PurchaseVoidComponent implements OnInit {
  purchsub_prd_id: any;
  purchsub_qty: any;
  purchsub_rate: any;
  purchsub_gd_id: any;
  purchsub_frieght: any;
  purch_id: any;
  items: any;
  purch_amount: any;
  purch_tax: any;
  purch_discount: any;
  purch_frieght: any;
  purch_inv_date: any;
  purchase_type: any;
  supp_name: any;
  purchpageLoadingImg:boolean;

  updateStock: any;
  updateStkf: any;
  visibility:boolean;
  resultobj: any = {};
  purchaseItems = [];
  purchData: any = {
    purch_id: null,
    purch_inv_no: null,
    error: null,
    message: null,
    data: null,
  };
  purchNote: any;
  purchId: any;
  purchTotal: any;
  up: any;
  country_dec: string;
  constructor(private apiService: ApiService, private coreService: CoreService
    , private _bottomSheet: MatBottomSheet,private datePipe: DatePipe,private translate: TranslateService

  ) { }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      // this.langChange();
    });
    this.visibility = false;
    this.up = this.coreService.getUserPrivilage();

    this.country_dec=this.coreService.setDecimalLength();
  }

  // form search
  purchase_search(search: string) {
    const searchval = new FormData();
    searchval.append('purch_id', search);
    this.apiService.getpurchaseDetails(searchval).subscribe((res) => {
      this.visibility = true;

// console.log(res);
      if (res.error != null) {
        this.resultobj = res.error;
        this.purchaseItems = [];
        this.purchData = {
          purch_id: null,
          purch_inv_no: null,
          error: null,
          message: null,
          data: null,
        };
        this.resultobj = this.coreService.getValidation(this.resultobj);
      } else {
        this.resultobj = {};
        this.purchaseItems = res.data;
        this.purchData = res.data;
        this.purchData.purch_date = this.datePipe.transform(this.purchData.purch_date,"dd-MM-yyyy");         
        this.purchId = res.data.purch_id;
        this.purchNote = res.data.purch_note;
        this.purchTotal = Number(res.data.purch_amount) + Number(res.data.purch_tax) + Number(res.data.purch_frieght) - Number(res.data.purch_discount);

      }


    });
  }

  voidPurchase(purchId: any) {

    var voidstk = confirm("Are you sure you want to Void this purchase ? ");
    if (voidstk) {
      this.purchpageLoadingImg =true;

    const searchval = new FormData();
    searchval.append('purch_id', purchId);
    this.apiService.voidPurchase(searchval).subscribe((res) => {
      this.purchpageLoadingImg = false;
      if (res.error != null) {
        this.resultobj = res.error;
        this.resultobj = this.coreService.getValidation(this.resultobj);

      } else {
        this.resultobj = {};

        this.apiService.getpurchaseDetails(searchval).subscribe((res) => {

          if (res.error != null) {
            this.resultobj = res.error;
            this.purchaseItems = [];
            this.purchData = {
              purch_id: null,
              purch_inv_no: null,
              error: null,
              message: null,
              data: null,
            };
            this.resultobj = this.coreService.getValidation(this.resultobj);
          } else {
            this.resultobj = {};
            this.purchaseItems = res.data;
            this.purchData = res.data;
          }


        });

        this.coreService.showMessage(res.message);
      }
    });
    this.apiService.updatebatchExNotification();
  }
  }


// NOTE MODAL OPEN 
  openBottomSheet(): void {

    const searchval = new FormData();
    searchval.append('purch_id', this.purchId);
    this.apiService.getpurchaseDetails(searchval).subscribe((res) => {

      this.purchNote = res.data.purch_note;
      this._bottomSheet.open(AddNotePopup, {
        data: { desc: this.purchNote, id: this.purchId }
      });
    });

}

// NOTE MODAL END


  resetAll() {
    this.resultobj = {};
    this.purchaseItems = [];
    this.purchData = {
      purch_id: null,
      purch_inv_no: null,
      error: null,
      message: null,
      data: null,
    };
  }


}



// NOTE MODAL COMPONENT

@Component({
  selector: 'note',
  templateUrl: '../common/notes/notes.component.html',
  // template: 'passed in {{ data.desc }}'
})
export class AddNotePopup {
  constructor(private apiService: ApiService, private coreService: CoreService
    , private _bottomSheetRef: MatBottomSheetRef<AddNotePopup>, @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) { }
  desc: any;
  resultobj: any;
  purchId: any;
  inputValue: any;
  openLink() {
    this.desc = $('#description').val();
    this.purchId = $('#noteid').val();

    const searchval = new FormData();
    searchval.append('purch_id', this.purchId);
    searchval.append('purch_note', this.desc);

    this.apiService.updatePurchaseNote(searchval).subscribe((res) => {
      if (res.error != null) {
        this.resultobj = res.error;
        this.resultobj = this.coreService.getValidation(this.resultobj);

      } else {
        this.resultobj = res;



      }


    });
    this._bottomSheetRef.dismiss();
    event.preventDefault();

  }
}
