import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';


@Component({
  selector: 'app-excise-category',
  templateUrl: './excise-category.component.html',
  styleUrls: ['./excise-category.component.css']
})
export class ExciseCategoryComponent implements OnInit {
 
  excstaxCategories: any;
  edit_data: any;

  constructor(private apiService: ApiService, private coreService: CoreService, private translate: TranslateService) { }

  ngOnInit() {
    let searchval=new FormData;
    this.apiService.getAllExcsTaxCategory(searchval).subscribe((res) => {
      this.excstaxCategories = res.data;
    });
  }

  editExcsCat(data){

    this.edit_data=data;
  }
  adExcsCat(){
    this.edit_data = 0;
  }

  excsCatUpdated(){
    let searchval=new FormData;
    this.apiService.getAllExcsTaxCategory(searchval).subscribe((res) => {
      this.excstaxCategories = res.data;
    });
  }
}
