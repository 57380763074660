import { Component, OnInit } from '@angular/core';
import { Repprddet } from 'src/app/model/report/repprddet.model';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import * as XLSX from 'xlsx'; 
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { CurrencyPipe } from '@angular/common';
import { ExcelService } from 'src/app/service/excel.service';

@Component({
  selector: 'app-unit-list',
  templateUrl: './unit-list.component.html',
  styleUrls: ['./unit-list.component.css']
})
export class UnitListComponent implements OnInit {
  fileName= 'Product_list.xlsx';

  search: any;
  prdCat: any[];
  categories: any;
  cat_id: any;
  prd_id: string;
  pageLoadingImg: boolean;
  pgend: boolean;
  pgstart: boolean;
  slnum: number;
  prduntdtls: any;
  curpage: number;
  lastpage: number;
  prd_ean: string;
  barcode: string;
  err_msg: any;
  brcd_ean_err: any;
  brcd_err: any;
  total_prds: any;
  exportLoader: boolean;
  branch_display_name: any;
  branch_display_code: any;
  ExptotalProducts: any;
  Expprduntdtls: any;
  derived_unt_ean: any;
  derived_unt: any;
  from: any;
  subcatagories: any;
  changeBox: any;
  sub_cat_id: any;
  Expprduntdtls1: any;
  branch_address: any;
  user_id: any;
  cur_lang: string;

  constructor( private apiService: ApiService,private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }

  ngOnInit() {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      // this.langChange();
    });
    this.cur_lang = sessionStorage.getItem("baseLang");
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    const searchval = new FormData();
    this.apiService.productUnitDetails(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.prduntdtls=res['data'];
      this.curpage=Number(res.current_page);
      this.lastpage=Number(res.last_page);
      this.total_prds=res.total;
      this.from=res['from'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;


    });
    this.user_id=this.coreService.getUserData('usr_id');
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');
  }


  prdUntDetail(formdata: { value: any; }, pageno: any) {
    $('.resultdata').empty();
    this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
  this.apiService.productUnitDetails(formdata.value, pageno).subscribe((res: any) => {
    this.pageLoadingImg = false;
    this.prduntdtls=res['data'];
    this.curpage=Number(res.current_page);
    this.lastpage=Number(res.last_page);
    this.total_prds=res.total;
    this.err_msg=res['error'];
    this.brcd_err=res['key1'];
    this.brcd_ean_err=res['key2'];
    this.from=res['from'];


      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;

// console.log(this.prduntdtls);

    });
  }


    // search product
    selectProd(name: string) {
      if(this.prd_id){
        this.barcode='';
        this.prd_ean='';
      }
      let searchval = new FormData();
      searchval.append("prod_name", name);
      searchval.append("flag[]", "1");
      if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
        searchval.append('alias_search', '1');
      }
      this.apiService.getProdByName(searchval).subscribe((res) => {
        this.search = res['data'];
      });
    }


    // search category

     searchCat(search: string) {
      let searchval = new FormData();
      searchval.append("cat_name", search);
      this.apiService.getCatbyName(searchval).subscribe((res) => {
        this.categories = res['data'];     
      });  
    }

    // search sub category

    searchSubcat(search: string)
{
      let searchval = new FormData();      
      searchval.append("subcat_name", search);    
       this.apiService.getSubcatByName(searchval).subscribe((res) => {
        this.subcatagories = res['data'];                
       });
       $('.prev-list').show(); 
}
    
    selectSubCats(catid: number) {
      this.apiService.getAllSubCatbyId(catid).subscribe((res) => {
        this.subcatagories = res['data'];
       
  
      });
    }

    listPrduct(cat_id){
      // this.prd_id=null;
      if(this.cat_id){
        this.barcode='';
        this.prd_ean='';
      }
      this.sub_cat_id=null;
      this.apiService.getPrdByCatId(cat_id).subscribe((res) => {
        this.search = res['data'];     

        this.selectSubCats(cat_id);
      });  
    }
    selectedprd(prdId :number){
      this.sub_cat_id=null;
      this.cat_id=null;
      this.apiService.getCatByPrdId(prdId).subscribe((res) => {
        this.categories = res['data'];     

        // console.log(this.categories);
        
      });  
    }

    pgEnd(curr, end) {
      if (curr == end) {
        this.pgend = true;
      } else {
        this.pgend = false;
      }
  
    }
  
    pgStart(curr) {
      if (curr == 1) {
        this.pgstart = true;
      } else {
        this.pgstart = false;
      }
    }

 
    exportexcel(): void 
    {
       /* table id is passed over here */   
       let element = document.getElementById('export-group'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       /* save to file */
       XLSX.writeFile(wb, this.fileName);
       this.exportLoader = false;
			
    }

    exportEXCL(Expformdata: { value: any; }){
      this.exportLoader = true;
       
        // debugger;
        Expformdata.value.export = 'export';
        
        this.apiService.productUnitDetails(Expformdata.value, 1).subscribe((res: any) => {
            this.Expprduntdtls = res['data'];
            this.ExptotalProducts =res.total;
            this.derived_unt=res['data'];
            this.derived_unt_ean=res['data'];
            
        
        setTimeout(() => {
          this.exportexcel(); 
      }, 3000);
      
      Expformdata.value.export = '';
     
  
      });
  
  
  
      }

      // code to export all products & details

      exportEXCL2(Expformdata: { value: any; }){
        this.exportLoader = true;
         
          // debugger;
          Expformdata.value.export = 'export';
          Expformdata.value.export2 = 'export2';
          
          this.apiService.productUnitDetails(Expformdata.value, 1).subscribe((res: any) => {
              this.Expprduntdtls1 = res['data'];
              // this.ExptotalProducts =res.total;
              // this.derived_unt=res['data'];
              // this.derived_unt_ean=res['data'];
              
          
          setTimeout(() => {
            this.exportexcel1(); 
        }, 3000);
        
        Expformdata.value.export = '';
       
    
        });
    
        }

        exportexcel1(): void 
        {
           /* table id is passed over here */   
           let element = document.getElementById('export-group1'); 
           const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });
    
           /* generate workbook and add the worksheet */
           const wb: XLSX.WorkBook = XLSX.utils.book_new();
           XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    
           /* save to file */
           XLSX.writeFile(wb, this.fileName);
           this.exportLoader = false;
          
        }

        // pdf download
      generatePdf(Expformdata: { value: any; }) {

        this.exportLoader = true;
         
        // debugger;
        Expformdata.value.export = 'export';
        
        this.apiService.productUnitDetails(Expformdata.value, 1).subscribe((res: any) => {
            this.Expprduntdtls1 = res['data'];

            const heads = [
              this.translate.instant('Table.sl') + ' ' + this.translate.instant('Common.no'),
              this.translate.instant('Common.prd_name'),
              this.translate.instant('Table.product_barcode'),
              this.translate.instant('Table.product_ean'),
              this.translate.instant('Common.category'),
              this.translate.instant('Common.sub_category'),
              this.translate.instant('Repproduct.base_unit'),
              this.translate.instant('Repproduct.base_unit_ean'),
              this.translate.instant('Common.Derived_Units'),
              this.translate.instant('Common.Derived_Unit_EAN')
            ];
        
              const lblXPos = 10;
              const headerHeight = 15;
              const valueXPos = 55;
              const data = [];
      
              this.Expprduntdtls1.forEach((item,i)=>{
                item.derived_unit.forEach((item1,j)=>{
                  const arr=[
                    item1.derived_unit,
                    item1.derived_unit_ean
                  ]

                  if(j==0){
                    arr.splice(0,0, i+1)
                    arr.splice(1,0, item.prd_name)
                    arr.splice(2,0, item.prd_barcode)
                    arr.splice(3,0, item.prd_ean)
                    arr.splice(4,0, item.cat_name)
                    arr.splice(5,0, item.sub_category)
                    arr.splice(6,0, item.base_unit)
                    arr.splice(7,0, item.base_unit_ean)
                  } else {
                    arr.splice(0,0, ' ')
                    arr.splice(1,0, ' ')
                    arr.splice(2,0, ' ')
                    arr.splice(3,0, ' ')
                    arr.splice(4,0, ' ')
                    arr.splice(5,0, ' ')
                    arr.splice(6,0, ' ')
                    arr.splice(7,0, ' ')
                  }
  
                  data.push(arr)
                })
              })
          
              let doc = this.excelService.getPdfObj();
      
              var addressLines = this.branch_address.split('\r\n');
              var addressText = addressLines.join(', ');
    
              doc.setFont('Amiri');
              doc.setFontSize(12);
              doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
    
              doc.setFont('Amiri');
              doc.setFontSize(9);
              doc.text(addressText, 71, headerHeight + 2);
      
              doc.setFontSize(10);
              doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 10);
              doc.setFontSize(10);
              doc.text(':  ' + `${this.translate.instant('HOME.prdt_unit_list')}`,  valueXPos, headerHeight + 10);
      
              doc.setFontSize(10);
              doc.text(`${this.translate.instant('HOME.total_no_of_products')}`, lblXPos, headerHeight + 15);
              doc.setFontSize(10);
              doc.text(':  ' + this.total_prds,  valueXPos, headerHeight + 15);
              
              doc = this.excelService.addTableToPdf(doc, headerHeight + 20, lblXPos, heads, data, null);
          
              doc.save(`Product Unit List.pdf`);

              this.exportLoader = false;
      
               Expformdata.value.export = '';
     
  
      });
  
        
      }

  }

