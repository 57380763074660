import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { Repprddet } from '../../../../../model/report/repprddet.model';
import { Subcatagory } from '../../../../../model/subcatagory.model';
import { Catagory } from '../../../../../model/catagory.model';
import { Feature } from '../../../../../model/feature.model';
import * as XLSX from 'xlsx'; 
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-rep-product-summary',
  templateUrl: './rep-product-summary.component.html',
  styleUrls: ['./rep-product-summary.component.css']
})
export class RepProductSummaryComponent implements OnInit {
  fileName= 'Product_summary.xlsx';

  prd_sub_cat_id: any ;
  elseBlocknostart: any ;
  elseBlocknoend: any ;
  manufacture_id: any ;
  unitid: any ;
  feat_id: any ;
  feat_val: any ;
  vat_type: any ;
  vat_val_1: any ;
  vat_val_2: any ;
  selectFeat: any ;
  selectPrdType:any;
  prd_stock_stat= 1;
  allfeat: any ;
  selectType: any ;
  id: any ;
  prd_ean:any;
  products:any;
  products_all: string[];
  catagories: string[];
  subcatagories: string[];
  mfs: string[];
  cat_id: number;
  prodsumms: string[];
  unit_all: string[];
  features: string[];
  baseunit: any;
  curpage: number;
  lastpage: number;
  changeBox: boolean;
  pgstart: boolean;
  pgend: boolean;
  pageLoadingImg:boolean;
  sub_cat_id: any;
  product_summary:any;
  pageFrom:any;
  totalProducts:any;
  totalCat:any;
  prd_id:any;
  up: any;
  exportLoader: boolean;
  Expprodsumms: any;
  ExptotalProducts: any;
  ExptotalCat: any;
  branch_display_name:any;
  branch_name:any;
  branch_display_code:any;
  branch_address:any;
  cur_lang: string;
  users: any;
  statuses = [
    { id: 1, name: 'Active' },
    { id: 0, name: 'Suspend' },
  ];
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }


  ngOnInit() {
    this.cur_lang = sessionStorage.getItem("baseLang");
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name= this.coreService.getUserData('branch_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');

    this.up = this.coreService.getUserPrivilage();
    this.pageLoadingImg = true;
    let searchval = new FormData();
    this.apiService.prodSumPage(searchval, 1).subscribe((res: Repprddet) => {
      this.pageLoadingImg = false;
      this.prodsumms = res['data']['data']['data'];

      this.totalCat   = res['data']['total_categories'];
      this.totalProducts = res['data']['total_products'];

      
      

      this.pageFrom = res['data']['data']['from'];
      this.curpage = res['data']['data']['current_page'];
      this.lastpage = res['data']['data']['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });

  }


  langChange(){
    this.translate.get(['Common.all','Product.Goods','Product.Services','Common.Active','Common.Suspend']).subscribe((res: string) => {    
     
      
      this.prod_types = [
        { id: '' ,name: res['Common.all'] },
        { id: '1', name: res['Product.Goods'] },
        { id: '0', name: res['Product.Services'] },
      ];
      this.statuses = [
        { id: 1, name: res['Common.Active'] },
        { id: 0, name: res['Common.Suspend'] },
      ];
    });

  }
  ngAfterContentInit(){

    this.searchCat('');
    this.reeadFeat();
    this.searchManf('');
    this.searchUnit('');
    

  }

  ngOnDestroy(){
    $('.close').click();
 
  }


  //form search
  searchForm(search: string) {
    let searchval = new FormData();
    searchval.append("cat_name", search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.catagories = res['data'];
    });

  }
// search product
selectProd(name: string) {

  const searchval = new FormData();
  searchval.append('prod_name', name);
  if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
    searchval.append('alias_search', '1');
  }
  this.apiService.getProdByName(searchval).subscribe((res) => {
    this.products = res.data;
  });
}
  
  searchCat(search: string) {
    const searchval = new FormData();
    searchval.append('cat_name', search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.catagories = res['data'];
    });

  }


  searchUser(search: string) {
    let searchval = new FormData();
    searchval.append("usr_name", search);
    this.apiService.getUserByName(searchval).subscribe((res) => {
      this.users = res['data'];
    });
  }



  //Remove Name Suggestions

  removeName() {
    this.catagories = [];
    this.reeadFeat();
  }


  selectCat(cat_id: number) {
    this.cat_id = cat_id;

  }



  pageNext(formdata: { value: any; }, pageno: any) {
   $('.resultdata').empty();
    this.pageLoadingImg = true;

    let searchval = new FormData();
    searchval.append("pageno", pageno);
    this.apiService.prodSumPage(formdata.value, pageno).subscribe((res: Repprddet) => {
      this.pageLoadingImg = false;
      this.prodsumms = res['data']['data']['data'];
      this.pageFrom = res['data']['data']['from'];
      this.totalProducts = res['data']['total_products'];
      this.totalCat = res['data']['total_categories'];

      this.curpage = res['data']['data']['current_page'];
      this.lastpage = res['data']['data']['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);

    });
  }

  selectSubCats(catid: number) {
    this.apiService.getAllSubCatbyId(catid).subscribe((res: Subcatagory[]) => {
      this.subcatagories = res['data'];
      if (this.changeBox)
        this.changeBox = false;
      else
        this.changeBox = true;

    });
  }

  //form search  manufacture
  searchManf(search: string) {
    let searchval = new FormData();
    searchval.append("manftr_comp_name", search);
    this.apiService.getManfbyName(searchval).subscribe((res) => {
      this.mfs = res['data'];
    });
  }

  //form search unit
  searchUnit(search: string) {
    let searchval = new FormData();
    searchval.append("unit_name", search);
    this.apiService.getUnitByName(searchval).subscribe((res) => {
      this.unit_all = res['data'];

    });

  }

  // vat value
  // unit types
  vat_types = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];
  prod_types = [
    { id: '' ,name: 'All' },
    { id: '1', name: 'Goods' },
    { id: '0', name: 'Services' },
  ];

  reeadFeat() {
    this.apiService.readFeat().subscribe((res: Feature[]) => {
      this.features = res['data'];

    });
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }





  exportexcel(): void 
    {
       /* table id is passed over here */   
       let element = document.getElementById('export-group'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       /* save to file */
       XLSX.writeFile(wb, this.fileName);
       this.exportLoader = false;
			
    }


    exportEXCL(Expformdata: { value: any; }){
    this.exportLoader = true;
     
      // debugger;
      Expformdata.value.export = 'export';
      
      this.apiService.prodSumPage(Expformdata.value, 1).subscribe((res: Repprddet) => {
          this.Expprodsumms = res['data']['data']['data'];
          this.ExptotalProducts = res['data']['total_products'];
          this.ExptotalCat = res['data']['total_categories'];
      
      setTimeout(() => {
        this.exportexcel(); 
    }, 3000);
    
    Expformdata.value.export = '';
   

    });



    }

    // pdf download
    generatePdf(Expformdata: { value: any; }) {

      this.exportLoader = true;
     
      // debugger;
      Expformdata.value.export = 'export';
      
      this.apiService.prodSumPage(Expformdata.value, 1).subscribe((res: Repprddet) => {
          this.Expprodsumms = res['data']['data']['data'];
          this.ExptotalProducts = res['data']['total_products'];
          this.ExptotalCat = res['data']['total_categories'];
      
          const heads = [
            this.translate.instant('Table.sl') + '\n' +  this.translate.instant('Common.no'),
            this.translate.instant('Common.prd_name'),
            this.translate.instant('Repproduct.product_alias'),
            this.translate.instant('HOME.PRODUCT') + '\n' +  this.translate.instant('Table.code'),
            this.translate.instant('Table.barcode'),
            this.translate.instant('Table.ean_barCode'),
            this.translate.instant('HOME.CATAGORY'),
            this.translate.instant('Production.sub') + '\n' +  this.translate.instant('HOME.CATAGORY'),
            this.translate.instant('Repproduct.base_unit')
          ];
      
            const lblXPos = 10;
            const headerHeight = 15;
            const valueXPos = 55;
            const data = [];
    
            this.Expprodsumms.forEach((item,i)=>{

              item.units.forEach(item1=>{
                const arr=[
                  i+1,
                  item.prd_name,
                  item.prd_alias,
                  item.prd_code,
                  item.prd_barcode,
                  item.prd_ean,
                  item.cat_name,
                  item.subcat_name,
                  item1.unit_name
                ]
  
                data.push(arr)
              })
            })
        
            let doc = this.excelService.getPdfObj();
    
            var addressLines = this.branch_address.split('\r\n');
            var addressText = addressLines.join(', ');
    
            doc.setFont('Amiri');
            doc.setFontSize(12);
            doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
    
            doc.setFont('Amiri');
            doc.setFontSize(9);
            doc.text(addressText, 71, headerHeight + 2);
    
            doc.setFontSize(10);
            doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 10);
            doc.setFontSize(10);
            doc.text(':  ' + `${this.translate.instant('HOME.product_summary')}`,  valueXPos, headerHeight + 10);
    
            doc.setFontSize(10);
            doc.text(`${this.translate.instant('HOME.total_no_of_products')}`, lblXPos, headerHeight + 15);
            doc.setFontSize(10);
            doc.text(':  ' + this.totalProducts,  valueXPos, headerHeight + 15);

            doc.setFontSize(10);
            doc.text(`${this.translate.instant('Table.totl_category')}`, lblXPos, headerHeight + 20);
            doc.setFontSize(10);
            doc.text(':  ' + this.totalCat,  valueXPos, headerHeight + 20);
            
            doc = this.excelService.addTableToPdf(doc, headerHeight + 25, lblXPos, heads, data, null);
        
           
            doc.save(`Product Summary.pdf`);
    
            this.exportLoader = false;
    
    Expformdata.value.export = '';
   

    });

      
    }


}
