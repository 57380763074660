import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { Repprddet } from '../../../../../model/report/repprddet.model';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-rep-product-details',
  templateUrl: './rep-product-details.component.html',
  styleUrls: ['./rep-product-details.component.css']
})
export class RepProductDetailsComponent implements OnInit {
  stkprdsel: any ;
elseBlocknoouterwrap: any ;
  selecTed: any = null;
 stkprd_all:string[];
 units:any[];
 total_stocks =[];
 branch_stocks:any[];
 branch_stksbool:boolean;
 id:any;
 prd_date:any;
 formula:any;
 reqqty:any;
 total_purch_amount:any;
 misc_exp:any;
 commission:any;
 sum_total_purch_amount:any;
 prdn_inspection_staff:any;
 addedby:any;
 purchId:any;
  up: any;
  pageLoadingImg = false;
ttlIn = 0;
ttlout = 0;
purchQtyTtl = 0;
purchPrcTtl = 0;
saleQtyTtl = 0;
salePrcTtl = 0;
saleRetQtyTtl = 0;
saleRetPrcTtl = 0;
transQtyTtl = 0;
transPrcTtl = 0;
transRecQtyTtl = 0;
transRecPrcTtl = 0;
prodQtyTtl = 0;
prodPrcTtl = 0;
purchRetQtyTtl = 0;
purchRetPrcTtl = 0;
stockdata = [];
vanTtl = 0;
shopGdTtl = 0;
ttlStock = 0;
  country_dec: string;

  branch_display_name: any;
  branch_display_code: any;
  usr_hide_purch_cost:any;
  branch_address:any;
  cur_lang: string;
  base_url: string;

  constructor(private apiService: ApiService,private coreService: CoreService,private translate: TranslateService) {}

  ngOnInit() {

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.usr_hide_purch_cost = this.coreService.getUserData('usr_hide_purch_cost');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');
    this.cur_lang = sessionStorage.getItem("baseLang");
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    this.country_dec=this.coreService.setDecimalLength();
    $("#report-wrapper").hide();
    this.up = this.coreService.getUserPrivilage();
    this.base_url = this.apiService.PHP_API_SERVER;
  }

       //product form search 
       searchStkPrd(search: string)
       {
          let searchval = new FormData();      
          searchval.append("keyword", search);
          if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
            searchval.append('alias_search', '1');
          }    
          this.apiService.getPrdRepkbyName(searchval).subscribe((res) => {
            this.stkprd_all = res['data'];
            this.selecTed = null; 
            
          });
       }
  
          //form search select
  selectStockPrd(res:Repprddet)
  {
    console.log(res);
  
    this.pageLoadingImg = true;
    this.selecTed = null;
    this.apiService.getProductDetails(res.prd_id).subscribe((res: any) => {

      this.pageLoadingImg = false;
      this.selecTed = res.data; 
      console.log(res);

      this.stockdata = (this.selecTed.stock_information.van.length > (this.selecTed.stock_information.godown.length +1))? 
                      this.selecTed.stock_information.van : this.selecTed.stock_information.godown

      this.ttlIn = this.selecTed.quantity_summary.opening_stock.av_qty + 
      this.selecTed.quantity_summary.purchase.qty + 
      this.selecTed.quantity_summary.sales_return.qty +
      this.selecTed.quantity_summary.production_output.qty +
      this.selecTed.quantity_summary.branch_receipt.qty +
      this.selecTed.quantity_summary.excess_stock.qty+
      this.selecTed.quantity_summary.stock_reciept.qty;

      this.ttlout = this.selecTed.quantity_summary.purchase_return.qty +
      this.selecTed.quantity_summary.sales.qty+
      this.selecTed.quantity_summary.used_as_ingredient_for_production.qty +
      this.selecTed.quantity_summary.branch_stock_transfer.qty +
      this.selecTed.quantity_summary.damages.qty +
      this.selecTed.quantity_summary.missing_stock.qty+
      this.selecTed.quantity_summary.stock_transfer.qty;

      this.vanTtl = 0;
      // considering stock_information.amount contain ony selected branch info
      this.shopGdTtl = this.selecTed.stock_information.amount[0].bs_stock_quantity_shop;
      
      this.selecTed.stock_information.godown.forEach((data: any) => {
        this.shopGdTtl += data.gs_qty;
      });
      this.selecTed.stock_information.van.forEach((data: any) => {
        this.vanTtl += data.vanstock_qty;
      });
      this.ttlStock = this.shopGdTtl + this.vanTtl;

      this.purchQtyTtl = 0;
      this.purchPrcTtl = 0; 
      this.selecTed.purchases.forEach((purch: any) => {
        this.purchQtyTtl += purch.purchsub_qty;
        this.purchPrcTtl += purch.purchsub_qty * purch.purchsub_rate;
      });

      this.purchRetQtyTtl = 0;
      this.purchRetPrcTtl = 0; 
      this.selecTed.purchase_returns.forEach((data: any) => {
        this.purchRetQtyTtl += data.purchretsub_qty;
        this.purchRetPrcTtl += data.price;
      });

      this.saleQtyTtl = 0;
      this.salePrcTtl = 0;
      this.selecTed.sales.forEach((data: any) => {
        this.saleQtyTtl += data.salesub_qty;
        this.salePrcTtl += data.price;
      });

      this.saleRetQtyTtl = 0;
      this.saleRetPrcTtl = 0;
      this.selecTed.sales_returns.forEach((data: any) => {
        this.saleRetQtyTtl += data.salesretsub_qty;
        this.saleRetPrcTtl += data.price;
      });

      this.transQtyTtl = 0;
      this.transPrcTtl = 0;
      this.selecTed.branch_transfer.forEach((data: any) => {
        this.transQtyTtl += data.stocktrsub_qty;
        this.transPrcTtl += data.price;
      });

      this.transRecQtyTtl = 0;
      this.transRecPrcTtl = 0;
      this.selecTed.branch_receipt.forEach((data: any) => {
        this.transRecQtyTtl += data.stocktrsub_qty;
        this.transRecPrcTtl += data.price;
      });

      this.prodQtyTtl = 0;
      this.prodPrcTtl = 0;
      this.selecTed.production_summary.forEach((data: any) => {
        this.prodQtyTtl += data.prdnsub_qty;
        this.prodPrcTtl += data.prdnsub_qty * data.prdnsub_rate;
      });
    });
  }
}
