import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { ApiService } from '../../../../../service/api.service';
import { CoreService } from '../../../../../service/core.service';
import { TranslateService } from '@ngx-translate/core';
import { NgForm } from '@angular/forms';
import { Group } from '../../../../../model/group.model';
import { Ledger } from '../../../../../model/ledger.model';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-journal',
  templateUrl: './journal.component.html',
  styleUrls: ['./journal.component.css']
})
export class JournalComponent implements OnInit {

  @ViewChild('closeModal', { static: false }) closeModal: ElementRef;
  resultobj: any = {};
  info = [];
  rec = {
    rec_no: '',
    rec_date: new Date(),
    rec_acc: { ledger_id: 0 },
    rec_balance: 0,

    note: '',
    ttlAmount: 0,
    rec_bal_sign: '',
    journalType: 1,
    godown_id: 0,
    ledgers: [{
      sub_id: '',
      ledger: <any>{},
      accLoading: false,
      accLoadingcr: false,
      rec_balance: 0,
      rec_balancecr: 0,
      rec_bal_sign: '',
      rec_bal_signcr: '',
      amount: <any> '',
      narration: '',
      fnlAmount: 0
    }]
  };
  account = [];
  nonAccount = [];
  isSubmitInProg = false;

  curpage = 1;
  lastpage = 1;
  perpage = 20;
  totalItem = 0;
  curForm = 'Create';
  pageLoadingImg = false;
  accLoading: boolean;
  nextId: any;
  lastledId: any;
  recPriviewLoader: boolean;
  printPreview: any;
  branch_display_name: any;
  branch_name: any;
  branch_display_code: any;
  branch_address: any;
  img_url: any;
  branch_phone: any;
  branch_tin: any;
  branch_mob: any;
  branch_reg_no: any;
  base_url: string;
  up: any;
  langText = {
    create: '',
    update: ''
  };
  cmpny = '';
  userType = '';
  pageLoadingImg1 = false;
  isSubmitInProg1 = false;
    customer = {
      is_supplier:false,
      id: '',
      ledger_id: '',
      name: '',
      alias: '',
      code: '',
      email : '',
      mobile: '',
      vat_no: '',
      op_bal: 0,
      op_bal_type: true,
      brch_id:0,
      usr_type:0,
      add: {
        addr: '',
        zip: '',
        city: '',
        country: '',
        state: '',
        state_code: '',
        fax: ''
      },
      del: {
        addr: '',
        zip: '',
        city: '',
        country: '',
        state: '',
        state_code: '',
        fax: ''
      },
      due_day: '',
      van_line: 0,
      price_group: 1,
      note: ''
    };
    ledger_name: any;
    ledger_accgrp_id: any;
    ledger_branch_id: any;
    ledger_alias: any;
    ledger_code: any;
    ledger_email: any;
    ledger_mobile: any;
    ledger_due: any;
    ledger_tin: any;
    ledger_address: any;
    ledger_notes: any;
    ledgersel: any;
    selData: any = { ledger_id: null, error: null, ledger_name: null, message: null, };
    group_all: string[];
    ledger_all: string[];
    branch_all: string[];
    leadger: any;
    private modalRef: NgbModalRef;
    closeResult: string;  
    customer_type = false;
    printError: any;
    searchleadger: any;
    supplier = false;
    godowns: any;
    priceGroupList = [];
    ledger_pricegrp_id = 1;
    isCostCategoryLedger = false;
    costcenterList:any;
    isCCContentLoading = false;
    ccindex:any;
    isCostCategoryLedger_c = false;
    costcenterList_c:any;
    isCCContentLoading_c = false;
    ccindex_c:any;
    logoImgUrl :any = '';
  country_dec: string;
  header_type: any;
  logoImg: any;
  vans: any;
  allvans: any;
  usr_block_pre_date_select: any;
  minDate: Date;

  constructor(private apiService: ApiService, private coreService: CoreService, private translate: TranslateService,private modalService: NgbModal) { }

  ngOnInit() {
    this.translate.get(['Common.create', 'Common.update']).subscribe((res: string) => {
      this.langText.create = res['Common.create'];
      this.langText.update = res['Common.update'];
      this.curForm = this.langText.create;
      this.getAllGroup();
    });
    this.getAllAccount();
    this.getAllNoncashLedgers();
    this.getInfo(this.curpage);
    this.getNextJournalId();
    this.getHeaderImage();
    this.searchAllVans('');


    this.cmpny = this.coreService.getUserData('cmpny');
    this.userType = this.coreService.getUserData('user_type');
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name = this.coreService.getUserData('branch_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');
    this.img_url = this.coreService.getUserData('img_url');
    this.branch_phone = this.coreService.getUserData('branch_phone');
    this.branch_mob = this.coreService.getUserData('branch_mob');
    this.branch_tin = this.coreService.getUserData('branch_tin');
    this.branch_reg_no = this.coreService.getUserData('branch_reg_no');
    this.base_url = location.origin;
    this.up = this.coreService.getUserPrivilage();
    this.listGodowns();
    this.apiService.getAllPriceGroup().subscribe((res) => {
      this.priceGroupList = res.data;
    });
    this.country_dec=this.coreService.setDecimalLength();

    this.usr_block_pre_date_select = this.coreService.getUserData("usr_block_pre_date_select");
    if(this.usr_block_pre_date_select){
      this.minDate= new Date();
    }else{
      this.minDate= new Date(this.coreService.getUserData("branch_open_date"));
    }
  }

  listGodowns() {
    let searchval = new FormData();
    this.apiService.getGodownList(searchval).subscribe((res) => {
      this.godowns = res['data'];

    });
  }
  searchVans(search: string) {
    const searchval = new FormData();
    searchval.append('van_name', search);
    this.apiService.searchVans(searchval).subscribe((res) => {
      
      this.vans = res['data'];
    });

  }

  searchAllVans(search: string) {
    const searchval = new FormData();
    searchval.append('van_name', search);
    this.apiService.searchVans(searchval).subscribe((res) => {
      
      this.allvans = res['data'];
      this.vans = res['data'];
    });

  }

  getInfo(pageNo) {
    this.pageLoadingImg = true;
    this.apiService.getAllJournal(pageNo, this.perpage).subscribe((res) => {
      this.info = res.data.data;
      this.pageLoadingImg = false;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
    });
  }
  getAllAccount() {
    this.apiService.getAllAccount().subscribe((res) => {
      this.account = res.data;
    });
  }
  getAllNoncashLedgers() {
    this.apiService.getAllNonCashLedgers().subscribe((res) => {
      this.nonAccount = res.data;
    });
  }
  addMore() {
    this.rec.ledgers.push({
      sub_id: '',
      ledger: { ledger_id: this.rec.ledgers[this.rec.ledgers.length - 1].ledger.ledger_id, jn_acc_ledId: this.rec.ledgers[this.rec.ledgers.length - 1].ledger.jn_acc_ledId },
      amount: '',
      narration: '',
      accLoading: false,
      accLoadingcr: false,

      rec_balance: 0,
      rec_balancecr: 0,
      rec_bal_sign: '',
      rec_bal_signcr: '',
      fnlAmount: 0
    });

    this.getAccLedgerBalance(this.rec.ledgers.length - 1);
    this.getLedgerBalance(this.rec.ledgers.length - 1);

  }
  remove(index) {
    if (confirm('Do you wish to remove this record?')) {
      if(this.ccindex == index){
        this.isCostCategoryLedger = false;
        this.costcenterList = [];
        this.isCCContentLoading = false;
        this.ccindex = null;
        this.isCostCategoryLedger_c = false;
        this.costcenterList_c = [];
        this.isCCContentLoading_c = false;
        this.ccindex_c = null;
      }
      this.rec.ledgers.splice(index, 1);
      this.updateTotoal();
    }
  }
  createJn(){
    this.curForm = this.langText.create;
    this.nextId = '';
    this.getNextJournalId();
    this.clearForm();
  }
  clearForm() {
    this.rec.ttlAmount = 0;
    this.rec.rec_date = new Date();
    this.rec.godown_id = 0;
    this.rec.ledgers = [];
    this.rec.ledgers = [{
      sub_id: '',
      ledger: { ledger_id: {}, jn_acc_ledId: {} },
      accLoading: false,
      accLoadingcr: false,
      rec_balance: 0,
      rec_balancecr: 0,
      rec_bal_sign: '',
      rec_bal_signcr: '',
      amount: '',
      narration: '',
      fnlAmount: 0
    }];
    this.isCostCategoryLedger = false;
    this.costcenterList = [];
    this.isCCContentLoading = false;
    this.ccindex = null;
    this.isCostCategoryLedger_c = false;
    this.costcenterList_c = [];
    this.isCCContentLoading_c = false;
    this.ccindex_c = null;
  }

  getAccLedgerBalance(i, jn_no=0) {

    if (Object.keys(this.rec.ledgers[i].ledger.jn_acc_ledId).length === 0) {

    } else {
      if(this.rec.ledgers[i].ledger.jn_acc_ledId && this.rec.ledgers[i].ledger.jn_acc_ledId.is_cost_category){
        this.isCostCategoryLedger = true;
        this.isCCContentLoading = true;
        this.ccindex = i;
        this.apiService.getCostCenter({ledger_id:this.rec.ledgers[i].ledger.jn_acc_ledId.ledger_id, refno: jn_no, vch_type:3}).subscribe((res) => {
          this.costcenterList = res.data;
          this.isCCContentLoading = false;
        });
      } else{
        this.isCostCategoryLedger = false;
        this.costcenterList = [];
        this.isCCContentLoading = false;
        this.ccindex = null;
      }
      this.rec.ledgers[i].accLoadingcr = true;
      //  console.log(this.rec.ledgers[i].ledger.jn_acc_ledId['ledger_id']);
      this.apiService.getLedgerBalance({ ledger_id: this.rec.ledgers[i].ledger.jn_acc_ledId['ledger_id'] }).subscribe((res) => {
        this.rec.ledgers[i].accLoadingcr = false;
        this.rec.ledgers[i].rec_balancecr = res.data.bal_without_sign;
        this.rec.ledgers[i].rec_bal_signcr = res.data.bal_sign;
      });
    }
  }

  getLedgerBalance(i, jn_no=0) {

    if (Object.keys(this.rec.ledgers[i].ledger.ledger_id).length === 0) {
    } else {
      if(this.rec.ledgers[i].ledger.ledger_id && this.rec.ledgers[i].ledger.ledger_id.is_cost_category){
        this.isCostCategoryLedger_c = true;
        this.isCCContentLoading_c = true;
        this.ccindex_c = i;
        this.apiService.getCostCenter({ledger_id:this.rec.ledgers[i].ledger.ledger_id.ledger_id, refno: jn_no, vch_type:3, credit: true}).subscribe((res) => {
          this.costcenterList_c = res.data;
          this.isCCContentLoading_c = false;
        });
      } else{
        this.isCostCategoryLedger_c = false;
        this.costcenterList_c = [];
        this.isCCContentLoading_c = false;
        this.ccindex_c = null;
      }
      this.rec.ledgers[i].accLoading = true;
      // console.log(this.rec.ledgers[i].ledger.ledger_id['ledger_id']);

      this.apiService.getLedgerBalance({ ledger_id: this.rec.ledgers[i].ledger.ledger_id['ledger_id'] }).subscribe((res) => {
        this.rec.ledgers[i].accLoading = false;
        this.rec.ledgers[i].rec_balance = res.data.bal_without_sign;
        this.rec.ledgers[i].rec_bal_sign = res.data.bal_sign;
      });
    }
  }

  getNextJournalId() {
    this.apiService.getNextJournalId().subscribe((res) => {
      this.nextId = res.data.ref_no;
    });
  }

  updateTotoal() {
    this.rec.ttlAmount = 0;
    this.rec.ledgers.forEach(element => {
      this.rec.ttlAmount += Number(element.amount);
    });
  }


  viewJournal(id) {
    this.recPriviewLoader = true;

    this.apiService.getJournal({ ref_no: id }).subscribe((res) => {
      this.recPriviewLoader = false;

      this.printPreview = res;

      // console.log(this.printPreview.rec_ttl_amount);

    });

  }

  editVoucher(jn_no){
    this.nextId = '';
    this.curForm = this.langText.update;
    this.clearForm();
    this.resultobj = [];
    this.apiService.getJournal({ref_no: jn_no}).subscribe((res) => {
        this.nextId = res.jn_no;
        this.rec.rec_no = res.jn_no;
        this.rec.rec_date = new Date(res.jn_datetime);
        this.rec.rec_acc = { ledger_id: 0 };
        this.rec.rec_balance = 0;
        this.rec.godown_id = res.godown_id;
        this.rec.note = res.jn_note;
        this.rec.ttlAmount = res.jn_ttl_amount;
        this.rec.rec_bal_sign = '';
        this.rec.journalType =  1;
        this.rec.ledgers = [];
        res.particulars.forEach((element,i) => {
          const result = this.nonAccount.filter(nonacc => nonacc.ledger_id == element.jnsub_ledger_id);
          const result1 = this.nonAccount.filter(nonacc => nonacc.ledger_id == element.jnsub_acc_ledger_id);
          var tmpRow = {
            sub_id: element.jnsub_id,
            ledger: { ledger_id: result[0], jn_acc_ledId: result1[0] },
            accLoading: true,
            accLoadingcr: true,
            rec_balance: 0,
            rec_balancecr: 0,
            rec_bal_sign: '',
            rec_bal_signcr: '',
            amount: element.jnsub_amount,
            narration: element.jnsub_narration,
            fnlAmount: element.jnsub_amount,
            van_id: element.van_id
          };
          this.rec.ledgers.push(tmpRow);
          this.getAccLedgerBalance(i, jn_no);
          this.getLedgerBalance(i, jn_no);
        });
        
        this.updateTotoal();
    });
  }


  validateAndSubmit(journalForm: NgForm) {

    this.resultobj = [];

    if (this.rec.rec_date == undefined) {
      console.log('date undefined');
      this.resultobj.rec_date = "t";
      this.resultobj.push({ 'rec_date': 'Required' });
    }
    else {
      if (this.rec.rec_date > new Date()) {
        console.log('date greter currnt date');
        console.log(this.rec.rec_date);
        console.log(new Date());
        this.resultobj.rec_date = "t";
        this.resultobj.push({ 'rec_date': 'Required' });
      } else {
        console.log('date ok');
        this.resultobj.rec_date = "f";
      }
    }
    if (this.resultobj.length <= 0) {

      if (confirm('Do you wish to continue?')) {

        this.isSubmitInProg = true;
        let callFunction = '';
        if (this.curForm === this.langText.create) {
          callFunction = 'createJournal';
        }
        if (this.curForm === this.langText.update) {
          callFunction = 'updateJournal';
        }
        if(this.ccindex != null){
          this.rec.ledgers[this.ccindex].ledger.jn_acc_ledId.cc = this.costcenterList;
        }
        if(this.ccindex_c != null){
          this.rec.ledgers[this.ccindex_c].ledger.ledger_id.cc = this.costcenterList_c;
        }
        this.apiService[callFunction](this.rec).subscribe((res) => {
          this.isSubmitInProg = false;
          if (res.error != null) {
            this.resultobj = res.error;
          } else {
            this.closeModal.nativeElement.click();
            this.getInfo(this.curpage);
            this.coreService.showMessage(res.message);
            this.clearForm();
            this.resultobj = {};
            this.rec.ttlAmount = 0;
            journalForm.reset();
            this.clearForm();
          }

        });
      }
    }
  }


  voidJournal(id) {

    if (confirm('Do you wish to continue?')) {
      this.apiService.voidJournal({ jn_no: id }).subscribe((res) => {
        this.isSubmitInProg = false;
        if (res.error != null) {
          this.coreService.showMessage(res.error);
          this.resultobj = res.error;
        } else {
          $('.close').click();
          this.getInfo(this.curpage);
          this.coreService.showMessage(res.message);
        }

      });
    }
  }


  searchInp(e) {
    const searchval = new FormData();
    searchval.append('search', e);
    this.apiService.searchjournalList(searchval).subscribe((res) => {
      this.info = res.data.data;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
    });
  }
  removeName() {
    this.ledger_all = [];
    $('.prev-list').hide();
  }
  
  getAllGroup() {
    this.apiService.readAllGrp().subscribe((res: Group[]) => {
      // console.log(res['data']);
      const LedgGroup = res['data'].filter((group) => group.accgrp_id !== 21);
      this.group_all = LedgGroup;
    });
  }
  openModal(content: any, size) {
  
    this.modalRef = this.modalService.open(content, { size: size, backdrop: 'static' });
    this.modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  
  validateAndSubmit1(){
    this.isSubmitInProg1 = true;
    let callFunction = 'createCustomer';
    
    if(this.userType =='ADMIN'){
      this.customer.usr_type = 1;
    }else{
      this.customer.usr_type = 0;
    
    }
    this.customer.is_supplier = this.supplier;
    this.apiService[callFunction](this.customer).subscribe((res) => {
      // console.log(this.staff);
      this.isSubmitInProg1 = false;
      if (res.error != null) {
        this.resultobj = res.error;
      } else {
        
        this.coreService.showMessage(res.message);
        // this.clearForm();
        this.resultobj = {};
        this.modalRef.close();
        this.clearCustForm();
        this.supplier = false;
        this.customer_type = false;
        this.getAllNonAccountLedgers();
      }
  
    });
  }
  createSupplier(formgroup: { value: any; }) {
    this.validatesupplier(formgroup.value);
    if (this.printError.length <= 0) {
      this.pageLoadingImg1 = true;
      
    if(this.userType =='ADMIN'){
      formgroup.value.usr_type = 1;
    }else{     
      formgroup.value.usr_type = 0;
    
    }
  
    this.apiService.createSupplier(formgroup.value).subscribe((res) => {
      this.pageLoadingImg1=false;
  
      if (res.error != null) {
        this.resultobj = res.error;
        this.resultobj = this.coreService.getValidation(this.resultobj);
      } else {
        this.coreService.createfunct(formgroup, this.resultobj, res.message);
        this.resultobj = {};
        this.modalRef.close();
        this.supplier = false;
        this.getAllNonAccountLedgers();
      }
  
    });
  }
  }
  validatesupplier(list) {
   
    this.printError = [];
    if (!list.supp_name) {
      this.printError.push({ 'supp_name': 'Required' });
      $('.supp_name').css("border", "1px solid red");
      $(".supp_name_err").text("Supplier name required");
    } else {
      $('.supp_name').css("border", "1px solid #dedede");
      $(".supp_name_err").text("");
    }
  }
  createLedger(formgroup: { value: any; }) {
  this.pageLoadingImg1 = true;
  this.apiService.createLedgerPopup(formgroup.value).subscribe((res: Ledger) => {
    this.pageLoadingImg1 = false;
  
    if (res.error != null) {
      this.resultobj = res.error;
      this.resultobj = this.coreService.getValidation(this.resultobj);
    }
    else {
      this.coreService.createfunct(formgroup, this.resultobj, res.message);
      this.resultobj = {};
      this.modalRef.close();
      this.getAllNonAccountLedgers();
    }
  
  });
  }
  searchLedger(search: string) {
  let searchval = new FormData();
  searchval.append("search", search);
  this.apiService.searchLedgerList(searchval, 1).subscribe((res) => {
    this.searchleadger = res['data']['data'];
    // console.log(this.searchleadger);
  });
  $('.prev-list').show();
  }
  clearCustForm() {
  this.customer = {
    is_supplier:false,
    id: '',
    ledger_id: '',
    name: '',
    alias: '',
    code: '',
    email : '',
    mobile: '',
    vat_no: '',
    op_bal: 0,
    op_bal_type: true,
    brch_id:0,
    usr_type:0,
    add: {
      addr: '',
      zip: '',
      city: '',
      country: '',
      state: '',
      state_code: '',
      fax: ''
    },
    del: {
      addr: '',
      zip: '',
      city: '',
      country: '',
      state: '',
      state_code: '',
      fax: ''
    },
    due_day: '',
    van_line: 0,
    price_group: 1,
    note: ''
  };
  }
  getAllNonAccountLedgers() {
    this.apiService.getAllNonAccountLedgers().subscribe((res) => {
      this.nonAccount = res.data;
    });
  }

  getHeaderImage() {
    this.apiService.getSettings().subscribe((res) => {

      this.header_type = res.data.prnt_header_type;
      this.logoImg = res.data.prnt_header_logo;

      if(res.data.prnt_header_logo && res.data.prnt_header_type != 2){
        this.logoImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_logo_img_path;
      }else if (res.data.prnt_common_header_img) {
        this.logoImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_common_header_img;
      } else {
        this.logoImgUrl = '';
      }
      

      // if (res.data.prnt_header_type === 2) {

      //   if(res.data.prnt_common_header_img){
      //     this.logoImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_common_header_img;
      //   }else if(res.data.prnt_header_img_path){
      //     this.logoImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_img_path;
      //   }else{
      //     this.logoImgUrl = '';
      //   } 
        
      // } else {
      //   this.logoImgUrl = '';
      // }
    });
  }


}
