import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
@Component({
  selector: 'app-van-settings',
  templateUrl: './van-settings.component.html',
  styleUrls: ['./van-settings.component.css']
})

export class VanSettingsComponent implements OnInit {



  types = [
    { id: 0, name: 'Normal' },
    { id: 1, name: 'Extented' },
  ];
  search_mthd = [
    { id: 0, name: 'By Barcode' },
    { id: 1, name: 'By Product Name' },
  ];
  rates = [
    { id: 0, name: 'Sales Rate' },
    { id: 1, name: 'Purchase Rate' },
  ];

  van_sales = [
    { id: 0, name: 'No' },
    { id: 1, name: 'Yes' },
  ];

  ret_duplicate_items = [
    { id: 0, name: 'Append' },
    { id: 1, name: 'Seperated' },
  ];

  van_rate = [
    { id: 0, name: 'Disable' },
    { id: 1, name: 'Enable' },
  ];
  display_qrs = [
    { id: 1, name: 'Show' },
    { id: 0, name: 'Hide' },
  ];
  inv_types = [
    { id: 1, name: 'Tax Invoice' },
    { id: 2, name: 'Simplified Tax Invoice' },
    { id: 3, name: 'Invoice' }
  ];
  rate_types = [
    { id: 0, name: 'Excl.vat' },
    { id: 1, name: 'Incl.vat' },
  ];
  cust_gel_loc_editable_type = [
    { id: 0, name: 'Disable' },
    { id: 1, name: 'Enable' },
  ];

  cust_gel_loc_enable_type = [
    { id: 0, name: 'Disable' },
    { id: 1, name: 'Enable' },
  ];

  ss_van_rate2_enabled_type = [
    { id: 0, name: 'Disable' },
    { id: 1, name: 'Enable' },
  ];

  ss_van_default_rates = [
    { id: 1, name: 'Rate1' },
    { id: 2, name: 'Rate2' },
  ];
  ss_van_cust_inv_type_auto_enable_type=[
    { id: 0, name: 'Disable' },
    { id: 1, name: 'Enable' },
  ];
  van_return_rates=[
    { id: 0, name: 'Last Transfer Rate' },
    { id: 1, name: 'Sales Rate' },
  ];
  settings: any;
  pageLoadingImg: boolean;
  van_searchType: any;
  dflt_search_methd: any;
  van_default_rate: any;
  van_sales_by_unit: any;
  van_block_cust_creation = 0;
  hide_van_zero_stock_items = 0;
  disc_vanlist:any[];
  disc_types = [
    { id: 0, name: 'No Limit' },
    { id: 1, name: 'Limit' },
  ];
  disc_pageLoadingImg: boolean;
  hide_van_transfr_rate: any;
  van_block_sale_date_change: any;
  van_item_rate_validation: any;
  van_app_reciept_write_off_option: any;
  van_app_invoice_types: any;
  van_disply_qr: any;
  van_cust_balance: any;
  van_sales_rate_type: any;
  ss_cust_geoloc_editable: any;
  ss_cust_geoloc_enabled :any;
  ss_van_cust_inv_type_auto_enable:any;
  ss_van_rate2_enabled:any;
  ss_van_default_rate:any;
  van_transfer_ptype: any;
  van_block_sreturn_without_inv: any;
  van_damage_enable: any;
  van_damage_menu_enable: any;
  van_sales_order_enable: any;
  van_transfer_request_enable: any;
  van_pos_enable: any;
  van_rate_edit_enable: any;
  van_discount_enable: any;
  van_selfie_attendance_enable: any;
  van_tarnsfer_return_rate: any;
  van_ret_duplicate_item: any;
  van_show_balance: any;
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService) { }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.getSalesSetting();
    this.getVanDiscountSetting();
    this.getTemplete();
  }
  getVanDiscountSetting()
  {
    this.disc_pageLoadingImg = true;
    this.apiService.getVanDiscountSetting().subscribe((res) => {
      this.disc_pageLoadingImg = false;
      this.disc_vanlist = res['data'];
    });
  }

  getSalesSetting() {

    this.apiService.getSalesSetting().subscribe((res) => {
      if (res['data']) {
        this.settings = res['data'];
        console.log(' this.settings ', this.settings );
        
        this.van_searchType = this.settings.van_searchType;
        this.dflt_search_methd = this.settings.van_default_search;
        this.van_default_rate = this.settings.van_default_rate;
        this.van_transfer_ptype = this.settings.van_transfer_rate_is_avg_prate;
        this.van_sales_by_unit = this.settings.van_sales_by_unit;
        this.van_block_cust_creation = this.settings.van_block_cust_creation;
        this.hide_van_zero_stock_items = this.settings.hide_van_zero_stock_items;
        this.hide_van_transfr_rate = this.settings.hide_van_transfr_rate;
        this.van_block_sale_date_change = this.settings.van_block_sale_date_change;
        this.van_item_rate_validation = this.settings.van_item_rate_validation;
        this.van_app_reciept_write_off_option = this.settings.van_app_reciept_write_off_option;
        this.van_app_invoice_types = this.settings.van_app_invoice_types;
        this.van_cust_balance = this.settings.van_customer_balance;
        this.van_sales_rate_type = this.settings.van_sales_rate_type;
        this.van_block_sreturn_without_inv = this.settings.van_sreturn_without_inv;
        this.ss_cust_geoloc_editable = this.settings.ss_cust_geoloc_editable;
        this.ss_cust_geoloc_enabled =this.settings.ss_cust_geoloc_enabled;
        this.ss_van_cust_inv_type_auto_enable =this.settings.ss_van_cust_inv_type_auto_enable;
        this.ss_van_rate2_enabled =this.settings.ss_van_rate2_enabled;
        this.ss_van_default_rate = this.settings.ss_van_default_rate;
        this.van_damage_enable =this.settings.van_damage_enable;
        this.van_tarnsfer_return_rate =this.settings.ss_van_return_default_rate;
        this.van_ret_duplicate_item =this.settings.ss_van_transf_ret_duplicate_item;
        this.van_show_balance =this.settings.ss_van_show_balance;

        this.van_discount_enable =this.settings.ss_van_discount_enable;
        this.van_rate_edit_enable =this.settings.ss_van_rate_edit_enable;
        this.van_pos_enable =this.settings.ss_van_pos_enable;
        this.van_transfer_request_enable =this.settings.ss_van_transfer_request_enable;
        this.van_sales_order_enable =this.settings.ss_sales_order_enable	;
        this.van_damage_menu_enable =this.settings.ss_van_damage_menu_enable;
        this.van_selfie_attendance_enable =this.settings.ss_van_selfie_attndnc_enable;
      
      } else { 
        this.van_searchType = 0;
        this.van_sales_by_unit = 0;
        this.van_cust_balance = 0;
      }
    });

    console.log("shameer");
    console.log(this.ss_van_default_rate);

  }
  update(formdata) {
    this.pageLoadingImg = true;
    formdata.value.aa = 'value2';
    formdata.value.discounts = JSON.stringify(this.disc_vanlist);
    // formdata.append("selected_deductions", JSON.stringify(this.selected_deductions));
    this.apiService.vanSetting(formdata.value).subscribe((res) => {
      this.pageLoadingImg = false;
      if (res.data) {
        this.coreService.showMessage('Setting Saved');
      }
    }, (error) => {
      this.pageLoadingImg = false;
    });
  }

  langChange(){
    this.translate.get(['Common.normal', 'Common.extended',
    'Common.No','Common.Yes','Common.Disable','Common.Enable','Common.Purch_Rate','Common.Sales_Rate','Common.Tax_Invoice','Common.Simplified_Tax_Invoice','Common.Invoice','Common.No_limit','Common.limit','Common.by_barcode','Common.by_prd_name'
  ]).subscribe((res: string) => {    
      

      this.types = [
        { id: 0, name: res['Common.normal']},
        { id: 1, name: res['Common.extended'] },
        
      ];
      this.van_sales = [
        { id: 0, name: res['Common.No']},
        { id: 1, name: res['Common.Yes'] },
        
      ];
      this.van_rate = [
        { id: 0, name: res['Common.Disable']},
        { id: 1, name: res['Common.Enable'] },
      ];
      this.rates = [
        { id: 0, name: res['Common.Purch_Rate']},
        { id: 1, name: res['Common.Sales_Rate'] },
      ];
      this.search_mthd = [
        { id: 0, name: res['Common.by_barcode']},
        { id: 1, name: res['Common.by_prd_name'] },
      ];
      this.disc_types = [
        { id: 0, name: res['Common.No_limit']},
        { id: 1, name: res['Common.limit'] },
      ];
      this.inv_types = [
        { id: 1, name: res['Common.Tax_Invoice']},
        { id: 2, name: res['Common.Simplified_Tax_Invoice'] },
        { id: 3, name: res['Common.Invoice'] },
       
      ];
    });


  }

  getTemplete() {

    this.apiService.getSettings().subscribe((res) => {
      
      this.van_disply_qr = res.data.prnt_display_qr_code;

    });
  }

}
