import { Component, Input, OnInit } from '@angular/core';
import { CoreService } from 'src/app/service/core.service';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
@Component({
  selector: 'app-qtn-template-two-print',
  templateUrl: './qtn-template-two-print.component.html',
  styleUrls: ['./qtn-template-two-print.component.css']
})
export class QtnTemplateTwoPrintComponent implements OnInit {

  @Input()saleQtnData;
  @Input()print_style;
  @Input()qr_inv;
  @Input()savedTemplate;
  @Input()is_prew_description;
  @Input()payTypes;
  @Input()cmpny;
  @Input()previewLoader;
  @Input()logoImgUrl;
  @Input()tempImgUrl;
  @Input()footImgUrl;
  @Input()sales_print_name;
  @Input()hide_item_discount;
  @Input()hide_vat;
  @Input()sales_settings;
  country_dec: any;
  noPage = [];
  noRowPerPage = 20;
  ttlPage = 1;
  lastpageIteemCount =0;
  data = [];

  constructor(private coreService: CoreService,private translate: TranslateService,) { }

  ngOnInit() {

    this.country_dec=this.coreService.setDecimalLength();
  }

  ngOnChanges(changes) {
    if (changes['saleQtnData'] && this.saleQtnData) {
      this.data = this.saleQtnData.sales_qtn_sub;
      this.ttlPage = Math.ceil(this.saleQtnData.sales_qtn_sub.length /this.noRowPerPage);
      this.lastpageIteemCount = this.saleQtnData.sales_qtn_sub.length % this.noRowPerPage;
      if(this.lastpageIteemCount == 0 || this.lastpageIteemCount > 16){
        this.ttlPage = this.ttlPage +1;
      }
      this.noPage = this.createRange(this.ttlPage);
    }
  }
  createRange(number,start=0){   
    let noPage = [];
    for(var i = start; i < number; i++){
       noPage.push(i);
    }
    return noPage;
  }

}
