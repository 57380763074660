import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-purchase-settings',
  templateUrl: './purchase-settings.component.html',
  styleUrls: ['./purchase-settings.component.css']
})

export class PurchaseSettingsComponent implements OnInit {
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '150px',
    minHeight: '150px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'http://127.0.0.1:8000/api/saless/upimgs',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };
  purchasePayType = [
    { id: 1, name: 'Credit' },
    { id: 2, name: 'Cash' }

  ];
  purch_pay_type: any;

  purchase_types = [
    { id: 1, name: 'Standard Rated Domestic' },
    { id: 2, name: 'Zero Rated Domestic' },
    { id: 3, name: 'Exempt Purchase' },
    { id: 4, name: 'Imports Vat Paid to Customs' },
    { id: 5, name: 'Imports Vat - reverse charge mechanism' }
  ];

  search_methods = [
    { id: 1, name: 'Product Search' },
    { id: 2, name: 'Barcode Search' }
  ];
  enbl_dsbl = [
    { id: 0, name: 'Disable' },
    { id: 1, name: 'Enable' },
  ];

  purch_sub_descriptions = [
    { id: 0, name: 'Disable' },
    { id: 1, name: 'Enable' },
  ];

  purch_godowns = [
    { id: 0, name: 'Disable' },
    { id: 1, name: 'Enable' },
  ];
  search_with_only_brcd = [
    { id: 0, name: 'Disable' },
    { id: 1, name: 'Enable' },
  ];
 

  pageLoadingImg: boolean;
  settings: any;
  purchtypesel: any;
  srch_mtd: any;
  freight_charges_on_total : any;
  purch_sub_description : any;
  show_goods_receipt:any;
  edit_goods_receipt:any;
  delete_goods_receipt:any;
  purch_godown:any;
  categories: any;
  hide_prdct_by_cat: any;
  po_default_terms = '';
  ps_search_with_only_barcod: any;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService) { }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    // this.searchCat('');
    this.langChange();
    this.getPurchSetting();
  }


  update(formdata: { value: any; }) {
    this.pageLoadingImg = true;

    this.apiService.updatePurchSetting(formdata.value).subscribe((res) => {
      this.pageLoadingImg = false;
      if (res.data) {
        this.coreService.showMessage('Setting Saved');

      }
    }, (error) => {
      this.pageLoadingImg = false;
      // console.log(error);
    });
  }


  getPurchSetting() {

    this.apiService.getPurchSetting().subscribe((res) => {
      if (res['data']) {
        this.settings = res['data'];
        this.purch_pay_type = this.settings.ps_pay_type;
        this.purchtypesel = this.settings.ps_purch_type;
        this.srch_mtd = this.settings.ps_srch_mtd;
        this.freight_charges_on_total = this.settings.ps_freight_charges_on_total;
        this.purch_sub_description = this.settings.ps_show_purch_sub_description;
        this.show_goods_receipt = this.settings.show_goods_receipt;
        this.edit_goods_receipt = this.settings.edit_goods_receipt;
        this.delete_goods_receipt = this.settings.delete_goods_receipt;
        this.purch_godown = this.settings.ps_godown;
        this.po_default_terms = this.settings.ps_po_default_terms;
        this.ps_search_with_only_barcod = this.settings.ps_search_with_only_barcod;
        // this.hide_prdct_by_cat = this.settings.ps_hidden_cat_ids;
      } else { // Default values if not setted
        this.purch_pay_type = 1;
        this.purchtypesel = 1;
        this.srch_mtd = 1;
        this.freight_charges_on_total = 0;
        this.purch_sub_description = 0;
        this.show_goods_receipt =0;
        this.edit_goods_receipt =0;
        this.delete_goods_receipt =0;
        this.purch_godown = 0;
        this.po_default_terms = '';
      }
    });

  }

  langChange(){
    this.translate.get(['Common.cash', 'HOME.credit','Common.standard_rated_domestic','Common.zero_rated_domestic','Common.exempt_purchase','Common.imports_vat_rvs_chage_mechnsm','Common.imports_vat_paid_custms','Common.Product_Search','Common.barcde_Search','Common.Disable','Common.Enable']).subscribe((res: string) => {    
     

      this.purchasePayType = [
        { id: 1, name: res['HOME.credit']},
        { id: 2, name: res['Common.cash'] },
  
      ];

      this.purchase_types = [
        { id: 1, name: res['Common.standard_rated_domestic']},
        { id: 2, name: res['Common.zero_rated_domestic'] },
        { id: 3, name: res['Common.exempt_purchase'] },
        { id: 4, name: res['Common.imports_vat_paid_custms'] },
        { id: 5, name: res['Common.imports_vat_rvs_chage_mechnsm'] },
  
      ];

      this.search_methods = [
        { id: 1, name: res['Common.Product_Search']},
        { id: 2, name: res['Common.barcde_Search'] },
  
      ];
      this.enbl_dsbl = [
        { id: 0, name: res['Common.Disable']},
        { id: 1, name: res['Common.Enable'] },

      ];
    
      this.purch_sub_descriptions = [
        { id: 0, name: res['Common.Disable']},
        { id: 1, name: res['Common.Enable'] },
      ];
    
      this.purch_godowns = [
        { id: 0, name: res['Common.Disable']},
        { id: 1, name: res['Common.Enable'] },
      ];
      this.search_with_only_brcd = [
        { id: 0, name: res['Common.Disable']},
        { id: 1, name: res['Common.Enable'] },
        
      ];
    });

  }

  // search category

  searchCat(search: string) {
    let searchval = new FormData();
    searchval.append("cat_name", search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.categories = res['data'];     
    });  
  }

}
