import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { Repprddet } from '../../../../../model/report/repprddet.model';
import { Subcatagory } from '../../../../../model/subcatagory.model';
import { Catagory } from '../../../../../model/catagory.model';
import { Feature } from '../../../../../model/feature.model';
import { Branch } from 'src/app/model/branch.model';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-branch-wise',
  templateUrl: './branch-wise.component.html',
  styleUrls: ['./branch-wise.component.css']
})
export class BranchWiseComponent implements OnInit {
  prd_sub_cat_id: any ;
  elseBlocknostart: any ;
  elseBlocknoend: any ;
  manufacture_id: any ;
  unitid: any ;
  feat_id: any ;
  feat_val: any ;
  vat_type: any ;
  vat_val_1: any ;
  vat_val_2: any ;
  selectFeat: any ;
  allfeat: any ;
  selectType: any ;
  id: any ;
  prd_ean:any;
  products:any;
  products_all: string[];
  catagories: string[];
  subcatagories: string[];
  mfs: string[];
  cat_id: number;
  prodsumms: any[];
  unit_all: string[];
  features: string[];
  baseunit: any;
  curpage: number;
  lastpage: number;
  changeBox: boolean;
  pgstart: boolean;
  pgend: boolean;
  pageLoadingImg:boolean;
  sub_cat_id: any;
  product_summary:any;
  pageFrom:any;
  totalProducts:any;
  totalCat:any;
  prd_id:any;
  up: any;
  branches: any;
  Selbranch: string[];
  cur_lang: string;

  branch_display_name:any;
  branch_display_code:any;
  branch_address:any;
  exportLoader: boolean;
  pdfExport: any;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }


  ngOnInit() {

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
   this.branch_display_code= this.coreService.getUserData('branch_code');
   this.branch_address = this.coreService.getUserData('branch_address');
   this.cur_lang = sessionStorage.getItem("baseLang");
   this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
  });
   
   this.apiService.cur_lang.subscribe(value =>{
     this.cur_lang = value;
   })
    
    let searchval = new FormData();
    this.apiService.getAllBranches().subscribe((branch: Branch[]) => {
      searchval.append("Selbranch",branch['selected']);
      this.branches = branch['data'];
      this.Selbranch  = branch['selected'];
    });

    this.up = this.coreService.getUserPrivilage();
    this.pageLoadingImg = true;
   
    this.apiService.prodSumWithstock(searchval, 1).subscribe((res: Repprddet) => {
      this.pageLoadingImg = false;
      this.prodsumms = res['data']['data'];

      this.totalCat   = res['data']['total_categories'];
      this.totalProducts = res['data']['total_products'];

      
      

      this.pageFrom = res['data']['from'];
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });

  }

  ngAfterContentInit(){

    this.searchCat('');
    this.reeadFeat();
    this.searchManf('');
    this.searchUnit('');
    

  }

  ngOnDestroy(){
    $('.close').click();
 
  }


  //form search
  searchForm(search: string) {
    let searchval = new FormData();
    searchval.append("cat_name", search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.catagories = res['data'];
    });

  }
// search product
selectProd(name: string) {

  const searchval = new FormData();
  searchval.append('prod_name', name);
  if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
    searchval.append('alias_search', '1');
  }
  this.apiService.getProdByName(searchval).subscribe((res) => {
    this.products = res.data;
  });
}
  
  searchCat(search: string) {
    const searchval = new FormData();
    searchval.append('cat_name', search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.catagories = res['data'];
    });

  }

  //Remove Name Suggestions

  removeName() {
    this.catagories = [];
    this.reeadFeat();
  }


  selectCat(cat_id: number) {
    this.cat_id = cat_id;

  }
  getBranchByName(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branches = res['data'];
    });
  }

  filter_types = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];


  pageNext(formdata: { value: any; }, pageno: any) {
   $('.resultdata').empty();
    this.pageLoadingImg = true;

    let searchval = new FormData();
    searchval.append("pageno", pageno);
    this.apiService.prodSumWithstock(formdata.value, pageno).subscribe((res: Repprddet) => {
      this.pageLoadingImg = false;
      this.prodsumms = res['data']['data'];
      this.pageFrom = res['data']['from'];
      this.totalProducts = res['data']['total_products'];
      this.totalCat = res['data']['total_categories'];

      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);

    });
  }

  selectSubCats(catid: number) {
    this.apiService.getAllSubCatbyId(catid).subscribe((res: Subcatagory[]) => {
      this.subcatagories = res['data'];
      if (this.changeBox)
        this.changeBox = false;
      else
        this.changeBox = true;

    });
  }

  //form search  manufacture
  searchManf(search: string) {
    let searchval = new FormData();
    searchval.append("manftr_comp_name", search);
    this.apiService.getManfbyName(searchval).subscribe((res) => {
      this.mfs = res['data'];
    });
  }

  //form search unit
  searchUnit(search: string) {
    let searchval = new FormData();
    searchval.append("unit_name", search);
    this.apiService.getUnitByName(searchval).subscribe((res) => {
      this.unit_all = res['data'];

    });

  }

  // vat value
  // unit types
  vat_types = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];

  reeadFeat() {
    this.apiService.readFeat().subscribe((res: Feature[]) => {
      this.features = res['data'];

    });
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  // pdf download
  generatePdf(formdata: { value: any; }) {

    this.exportLoader = true;

    // debugger;
    formdata.value.export = 'export';

    this.apiService.prodSumWithstock(formdata.value, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.pdfExport = res.data.data;
      this.totalProducts = res['data']['total_products'];
      this.totalCat = res['data']['total_categories'];

      console.log(this.totalProducts);
      console.log(this.totalCat);
      console.log(this.pdfExport);

      const heads = [
        this.translate.instant('Table.sl')+'\n'+this.translate.instant('Common.no'),
        this.translate.instant('Common.prd_name'),
        this.translate.instant('Repproduct.product_alias'),
        this.translate.instant('Table.barcode'),
        this.translate.instant('HOME.CATAGORY'),
        this.translate.instant('HOME.SUB CATAGORY'),
        this.translate.instant('Repproduct.base_unit'),
        this.translate.instant('Common.stock')
      ];
  
        const lblXPos = 10;
        const headerHeight = 15;
        const valueXPos = 55;
        const data = [];
        
  
        this.pdfExport.forEach((item,i)=>{

          item.branch_stocks.forEach(item1=>{

            if(item1.stock!=0){
            const arr=[
              i+1,
              item.prd_name,
              item.prd_alias,
              item.prd_barcode,
              item.cat_name,
              item.subcat_name,
              item.unit_name
            ]
            data.push(arr)

            if(item1.stock!=0){
              arr.splice(7,0, item1.branch_name + ' :  ' + this.currencyPipe.transform(item1.stock, '', '', '1.1-1'))  
          }

          }
          

          })
        })
    
        let doc = this.excelService.getPdfObj();
  
        var addressLines = this.branch_address.split('\r\n');
        var addressText = addressLines.join(', ');
  
        doc.setFont('Amiri');
        doc.setFontSize(12);
        doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
  
        doc.setFont('Amiri');
        doc.setFontSize(9);
        doc.text(addressText, 71, headerHeight + 2);
  
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 10);
        doc.setFontSize(10);
        doc.text(':  ' + `${this.translate.instant('Common.branch_wise_stock')}`,  valueXPos, headerHeight + 10);
  
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.total_no_of_products')}`, lblXPos, headerHeight + 15);
        doc.setFontSize(10);
        doc.text(':  ' + this.totalProducts,  valueXPos, headerHeight + 15);
  
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Table.totl_category')}`, lblXPos, headerHeight + 20);
        doc.setFontSize(10);
        doc.text(':  ' + this.totalCat,  valueXPos, headerHeight + 20);
        
        doc = this.excelService.addTableToPdf(doc, headerHeight + 25, lblXPos, heads, data, null);
    
       
        doc.save(`Branch Wise Stock Report.pdf`);

        this.exportLoader = false;

    });
    
  }

}
