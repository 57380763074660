import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import * as XLSX from 'xlsx'; 

@Component({
  selector: 'app-fuel-profit-loss',
  templateUrl: './fuel-profit-loss.component.html',
  styleUrls: ['./fuel-profit-loss.component.css']
})
export class FuelProfitLossComponent implements OnInit {
  fileName= 'fuel_profit_loss.xlsx';
  branch_all: any;
  filterTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'tm', name: 'This Month' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];
  rateTyes = [
    { id: 'bs_prate', name: 'Last Purchase Rate' },
    { id: 'bs_avg_prate', name: 'Avg Purchase Rate' }
  ];
  userType: any;
  pageLoadingImg: boolean;
  feulProfitLoss: any;
  excs_type={
    0: 'Nill',
    1: 'Diesel Mix',
    2: 'LB',
    
  };
  period_type: any;
  repDate: any;
  exportLoader: boolean;
  branch_display_name: any;
  branch_display_code: any;
  branch_address: any;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService) { }

  ngOnInit() {
    this.getAllBranch();
    this.userType = this.coreService.getUserData('user_type');
    this.period_type = 't';
    let searchval=new FormData();
    searchval.append('period_type','t');
    this.pageLoadingImg = true;
    this.apiService.fuelProfitAndLoss(searchval, 1).subscribe((res) => {
      this.pageLoadingImg = false;
      this.feulProfitLoss = res['data'];
      this.repDate=res['data']['Date'];    
    });
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');
  }
  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res) => {
      this.branch_all = res['data'];
     
    });
  }

  pageNext(formdata: { value: any; }, pageno: any) {
    this.pageLoadingImg = true;
    
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
     
    }  
    this.apiService.fuelProfitAndLoss(formdata.value, pageno).subscribe((res) => {
      this.pageLoadingImg = false;
      this.feulProfitLoss = res['data'];
      this.repDate=res['data']['Date'];
    });

  }

  exportEXCL(){
    this.exportLoader = true;
    this.exportexcel();
  }
  exportexcel(): void 
  {
     /* table id is passed over here */   
     let element = document.getElementById('export-group'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.fileName);
     this.exportLoader = false;
    
  }

}
