
import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { log } from 'util';

@Component({
  selector: 'app-offer-flyer-poster',
  templateUrl: './offer-flyer-poster.component.html',
  styleUrls: ['./offer-flyer-poster.component.css']
})
export class OfferFlyerPosterComponent implements OnInit {
  result: string;
  prod_alias: any;
  stkprd_all: string[];
  salesRate: any;
  imageError: any;
  srch_length: any;
  offer_image: any;
  stkprdsel: any;
  savedTemplate = {

    logo: false,
    header_type: 1
  }
  product_name: any;
  product_alias: any;
  unit_id: any;
  resultobj: any = {};
  offerdata: any;
  logoImgUrl: string;
  tempImgUrl: string;
  offer_sale_rate: any;
  stkunitsel: any;
  price_group_id: any;
  selctedProd: any = { prd_id: null};
  unit: any;
  sel_rate_type = 1;
  rate: any;
  ofp_img_url: any;
  country_dec: string;
  ofp_offer_price: any;
  ofp_prd_name: any;
  ofp_prd_alias: any;
  ofp_price: any;
  unit_name: any;
  unit_base_qty: any;
  img: any;
  cur_lang: any;
  start_date: any;
  end_date: any;
  startDate: any;
  endDate: any;
  constructor(private apiService: ApiService,
    private coreService: CoreService,private ActiveRoute: ActivatedRoute,private translate1: TranslateService) { }

  ngOnInit() {
    this.cur_lang = sessionStorage.getItem("baseLang");
    this.aliasChecked()
    this.apiService.cur_lang.subscribe(value =>{
        this.cur_lang = value;
        this.aliasChecked()
      })
    this.getTemplete();
    this.listofferdata();
    this.country_dec=this.coreService.setDecimalLength();
  }

  aliasChecked(){
    if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      this.prod_alias = 'alias_true';
     
    }else{
      this.prod_alias =null;
     
    }
  }


  searchStkPrdBarcode(search: any) {
  let searchval = new FormData();
  searchval.append("prd_barcd", search);
  if(this.prod_alias){
       searchval.append('alias_search', '1');
       }
  this.apiService.searchBarcodeAndProduct(searchval).subscribe((res) => {
    this.stkprd_all = res['data'];
    console.log('this.stkprd_all');
    console.log(this.stkprd_all);
    
    this.srch_length =  this.stkprd_all.length;
 
  });
  
}
selectedProduct(prd_id) {
  const searchval = new FormData();
  searchval.append('prd_id', prd_id);
  this.price_group_id=1
  searchval.append('price_group_id', this.price_group_id);
  this.apiService.getsaleProdDetails(searchval).subscribe((res) => {
    this.selctedProd = res['data'];
    this.product_name=this.selctedProd.prd_name;
    this.product_alias=this.selctedProd.prd_alias;
    this.unit = (this.stkprdsel.produnit_unit_id ? this.stkprdsel.produnit_unit_id : this.selctedProd.prd_base_unit_id);
    this.selectedUnit(this.unit);
  });

}

selectedUnit(unit) {
  this.stkunitsel=unit;
  const prdUnts = this.selctedProd.prod_units.filter((ProdUnits) => ProdUnits.produnit_unit_id === unit);
  var unit_rate = (this.sel_rate_type == 1) ? prdUnts[0].sur_unit_rate : prdUnts[0].sur_unit_rate2;
  
// in case of excs tax item edit grid makes excs tax wrong after change rate

this.salesRate = (unit_rate ? unit_rate : prdUnts[0].unit_base_qty * this.selctedProd.base_unit_rate);
this.offer_sale_rate = (unit_rate ? unit_rate : prdUnts[0].unit_base_qty * this.selctedProd.base_unit_rate);
}

addnew(){
  this.clearall();
  this.resultobj=[];
}

clearall(){
  this.product_name=null; 
  this.product_alias=null;
  this.salesRate=null;
  this.offer_sale_rate=null;
  this.salesRate=null;
  this.stkprdsel=null;
  this.unit=null;
  // this.start_date = new Date();
  // this.end_date = new Date();

}


fileChangeEvent(fileInput: any) {
  this.imageError = null;
  if (fileInput.target.files && fileInput.target.files[0]) {
    // Size Filter Bytes
    const max_size = 2048000;
    const allowed_types = ['image/png', 'image/jpeg'];
    if (fileInput.target.files[0].size > max_size) {
      this.imageError =
        'Maximum size allowed is ' + max_size / 1000 / 1024 + 'MB';

      return false;
    }

    if (allowed_types.indexOf(fileInput.target.files[0].type) !== -1) { } else {
      this.imageError = 'Only Images are allowed ( JPG | PNG )';
      return false;
    }



    const reader = new FileReader();
    reader.onload = (e: any) => {
      const image = new Image();
      image.src = e.target.result;
      image.onload = rs => {
        // const img_height = rs.currentTarget['height'];
        // const img_width = rs.currentTarget['width'];

        // console.log(img_height, img_width);


        // if (img_height > max_height || img_width > max_width) {
        //   this.imageError =
        //     'Maximum dimentions allowed ' +
        //     max_height +
        //     ' X ' +
        //     max_width +
        //     'px';
        //   return false;
        // } else {
        // this.pdcImgUrl = e.target.result;
        // }
      };
    };

    reader.readAsDataURL(fileInput.target.files[0]);




  }
}


createOffer(data) {
 let searchval = new FormData(data);
 if(this.start_date){
  this.start_date = (this.start_date) ? this.apiService.formatDate(this.start_date) : this.start_date;
  searchval.append("start_date", this.start_date); 
}
if(this.end_date){
  this.end_date = (this.end_date) ? this.apiService.formatDate(this.end_date) : this.end_date;
  searchval.append("end_date",this.end_date);
}
  searchval.append("prd_id", this.selctedProd.prd_id);   
  searchval.append("unit_id",this.stkunitsel);
  this.apiService.offerFlyerPosterCreation(searchval).subscribe((res) => {
   
      if (!res.error) {
        this.coreService.showMessage('Created Successfully');
        $('.custPopCloseBtn').click();
        this.listofferdata();
      } else {
        this.resultobj = res.error;
        console.log(this.resultobj);
      }
    });
  }
  deleteOffer(offer){
    let searchval = new FormData();
    console.log('data.ofp_id');
    console.log(offer.ofp_id);
    searchval.append("ofp_id", offer.ofp_id);

    this.apiService.deleteOfferFlyerPoster(searchval).subscribe((res) => {
      
      if (res['data']) {
        this.coreService.showMessage('Voided Successfully');

        this.listofferdata();
      }
    });

  }



  listofferdata(){
    let searchval=new FormData;
    this.apiService.GetofferFlyerPosterList(searchval).subscribe((res) => {
      this.offerdata = res.data;
      this.img =res.img.ofp_img_url;
    });
  }


  getpreview(data) {
    this.ofp_img_url = data.ofp_img_url? this.apiService.PHP_API_SERVER + '/' + data.ofp_img_url:this.apiService.PHP_API_SERVER + '/' +this.img;
    this.ofp_offer_price = data.ofp_offer_price;
    this.ofp_prd_name = data.ofp_prd_name;
    this.ofp_prd_alias = data.ofp_prd_alias;
    this.ofp_price = data.ofp_price;
    this.unit_name = data.unit_name;
    this.unit_base_qty = data.unit_base_qty;
    this.startDate = data.ofp_start_date;
    this.endDate = data.ofp_end_date?data.ofp_end_date:null;
    console.log(this.startDate);
    console.log(this.endDate);
    


    setTimeout(function(){ 
      $('#flyerBtn').click();
      },200);

  }



  getTemplete() {


    this.apiService.getSettings().subscribe((res) => {

      this.savedTemplate = {

        logo: res.data.prnt_header_logo,
        
        header_type: res.data.prnt_header_type
      }
      if (res.data.prnt_header_logo) {
        this.logoImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_logo_img_path;
      } else {
        this.logoImgUrl = '';
      }

      if (res.data.prnt_header_type == 2) {
        this.tempImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_img_path;
      } else {
        this.tempImgUrl = '';
      }

    });
  }



}
