import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';

@Component({
  selector: 'app-einvoicing-tax-invoice',
  templateUrl: './einvoicing-tax-invoice.component.html',
  styleUrls: ['./einvoicing-tax-invoice.component.css']
})
export class EinvoicingTaxInvoiceComponent implements OnInit {

  @Input()saleQtnData;
  @Input()print_style;
  @Input()show_base_unit_in_print;
  @Input()qr_inv;
  @Input()savedTemplate;
  @Input()is_prew_description;
  @Input()payTypes;
  @Input()cmpny;
  @Input()previewLoader;
  @Input()logoImgUrl;
  @Input()tempImgUrl;
  @Input()sales_print_name;
  @Input()hide_item_discount;
  @Input()hide_vat;
  @Input()sales_settings;
  @Input()whatsapplink;
  country_dec: any;
  constructor(private coreService: CoreService,private apiService: ApiService) { }

  branch_e_invoice_enabled = 0;
  eInvoiceSupport: boolean=false;

  ngOnInit() {
    this.country_dec=this.coreService.setDecimalLength();
    this.branch_e_invoice_enabled = this.coreService.getUserData('is_e_invoice');

    this.apiService.getClientSettingBykey({ key: 'enable_e_invoice' }).subscribe((res) => {
      if (res['data']) {
        this.eInvoiceSupport = ( (res['data']['cs_value'] ? true : false) && this.branch_e_invoice_enabled) ? true : false;
      }


    });
  }

  startPrint() {

    $('#printButton').click();
  }

}
