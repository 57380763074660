import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
@Component({
  selector: 'app-sales-void',
  templateUrl: './sales-void.component.html',
  styleUrls: ['./sales-void.component.css']
})

export class SalesVoidComponent implements OnInit {

  pageLoadingImg: boolean;
  showdata: boolean;
  resultobj: any;
  purchaseItems: any;
  sales_inv_no: any;
  saleData: any = {
    sales_id: null,
    sales_inv_no: null,
    error: null,
    message: null,
    data: null,
  };
  payType = ['Cash', 'Credit'];
  country_dec: string;
  loadingImg: boolean;
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService) { }


  ngOnInit() {
    this.pageLoadingImg = false;
    this.showdata = false;

    this.country_dec=this.coreService.setDecimalLength();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      // this.langChange();
    });
  }


  sale_search(search: string) {

    this.pageLoadingImg = true;
    const searchval = new FormData();
    searchval.append('sales_inv_no', search);
    this.apiService.getSalesInv(searchval).subscribe((res) => {
      this.pageLoadingImg = false;

      if (res.error != null) {
        this.resultobj = res.error;
        this.purchaseItems = [];
        this.saleData = {
          sales_id: null,
          sales_inv_no: null,
          error: null,
          message: null,
          data: null,

        };
        this.resultobj = this.coreService.getValidation(this.resultobj);
      } else {
        this.showdata = true;

        this.resultobj = {};
        this.purchaseItems = res.data;
        this.saleData = res.data;
        console.log(this.saleData);
        console.log(this.saleData.sales_flags);
      }


    });
  }




  salesVoid(purchId: any) {
    
    this.loadingImg=true;
    const searchval = new FormData();
    searchval.append('sales_inv_num', purchId);
    this.apiService.salesDet(searchval).subscribe((res) => {
             

    if(res){
      var voidstk = confirm("This Sales converted from delivery note , Are you sure  want to Void this Sale and Delivery Note ? ");
    }
    else{
      var voidstk = confirm("Are you sure  want to Void this Sale ? ");
    }

   
    if (voidstk) {
      this.pageLoadingImg = true;
      

      const searchval = new FormData();
      searchval.append('sales_inv_num', purchId);
      this.apiService.salesVoid(searchval).subscribe((res) => {
        this.pageLoadingImg = false;
        this.loadingImg=false;
       
        if (res.error != null) {
          this.saleData.is_returned = 1;
      }else{
        this.loadingImg=true;
        searchval.append('sales_inv_no', purchId);
        this.apiService.getSalesInv(searchval).subscribe((res) => {
          this.pageLoadingImg = false;
          this.loadingImg=false;
          if (res.error != null) {
            this.resultobj = res.error;
            this.purchaseItems = [];
            this.saleData = {
              sales_id: null,
              sales_inv_no: null,
              error: null,
              message: null,
              data: null,
    
            };
            this.resultobj = this.coreService.getValidation(this.resultobj);
          } else {
            this.showdata = true;
    
            this.resultobj = {};
            this.purchaseItems = res.data;
            this.saleData = res.data;
          }
    
    
        });
        this.coreService.showMessage(res.message);
      }
      });
      this.apiService.updatebatchExNotification();
      this.apiService.updateEinvoiceNotificationChange();

    }

  });
  }



}
