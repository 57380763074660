import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import html2canvas from 'html2canvas';
import { CurrencyPipe, DatePipe } from '@angular/common';
// import * as jspdf from 'jspdf';
import { ExcelService } from '../../../../../service/excel.service';
import { TranslateService } from '@ngx-translate/core';
import { LangChangeEvent } from '@ngx-translate/core';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-acc-grp-summary',
  templateUrl: './acc-grp-summary.component.html',
  styleUrls: ['./acc-grp-summary.component.css'],
  providers: [DatePipe]
})


export class AccGrpSummaryComponent implements OnInit {

  @ViewChild('printBtn',{static: true}) printBtn: ElementRef<HTMLElement>;

  resultobj: any;
  elseBlocknostart: any;
  elseBlocknoend: any;
  period_type: any;
  date1: any;
  date2: any;
  inv_filter: any;
  inv_val: any;
  inv_val1: any;
  purch_price: any;
  price1: any;
  price2: any;
  purch_discount: any;
  discount1: any;
  discount2: any;
  added_by: any;
  vch_type: any;
  disp_void = 2;
  vch_filter: any;
  ret_ref_filter: any;
  selecTed: any;
  with_prev_bal: boolean;
  is_branch_ref_no: boolean;
  is_ref_no: boolean;
  is_notes: boolean;
  tempImgUrl:any = '';


  dayList: string[];
  curpage: number;
  lastpage: number;
  pgstart: boolean;
  pgend: boolean;
  slnum: number;
  supplierAll: string[];
  ModelData: boolean;
  // total_purchase:number;
  total_return: any;
  total_purchase_amount: any;

  pageLoadingImg: boolean;
  report_type: string;
  is_year_rep: any;
  filter_types: any;
  filter_refnum: any;
  filter_price: any;
  filter_discount: any;
  // filter_paytype:any;
  // filter_purchtypes:any;
  selectType: any;
  // selectRefNo:any;
  selectPrice: any;
  // selectDiscount:any;



  filterTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];

  // filter  types
  sortType = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' },
  ];

  filter_paytype = [
    { id: '1', name: 'Credit' },
    { id: '2', name: 'Cash' },

  ];

  filter_purchtypes = [
    { id: '2', name: 'Group wise' },
    { id: '1', name: 'Ledger wise' },
    { id: '3', name: 'Grouped Ledger' },

  ];
  repDate: any;
  voucherType: any;
  ledger_all: any;
  users: any;
  show_notes: any;
  dispnote: any;
  debitAmt: any;
  crdAmt: any;
  group_all: any;
  ledgerList: any;
  expledgerList : any;
  total_debit: any;
  total_credit: any;
  reportData: any;
  up: any;
  branch_all: any;
  userType: any;
  exportLoader: boolean;
  branch_display_name:any;
  branch_name:any;
  branch_display_code:any;
  branch_address:any;
  van_lines = [];
  fileName = 'account_group_summary_report.xlsx';
  show_mobile = false;
  country_dec: number=2;
  agents: any;
  lang_filter: any;
  in_amount: any;
  out_amount: any;

  constructor(private apiService: ApiService, private coreService: CoreService, private excelService: ExcelService, private translate: TranslateService,
    private currencyPipe: CurrencyPipe,private datepipe: DatePipe) { }

  ngOnInit() {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name= this.coreService.getUserData('branch_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');
    this.branch_address= this.coreService.getUserData('branch_address');

    this.userType = this.coreService.getUserData('user_type');
    this.getAllBranch();
    this.getAllVanlines();
    this.disp_void = 2;
    this.searchSupplier('');
    this.pageLoadingImg = true;
    this.up = this.coreService.getUserPrivilage();
    let searchval = new FormData();
    this.apiService.groupSummaryReport(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.ledgerList = res.data.data;
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.total_debit = res.info.total_debit;
      this.total_credit = res.info.total_credit;
      this.repDate = res.info.Date;



      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;

      this.ModelData = false;
    });
    this.exportLoader = false;

    this.country_dec=this.coreService.setDecimalLength();

    this.is_branch_ref_no = true;
    this.is_ref_no = true;
    this.is_notes = true;
  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','ACCOUNTS.Group_wise','ACCOUNTS.ledger_wise','ACCOUNTS.grpd_ledger']).subscribe((res: string) => {    
     
      
      this.filterTypes = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];

      this.filter_purchtypes = [
        { id: '2', name: res['ACCOUNTS.Group_wise'] },
        { id: '1', name: res['ACCOUNTS.ledger_wise'] },
        { id: '3', name:res['ACCOUNTS.grpd_ledger'] },
    
      ];
    });

  }
  ngOnDestroy() {
    $('.close').click();

  }
  shwMobile(evt){
    this.show_mobile=false;
    if(evt.target.checked) {
      this.show_mobile=true;
    }
  }


  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res) => {
      this.branch_all = res['data'];

    });
  }
  getAllVanlines() {
    this.apiService.getAllVanlines().subscribe((res) => {
      this.van_lines = res['data'];

    });
  }
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
    
    });
  }



  pageNext(formdata: { value: any; }, pageno: any) {




    $('.resultdata').empty();
    this.pageLoadingImg = true;

    let searchval = new FormData();
    searchval.append("pageno", pageno);

    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    formdata.value.export = '';

    this.apiService.groupSummaryReport(formdata.value, pageno).subscribe((res: any) => {

      this.pageLoadingImg = false;
      this.ledgerList = res.data.data;
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];

      this.total_debit = res.info.total_debit;
      this.total_credit = res.info.total_credit;
      this.repDate = res.info.Date;



      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;


      this.repDate = res.info.Date;
      // this.dispnote = res.data.show_notes;
      // this.debitAmt = res.data.debit_amnt;
      // this.crdAmt = res.data.credit_amnt;


      if (!this.disp_void) {
        this.disp_void = 2;
      }

    });
  }

  // searchAgent(search: string) {
  //   const searchval = new FormData();
  //   searchval.append('ledger_name', search);
  //   this.apiService.searchAgents(searchval).subscribe((res) => {
  //     this.agents = res['data'];
  //   });
  // }




  searchSupplier(search: string) {
    const searchval = new FormData();
    searchval.append('supp_name', search);
    this.apiService.searchSupplier(searchval).subscribe((res) => {
      this.supplierAll = res['data'];
    });

  }



  searchVoucherType(search: string) {
    const searchval = new FormData();
    searchval.append('vchtype_name', search);
    this.apiService.searchVoucherType(searchval).subscribe((res) => {
      this.voucherType = res['data'];
    });
  }

  searchLedger(search: string) {
    let searchval = new FormData();
    searchval.append("ledger_name", search);
    this.apiService.getLedByName(searchval).subscribe((res) => {
      this.ledger_all = res['data'];
    });
  }

  searchAccGroup(search: string) {
    let searchval = new FormData();
    searchval.append("name", search);
    this.apiService.getAccGroup(searchval).subscribe((res) => {
      this.group_all = res['data'];

      console.log(this.group_all)
    });
  }



  //form search
  searchUser(search: string) {
    let searchval = new FormData();
    searchval.append("usr_name", search);
    this.apiService.getUserByName(searchval).subscribe((res) => {
      this.users = res['data'];
    });
  }



  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  grpSummaryexport() {

  }

  openPDF() {
    $("#contentToConvert").show();

    var data = document.getElementById('contentToConvert');
    html2canvas(data).then(canvas => {
      // Few necessary setting options  
      var imgWidth = 208;
      //208  
      var pageHeight = 495;
      var imgHeight = canvas.height * imgWidth / canvas.width;

      const contentDataURL = canvas.toDataURL('image/jpeg', 1.0)
      // let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF         
      // var position = 0;
      // pdf.addImage(contentDataURL, 'JPG', 0, position, imgWidth, imgHeight);

      // pdf.save('Account Group Summary Report.pdf'); // Generated PDF         

    });

    $("#contentToConvert").hide();


  }

  // pdf download
  generatePdf(formdata: { value: any; }) {
      
      this.apiService.groupSummaryReport(formdata.value, 1).subscribe((res: any) => {
     
        this.expledgerList = res.data;
        this.branch_display_name=res.info.company.branch;
        this.total_debit = res.info.total_debit;
        this.total_credit = res.info.total_credit;
        this.repDate = res.info.Date;
  
        this.repDate = res.info.Date;
        if (!this.disp_void) {
          this.disp_void = 2;
        }
         
        const heads = [
          this.translate.instant('ACCOUNTS.Particulars'),
          this.translate.instant('ACCOUNTS.Debit_Amount'),
          this.translate.instant('ACCOUNTS.Credit_Amount')
      ];
  
        const lblXPos = 10;
        const headerHeight = 15;
        const valueXPos = 55;
        const data = [];

        console.log(this.expledgerList);

        this.expledgerList.data.forEach(item => {

          if(this.disp_void!=1){

            if(this.translate.currentLang == 'Arabic'){
             this.lang_filter = item.accgrp_name_ar;

            } else if (this.translate.currentLang == 'English'){
              this.lang_filter = item.accgrp_name;
            }

          data.push([
            this.lang_filter, 
            Number(item.vch_in).toFixed(this.country_dec),
            Number(item.vch_out).toFixed(this.country_dec),
            ])

          }

          item.ledgers.forEach(item1 => {
           if(item1.length != 0 && this.disp_void!=2){

            if(item1.vch_in != 0 || item1.vch_out !=0){

            if(this.translate.currentLang == 'Arabic'){
              this.lang_filter = item1.ledger_alias;
 
             } else if (this.translate.currentLang == 'English'){
               this.lang_filter = item1.ledger_name;
             }
             
             if(item1.vch_in != 0){
              this.in_amount = (item1.vch_in).toFixed(this.country_dec)
             } else if(item1.vch_in == 0){
              this.in_amount = ''
             }

             if(item1.vch_out != 0){
              this.out_amount = (item1.vch_out).toFixed(this.country_dec)
             } else if(item1.vch_out == 0){
              this.out_amount = ''
             }

             data.push(['                      ' +this.lang_filter, '                     ' +this.in_amount, '                      ' +this.out_amount])
            }
           }
          });

          item.sub_groups.forEach(item2 => {
            if(item2.length != 0){

              if(this.disp_void!=1 &&(item2.vch_in||item2.vch_out)){

                if(this.translate.currentLang == 'Arabic'){
                  this.lang_filter = item2.accgrp_name_ar;
     
                 } else if (this.translate.currentLang == 'English'){
                   this.lang_filter = item2.accgrp_name;
                 }

                 if(item2.vch_in != 0){
                  this.in_amount = (item2.vch_in).toFixed(this.country_dec)
                 } else if(item2.vch_in == 0){
                  this.in_amount = ''
                 }
    
                 if(item2.vch_out != 0){
                  this.out_amount = (item2.vch_out).toFixed(this.country_dec)
                 } else if(item2.vch_out == 0){
                  this.out_amount = ''
                 }
                
                data.push(['            ' +this.lang_filter, '            ' +this.in_amount, '            ' +this.out_amount])

              }
           
            }

            item2.ledgers.forEach(item3=> {
              if(item3.length != 0 && this.disp_void!=2){
                if(item3.vch_in != 0||item3.vch_out != 0){

                  if(this.translate.currentLang == 'Arabic'){
                    this.lang_filter = item3.ledger_alias;
       
                   } else if (this.translate.currentLang == 'English'){
                     this.lang_filter = item3.ledger_name;
                   }
                   
                   if(item3.vch_in != 0){
                    this.in_amount = (item3.vch_in).toFixed(this.country_dec)
                   } else if(item3.vch_in == 0){
                    this.in_amount = ''
                   }
      
                   if(item3.vch_out != 0){
                    this.out_amount = (item3.vch_out).toFixed(this.country_dec)
                   } else if(item3.vch_out == 0){
                    this.out_amount = ''
                   }

                  data.push(['                      ' +this.lang_filter, '                     ' +this.in_amount, '                      ' +this.out_amount])

                }
              }
            });
          });
        });
  
        let doc = this.excelService.getPdfObj();

        var addressLines = this.branch_address.split('\r\n');
        var addressText = addressLines.join(', ');

        doc.setFont('Amiri');
        doc.setFontSize(12);
        doc.text(this.branch_display_name, 75, headerHeight - 2);

        doc.setFont('Amiri');
        doc.setFontSize(9);
        doc.text(addressText, 70, headerHeight + 2);
    
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 10);
        doc.setFontSize(10);
        doc.text(':  ' + `${this.translate.instant('ACCOUNTS.ACCOUNT_GROUP_SUMMARY')}`,  valueXPos, headerHeight + 10);
  
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 15);
        doc.setFontSize(10);
        doc.text(':  ' + this.repDate.date1 + '   to   ' + this.repDate.date2  ,  valueXPos, headerHeight + 15);
  
        doc = this.excelService.addTableToPdf(doc, headerHeight + 20, lblXPos, heads, data, null);

      doc.save(`Account Group Summary.pdf`)
        
        
    });
      

  }

  exportAsXLSX(formdata: { value: any; }): void {
    this.exportLoader = true;

    this.apiService.groupSummaryToatalReport(formdata.value).subscribe((res: any) => {
      this.reportData = res['data'];
      // console.log(this.reportData);
    this.excelService.exportAsExcelFile(this.reportData, 'Account Group Summary Report');
    this.exportLoader = false;

    });

  }

  saveAndPrint(formdata: { value: any; }){
    this.exportLoader = true;

    formdata.value.export = 'export';
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }

    this.apiService.groupSummaryReport(formdata.value, 1).subscribe((res: any) => {
     
      this.expledgerList = res.data;
     
      this.total_debit = res.info.total_debit;
      this.total_credit = res.info.total_credit;
      this.repDate = res.info.Date;

      this.repDate = res.info.Date;
      if (!this.disp_void) {
        this.disp_void = 2;
      }
      
      
      setTimeout(() => {
        this.exportLoader = false;
        let el: HTMLElement = this.printBtn.nativeElement;
        el.click();
    }, 3000);

    

    });
    
  }

  export(formdata: { value: any; }) {


    this.exportLoader = true;

    formdata.value.export = 'export';
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }

    this.apiService.groupSummaryReport(formdata.value, 1).subscribe((res: any) => {
     
      this.expledgerList = res.data;
     
      this.total_debit = res.info.total_debit;
      this.total_credit = res.info.total_credit;
      this.repDate = res.info.Date;

      this.repDate = res.info.Date;
      if (!this.disp_void) {
        this.disp_void = 2;
      }
      
      
      setTimeout(() => {
        this.exportexcel();
    }, 3000);

    

    });
  }

  exportexcel(): void 
  {
     /* table id is passed over here */   
     let element = document.getElementById('export-group'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.fileName);
     this.exportLoader = false;
    
  }

}
