

import { Component, OnInit, ɵConsole } from '@angular/core';

import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';

import { Vanline } from '../../../model/vanline.model';

@Component({
  selector: 'app-van-line',
  templateUrl: './van-line.component.html',
  styleUrls: ['./van-line.component.css']
})
export class VanLineComponent implements OnInit {

  constructor(private apiService: ApiService,private coreService: CoreService) { }
  product_summary: any;
  elseBlocknostart: any;
  elseBlocknoend: any;

  vanline_name:String;
  vanline_desc:String;
  vanline_id:number;
  vanlines:any[];
  VanLine:any[];
  curpage:number;
  lastpage:number;
  changeBox :boolean;
  pgstart:boolean;
  pgend:boolean;
  slnum:number;
  vanline_credit_limit: number;
  resultobj: {};
  ngOnInit() {
    this.readVanlines();
  }
  readVanlines()
 {
  let searchval = new FormData();   
 this.apiService.ListVanline(searchval,1).subscribe((res) => {
  this.vanlines = res['data']['data'];
  this.curpage = res['data']['current_page'];  
  this.lastpage= res['data']['last_page']; 
  this.pgEnd(this.curpage,this.lastpage); 
  this.pgStart(this.curpage);
  this.slnum=0;
});
}


pageNext(formdata: { value: any; }, pageno:any)
{

let searchval = new FormData();   
this.apiService.ListVanline(searchval,pageno).subscribe((res) => {
 this.vanlines = res['data']['data'];
 this.curpage = res['data']['current_page'];  
 this.lastpage= res['data']['last_page']; 
 this.pgEnd(this.curpage,this.lastpage); 
 this.pgStart(this.curpage);
 this.slnum=0;


});
}


addNewVanLine(formgroup: { value: any; }) {
  this.apiService.addNewVanLine(formgroup.value).subscribe((res: Vanline) => {

    if (res.error != null) {
      this.resultobj = res.error;
      this.resultobj = this.coreService.getValidation(this.resultobj);
    } else {
      this.readVanlines();
      this.coreService.createfunct(formgroup, this.resultobj, res.message);
      this.resultobj = {};
    }

  });
}


updateVanLine(formgroup: { value: any; }) {
 
  this.apiService.updateVanLine(formgroup.value).subscribe((res: Vanline) => {

    if (res.error != null) {
      this.resultobj = res.error;
      this.resultobj = this.coreService.getValidation(this.resultobj);
    } else {
      this.readVanlines();
      this.coreService.createfunct(formgroup, this.resultobj, res.message);
      this.resultobj = {};
    }

  });
}

setValues(vanline_id,vanline_name,vanline_desc,vanline_credit_limit)
{
  this.vanline_id =vanline_id;
this.vanline_name =vanline_name;
this.vanline_desc =vanline_desc;
this.vanline_credit_limit=vanline_credit_limit
}

activate(vanlineId) {
  this.apiService.changeStatus(vanlineId).subscribe((res: Vanline) => {
    this.coreService.showMessage(res.message);
  });
}


pgEnd(curr,end)
{
if(curr==end)
this.pgend = true;
else
this.pgend = false;

}

pgStart(curr)
{
if(curr==1)
this.pgstart = true;
else
this.pgstart = false;
}
}
