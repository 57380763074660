import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-excel-convert',
  templateUrl: './excel-convert.component.html',
  styleUrls: ['./excel-convert.component.css']
})
export class ExcelConvertComponent implements OnInit {

  fileName = 'Sample Import Doccument.xlsx';
  pageLoadingImg = false;
  resultobj: any = {};
  converted_excel_rows=[];
  export: string="export-group1";
  excel_type: number;
  constructor(private apiService: ApiService,private coreService: CoreService) { }

  ngOnInit() {
    this.excel_type=1;
  }

  downloadSheet() {
    /* table id is passed over here */
    let element = document.getElementById(this.export);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  changeExcel(id){

    this.export='export-group'+id;
    
  }

  uploadLogFile(form) {
    var cnfrm = confirm("Are You Sure To Continue ?");
    if (cnfrm) {
      this.pageLoadingImg = true;
      let searchval = new FormData(form);
      this.converted_excel_rows = [];
      let callFunction = '';
      if (this.excel_type === 1) {
        callFunction = 'convertExcel';
      }
      if (this.excel_type === 2) {
        callFunction = 'convertExcelBarcode';
      }
      
      this.apiService[callFunction](searchval).subscribe((res) => {
        this.pageLoadingImg = false;
        if (res.error) {
          if (res.error && res.error.length > 0) {
            this.coreService.showMessage(res.message,1000);
          }
        } else{
          this.converted_excel_rows = res.data.converted_excel;
          setTimeout(() =>{ this.downloadConvertedSheet(); }, 500);
        }
      });


    }
  }

  downloadConvertedSheet() {
    /* table id is passed over here */
    let element = document.getElementById('converted_excel_div');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { raw: true });

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'product_converted.xlsx');
  }

}
