import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-van-missing-stock',
  templateUrl: './van-missing-stock.component.html',
  styleUrls: ['./van-missing-stock.component.css']
})
export class VanMissingStockComponent implements OnInit {
  modalRef: NgbModalRef; 
  closeResult: string;
  damage_date: Date;
  vans: any;
  units_all: any;
  stkprd_all: any;
  unit: any;
  gsl_from: any;
  prd_id: any;
  trans_stocks = [];
  noItem: boolean;
  pageLoadingImg: boolean;
  selecTed: any = {};
  missing_stocks: any;
  slno: any;
  printError=[]; errObjArr = {
    unitqtyErr: null, vnErr: null,
    prodErr: null, dtErr: null, unitErr: null, dmgdErr: null, dmqtyErr: null
  };
  
  unit_qty: number;
  qty: string;
  stk_unit_qty: string;
  damage_notes: string;
  unit_base_qty: any;
  sel_unit: any;
  unit_name: any;
  van_id: any;
  noItemforBarcodeMsg: boolean;
  stkprdsel: { prd_name: any; };
  van_missing_stocks: any;
  curpage: any;
  lastpage: any;
  from: any;
  pgend: boolean;
  pgstart: boolean;
  country_dec: string;
  loading: boolean;
  voidloading: boolean;
  alias: any;
  prod_alias: any;
  cur_lang: string;
  g_settings: any;
  generalSearch_type: any;

  constructor(private apiService: ApiService, private coreService: CoreService, private modalService: NgbModal, private translate: TranslateService,) { }

  ngOnInit() {
     this.getGeneralSetting();
this.cur_lang = sessionStorage.getItem("baseLang");
this.aliasChecked()
  this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
      this.aliasChecked()
    })
    this.country_dec=this.coreService.setDecimalLength();
    this.getVanMissingStockList('');
    
  }


  aliasChecked(){
    if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      this.prod_alias = 'alias_true';
      this.alias='alias_checked';
    }else{
      this.prod_alias =null;
      this.alias=null;
    }
  }
    searchStkPrdBarcode(search: any) {
      let searchval = new FormData();
      searchval.append("barcode", search);
      this.apiService.getStockbyBarcode(searchval).subscribe((res) => {
        // this.stkprd_all = res['data'];
        if (res.data) {
          this.noItemforBarcodeMsg = false;
          this.unit = res.data.produnit_unit_id;
          // this.gsl_from = null;
          this.selecTed = res['data'];
          this.searchVans('');
          this.getUnits();
          this.stkprdsel = {
            prd_name: res.data.prd_name
           };
          this.selecTed.prd_code = search;
          this.unit_qty = 0;
  
        } else {
          this.selecTed = [];
          this.noItemforBarcodeMsg = true;
          this.unit = null;
          this.stkprdsel = {
            prd_name: null
  
          };
        }
        //  console.log(res.data.length);
  
        //  console.log(res['data']);
  
        $('#addpurchase').hide();
        // console.log(this.unit);
        
  
      });
    }

  selectVanStk(van_id) {
    if (van_id) {
      

    let searchval = new FormData();
    searchval.append("prd_id", this.selecTed.prd_id);
    searchval.append("van_id", van_id);
    this.unit_qty = 0;

    this.apiService.getVanStock(searchval).subscribe((res) => {
      this.selecTed.vanstock_qty = res['data'][0]['vanstock_qty'];
// console.log(this.selecTed.vanstock_qty);


      this.selectUnits(this.unit);



    });
    }

  }
  selectUnits(unitid: any) {
    if (unitid) {
      this.sel_unit = this.units_all.find(x => x.unit_id === unitid).unit_id;
      this.unit_base_qty = this.units_all.find(x => x.unit_id === unitid).unit_base_qty;
      this.unit_name = this.units_all.find(x => x.unit_id === unitid).unit_name;
      this.unit_qty = this.selecTed.vanstock_qty / this.units_all.find(x => x.unit_id === unitid).unit_base_qty;
      // console.log(this.unit_qty);
    }
  }

  addToList(form) {

    
    this.printError = [];
    if (form.value.qty == "" || form.value.qty == undefined) {
      this.errObjArr.unitqtyErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });
    }
    else if (Number(form.value.qty) > Number(this.unit_qty)) {

      this.errObjArr.unitqtyErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });
    }
    else {
      this.errObjArr.unitqtyErr = "f";
    }

    if (form.value.van_id == undefined) {
       this.errObjArr.vnErr = "t";
       this.printError.push({ 'fieldErr': 'Required' });
    }
    else {
      this.errObjArr.vnErr = "f";
    }


    if (form.value.unit == undefined) {
      this.errObjArr.unitErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });
    }
    else {
      this.errObjArr.unitErr = "f";
    }

    if (form.value.damage_date == undefined) {
      this.errObjArr.dtErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });
    }
    else {
      if (form.value.damage_date > new Date()) {
        this.errObjArr.dtErr = "t";
        this.printError.push({ 'fieldErr': 'Required' });
      } else {
        this.errObjArr.dtErr = "f";
      }
    }

    if (form.value.stkprdsel == undefined) {
      this.errObjArr.prodErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });
    }
    else {
      this.errObjArr.prodErr = "f";
    }


    if (this.printError.length <= 0) {
      this.slno = this.trans_stocks.length + 1;
      //check duplicate
      const { length } = this.trans_stocks;
      const id = length + 1;
      const prd = this.trans_stocks.some(el => el.prd_id === form.value.prd_id);
      const van_id = this.trans_stocks.some(el => el.van_id === form.value.van_id);

      if (!prd)
        this.trans_stocks.push({
          "sl_no": this.slno, "prd_id": form.value.prd_id, "itemname": form.value.itemname,"prd_alias": form.value.prd_alias, "qty": form.value.qty * this.unit_base_qty, "unit_id": this.sel_unit, "unit_name": this.unit_name, "added_qty": form.value.qty, "van_id": form.value.van_id,
          "damage_date": form.value.damage_date, "damage_notes": form.value.damage_notes
        });
        // console.log(this.trans_stocks);
        
      this.unit = '';
      this.van_id = null;
      this.qty = '';
      this.stk_unit_qty = '';
      this.unit_qty = 0;
      this.damage_notes = '';
      form.reset();
      this.damage_date = new Date();
      this.selecTed = {};
      // this.stkprdsel = this.stkprdsel[0];
      // this.prd_id = '';
     
    }

  }
  deleteProd(data) {
    var remopstk = confirm("Removing Selected Product from the table");
    if (remopstk) {
      var type = data.sl_no,
        i;
      for (i = this.trans_stocks.length - 1; i >= 0; --i) {
        if (this.trans_stocks[i].sl_no == type) {
          this.trans_stocks.splice(i, 1);
        }
      }

    }
  }

  submitVanMissingStock(form: NgForm) {

    if (this.trans_stocks.length > 0) {



      if (confirm('Do you wish to continue?')) {
        this.noItem = false;

        form.value.prdlist = this.trans_stocks;
        form.value.damage_date = (form.value.damage_date) ?
          this.apiService.formatDate(form.value.damage_date) : form.value.damage_date;
        this.pageLoadingImg = true;
        this.apiService.addtoVanMissingStock(form.value).subscribe((res) => {
          this.coreService.showMessage('Added Successfully');
          this.trans_stocks = [];
          form.reset();
          this.selecTed = [];
          this.modalRef.close();
          this.unit = null;
          this.pageLoadingImg = false;


          this.getVanMissingStockList('');
        });
      }
    } else {
      this.noItem = true;
    }
  }

  getVanMissingStockList(prd_id) {
    this.loading=true;
    let searchval = new FormData();
    searchval.append("keyword", prd_id);
    if(this.alias){
      searchval.append('alias_search', '1');
    }
    this.apiService.getVanMissingStockList(searchval,1).subscribe((res) => {
    this.loading=false;
      this.van_missing_stocks = res.data.data;
        this.curpage = res.data['current_page'];
      this.lastpage = res.data['last_page'];
      this.from = res.data['from'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);

    });

  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  pageNext(keyword,pageNo){
    let searchval = new FormData();
    if(keyword)
    searchval.append("keyword", keyword);
    this.apiService.getVanMissingStockList(searchval,pageNo).subscribe((res) => {
      this.van_missing_stocks = res.data.data;
        this.curpage = res.data['current_page'];
      this.lastpage = res.data['last_page'];
      this.from = res.data['from'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);


    });
  }

  openModal(content) {

    this.modalRef = this.modalService.open(content, { size: 'lg', backdrop: 'static' });
    this.modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    this.damage_date = new Date();


  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  
  searchVans(search: string) {
    const searchval = new FormData();
    searchval.append('van_name', search);
    this.apiService.searchVans(searchval).subscribe((res) => {
      this.vans = res['data'];

  
    });

  }


  getUnits() {
    const searchval = new FormData();
    searchval.append("prd_id",this.selecTed.prd_id);
    this.apiService.unitPrdtWise(searchval).subscribe((res) => {
      this.units_all = res['data'];

    });

  }


   //form search product
   searchStkPrd(search: any, eventChar) {
    let searchval = new FormData();
    searchval.append("keyword", search);
    if(this.prod_alias){
      searchval.append('alias_search', '1');
    }
    this.apiService.getStockbyItem(searchval).subscribe((res) => {
      this.stkprd_all = res['data'];
      $('#addpurchase').hide();

    });
  }

  selectStockPrdSel(stkprdsel) {
    this.unit_qty = 0;
    if (stkprdsel) {
      this.unit = null;
      this.selecTed = stkprdsel;
      this.van_id = null;
      this.searchVans('');
      this.getUnits();
    } else {
      this.selecTed = [];

    }
  }
  removeVanMissingStock(id) {

    if (confirm("Do you wish to void this entry?")) {
      let searchval = new FormData();
      this.voidloading= true;
      searchval.append("van_stock", id);
      this.apiService.voidVanMissingStock(searchval).subscribe((res) => {
        if (res.message)
          this.coreService.showMessage('Voided Successfully');
          
        this.getVanMissingStockList('');
        this.voidloading = false;
      });

    }

  }

  getGeneralSetting() {

    this.apiService.getGeneralSetting().subscribe((res) => {

      // console.log(res['data']);
      this.g_settings = res['data'];
      this.generalSearch_type = this.g_settings.g_s_search_type;
      console.log(this.generalSearch_type);
      
    });

  }

}
