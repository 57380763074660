import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';

import { ApiService } from '../../../../../service/api.service';
import { CoreService } from '../../../../../service/core.service';
import {TranslateService,LangChangeEvent} from '@ngx-translate/core';
import { numberSymbols } from '@progress/kendo-angular-intl';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-invoice-due-receipt',
  templateUrl: './invoice-due-receipt.component.html',
  styleUrls: ['./invoice-due-receipt.component.css']
})

export class InvoiceDueReceiptComponent implements OnInit {
  fileName = 'invoice_due_recp.xlsx';
  @Input()base_url;
  @ViewChild('closeModal', { static: false }) closeModal: ElementRef;
  resultobj: any = {};
  info = [];
  wallet_info = [];
  rec = {
    rec_no: '',
    doc_no:'',
    rec_date: new Date(), 
    rec_acc: 0,
    rec_acc_balance: 0,
    rec_acc_bal_sign: '',
    rec_cust_ledger: 0,
    rec_cust_balance: 0,
    rec_cust_bal_sign: '',
    note: '',
    invs: [],
    ttl_amount: 0,
    ttl_wo_amount:0,
    ttl_input_amount: 0,
    ttl_due: 0,
    payType: 1,
    collected_by_agent: 0,
    balance_to_wallet:this.covertToPrice(0,2),
    is_advance : false
  };
  dueInv = [];
  account = [];
  nonAccount = [];
  isSubmitInProg = false;

  curpage = 1;
  lastpage = 1;
  perpage = 20;
  wallet_perpage = 20;
  wallet_curpage = 1;
  wallet_lastpage = 1;

  totalItem = 0;
  wallet_totalItem = 0;
  curForm = 'Create';
  pageLoadingImg = false;
  walletPageLoadingImg = false;
  ttlAmount = 0;
  grandTtlAmount = 0;
  ttlVatAmount = 0;
  langText = {
    create: '',
    update: ''
  };

  ledgerLoading = false;
  accLoading = false;
  invLoading = false;
  pendingInvs = JSON.stringify([]);
  rowLoadingImg: any;
  nextId = '';
  up: any;
  recPriviewLoader: boolean;
  recWalletPriviewLoader: boolean;
  printPreview: any;
  printWalletPreview: any;
  savedTemplate = {
    title_text: '',
    color: '#000',
    email_color: '#000',
    font_size: '12',
    orientation: "P",
    paper: 'A4-P',
    m_left: '',
    m_top: '',
    m_right: '',
    m_bottom: '',
    logo: false,
    header_type: 1,
    header_img: [],
    header_all_page: false,
    header_due: false,
    sl_no: false,
    item_desc: false,
    tax_detail: false,
    tax_type: 0,
    name_type: 'N',
    footer_text: ''
  }
  logoImgUrl: string;
  tempImgUrl: string;
  branch_display_name: any;
  lstbalAmt = 0;
  wallet = 0;
  isRemoveLaoding:any = {};  
 branch_code: any;
 ledger_name: any;
  exportLoader: boolean;
  bal_amt: any;
  bal_inv_amt: any;
  total_inv_amnt: number;
  total_amnt_due: void;
  branch_addrss: any;
  sale_agent: any;
  sales_agents: any;
  is_advance = false;
  country_dec: string;
  country_dec_exp: any;
  footImgUrl: string;
  ledger_alias: any;
  isEdit= false;
  cmpny1:any;
  usr_hide_others_sales_data: any;
  usr_block_pre_date_select: any;
  minDate: Date;

  constructor(private apiService: ApiService, private coreService: CoreService, private translate: TranslateService) { }

  ngOnInit() {
    this.country_dec_exp=this.coreService.inpCurrencyLength();
    this.translate.get(['Common.create', 'Common.update']).subscribe((res: string) => {
      this.langText.create = res['Common.create'];
      this.langText.update = res['Common.update'];
      this.curForm = this.langText.create;
    });
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      // this.langChange();
    });
    this.usr_hide_others_sales_data = this.coreService.getUserData('usr_hide_othr_sales_data');
    this.getNextReceiptId();
    this.getAllAccount();
    this.getCustomerLedgres();
    this.getInfo(this.curpage);
    this.getPaidByWalletInfo(this.wallet_curpage)
    this.up = this.coreService.getUserPrivilage();
    this.getTemplete();
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_code = this.coreService.getUserData('branch_code');
    this.branch_addrss = this.coreService.getUserData('branch_address');
    this.getAllAgents();

    this.country_dec=this.coreService.setDecimalLength();
    this.cmpny1 = this.coreService.getUserData('cmpny');
    this.usr_block_pre_date_select = this.coreService.getUserData("usr_block_pre_date_select");
    if(this.usr_block_pre_date_select){
      this.minDate= new Date();
    }else{
      this.minDate= new Date(this.coreService.getUserData("branch_open_date"));
    }
  }
  getAllAgents() {
    this.apiService.getAllAgents().subscribe((res) => {
      this.sales_agents = res['data'];
    });
  }
  searchAgents(search: string) {
    const searchval = new FormData();
    searchval.append('ledger_name', search);
    this.apiService.searchAgents(searchval).subscribe((res) => {
      this.sales_agents = res['data'];
    });
  }
  getTemplete() {

    this.apiService.getSettings().subscribe((res) => {

      this.savedTemplate = {
        title_text: res.data.prnt_title_text,
        color: '#000',
        email_color: '#000',
        font_size: '12',
        orientation: "P",
        paper: 'A4-P',
        m_left: '',
        m_top: '',
        m_right: '',
        m_bottom: '',
        logo: res.data.prnt_header_logo,
        header_type: res.data.prnt_header_type,
        header_img: [],
        header_all_page: false,
        header_due: false,
        sl_no: false,
        item_desc: false,
        tax_detail: false,
        tax_type: 0,
        name_type: 'N',
        footer_text: res.data.prnt_display_footer_text
      }
      if (res.data.prnt_header_logo) {
        this.logoImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_logo_img_path;
      } else {
        this.logoImgUrl = '';
      }

      if (res.data.prnt_header_type == 2) {
        this.tempImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_img_path;
      } else {
        this.tempImgUrl = '';
      }
      this.footImgUrl=this.apiService.PHP_API_SERVER + '/' + res.data.prnt_reciept_footer_img;

    });
  }

  getNextReceiptId() {
    this.apiService.getNextReceiptId().subscribe((res) => {
      this.nextId = res.data.ref_no;
    });
  }
  getLedgerBalance() {
    this.accLoading = true;
    this.apiService.getLedgerBalance({ ledger_id: this.rec.rec_acc }).subscribe((res) => {
      this.accLoading = false;
      this.rec.rec_acc_balance = res.data.bal_without_sign;
      this.rec.rec_acc_bal_sign = res.data.bal_sign;
    });
    // this.ShowInvDetails();
  }
  getBalance() {
    if(!this.isEdit ){
      console.log('clearrrr');
      
    this.clearData();
    }
    this.rec.payType = 1;
    this.ledgerLoading = true;
    if(!this.rec.rec_cust_ledger) return;
    this.apiService.getCustomerBalance({ cust_ledger_id: this.rec.rec_cust_ledger }).subscribe((res) => {
      this.ledgerLoading = false;
      this.rec.rec_cust_balance = res.data.balance;
      this.wallet = res.data.wallet;
      this.rec.rec_cust_bal_sign = res.data.bal_sign;
      this.ledger_name=res.data.ledger_name;
      this.ledger_alias=res.data.ledger_alias;
      this.bal_amt=res.data.total_due_amnt;
      this.bal_inv_amt=res.data.total_inv_amnt;
      this.total_amnt_due=res.data.total_amount_due;
      if(!this.isEdit ){
        this.rec.collected_by_agent=res.data.agent;
      }
     
      this.ShowInvDetails();
     
    });

    // this.apiService.getLedgerBalance({ ledger_id: this.rec.rec_cust_ledger }).subscribe((res) => {
    //   this.ledgerLoading = false;
    //   this.rec.rec_cust_balance = res.data.bal_without_sign;
    //   this.rec.rec_cust_bal_sign = res.data.bal_sign;
    // });
  }

  addInvoice(indx) {
    // this.dueInv[indx].rec_amt = this.dueInv[indx].salesduesub_inv_balance;    
    if(this.rec.payType==2){
      // if(this.rec.balance_to_wallet>0){
      //   if(this.dueInv[indx].salesduesub_inv_balance>this.rec.balance_to_wallet){
      //     this.coreService.showMessage("Invoice amount greater-than available balance");
      //     return false;
      //   }
      // }else{
      //   if(this.dueInv[indx].salesduesub_inv_balance>this.wallet){
      //     this.coreService.showMessage("Insufficient wallet balance");
      //     return false;
      //   }
      // }
      if(!this.wallet){
        this.coreService.showMessage("Insufficient wallet balance");
        return false;
      }
    }
    
    
    this.rec.invs.push(this.dueInv[indx]);
    console.log(this.rec.invs);
    this.dueInv.splice(indx, 1);
    this.updateTotal();
  }

  // get sortData() {
  //   return this.dueInv.sort((a, b) => {
  //     return <any>new Date(a.salesduesub_date) - <any>new Date(b.salesduesub_date);
  //   });
  // }

  removeInvoice(indx) {
    this.dueInv.push(this.rec.invs[indx]);
    this.rec.invs.splice(indx, 1);
    this.updateTotal();
  }
  getWalletclearData(){
    this.clearData();
    // wallet amount comes here
    this.rec.ttl_input_amount =  this.wallet;
    // this.updatePrice();
  }
  clearData(){
    this.dueInv = JSON.parse(this.pendingInvs);
    this.rec.invs = [];
    this.rec.ttl_input_amount = 0;
    this.rec.balance_to_wallet = this.covertToPrice(0,2);
    this.updateTotal();
    this.isRemoveLaoding = {};
    this.is_advance = false;
  }
  isAdvaceChange(){
    if(this.is_advance){
      this.rec.invs = [];
      this.dueInv = JSON.parse(this.pendingInvs);
      this.updateTotal();
      this.isRemoveLaoding = {};
    }else{
      this.updatePrice();
    }
  }
  
  updatePrice() {
    if(this.is_advance){
      this.rec.invs = [];
      this.dueInv = JSON.parse(this.pendingInvs);
      this.updateTotal();
      this.isRemoveLaoding = {};
    }else{
      this.dueInv = JSON.parse(this.pendingInvs);
      this.rec.invs = [];
      if (Number(this.rec.ttl_input_amount) !== 0) {
        let balAmt = this.rec.ttl_input_amount;
        const addInx = [];
        this.dueInv.forEach((element, indx) => {
          if (balAmt > 0 ) {
            const remBal = Number(balAmt) - Number(element.salesduesub_inv_balance);
            if (remBal < 0) {
              this.dueInv[indx].rec_amt = this.lstbalAmt = Number(balAmt);
              console.log('main loop balance amut' +this.lstbalAmt);
              this.rec.balance_to_wallet = this.covertToPrice(0,2);
            } else {
              this.lstbalAmt = Number(balAmt);
              this.rec.balance_to_wallet = this.covertToPrice(remBal,2);
              console.log('main loop balance amt 2 ' +this.lstbalAmt);
            }
            addInx.push(indx);
            balAmt = Number(remBal);
          }
        });
        addInx.forEach((indx, i) => {
          this.rec.invs.push(this.dueInv[indx - i]);
          this.dueInv.splice(indx - i, 1);
        });
        this.updateTotal();
      }
    }
  }

  updateRecAmount(i){
    // blank write off set as zero
    this.rec.invs[i].write_off = (this.rec.invs[i].write_off == '') ? 0 : this.rec.invs[i].write_off;
    // last invoice partial receipt (by amount or wallet) assign last received amount
    var currBal = (this.rec.payType == 1 || (this.rec.invs.length -1) != i) ? this.rec.invs[i].salesduesub_inv_balance : this.lstbalAmt;
    // not allow writeoff greater than the invoice receiving amount
    if(parseFloat(this.rec.invs[i].write_off) > parseFloat(this.covertToPrice(currBal,2).replace(/,/g, ''))){
      $('#write-off-'+i).val(0);
      this.rec.invs[i].write_off = 0;
      this.rec.invs[i].rec_amt = currBal;
    } else{
      this.rec.invs[i].rec_amt = parseFloat(this.covertToPrice(currBal,2).replace(/,/g, '')) - parseFloat(this.rec.invs[i].write_off);
    }

    if(this.rec.payType != 1){
      this.updateTotal();
      let balAmt = this.rec.ttl_input_amount - this.rec.ttl_amount;
      if(balAmt < 0) {
        console.log('balance in negative')
        while (true) {
          balAmt = this.rec.ttl_input_amount - this.rec.ttl_amount;
          console.log('balance ' + balAmt)
          if(this.rec.invs[this.rec.invs.length-1].rec_amt > Math.abs(balAmt)){
            console.log('last row suuf to minus amount ' + Math.abs(balAmt))
            console.log('last row bal ' + this.rec.invs[this.rec.invs.length-1].rec_amt)
          this.lstbalAmt = this.rec.invs[this.rec.invs.length-1].rec_amt += balAmt;
            this.lstbalAmt = this.rec.invs[this.rec.invs.length-1].rec_amt;
            this.updateTotal();
            break;
          } else {
            this.removeInvoice(this.rec.invs.length-1);
            this.updateTotal();
          }
          
        }

      } else {

        var write_off_ttl = 0;
        if(this.rec.invs.length > 2){
          for(var j=0; j < this.rec.invs.length-1; j++){
            write_off_ttl += parseFloat(this.rec.invs[j].write_off) || 0;
          }
        }
        // console.log('if 1st : ' + parseFloat(this.covertToPrice(this.rec.invs[this.rec.invs.length-1].salesduesub_inv_balance,2)))
        // console.log('if 2nd : ' + (write_off_ttl + this.lstbalAmt))
        // console.log('if 2nd : ' + write_off_ttl + this.lstbalAmt + parseFloat(this.rec.invs[this.rec.invs.length-1].write_off) || 0))
        var tmpttl = (parseFloat(this.covertToPrice(write_off_ttl,2).replace(/,/g, '')) + parseFloat(this.covertToPrice(this.lstbalAmt,2).replace(/,/g, '')) + (parseFloat(this.rec.invs[this.rec.invs.length-1].write_off) || 0));
        // console.log('if 2nd new : ' + tmpttl)
        if(parseFloat(this.covertToPrice(this.rec.invs[this.rec.invs.length-1].salesduesub_inv_balance,2).replace(/,/g, '')) >= tmpttl){
          this.rec.invs[this.rec.invs.length-1].rec_amt = write_off_ttl + this.lstbalAmt;
        } else {
          this.rec.invs[this.rec.invs.length-1].rec_amt = parseFloat(this.covertToPrice(this.rec.invs[this.rec.invs.length-1].salesduesub_inv_balance,2).replace(/,/g, '')) - (parseFloat(this.rec.invs[this.rec.invs.length-1].write_off) || 0);
          // console.log('call next invoice');
          // currBal = (write_off_ttl + this.lstbalAmt + parseFloat(this.rec.invs[this.rec.invs.length-1].write_off) || 0) - this.rec.invs[this.rec.invs.length-1].rec_amt;
          this.updateTotal();
          let balAmt = this.rec.ttl_input_amount - this.rec.ttl_amount;
          // let balAmt = this.rec.ttl_input_amount;
          console.log('balance ' + balAmt);
          console.log(this.dueInv);
          const addInx = [];
          this.dueInv.forEach((element, indx) => {
            if (balAmt > 0 ) {
              const remBal = Number(balAmt) - Number(element.salesduesub_inv_balance);
              if (remBal < 0) {
                this.dueInv[indx].rec_amt = this.lstbalAmt = Number(balAmt);
                this.rec.balance_to_wallet = this.covertToPrice(0,2);
              } else{
                this.lstbalAmt = Number(balAmt);
                this.rec.balance_to_wallet = this.covertToPrice(remBal,2);
              }
              addInx.push(indx);
              balAmt = Number(remBal);
            }
          });
          addInx.forEach((indx, i) => {
            this.rec.invs.push(this.dueInv[indx - i]);
            this.dueInv.splice(indx - i, 1);
          });
          this.updateTotal();
          
          
        }
      }
      // write_off_ttl -= parseFloat(this.rec.invs[this.rec.invs.length-1].write_off) || 0;
      
      // console.log('write off rem ' + write_off_ttl);
    }
    this.updateTotal();
  }

  searchDuelList(e) {
    let searchval = new FormData();
    searchval.append("search", e);
    if(this.usr_hide_others_sales_data){ 
      let hide_other='hide';
      searchval.append("hide_others", hide_other);
    }
    if(this.translate.currentLang  == "Arabic"){ searchval.append("with-alias", "1");}
    this.pageLoadingImg = true;
    this.apiService.searchDuelList(searchval, 1, this.perpage).subscribe((res) => {
      this.info = res.data.data;
      this.pageLoadingImg = false;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
    });
  }

  searchWalletDuelList(e) {
    let searchval = new FormData();
    searchval.append("search", e);
    if(this.usr_hide_others_sales_data){ 
      let hide_other='hide';
      searchval.append("hide_others", hide_other);
    }
    if(this.translate.currentLang  == "Arabic"){ searchval.append("with-alias", "1");}
    this.walletPageLoadingImg = true;
    this.apiService.searchWalletDuelList(searchval, 1, this.perpage).subscribe((res) => {
      this.wallet_info = res.data.data;
      this.walletPageLoadingImg = false;
      this.wallet_curpage = res.data.current_page;
      this.wallet_lastpage = res.data.last_page;
      this.wallet_totalItem = res.data.total;
    });
  }
  updateTotal() {
    if(!this.isEdit){
      this.rec.ttl_amount = 0;
      this.rec.ttl_wo_amount = 0;
    console.log(" this.rec.invs");
    console.log(this.rec.invs);
    this.rec.invs.forEach((element, indx) => {
      if(!isNaN(element.rec_amt)){
        this.rec.ttl_amount += Number(element.rec_amt); 
      }else{
        this.rec.ttl_amount +=Number(element.rec_amt.replace(/[^0-9.]/g, ''));
      }
      element.write_off = (element.write_off) ? element.write_off : 0;
      if(!isNaN(element.write_off)){
        this.rec.ttl_wo_amount += Number(element.write_off); 
      }else{
        this.rec.ttl_wo_amount +=Number(element.write_off.replace(/[^0-9.]/g, ''));
      }      
    });
    this.rec.ttl_due = this.rec.rec_cust_balance - this.rec.ttl_amount;
    console.log('rec.ttl_input_amount : ' + this.rec.ttl_input_amount);
    this.rec.balance_to_wallet = (this.rec.ttl_input_amount == 0) ? this.covertToPrice(0,2) : this.covertToPrice((this.rec.ttl_input_amount-this.rec.ttl_amount),2);
    // this.rec.balance_to_wallet = (this.rec.ttl_input_amount == 0) ? this.rec.ttl_amount : this.rec.ttl_input_amount;
  }
  }

  ShowInvDetails() {
    if(!this.isEdit ){
    this.rec.invs = [];
    }
    this.pendingInvs = JSON.stringify([]);
    if (this.rec.rec_cust_ledger !== 0 ) {
      this.invLoading = true;
      this.apiService.getCustomerInvoice({ cust_ledger_id: this.rec.rec_cust_ledger }).subscribe((res) => {
        this.invLoading = false;
        this.pendingInvs = JSON.stringify(res.data);
        this.dueInv = res.data;
        this.total_inv_amnt = 0;
        this.dueInv.forEach((element, indx) => {
          this.total_inv_amnt += Number(element.salesduesub_inv_amount);
        });
        this.updateTotal();
        console.log('pay type ' +this.rec.payType );
        if(this.rec.payType == 0 || this.rec.payType ==2){
          console.log('in if paytype');
          this.updatePrice();
        }
        // this.rec.invs = res.data;
      });
    }
  }
  // sort invoice with sales date
  sortwithDate(){
    const searchval = new FormData();
    let ledgerid:any;
    ledgerid=this.rec.rec_cust_ledger;
    searchval.append('cust_ledger_id', ledgerid);
    searchval.append('sort_with_date', '1');
    this.apiService.getCustomerInvoice( searchval ).subscribe((res) => {
      this.invLoading = false;
      this.pendingInvs = JSON.stringify(res.data);
      this.dueInv = res.data;
      this.total_inv_amnt = 0;
      this.dueInv.forEach((element, indx) => {
        this.total_inv_amnt += Number(element.salesduesub_inv_amount);
      });
      this.updateTotal();
      console.log('pay type ' +this.rec.payType );
      if(this.rec.payType == 0 || this.rec.payType ==2){
        console.log('in if paytype');
        this.updatePrice();
      }
      // this.rec.invs = res.data;
    });
  }

  getInfo(pageNo) {
    let searchval = new FormData();   
    if(this.usr_hide_others_sales_data){ 
      let hide_other='hide';
      searchval.append("hide_others", hide_other);
    }
    this.pageLoadingImg = true;
    this.apiService.getAllDueReceipt(searchval,pageNo, this.perpage).subscribe((res) => {
      this.info = res.data.data;
      this.pageLoadingImg = false;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
    });
  }
  getPaidByWalletInfo(pageNo) {
    let searchval = new FormData();   
    if(this.usr_hide_others_sales_data){ 
      let hide_other='hide';
      searchval.append("hide_others", hide_other);
    }
      this.walletPageLoadingImg = true;
      this.apiService.getAllWalletDueReceipt(searchval,pageNo, this.wallet_perpage).subscribe((res) => {
        this.wallet_info = res.data.data;
        this.walletPageLoadingImg = false;
        this.wallet_curpage = res.data.current_page;
        this.wallet_lastpage = res.data.last_page;
        this.wallet_totalItem = res.data.total;
      });
    
  }
  getAllAccount() {
    this.apiService.getAllAccount().subscribe((res) => {
      this.account = res.data;
    });
  }
  getCustomerLedgres() {
    this.apiService.getCustomerLedgres('').subscribe((res) => {
      this.nonAccount = res.data;
    });
  }

  searchNonAccount(search: string){
    console.log('search');
    console.log(search);
    
    const searchval = new FormData();
    searchval.append('search', search);
    this.apiService.getCustomerLedgres(searchval).subscribe((res) => {
      this.nonAccount = res.data;
    });
  }

  clearForm() {
    this.rec = {
      rec_no: '',
      doc_no:'',
      rec_date: new Date(),
      rec_acc: 0,
      rec_acc_balance: 0,
      rec_acc_bal_sign: '',
      rec_cust_ledger: 0,
      rec_cust_balance: 0,
      rec_cust_bal_sign: '',
      note: '',
      invs: [],
      ttl_amount: 0,
      ttl_wo_amount: 0,
      ttl_input_amount: 0,
      ttl_due: 0,
      payType: 1,
      collected_by_agent: 0,
      balance_to_wallet:this.covertToPrice(0,2),
      is_advance : false
    };
    this.dueInv = [];
    this.rec.invs = [];
    this.isRemoveLaoding = {};
    this.is_advance = false;
    this.isEdit =false;
    this.wallet = 0;
    this.curForm = this.langText.create;
    this.getNextReceiptId();

  }



  validateAndSubmit() {
    // const confrm = confirm('Do you wish to continue?');
    
    if(!this.isEdit){

      if(this.rec.payType==1 && (!this.rec.ttl_amount && !this.rec.ttl_wo_amount)){
        return false;
      }
      if(this.rec.payType==2){  
        this.rec.doc_no=null;    
        if (this.rec.ttl_amount > this.wallet ) {
          this.coreService.showMessage('Total amount should be lessthan or equal to wallet balance');
          this.isSubmitInProg = false;
          return false;
        }
      }
      if(!this.is_advance && !this.rec.invs.length){
        this.coreService.showMessage('No invoice selected');
        this.isSubmitInProg = false;
        return false;
      } 
      if(this.is_advance && (this.rec.balance_to_wallet<=0 || !this.rec.balance_to_wallet)){
        this.coreService.showMessage('Enter advance amount');
        this.isSubmitInProg = false;
        return false;
      } 

    }
 
    if (confirm('Do you wish to continue?')) {
      this.isSubmitInProg = true;
      let callFunction = '';
      this.rec.is_advance = this.is_advance;
      if(this.rec.payType==2){ 
        this.rec.rec_acc =0;
      }
  
      if (this.curForm === this.langText.create) {
        callFunction = 'createDueReceipt';
      }
      if (this.curForm === this.langText.update) {
        callFunction = 'updateDueReceipt';
      }
      // if (this.rec.ttl_input_amount > this.rec.rec_cust_balance ) {
      //   this.resultobj = {ttl_input_amount: 'Invalid Amount'};
      //   this.coreService.showMessage('Invalid Input Amount');
      //   this.isSubmitInProg = false;
      // } else {
        this.apiService[callFunction](this.rec).subscribe((res) => {
          // console.log(this.staff);
          this.isSubmitInProg = false;
          if (res.error != null) {
            this.resultobj = res.error;
            if(this.resultobj.doc_no){
              alert(this.resultobj.doc_no);
            }
            if(res.message) {
              this.coreService.showMessage(res.message);
            }
          } else {
            this.closeModal.nativeElement.click();
            this.getInfo(this.curpage);
            this.getPaidByWalletInfo(this.wallet_curpage);
            this.coreService.showMessage(res.message);
            this.clearForm();
            this.resultobj = {};
            this.getNextReceiptId();
            this.getBalance();
          }

        });
      // }
    }
  }

  voidDueReceipt(id,index){
    // const confrm = confirm('Do you wish to continue?');
    if (confirm('Do you wish to continue?')) {
      this.info[index].void_in_progress = true;
      this.apiService.voidDueReceipt({rec_no: id}).subscribe((res) => {
        // console.log(this.staff);
        this.isSubmitInProg = false;
        if (res.error != null) {
          this.resultobj = res.error;
        } else {
          this.getInfo(this.curpage);
          // this.getPaidByWalletInfo(this.wallet_curpage);
          this.getBalance();
          this.coreService.showMessage(res.message);
          this.clearForm();
          this.resultobj = {};
        }

      });
    }
  }
  voidWalletDueReceipt(id,duesub_id){
    // const confrm = confirm('Do you wish to continue?');
    if (confirm('Do you wish to continue?')) {
      this.apiService.voidWalletDueReceipt({rec_no: id,duesub_id: duesub_id}).subscribe((res) => {
        // console.log(this.staff);
        this.isSubmitInProg = false;
        if (res.error != null) {
          this.resultobj = res.error;
        } else {
          this.getPaidByWalletInfo(this.wallet_curpage);
          this.getBalance();
          this.coreService.showMessage(res.message);
          this.clearForm();
          this.resultobj = {};
        }

      });
    }
  }
  editDueReceipt(id,index){
    this.isEdit =true;

    console.log("hiaaaaaaa");
    console.log(id);
    this.curForm = this.langText.update;

    this.apiService.getInvoiceDueReceipt({rec_no: id}).subscribe((res) => {

      console.log("res");
    console.log(res);
    console.log("aaaaa");
    // console.log(res.inv_due_particulars[0].salesduesub_agent_ledger_id);
    this.nextId =res.rec_no;
    // this.rec.invs =res.inv_due_particulars;


     this.rec.rec_date=new Date(res.rec_datetime);
     this.rec.rec_acc =res.rec_acc_ledger_id;

     console.log("ffff");
     console.log(this.rec.rec_acc);
    
     console.log('this.nonAccount');
     console.log(this.nonAccount);
     this.getLedgerBalance();
     
     if(res.inv_due_particulars.length >0){
      console.log('res.inv_due_particulars[0].led_name');
      console.log(res.inv_due_particulars[0].led_name);
      
      this.searchNonAccount(res.inv_due_particulars[0].led_name)
      
      this.rec.rec_cust_ledger =res.inv_due_particulars[0].ledger_id;
      this.rec.collected_by_agent =res.inv_due_particulars[0].salesduesub_agent_ledger_id;
      console.log( ' this.rec.rec_cust_ledger  inv_due_particulars');
      console.log(this.rec.rec_cust_ledger);
     }
     else{
      this.searchNonAccount(res.led_name)
      this.rec.rec_cust_ledger =res.rec_cust_ledger;
      this.rec.collected_by_agent = 0;
      console.log( 'this.rec.rec_cust_ledger');
      console.log(this.rec.rec_cust_ledger);
     }
    
     this.getBalance();
     
    // this.rec.rec_date = new Date(res.rec_datetime);

    this.rec.invs=res.inv_due_particulars;
    this.rec.note =res.rec_note;
    this.rec.rec_no = res.rec_no;
    this.rec.ttl_amount =  res.rec_ttl_amount;
    console.log("this.rec.ttl_amount0");
    console.log(this.rec.ttl_amount);

    // if(this.rec.invs.length > 0){
    //   this.updateTotal();
    // }

    console.log("inv_due_particulars");
    console.log(this.rec.invs);

    console.log("rec.invs.length");
    console.log(this.rec.invs.length);

    console.log("this.rec.ttl_amount1");
    console.log(this.rec.ttl_amount);

      
    });
  }


  public dayBetweenDates(sdate, edate = new Date()) {
    // Get 1 day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;
    // Convert both dates to milliseconds
    const sdateMs = new Date(sdate).getTime();
    const edateMs = new Date(edate).getTime();
    // Calculate the difference in milliseconds
    const differenceMs = edateMs - sdateMs;
    // Convert back to days and return
    return Math.round(differenceMs / oneDay);
  }

  public checkDueExp(dueDate) {
    const duedateMs = new Date(dueDate).getTime();
    const crrdateMs = new Date().getTime();
    const differenceMs = duedateMs - crrdateMs;
    if (differenceMs < 0) {
      return true;
    } else {
      return false;
    }
  }

  public covertToPrice(num, decimals){ 
    return num.toLocaleString('en-US', {
        minimumFractionDigits: this.country_dec_exp,      
        maximumFractionDigits: this.country_dec_exp,
    });
  }
  viewReceipt(id){
    this.recPriviewLoader = true;
      this.apiService.getInvoiceDueReceipt({rec_no: id}).subscribe((res) => {
        this.recPriviewLoader = false;
        this.printPreview = res;
      });
  }
  viewWalletReceipt(id){
    this.recWalletPriviewLoader = true;
      this.apiService.getInvoiceDueReceiptByWallet({rec_no: id}).subscribe((res) => {
        this.recWalletPriviewLoader = false;
        this.printWalletPreview = res;
      });
  }
  
  removeWriteOff(duesub_id,index){
    this.isRemoveLaoding[index] = true; 
    // this.rec.invs[index].salesduesub_flags = 0;
    if (confirm('Do you wish to continue?')) {
      this.apiService.removeWriteOff({duesub_id: duesub_id}).subscribe((res) => {
        // console.log(this.staff);
        
        if (res.error != null) {
          this.resultobj = res.error;
        } else {
          this.pendingInvs = JSON.stringify([]);
          if (this.rec.rec_cust_ledger !== 0 ) {
            this.apiService.getCustomerInvoice({ cust_ledger_id: this.rec.rec_cust_ledger }).subscribe((res) => {
              this.dueInv = res.data;
              this.pendingInvs = JSON.stringify(res.data);
              this.isRemoveLaoding[index] = false;
              // var newRow = res.data.find(c => c.branch_inv_no = this.rec.invs[index].branch_inv_no);

              this.rec.invs.splice(index, 1);
              // this.rec.invs[index] = newRow;
              this.updateTotal();
              this.coreService.showMessage("Write-off removed Successfully");
              this.resultobj = {};
            });
          }
        }

      });
    }
  }

  exportexcel(): void {
    this.exportLoader = true;

    /* table id is passed over here */
    let element = document.getElementById('export-section');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }
}
