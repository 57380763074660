import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import * as XLSX from 'xlsx';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
@Component({
  selector: 'app-item-unitwise-tracking',
  templateUrl: './item-unitwise-tracking.component.html',
  styleUrls: ['./item-unitwise-tracking.component.css']
})
export class ItemUnitwiseTrackingComponent implements OnInit {
  fileName = 'item_unitwise_tracking_report.xlsx';

  units_all: any;
  products: any[];
  unit_id: string;
  periodTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];
  

  pgend: boolean;
  pgstart: boolean;
  pageLoadingImg: boolean;
  summary: any;
  pageFrom: any;
  bar_filter: any;
  curpage: any;
  lastpage: any;
  bar_val: any;
  bar_val2: any;
  total_qty: any;
  exportLoader: boolean;
  repDate: any;
  Expsummary: any;
  exp_total_qty: any;
  branch_display_name: any;
  branch_display_code: any;
  godown_all: any;
  gd_id: any;
  is_godown: any;
  catagories: any;
  userType: any;
  branch_all: any;
  cur_lang: string;


  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService) { }

  ngOnInit() {
    this.cur_lang = sessionStorage.getItem("baseLang");
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.gd_id= 0; 
    this.pageLoadingImg = true;
    let searchval = new FormData();
    searchval.append('gd_id', this.gd_id);
    this.apiService.itemUnitWiseTarckingReport(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.repDate = res.data.Date;
      this.summary = res['data']['data'];
      this.pageFrom = res['data']['from'];
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.is_godown = res['data']['godown'];
      this.total_qty = res.data.all_total;
     
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      // this.colspan=8;
      // if(this.summary.){

      // }
      
      
    });
    this.userType = this.coreService.getUserData('user_type');
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');

  this.getGodownList();

  }
  langChange(){
    this.translate.get(['Common.today','Common.last_day','Common.last_week','Common.this_month','Common.last_yr','Common.cust_date','Common.all',]).subscribe((res: string) => {

     this.periodTypes = [
        { id: 't', name:res['Common.today'] },
        { id: 'ld', name:res['Common.last_day'] },
        { id: 'lw', name:res['Common.last_week'] },
        { id: 'lm', name:res['Common.this_month'] },
        { id: 'ly', name:res['Common.last_yr'] },
        { id: 'c', name:res['Common.cust_date'] },
        { id: '', name:res['Common.all'] }
      ];
    
    });

  } 


  pageNext(formdata: { value: any; }, pageno: any) {
    formdata.value.export = '';

    this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }

  // console.log(formdata.value.hideqty);
  

    this.apiService.itemUnitWiseTarckingReport(formdata.value, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.repDate = res.data.Date;
      this.summary = res['data']['data'];
      this.pageFrom = res['data']['from'];
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.is_godown = res['data']['godown'];
      this.total_qty = res.data.all_total;
      console.log(this.is_godown);
     

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });

  }
  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  stk_ean_barcode = [
    { id: 'all', name: 'All' },
    { id: 'Custom', name: 'Custom' }
  ];
  
  selectStkEanBarcode(id: string) {
    if (id == 'Custom') {
      $('#ean_key').show();
    }
    else {
      $('#ean_key').hide();
    }
  }


    // search product
    selectProd(name: string) {

      const searchval = new FormData();
      searchval.append('prod_name', name);
      if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
        searchval.append('alias_search', '1');
      }
      this.apiService.getProdByName(searchval).subscribe((res) => {
        this.products = res.data;
      });
    }

    //search Units
  getUnits(prd_id) {
    this.unit_id=null;
    let searchval = new FormData();
    searchval.append("prd_id", prd_id);
    this.apiService.getUnitsItemwise(searchval).subscribe((res) => {
      this.units_all = res['data'];

    });

  }

// godown list
  getGodownList()
  {
       let searchval = new FormData();      
        
         this.apiService.getGodownList(searchval).subscribe((res) => {
          this.godown_all = res['data'];  
      this.godown_all.push({ "gd_id": '', 'gd_name': 'All' });

         });
  }
  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }



  exportEXCL(formdata: { value: any; }, pageno = 1) {
    formdata.value.export = 'export';
    this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.exportLoader = true;

    this.apiService.itemUnitWiseTarckingReport(formdata.value, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.repDate = res.data.Date;
      this.Expsummary = res['data']['data'];
      this.exp_total_qty = res.data.all_total;

      setTimeout(() => {
        this.exportexcel();
      }, 3000);


    });

  }

    //form search  for category
    searchForm(search: string) {
      let searchval = new FormData();
      searchval.append("cat_name", search);
      this.apiService.getCatbyName(searchval).subscribe((res) => {
        this.catagories = res['data'];
      });
  
    }

    searchBranch(search: string) {
      let searchval = new FormData();
      searchval.append("branch_name", search);
      this.apiService.getBranchByName(searchval).subscribe((res) => {
        this.branch_all = res['data'];
      });
    }

}
