import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-sales-return-void',
  templateUrl: './sales-return-void.component.html',
  styleUrls: ['./sales-return-void.component.css']
})
export class SalesReturnVoidComponent implements OnInit {

  pageLoadingImg: boolean;
  showdata: boolean;
  resultobj: any;
  purchaseItems: any;
  sales_inv_no: any;
  saleData: any = {
    sales_id: null,
    sales_inv_no: null,
    error: null,
    message: null,
    data: null,
  };
  payType = ['Cash', 'Credit'];
  formula_list=[];
  from: any;
  curpage= 1;
  totalItem: any;
  lastpage: any;
  saleprivewLoadingImg: boolean;
  salesRetPreview: any;
  per_page: any;
  country_dec: string;
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService) { }


  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      // this.langChange();
    });
    this.pageLoadingImg = false;
    this.showdata = false;
    this.getInfo(1);

    this.country_dec=this.coreService.setDecimalLength();
  }

  getInfo(pageNo) {
    this.pageLoadingImg = true;
    this.apiService.salesReturnVoidList(pageNo).subscribe((res) => {
      this.formula_list = res.data.data;
      this.pageLoadingImg = false;
      this.from = res.data.from;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
      this.per_page =res.data.per_page;

    });
  }
  pageNext(formdata: { value: any; }, pageno: any) {

    this.pageLoadingImg = true;
      this.apiService.salesReturnVoidList(pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      });

    }


    getsalesReturnPrev(prdn_id) {
      this.saleprivewLoadingImg = true;
// console.log(prdn_id);
    let searchval = new FormData();
    searchval.append("ref_no", prdn_id);
    this.apiService.getsalesReturnPrev(searchval).subscribe((res) => {
      this.saleprivewLoadingImg = false;
      this.salesRetPreview = res;
      this.salesRetPreview.total_vat = Number(res.total_vat);
      this.salesRetPreview.total_amt = Number(res.total_amt);

      //this.godown_all.push({ "gd_id": 0, 'gd_name': 'Shop' });

    });

  }

  sale_search(search: string) {

    this.pageLoadingImg = true;
    const searchval = new FormData();
    searchval.append('sales_inv_no', search);
    this.apiService.getSalesReturnInv(searchval).subscribe((res) => {
      this.pageLoadingImg = false;

      if (res.error != null) {
        this.resultobj = res.error;
        this.purchaseItems = [];
        this.saleData = {
          sales_id: null,
          sales_inv_no: null,
          error: null,
          message: null,
          data: null,

        };
        this.resultobj = this.coreService.getValidation(this.resultobj);
      } else {
        this.showdata = true;

        this.resultobj = {};
        this.purchaseItems = res.data;
        this.saleData = res.data;
      }


    });
  }




  salesVoid(purchId: any) {

    var voidstk = confirm("Are you sure you want to Void this Sales Return ? ");
    if (voidstk) {
      this.pageLoadingImg = true;

      const searchval = new FormData();
      searchval.append('sales_inv_num', purchId);
      this.apiService.salesReturnVoid(searchval).subscribe((res) => {
        this.pageLoadingImg = false;
       
        if (res.error != null) {
          this.saleData.is_returned = 1;
      }else{
        
        searchval.append('sales_inv_no', purchId);
        this.apiService.getSalesReturnInv(searchval).subscribe((res) => {
          this.pageLoadingImg = false;
    
          if (res.error != null) {
            this.resultobj = res.error;
            this.purchaseItems = [];
            this.saleData = {
              sales_id: null,
              sales_inv_no: null,
              error: null,
              message: null,
              data: null,
    
            };
            this.resultobj = this.coreService.getValidation(this.resultobj);
          } else {
            this.showdata = true;
    
            this.resultobj = {};
            this.purchaseItems = res.data;
            this.saleData = res.data;
          }
    
    
        });
        this.coreService.showMessage(res.message);
      }
      });
      this.apiService.updatebatchExNotification();
      this.apiService.updateEinvoiceNotificationChange();
    }
  }



}
