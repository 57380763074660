import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { ApiService } from '../../../../../service/api.service';
import { CoreService } from '../../../../../service/core.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-inter-branch-payment',
  templateUrl: './inter-branch-payment.component.html',
  styleUrls: ['./inter-branch-payment.component.css']
})
export class InterBranchPaymentComponent implements OnInit {
  @ViewChild('closeModal', { static: false }) closeModal: ElementRef;
  resultobj: any = {};
  info = [];
  pay = {
    pay_no: '',
    pay_date: new Date(),
    pay_acc: { ledger_id : 0 },
    pay_balance: 0,
    pay_bal_sign: '',
    pay_branch_id: 0,
    amount: <any>'',
    narration: '',
    accLoading: false,
    is_resubmit: false
  };
  // pay.pay_acc: any = {};
  account = [];
  branchAll = [];
  isSubmitInProg = false;

  curpage = 1;
  lastpage = 1;
  perpage = 20;
  totalItem = 0;
  curForm = 'Submit';
  pageLoadingImg = false;

  langText = {
    create: '',
    update: ''
  };
  nextId = '';
  accLoading = false;
  isResubmit = false;
  up: any;
  country_dec: string;
  usr_hide_others_sales_data: any;
  usr_block_pre_date_select: any;
  minDate: Date;

  constructor(private apiService: ApiService, private coreService: CoreService, private translate: TranslateService) { }

  ngOnInit() {
    this.translate.get(['Common.submit', 'Common.update']).subscribe((res: string) => {
      this.langText.create = res['Common.submit'];
      this.langText.update = res['Common.update'];
      this.curForm = this.langText.create;
    });
    this.usr_hide_others_sales_data = this.coreService.getUserData('usr_hide_othr_sales_data');
    this.getNextPaymentId();
    this.getAllAccount();
    this.getInfo(this.curpage);
    this.searchBranch();
    this.up = this.coreService.getUserPrivilage();
    
    this.country_dec=this.coreService.setDecimalLength();
    this.usr_block_pre_date_select = this.coreService.getUserData("usr_block_pre_date_select");
    if(this.usr_block_pre_date_select){
      this.minDate= new Date();
    }else{
      this.minDate= new Date(this.coreService.getUserData("branch_open_date"));
    }

  }
  getNextPaymentId() {
    this.apiService.getNextPaymentId().subscribe((res) => {
      this.nextId = res.data.ref_no;
    });
  }
  getAccLedgerBalance() {
    this.accLoading = true;
    this.apiService.getLedgerBalance({ ledger_id: this.pay.pay_acc.ledger_id }).subscribe((res) => {
      this.accLoading = false;
      this.pay.pay_balance = res.data.bal_without_sign;
      this.pay.pay_bal_sign = res.data.bal_sign;
    });
  }

  editInterPayment(index, isReSubmit) {
    if (isReSubmit) {
      this.isResubmit = true;
    } else {
      this.isResubmit = false;
    }
    this.curForm = this.langText.update;
    this.nextId = this.info[index].pay_no;
    this.pay = {
      pay_no: this.info[index].pay_no,
      pay_date: new Date(this.info[index].pay_date),
      pay_acc: this.account.find(element => element.ledger_id === this.info[index].pay_acc_ledger_id),
      pay_balance: 0,
      pay_bal_sign: '',
      pay_branch_id: this.info[index].pay_inter_branch_id,
      amount: this.info[index].pay_ttl_amount,
      narration: this.info[index].pay_note,
      accLoading: false,
      is_resubmit: (isReSubmit) ? true : false
    };
  }

  getInfo(pageNo) {
    let searchval = new FormData();   
    if(this.usr_hide_others_sales_data){ 
      let hide_other='hide';
      searchval.append("hide_others", hide_other);
    }
    this.pageLoadingImg = true;
    this.apiService.getAllInterBranchPayment(searchval,pageNo, this.perpage).subscribe((res) => {
      this.info = res.data.data;
      this.pageLoadingImg = false;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
    });
  }

  searchInp(e) {
    this.pageLoadingImg = true;

    const searchval = new FormData();
    searchval.append('search', e);
    if(this.usr_hide_others_sales_data){ 
      let hide_other='hide';
      searchval.append("hide_others", hide_other);
    }
    this.apiService.searchPaymentList(searchval).subscribe((res) => {
      this.info = res.data.data;
      this.pageLoadingImg = false;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
     });
  }

  getAllAccount() {
    this.apiService.getAllAccount().subscribe((res) => {
      this.account = res.data;
    });
  }

  searchBranch() {
    const searchval = new FormData();
    searchval.append('branch_name', '');
    searchval.append('getallbranch', '1');
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branchAll = res['data'];
    });
  }
  addNew() {
    this.getNextPaymentId();
    this.curForm = this.langText.create;
    this.clearForm();
  }
  clearForm() {
    this.isResubmit = false;
    this.pay = {
      pay_no: '',
      pay_date: new Date(),
      pay_acc: { ledger_id : 0 },
      pay_balance: 0,
      pay_bal_sign: '',
      pay_branch_id: 0,
      amount: '',
      narration: '',
      accLoading: false,
      is_resubmit: false
    };
  }

  checkTabClicked(keycode) {
    console.log(keycode);
    if (keycode.keyCode === 9) {
      this.validateAndSubmit();
    }
  }

  voidPayment(id, index){
    // const confrm = confirm('Do you wish to continue?');
    if (confirm('Do you wish to continue?')) {
      this.info[index].void_in_progress = true;
      this.apiService.voidPayment({pay_no: id}).subscribe((res) => {
        // console.log(this.staff);
        this.isSubmitInProg = false;
        if (res.error != null) {
          this.resultobj = res.error;
        } else {
          this.getInfo(this.curpage);
          this.coreService.showMessage(res.message);
        }

      });
    }
  }

  validateAndSubmit() {

    this.resultobj = [];

    if (this.pay.pay_date == undefined) {
      this.resultobj.pay_date = "t";
      this.resultobj.push({ 'pay_date': 'Required' });
    }
    else {
      if (this.pay.pay_date > new Date()) {
        this.resultobj.pay_date = "t";
        this.resultobj.push({ 'pay_date': 'Required' });
      } else {
        this.resultobj.pay_date = "f";
      }
    }
    if (this.resultobj.length <= 0) {

    if (confirm('Do you wish to continue?')) {
      this.isSubmitInProg = true;
      let callFunction = '';
      if (this.curForm === this.langText.create) {
        callFunction = 'createInterPayment';
      }
      if (this.curForm === this.langText.update) {
        callFunction = 'updateInterPayment';
      }
      this.apiService[callFunction](this.pay).subscribe((res) => {
        // console.log(this.staff);
        this.isSubmitInProg = false;
        if (res.error != null) {
          this.resultobj = res.error;
          if(res.error.common && res.error.common.length > 0){
            this.coreService.showMessage(res.error.common[0],3000);
          }
        } else {
          this.closeModal.nativeElement.click();
          this.getInfo(this.curpage);
          this.coreService.showMessage(res.message);
          this.clearForm();
          this.getNextPaymentId();
          this.resultobj = {};
        }

      });
    }
  }
}
}
