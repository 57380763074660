import { Component, Input, OnInit } from '@angular/core';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-sales-ret-thermal',
  templateUrl: './sales-ret-thermal.component.html',
  styleUrls: ['./sales-ret-thermal.component.css']
})
export class SalesRetThermalComponent implements OnInit {

  @Input()salesRetPreview;
  @Input()print_style;
  @Input()qr_inv;
  @Input()savedTemplate;
  @Input()is_prew_description;
  @Input()payTypes;
  @Input()cmpny;
  @Input()previewLoader;
  @Input()logoImgUrl;
  @Input()tempImgUrl;
  @Input()sales_print_name;
  @Input()hide_item_discount;
  @Input()hide_vat;
  @Input()sales_settings;

  constructor(private translate:TranslateService,) { }

  ngOnInit() {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      // this.langChange();
    });
  }

}
