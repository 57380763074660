import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';


@Component({
  selector: 'app-excisecat-popup',
  templateUrl: './excisecat-popup.component.html',
  styleUrls: ['./excisecat-popup.component.css']
})
export class ExcisecatPopupComponent implements OnInit {
  @Input()edit_data;
  @Output() excsCatUpdated = new EventEmitter();
  pageLoadingImg: boolean;
  result: any;
  excstaxCategories: any;
  excs_cat_name: any;
  excs_cat_alias: any;
  excs_cat_perc: any;
  excs_cat_remarks: any;
  excs_cat_id: any;

  constructor(private apiService: ApiService, private coreService: CoreService, private translate: TranslateService) { }

  ngOnInit() {
   
  }
  ngOnChanges(changes) {
    this.clearForm();
    if(this.edit_data){
      this.excs_cat_name=this.edit_data.excs_cat_name;
      this.excs_cat_alias=this.edit_data.excs_cat_alias;
      this.excs_cat_perc=this.edit_data.excs_tax_percent;
      this.excs_cat_remarks=this.edit_data.excs_cat_remarks;
      this.excs_cat_id=this.edit_data.excs_cat_id;
    }
  }

  createExcsCat(formdata: { value: any; }){
    this.pageLoadingImg = true;

      this.apiService.createExcsCat(formdata.value).subscribe((res: any) => {
        this.pageLoadingImg = false;

        if (res.error != null) {        
          this.result = res.error;  
          console.log(this.result);     
        }
        else {
        this.coreService.createfunct(formdata,this.result,res.message);
        $('.custPopCloseBtn').click();
        // this.getInfo(this.curpage);
        this.excsCatUpdated.emit();
        }

      });

  }

  updateExcsCat(formdata: { value: any; }){
    this.pageLoadingImg = true;

      this.apiService.updateExcsCat(formdata.value).subscribe((res: any) => {
        this.pageLoadingImg = false;

        if (res.error != null) {        
          this.result = res.error;  
          console.log(this.result);     
        }
        else {
        this.coreService.updatefunct(formdata,this.result,res.message);
        $('.custPopCloseBtn').click();
        // this.getInfo(this.curpage);
        this.excsCatUpdated.emit();
        }

      });

  }
 getallExcsTax(){
  let searchval=new FormData;
  this.apiService.getAllExcsTaxCategory(searchval).subscribe((res) => {
    this.excstaxCategories = res.data;
  });
 }
 clearForm(){
      this.excs_cat_name='';
      this.excs_cat_alias='';
      this.excs_cat_perc='';
      this.excs_cat_remarks='';
      this.excs_cat_id='';
 }
}
