import { Component, OnInit,ElementRef,ViewChild } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import * as XLSX from 'xlsx';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { TranslateService } from '@ngx-translate/core';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-van-stock-traking-unitwise',
  templateUrl: './van-stock-traking-unitwise.component.html',
  styleUrls: ['./van-stock-traking-unitwise.component.css']
})
export class VanStockTrakingUnitwiseComponent implements OnInit {

  @ViewChild('exportall', { static: true }) reportContent: ElementRef;
  @ViewChild('printBtn',{static: true}) printBtn: ElementRef<HTMLElement>;
  fileName= 'Van_stock_tracking_unit.xlsx';
  search: any;
  prds:any;
  prdCat: any[];
  categories: any;
  cat_id: any;
  prd_id: string;
  pageLoadingImg: boolean;
  pgend: boolean;
  pgstart: boolean;
  slnum: number;
  prduntdtls: any;
  curpage: number;
  lastpage: number;
  prd_ean: string;
  barcode: string;
  err_msg: any;
  brcd_ean_err: any;
  brcd_err: any;
  total_prds: any;
  exportLoader: boolean;
  branch_display_name: any;
  branch_display_code: any;
  ExptotalProducts: any;
  Expprduntdtls: any;
  derived_unt_ean: any;
  derived_unt: any;
  unit_id: string;
  remark: any;
  Expremark:any;
  datePipe: any;
  // currencyPipe: any;
  // excelService: any;
  repDate: any;
  PrintpageLoadingImg: any;
  Printprduntdtls: any;
  Printcurpage: any;
  Printlastpage: any;
  Printtotal_prds: any;
  Printremark: any;
  from: any;
  vans: any;
  period_type:any;
  branch_address:any;

  filterTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];
  Expperiod_type: string;
  ExppageLoadingImg: boolean;
  Expvans: void;
  Expcurpage: number;
  Explastpage: number;
  Exptotal_prds: any;
  Expfrom: any;
  ExprepDate: any;
  van: any;
  prduntdtls1: any;
  preBaseTotal:any;
  preDerivedTotal :any;
  Expprduntdtls1: any;
  availablestock: any;
  cmpny: any;
  hideexcsmsng: any;
  cur_lang: string;
  ModelData1: any;

  constructor(private apiService: ApiService,private coreService: CoreService,private translate: TranslateService) { }

  ngOnInit() {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.cur_lang = sessionStorage.getItem("baseLang");
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    this.langChange();
    this.cmpny = this.coreService.getUserData('cmpny');
    const searchval = new FormData();
    searchval.append("period_type", '');
    this.pageLoadingImg = true;

    this.apiService.vanStockTrackingUnitWise(searchval, 1).subscribe((res: any) => {
      this.period_type = '';
      this.pageLoadingImg = false;
      this.vans = this.searchVans('');
      this.prduntdtls=res['data'];
      this.prduntdtls1=res['gttl'];
      this.preBaseTotal=res['preBaseTotal'];
      this.preDerivedTotal=res['preDerivedTotal'];
      console.log("hiii");
      console.log(this.prduntdtls1);
      this.curpage=Number(res['current_page']);
      this.lastpage=Number(res['last_page']);
      this.total_prds=res['total'];
      this.from=res['from'];
      this.repDate=res['date'];
      this.van=res['van'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;


      // console.log(this.curpage);


    });
    // this.cmpny='AQE';
    if(this.cmpny=='AQE'){
      this.hideexcsmsng=true;
    }
    console.log(this.cmpny);
    
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');

  }


  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all']).subscribe((res: string) => {    
     
      
      this.filterTypes = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
    });
  }

  stockUntDetail(formdata: { value: any; }, pageno: any,from='') {
    console.log(formdata.value);
    this.pageLoadingImg = true;
    formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
    formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    formdata.value.preDerivedTotal = this.preDerivedTotal;
    formdata.value.preBaseTotal = this.preBaseTotal;
    formdata.value.from = from;
  this.apiService.vanStockTrackingUnitWise(formdata.value, pageno).subscribe((res: any) => {
     this.pageLoadingImg = false;
     this.prduntdtls=res['data'];
     this.prduntdtls1=res['gttl'];
     this.preBaseTotal =res['preBaseTotal'];
     this.preDerivedTotal =res['preDerivedTotal'];
     console.log("hlo");
      console.log(this.prduntdtls1);
     this.curpage=Number(res['current_page']);
     this.lastpage=Number(res['last_page']);
     this.total_prds=res['total'];
     this.from=res['from'];
     this.repDate=res['date'];
     this.van=res['van'];

    //console.log(this.curpage);


       this.pgEnd(this.curpage, this.lastpage);
       this.pgStart(this.curpage);
       this.slnum = 0;

    // console.log(this.prduntdtls);

    });
  }

      // search category

      searchCat(search: string) {
        let searchval = new FormData();
        searchval.append("cat_name", search);
        this.apiService.getCatbyName(searchval).subscribe((res) => {
          this.categories = res['data'];
        });
      }



    // search product
    selectProd(name: string) {

      let searchval = new FormData();
      searchval.append("prod_name", name);
      searchval.append("flag[]", "1");
      if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
        searchval.append('alias_search', '1');
      }
      this.apiService.getProdByName(searchval).subscribe((res) => {
        this.prds = res['data'];
      });
    }

  listPrduct(cat_id){
    this.prd_id=null;
    if(this.cat_id){
      this.barcode='';
      this.prd_ean='';
    }
    this.apiService.getPrdByCatId(cat_id).subscribe((res) => {
      this.prds = res['data'];
    });
  }

  listUnit(prd_id){
    this.prd_id != null;
     if(this.prd_id){
      this.unit_id ="";
     }
    this.apiService.getUnitByPrdId(prd_id).subscribe((res) => {
      this.search = res['data'];
    });
  }

  filter_prate = [
    { id: 0, name: 'Last Purchase Rate' },
    { id: 1, name: 'Avg. Purchase rate' }
  ];

  pgEnd(curr, end) {
    if (curr == end) {
      this.pgend = true;
    } else {
      this.pgend = false;
    }

  }

  pgStart(curr) {
    if (curr == 1) {
      this.pgstart = true;
    } else {
      this.pgstart = false;
    }
  }
  searchVans(search: string) {
    const searchval = new FormData();
    searchval.append('van_name', search);
    this.apiService.searchVans(searchval).subscribe((res) => {
      this.vans = res['data'];
    });

  }

  exportexcel(): void
  {
     /* table id is passed over here */
     let element = document.getElementById('export-group');
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.fileName);
     this.exportLoader = false;

  }

  exportEXCL(Expformdata: { value: any; }){
    this.exportLoader = true;
    Expformdata.value.filter =1;
    Expformdata.value.export = 'export';
    if (Expformdata.value.period_type === 'c') {
      Expformdata.value.date1 = (Expformdata.value.date1) ? this.apiService.formatDate(Expformdata.value.date1) : Expformdata.value.date1;
      Expformdata.value.date2 = (Expformdata.value.date2) ? this.apiService.formatDate(Expformdata.value.date2) : Expformdata.value.date2;
    }


      this.apiService.vanStockTrackingUnitWise(Expformdata.value, 1).subscribe((res: any) => {
        this.exportLoader = false;

        this.Expperiod_type = '';
        this.ExppageLoadingImg = false;
        this.Expvans = this.searchVans('');
        this.Expprduntdtls=res['data'];
        this.Expprduntdtls1=res['gttl'];
        this.Expcurpage=Number(res['current_page']);
        this.Explastpage=Number(res['last_page']);
        this.Exptotal_prds=res['total'];
        this.Expfrom=res['from'];
        this.ExprepDate=res['date'];
        this.van=res['van'];
        this.pgEnd(this.Expcurpage, this.Explastpage);
        this.pgStart(this.Expcurpage);
        this.slnum = 0;



      setTimeout(() => {
        this.exportexcel();
    }, 3000);

    Expformdata.value.export = '';


    });
    }


    saveAndPrint(Expformdata: { value: any; }) {

      // debugger;
      Expformdata.value.export = 'export';


        this.apiService.stockUnitDetails(Expformdata.value, 1).subscribe((res: any) => {

          this.PrintpageLoadingImg = false;
          this.Printprduntdtls=res['data']['data'];
          this.Printcurpage=Number(res['data'].current_page);
          this.Printlastpage=Number(res['data'].last_page);
          this.Printtotal_prds=res['data'].total;
          this.Printremark=res['data'].remark;
          this.pgEnd(this.Printcurpage, this.Printlastpage);
          this.pgStart(this.Printcurpage);
          this.slnum = 0;

        setTimeout(() => {
          this.exportLoader = false;
          let el: HTMLElement = this.printBtn.nativeElement;
          el.click();
      }, 2000);
        Expformdata.value.export = '';


      });



    }


    check(){
      console.log("hiii");
      console.log(this.availablestock);
    }

}
