import { Component, OnInit } from '@angular/core';
import { CoreService } from 'src/app/service/core.service';

@Component({
  selector: 'app-journal-dash',
  templateUrl: './journal-dash.component.html',
  styleUrls: ['./journal-dash.component.css']
})
export class JournalDashComponent implements OnInit {
  up: any;
  cmpny:any;

  constructor(private coreService: CoreService) { }

  ngOnInit() {
    this.up = this.coreService.getUserPrivilage();
    this.cmpny = this.coreService.getUserData('cmpny');
  }

}
