import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CoreService } from 'src/app/service/core.service';

@Component({
  selector: 'app-invoice-with-header-footer-temp7',
  templateUrl: './invoice-with-header-footer-temp7.component.html',
  styleUrls: ['./invoice-with-header-footer-temp7.component.css']
})
export class InvoiceWithHeaderFooterTemp7Component implements OnInit {

  @Input() saleQtnData;
  @Input() print_style;
  @Input() qr_inv;
  @Input() savedTemplate;
  @Input() is_prew_description;
  @Input() payTypes;
  @Input() cmpny;
  @Input() previewLoader;
  @Input() logoImgUrl;
  @Input() tempImgUrl;
  @Input() footImgUrl;
  @Input() sales_print_name;
  @Input() hide_item_discount;
  @Input() hide_vat;
  @Input() sales_settings;
  @Input()water_mark;
  country_dec: any;

  // pc / dip = inch
  // inch * 25.4 = mm
  // in mm milli meter ( pixcel / dip * 25.4)
  // 130px means 130 / 96 * 25.4 =  34.3958
  // headerheightInmm = 34.4;

  // 73.5mm (278px) maximum height allowed 
  headerheightInmm = 34.4;

  // 130px means 130 / 96 * 25.4 =  31.75
  /// 73.5mm (278px) maximum height allowed 
  footerheightInmm = 31.75;

  pagelabelBottomInmm = 26.46; // 100px
  pagelabelLeftInmm = 92.60;
  dispalyPageNo = false;
  pageLabel = "Page [page_no] of [page_total_no]";
  constructor(private coreService: CoreService,private translate: TranslateService) { }

  ngOnInit() {

    this.country_dec = this.coreService.setDecimalLength();

  }

  ngOnChanges(changes) {
    if (changes['saleQtnData'] && this.saleQtnData) {

      this.headerheightInmm = this.savedTemplate.header_height;
      this.footerheightInmm = this.savedTemplate.footer_height;
      this.pagelabelBottomInmm = this.savedTemplate.botom_h;
      this.pagelabelLeftInmm = this.savedTemplate.left_h;
      if (this.savedTemplate.page_no_display) {
        this.dispalyPageNo = true;
        this.pageLabel = this.savedTemplate.page_format;
      }

    }
  }

  startPrint() {

    $('#printButton').click();
  }

}
