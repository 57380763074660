import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-currency-master',
  templateUrl: './currency-master.component.html',
  styleUrls: ['./currency-master.component.css']
})
export class CurrencyMasterComponent implements OnInit {
  @ViewChild('closeModal', { static: false }) closeModal: ElementRef;
  resultobj: any = {};
  info = [];

  currency = {
    cur_id: '',
    cur_name:'',
    cur_short_code:'',
    cur_symbol:'',
    cur_exchange_rate:1,
    monetary_unit:'',
    sub_unit:'',
  }

  isSubmitInProg = false;

  curpage = 1;
  lastpage = 1;
  perpage = 20;
  totalItem = 0;
  curForm = 'Create';
  pageLoadingImg = false;

  langText = {
    create: '',
    update: ''
  };
  up: any;
  
  constructor(private apiService: ApiService, private coreService: CoreService, private translate: TranslateService,private modalService: NgbModal) { }

  ngOnInit() {
    this.translate.get(['Common.create', 'Common.update']).subscribe((res: string) => {
      this.langText.create = res['Common.create'];
      this.langText.update = res['Common.update'];
      this.curForm = this.langText.create;
    
      this.up = this.coreService.getUserPrivilage();
    
    });
    this.getInfo(this.curpage);
  }

  getInfo(pageNo) {
    this.pageLoadingImg = true;
    this.apiService.getAllCurrency(pageNo, this.perpage).subscribe((res) => {
      this.info = res.data.data;
      this.pageLoadingImg = false;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
    });
  }


  createCurrency(){
    this.curForm = this.langText.create;
    this.clearForm();
  }

  clearForm() {
    this.currency = {
      cur_id: '',
      cur_name:'',
      cur_short_code:'',
      cur_symbol:'',
      cur_exchange_rate:1,
      monetary_unit:'',
      sub_unit:'',
    };
  }

  voidReceipt(id){
    // const confrm = confirm('Do you wish to continue?');
    if (confirm('Do you wish to continue?')) {
      this.apiService.voidReceipt({rec_no: id}).subscribe((res) => {
        // console.log(this.staff);
        this.isSubmitInProg = false;
        if (res.error != null) {
          this.resultobj = res.error;
        } else {
          this.getInfo(this.curpage);
          this.coreService.showMessage(res.message);
          $('.close').click();
        }

      });
    }
  }

  editCurrency(cur_id){
    this.curForm = this.langText.update;
    this.clearForm();
    this.resultobj = [];
    this.apiService.getCurrency({cur_id: cur_id}).subscribe((res) => {
      this.currency = {
        cur_id: res.cur_id,
        cur_name: res.cur_name,
        cur_short_code: res.cur_short_code,
        cur_symbol: res.cur_symbol,
        cur_exchange_rate: res.cur_exchange_rate,
        monetary_unit:res.cur_monetary_unit,
        sub_unit:res.cur_sub_unit,
      };
    });
  }

  validateAndSubmit() {


    if (confirm('Do you wish to continue?')) {
      this.isSubmitInProg = true;
      let callFunction = '';
      if (this.curForm === this.langText.create) {
        callFunction = 'createCurrency';
      }
      if (this.curForm === this.langText.update) {
        callFunction = 'updateCurrency';
      }
      this.apiService[callFunction](this.currency).subscribe((res) => {
        this.isSubmitInProg = false;
        if (res.error != null) {
          this.resultobj = res.error;
        } else {
          this.closeModal.nativeElement.click();
          this.getInfo(this.curpage);
          this.coreService.showMessage(res.message);
          this.clearForm();
          this.resultobj = {};
        }

      });
    }
  
}


}
