import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import * as XLSX from 'xlsx';
import { ExcelService } from '../../../../../service/excel.service';

@Component({
  selector: 'app-supplier-report',
  templateUrl: './supplier-report.component.html',
  styleUrls: ['./supplier-report.component.css']
})
export class SupplierReportComponent implements OnInit {
  cur_lang: string;
  supplierAll: string[];
  resultobj: any;
  showBal: boolean;
  accLoading = false;
  pq_supp_type: 0;
  sup_balance: 0;
  sup_bal_sign: '';
  sup_tin: any;
  supp_default_currency_id = 0;
  pageLoadingImg: boolean;
  selecTed: any;
  branch_name: any;
  sup_name: any='';
  country_dec: number=3;
  curpage: number;
  lastpage: number;
  pgstart: boolean;
  pgend: boolean;
  purch_supp_id: any;
  fileName = 'Supplier_report.xlsx';
  exportLoader: boolean;
  ExpselecTed: any;
  Expsup_name: any;
  Expbranch_name: any;
 
  sup_bal: any=0;
  expselecTed: any;
  expsup_name: any;
  expbranch_name: any;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService) { }

  ngOnInit() {
    
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    this.country_dec=this.coreService.setDecimalLength();
  }

  searchSupplier(search: string) {
    const searchval = new FormData();
    searchval.append('supp_name', search);
     if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      searchval.append("with-alias", "1");
    }
    this.apiService.searchSupplier(searchval).subscribe((res) => {
      this.supplierAll = res.data;
    });
  }
  selectSupplier(sup_id){
    if(sup_id){
      this.pageLoadingImg = true;
      const searchval = new FormData();
      searchval.append('sup_id', sup_id);
      this.apiService.supplierRep(searchval,1).subscribe((res) => {
        this.pageLoadingImg = false;
      
        this.selecTed = res['data']['data']
       
        this.sup_name=res.sup_name.supp_name;
        this.branch_name=res.branch_name.branch
        this.curpage = res['data']['current_page'];
        this.lastpage = res['data']['last_page'];
  
        this.pgEnd(this.curpage, this.lastpage);
        this.pgStart(this.curpage);
       
    
       
      });
      this.getSupBalance(sup_id)
    }else{
      this.selecTed = [];
      // this.sup_name=[]
      this.branch_name=[]
      this.sup_bal_sign=''
      this.sup_bal=0;
    }
  


  }
  getSupBalance(sup_id, $exchngeRate = null) {
   
    this.showBal = false;
    this.accLoading = false;
    this.pq_supp_type=0;
    if (sup_id) {
      this.accLoading = true;
      this.apiService.getSupBalance({ sup_id: sup_id }).subscribe((res) => {
        this.accLoading = false;
        this.sup_balance = res.data.bal_without_sign;
        this.sup_bal = res.data.bal_without_sign;
        this.sup_bal_sign = res.data.bal_sign;
        this.sup_tin = res.data.supp_tin;
        this.supp_default_currency_id = res.data.default_currency_id;
        // if (this.supp_default_currency_id &&  this.multipleCurrency) {
        //   this.selcurrency = this.currencies.find(x => x.cur_id == this.supp_default_currency_id);
        //   if ($exchngeRate == null) {
        //     this.exchange_rate = this.selcurrency.cur_exchange_rate;
        //   } else {
        //     this.exchange_rate = $exchngeRate;
        //   }
        // } else {
        //   this.selcurrency = null;
        //   this.exchange_rate = 1;
        // }
        this.showBal = true;
      });
      // let searchval = new FormData();
      // if(this.purchasenumber){
      //   searchval.append('purchaseno', this.purchasenumber);
      // }
      //  searchval.append('sup_id', sup_id);
      // this.apiService.getSupInvoice(searchval).subscribe((res) => {
      //   this.sup_invoice_no = res.data;
      // });

    }
  }
  pageNext(formdata: { value: any; }, pageno: any) {

      this.pageLoadingImg = true;
       formdata.value.sup_id = this.purch_supp_id;
      this.apiService.supplierRep(formdata.value, pageno).subscribe((res) => {
        this.pageLoadingImg = false;
      
        this.selecTed = res['data']['data']
       
        this.sup_name=res.sup_name.supp_name;
        this.branch_name=res.branch_name.branch
        this.curpage = res['data']['current_page'];
        this.lastpage = res['data']['last_page'];
  
        this.pgEnd(this.curpage, this.lastpage);
        this.pgStart(this.curpage);
  
    
       
      });
  }

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }

  
  exportEXCL(formdata: { value: any; }) {
    this.exportLoader = true;

    // debugger;
    formdata.value.sup_id=this.purch_supp_id;
    formdata.value.export = 'export';
    this.apiService.supplierRep(formdata.value, 1).subscribe((res) => {
      this.pageLoadingImg = false;
      this.ExpselecTed= res['data'];
      this.Expsup_name=res.sup_name.supp_name;
      this.Expbranch_name=res.branch_name.branch


      setTimeout(() => {
        this.exportexcel();
      }, 3000);



    });
  



  }

  generatePdf(formdata: { value: any; }) {

    this.exportLoader = true;

  // debugger;
  formdata.value.sup_id=this.purch_supp_id;
  formdata.value.export = 'export';
  this.apiService.supplierRep(formdata.value, 1).subscribe((res) => {
    this.pageLoadingImg = false;

    

    this.expselecTed= res['data'];
      this.expsup_name=res.sup_name.supp_name;
      this.expbranch_name=res.branch_name.branch

    const heads = [
      this.translate.instant('Common.prd_name') ,
      this.translate.instant('Table.barcode'),
      this.translate.instant('HOME.STOCK'),
      this.translate.instant('Common.Last_Purchase_Rate'),
      this.translate.instant('Common.cost_value'),
      this.translate.instant('Common.sales_rate_incl_tax'),
      this.translate.instant('Table.mrp_value') 
    ];

      const lblXPos = 10;
      const headerHeight = 15;
      const valueXPos = 87;
      const data = [];

      this.expselecTed.forEach((item)=>{
        const arr=[
          //  item.prd_name,
          (this.translate.currentLang == 'Arabic') ? item.prd_alias : item.prd_name,
           item.prd_ean,
           item.bs_stock_quantity.toFixed(this.country_dec),
           item.bs_prate.toFixed(this.country_dec),
           (Number(item.bs_stock_quantity.toFixed(this.country_dec)) * Number(item.bs_prate.toFixed(this.country_dec))).toFixed(this.country_dec),
           item.sales_rate_with_tax.toFixed(this.country_dec),
           (Number(item.bs_stock_quantity.toFixed(this.country_dec)) * Number(item.sales_rate_with_tax.toFixed(this.country_dec))).toFixed(this.country_dec)
        ]

        // if(this.translate.currentLang == 'English'){
        //   arr.splice(3,0, item.supp)
        // } else if(this.translate.currentLang == 'Arabic'){
        //   arr.splice(3,0, item.supp_alias)
        // }

       

        data.push(arr)
      })
  
      let doc = this.excelService.getPdfObj();

      // var addressLines = this.branch_address.split('\r\n');
      // var addressText = addressLines.join(', ');

        doc.setFont('Amiri');
        doc.setFontSize(20);
        doc.text( `${this.translate.instant('Purchase.supplier_report')}`, 71, headerHeight + 2);

      // doc.setFont('Amiri');
      // doc.setFontSize(9);
      // doc.text(addressText, 71, headerHeight + 2);

      // if(this.exp_repDate){
      //   doc.setFontSize(10);
      //   doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
      //   doc.setFontSize(10);
      //   doc.text(':  ' + this.exp_repDate.date1 + '   to   ' + this.exp_repDate.date2  ,  valueXPos, headerHeight + 10);
      // }

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('HOME.branch_name')}`, lblXPos, headerHeight + 15);
      doc.setFontSize(10);
      doc.text(':  ' + (this.expbranch_name),    valueXPos, headerHeight + 15);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Purchase.supplier')}`, lblXPos, headerHeight + 20);
      doc.setFontSize(10);
      doc.text(':  ' + (this.expsup_name),  valueXPos, headerHeight + 20);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Common.closing_bal')}`, lblXPos, headerHeight + 25);
      doc.setFontSize(10);
      doc.text(':  ' + (this.sup_bal).toFixed(this.country_dec)+ '' +(this.sup_bal_sign),  valueXPos, headerHeight + 25);

     
      
      doc = this.excelService.addTableToPdf(doc, headerHeight + 30, lblXPos, heads, data, null);
  
     
      doc.save(`Supplier Report.pdf`);

      this.exportLoader = false;

  });
   
  }



  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;
  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }


}
