import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import * as XLSX from 'xlsx'; 

@Component({
  selector: 'app-pro-detailed-rep',
  templateUrl: './pro-detailed-rep.component.html',
  styleUrls: ['./pro-detailed-rep.component.css']
})
export class ProDetailedRepComponent implements OnInit {
  [x: string]: any;
  prd_id: string;
  barcode: string;
  prd_ean: string;
  categories: any;
  search: any;
  subcatagories: any;
  cat_id: any;
  sub_cat_id: any;
  pgend: boolean;
  pgstart: boolean;
  prduntdtls: any;
  mfs: string[];
  pageLoadingImg:boolean;
  total_prds: any;
  branch_display_name: any;
  branch_display_code: any;
  curpage: number;
  lastpage: number;
  from: any;
  exportLoader: boolean;
  fileName= 'Product_detailed_report.xlsx';
  Expprduntdtls: any;
  
  ExptotalProducts:any;
  constructor( private apiService: ApiService,private coreService: CoreService) { }

  ngOnInit() {
    const searchval = new FormData();
    this.pageLoadingImg = true;
    this.apiService.productDetailsreport(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.prduntdtls=res['data']['data'];
      this.curpage=Number(res.data.current_page);
      this.lastpage=Number(res.data.last_page);
      this.total_prds=res['data']['total'];
      this.from=res['data']['from'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      // this.slnum = 0;


    });

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');
  }


  prdtDetailRep(formdata: { value: any; }, pageno: any){
    this.pageLoadingImg=true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.apiService.productDetailsreport(formdata.value,pageno).subscribe((res:any)=>{
      this.pageLoadingImg=false;
      this.prduntdtls=res['data']['data'];
      this.curpage=Number(res.data.current_page);
      this.lastpage=Number(res.data.last_page);
      this.total_prds=res['data']['total'];
      this.err_msg=res['error'];
      this.brcd_err=res['key1'];
    this.brcd_ean_err=res['key2'];
    this.brcdadd_ean_err=res['key3'];

      this.from=res['data']['from'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);


    })
  }

   // search product
   selectProd(name: string) {
    if(this.prd_id){
      this.barcode='';
      this.prd_ean='';
    }
    let searchval = new FormData();
    searchval.append("prod_name", name);
    searchval.append("flag[]", "1");
    this.apiService.getProdByName(searchval).subscribe((res) => {
      this.search = res['data'];
    });
  }


  // search category

   searchCat(search: string) {
    let searchval = new FormData();
    searchval.append("cat_name", search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.categories = res['data'];     
    });  
  }

  // search sub category

  searchSubcat(search: string)
{
    let searchval = new FormData();      
    searchval.append("subcat_name", search);    
     this.apiService.getSubcatByName(searchval).subscribe((res) => {
      this.subcatagories = res['data'];                
     });
     $('.prev-list').show(); 
}
  
  selectSubCats(catid: number) {
    this.apiService.getAllSubCatbyId(catid).subscribe((res) => {
      this.subcatagories = res['data'];
     

    });
  }

  listPrduct(cat_id){
    // this.prd_id=null;
    if(this.cat_id){
      this.barcode='';
      this.prd_ean='';
    }
    this.sub_cat_id=null;
    this.apiService.getPrdByCatId(cat_id).subscribe((res) => {
      this.search = res['data'];     

      this.selectSubCats(cat_id);
    });  
  }
  selectedprd(prdId :number){
    this.sub_cat_id=null;
    this.cat_id=null;
    this.apiService.getCatByPrdId(prdId).subscribe((res) => {
      this.categories = res['data'];     

      // console.log(this.categories);
      
    });  
  }
  searchManf(search: string) {
    let searchval = new FormData();
    searchval.append("manftr_comp_name", search);
    this.apiService.getManfbyName(searchval).subscribe((res) => {
      this.mfs = res['data'];
    });
  }
  pgEnd(curr, end) {
    if (curr == end) {
      this.pgend = true;
    } else {
      this.pgend = false;
    }
    console.log(this.pgend);
    

  }

  pgStart(curr) {
    if (curr == 1) {
      this.pgstart = true;
    } else {
      this.pgstart = false;
    }
  }

  
  // exportexcel(): void 
  // {
  //    /* table id is passed over here */   
  //    let element = document.getElementById('export-group'); 
  //    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

  //    /* generate workbook and add the worksheet */
  //    const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  //    /* save to file */
  //    XLSX.writeFile(wb, this.fileName);
  //    this.exportLoader = false;
    
  // }
  exportexcel(): void 
  {
     /* table id is passed over here */   
     let element = document.getElementById('export-group'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.fileName);
     this.exportLoader = false;
    
  }

  exportEXCL(Expformdata: { value: any; }){
    this.exportLoader = true;
     
      // debugger;
      Expformdata.value.export = 'export';
      
      this.apiService.productDetailsreport(Expformdata.value, 1).subscribe((res: any) => {
          this.Expprduntdtls = res['data']['data'];
          console.log(this.Expprduntdtls);
          
          this.ExptotalProducts =res.data.total;
          // this.derived_unt=res['data'];
          // this.derived_unt_ean=res['data'];
          
      
      setTimeout(() => {
        this.exportexcel(); 
    }, 3000);
    
    Expformdata.value.export = '';
   

    });



    }



}
