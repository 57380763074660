import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-stock-rate-by-excel',
  templateUrl: './stock-rate-by-excel.component.html',
  styleUrls: ['./stock-rate-by-excel.component.css']
})
export class StockRateByExcelComponent implements OnInit {
  fileName = 'update_rate_sample.xlsx';
  branch_id: any;
  branch_all: any;
  loadingExcel: boolean;
  log_file: any;
  tempFilePath: any;
  fileError: string;
  exportLoader: boolean;
  new_error: any[];
  validateError: boolean;
  userType: any;

  constructor(private apiService: ApiService, private coreService: CoreService) { }

  ngOnInit() {
    this.getAllBranch();
    this.branch_id=this.coreService.getUserData('branch_id');
    this.userType = this.coreService.getUserData('user_type');
  }

  uploadRateExcelFile(form){
    
    if (confirm("Do you want to update the rate?")&&!this.validateError) {
      let searchval = new FormData(form);
      searchval.append("branch", this.branch_id);
      this.loadingExcel=true;
      this.apiService.rateUpdateWithExcel(searchval).subscribe((res) => {
        if (res.error) {
          this.new_error = res.error;
        } else {
          this.new_error = [];
          this.coreService.showMessage(res.message);
         
          this.loadingExcel=false;
        
  
        }
      });
    }
 
  }

  file(file) {
    // console.log($('#logo_img').val());

    //this.template.header_img = file;
    this.log_file = file.target.files[0];
    var render = new FileReader();
    const max_size = 2048000;
    var nn = render.readAsDataURL(file.target.files[0]);
    render.onload = (event: any) => {
      this.tempFilePath = event.target.result;
    }
    this.fileError=null;
    this.validateError=false;
    
    console.log(file.target.files[0].size);
    if (file.target.files[0].size > max_size) {
      
      this.validateError=true;
      this.fileError=
        'Maximum size allowed is ' + max_size / 1000 / 1024 + 'MB';
        console.log(this.fileError);
      return false;
    }

  }
  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res:any) => {
      this.branch_all = res.data;

    });
  }


  exportexcel(): void {
    this.exportLoader = true;

    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }
}
