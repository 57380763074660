// stock-routing.module.ts

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { StockDashComponent } from './stock-dash/stock-dash.component';
import { EditstockComponent } from './stock-dash/editstock/editstock.component';
import { OpeningStockComponent } from './stock-dash/opening-stock/opening-stock.component';
import { OpeningStockRemoveComponent } from './stock-dash/opening-stock-remove/opening-stock-remove.component';
import { StockRatesComponent } from './stock-dash/stock-rates/stock-rates.component';
import { BranchStockRatesComponent } from './stock-dash/branch-stock-rates/branch-stock-rates.component';
import { MissingStockComponent } from './stock-dash/missing-stock/missing-stock.component';
import { ExcessStockComponent } from './stock-dash/excess-stock/excess-stock.component';
import { StockVerifyComponent } from './stock-dash/stock-verify/stock-verify.component';
import { ImportOpeningStockComponent } from './stock-dash/import-opening-stock/import-opening-stock.component';
import { CopyStockDifferentBranchComponent } from './stock-dash/copy-stock-different-branch/copy-stock-different-branch.component';
import { PriceGroupComponent } from './stock-dash/price-group/price-group.component';
import { PriceGroupRatesComponent } from './stock-dash/price-group-rates/price-group-rates.component';
import { WarrantyStockComponent } from './stock-dash/warranty-stock/warranty-stock.component';
import { PriceListComponent } from './stock-dash/price-list/price-list.component';
import { UpdateBranchComponent } from './update-branch/update-branch.component';
import { StockRateByExcelComponent } from './stock-dash/stock-rate-by-excel/stock-rate-by-excel.component';

const routes: Routes = [
    {path: 'stock', component: StockDashComponent}, 
    {path: 'editstock', component: EditstockComponent},
    {path: 'openingstock', component: OpeningStockComponent},
    {path: 'openingstock/remove', component: OpeningStockRemoveComponent},
    {path: 'editrates', component: StockRatesComponent},        
    {path: 'multi-branch-rates', component: BranchStockRatesComponent},
    {path: 'stock/missing', component: MissingStockComponent}   ,     
    {path: 'stock/excess', component: ExcessStockComponent}   , 
    {path: 'stock/verify', component: StockVerifyComponent}  ,
    {path: 'import-openingstock', component: ImportOpeningStockComponent},
    {path: 'copy-stock-different-branch', component: CopyStockDifferentBranchComponent},
    {path: 'price-group', component: PriceGroupComponent},
    {path: 'price-group-rates/:id', component: PriceGroupRatesComponent},
    {path: 'stock/warranty', component: WarrantyStockComponent},
    {path:'price-list',component:PriceListComponent},
    {path:'stock/update-rate',component:UpdateBranchComponent},
    {path:'stock/update-rate-with-excel',component:StockRateByExcelComponent}

       
    
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class StockRoutingModule { }