import { Component, OnInit } from '@angular/core';

import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { FormArray } from '@angular/forms';
import * as XLSX from 'xlsx'; 
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';


@Component({
  selector: 'app-input-expense-vat-summary',
  templateUrl: './input-expense-vat-summary.component.html',
  styleUrls: ['./input-expense-vat-summary.component.css']
})

export class InputExpenseVatSummaryComponent implements OnInit {
  fileName= 'input_expense_vat_summary.xlsx';

  pgend: boolean;
  pgstart: boolean;
  pageLoadingImg: boolean;
  values: any;
  curpage: any;
  lastpage: any;
  stndData: any;
  standcurpage: any;
  standlastpage: any;
  standpgend: boolean;
  standpgstart: boolean;
  returnstndData: any;
  returnstandpgend: boolean;
  returnstandpgstart: boolean;
  returnstandcurpage: any;
  returnstandlastpage: any;
  strdfrom: any;
  retstrdfrom: any;
  retstrdnxtpg: any;
  retstrdprevpg: any;
  strdprevpg: any;
  strdnxtpg: any;
  purchase_types = [
    { id: 1, name: 'Standard Rated Domestic' },
    { id: 2, name: 'Zero Rated Domestic' },
    { id: 3, name: 'Exempt Purchase' },
    { id: 4, name: 'Imports Vat Paid to Customs' },
    { id: 5, name: 'Imports Vat - reverse change mechanism' }
  ];
  purch_type: any;
  zeropgstart: boolean;
  zeropgend: boolean;
  zeroData: any;
  zerocurpage: any;
  zerolastpage: any;
  returnzerocurpage: any;
  returnzerolastpage: any;
  returnzeroData: any;
  retzerofrom: any;
  retzeronxtpg: any;
  retzeroprevpg: any;
  zeroprevpg: any;
  zeronxtpg: any;
  exemptData: any;
  exemptcurpage: any;
  exemptlastpage: any;
  exemptnxtpg: any;
  exemptprevpg: any;
  returnexemptData: any;
  returnexemptcurpage: any;
  returnexemptlastpage: any;
  retexemptfrom: any;
  exemptfrom: any;
  retexemptnxtpg: any;
  retexemptprevpg: any;
  exemptpgend: boolean;
  exemptpgstart: boolean;
  returnexemptpgend: boolean;
  returnexemptpgstart: boolean;
  imprtCustData: any;
  imprtCustcurpage: any;
  imprtCustlastpage: any;
  imprtCustfrom: any;
  imprtCustnxtpg: any;
  imprtCustprevpg: any;
  returnimprtCustData: any;
  returnimprtCustcurpage: any;
  returnimprtCustlastpage: any;
  retimprtCustfrom: any;
  retimprtCustprevpg: any;
  retimprtCustnxtpg: any;
  imprtCustpgend: boolean;
  imprtCustpgstart: boolean;
  returnimprtCustpgend: boolean;
  returnimprtCustpgstart: boolean;
  zerofrom: any;
  tax_cat: any;
  repDate: any;
  userType: any;
  branches: any;
  up: any;
  exportLoader: boolean;
  branch_all: any;
  filter_branch_id: any;
  usr_username: any;
  filter_cmptype = [
    { id: '1', name: 'Establishment' },
    { id: '2', name: 'Company' },

  ];
  cmp_type: string;
  Alldet: any;
  country_dec: string;

  constructor(private apiService: ApiService, private coreService: CoreService,  private translate: TranslateService) { }


  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.country_dec=this.coreService.setDecimalLength();
    this.cmp_type = '1';

    this.up = this.coreService.getUserPrivilage();
    this.readProdSumm(1);
    this.getAllTaxCategory();
    this.userType = this.coreService.getUserData('user_type');
    this.usr_username =  this.coreService.getUserData('usr_username');
    this.exportLoader = false;
    this.changeCmpType(1);

  }
  langChange(){
    this.translate.get(['Common.standard_rated_domestic', 'Common.zero_rated_domestic','Common.exempt_purchase', 'Common.imports_vat_paid_custms','Common.imports_vat_rvs_chage_mechnsm','Common.January','Common.February','Common.March','Common.April','Common.May','Common.June','Common.July','Common.August','Common.September','Common.October','Common.November','Common.December']).subscribe((res: string) => {    
     
      this.filterDate = [
        
        { id: 1, name: res['Common.January']},
    { id: 2, name: res['Common.February'] },
    { id: 3, name: res['Common.March' ]},
    { id: 4, name: res['Common.April'] },
    { id: 5, name: res['Common.May'] },
    { id: 6, name: res['Common.June'] },
    { id: 7, name: res['Common.July'] },
    { id: 8, name: res['Common.August'] },
    { id: 9, name: res['Common.September'] },
    { id: 10, name: res['Common.October'] },
    { id: 11, name: res['Common.November' ]},
    { id: 12, name: res['Common.December' ]},         
          ];
     
    });

  }
  readProdSumm(pageno) {
    this.pageLoadingImg = true;

    let searchval = new FormData();
    searchval.append("period_type", '2');

    this.apiService.inputExpenseVatReport(searchval, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.Alldet = res['data'].Summary;


      this.stndData = res.data.data;
      this.repDate = res.data.Date;

      // console.log(this.stndData);

    });


  }

  standpgEnd(curr, end) {
    if (curr == end)
      this.standpgend = true;
    else
      this.standpgend = false;
  }

  standpgStart(curr) {
    if (curr == 1)
      this.standpgstart = true;
    else
      this.standpgstart = false;
  }




  pageNext(formdata: { value: any; }, pageno: any, pagination_for: any) {

    this.stndData = [];

    // formdata.value = pagination_for;
    this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.apiService.inputExpenseVatReport(formdata.value, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.Alldet = res['data'].Summary;

      this.stndData = res.data.data;
      this.repDate = res.data.Date;


    });
  }

  filterDate = [

    { id: 1, name: 'January' },
    { id: 2, name: 'February' },
    { id: 3, name: 'March' },
    { id: 4, name: 'April' },
    { id: 5, name: 'May' },
    { id: 6, name: 'June' },
    { id: 7, name: 'July' },
    { id: 8, name: 'August' },
    { id: 9, name: 'September' },
    { id: 10, name: 'October' },
    { id: 11, name: 'November' },
    { id: 12, name: 'December' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];
  // filter  types
  filterTypes = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' }
  ];




  //form search
  searchUser(search: string) {
    let searchval = new FormData();
    searchval.append("usr_name", search);
    this.apiService.getUserByName(searchval).subscribe((res) => {
      this.values = res['data'];
    });
  }

  getAllTaxCategory() {
    this.apiService.getAllTaxCategory().subscribe((res) => {
      this.tax_cat = res['data'];
    });
  }


  getBranchByName(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branches = res['data'];
    });
  }
  
  changeCmpType(cmptype) {
    let searchval = new FormData();
    searchval.append("cmp_type", cmptype);
    this.filter_branch_id = [];
    this.apiService.getBranchbyType(searchval).subscribe((res) => {
      this.branch_all = res['data'];
      this.filter_branch_id = res['selected'];
      
    });
  }


  exportexcel(): void {
    this.exportLoader = true;

    /* table id is passed over here */
    //let element = document.getElementById('print-vat-inv');
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    //const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }

  



}
