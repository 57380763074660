import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from '../../../../../service/api.service';
import { CoreService } from '../../../../../service/core.service';
import { TranslateService } from '@ngx-translate/core';
//irfan
import { Group } from '../../../../../model/group.model';
import { Ledger } from '../../../../../model/ledger.model';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-expense-tax-journal',
  templateUrl: './expense-tax-journal.component.html',
  styleUrls: ['./expense-tax-journal.component.css']
})
export class ExpenseTaxJournalComponent implements OnInit {

  @ViewChild('closeModal', { static: false }) closeModal: ElementRef;
  resultobj: any = {};
  info = [];
  pay = {
    pay_no: '',
    pay_date: new Date(),
    pay_acc: { ledger_id : 0 },
    note: '',
    ttlAmount: 0,
    grandTtlAmount: 0,
    ttlVatAmount: 0,
    pay_balance: 0,
    pay_bal_sign: '',
    sup_name:'',
    sup_tax_no:'',
    sup_inv_no:'',
    qty:'',
    van_id:0,
    desc:'',
    ttl_round_off:0,
    godown_id: 0,
    ledgers: [{
      sub_id: '',
      ledger: { ledger_id : 0 },
      amount: <any> '',
      narration: '',
      vatIncExc: 0,
      vat_per: 15,
      vat_amount: 0,
      actlAmount: 0,
      round_off: 0,
      fnlAmount: 0,
      pay_balance: 0,
      pay_bal_sign: '',
      accLoading: false
    }]
  };
  // pay.pay_acc: any = {};
  account = [];
  nonAccount = [];
  isSubmitInProg = false;

  curpage = 1;
  lastpage = 1;
  perpage = 20;
  totalItem = 0;
  curForm = 'Create';
  pageLoadingImg = false;

  langText = {
    create: '',
    update: ''
  };
  nextId = '';
  accLoading = false;
  up: any;
  payPriviewLoader:any;
  printPreview:any;
  cmpny = '';
  userType = '';
//irfan
pageLoadingImg1 = false;
isSubmitInProg1 = false;
  customer = {
    is_supplier:false,
    id: '',
    ledger_id: '',
    name: '',
    alias: '',
    code: '',
    email : '',
    mobile: '',
    vat_no: '',
    op_bal: 0,
    op_bal_type: true,
    brch_id:0,
    usr_type:0,
    add: {
      addr: '',
      zip: '',
      city: '',
      country: '',
      state: '',
      state_code: '',
      fax: ''
    },
    del: {
      addr: '',
      zip: '',
      city: '',
      country: '',
      state: '',
      state_code: '',
      fax: ''
    },
    due_day: '',
    van_line: 0,
    price_group: 1,
    note: ''
  };

  savedTemplate = {
    title_text: '',
    color: '#000',
    email_color: '#000',
    font_size: '12',
    orientation: "P",
    paper: 'A4-P',
    m_left: '',
    m_top: '',
    m_right: '',
    m_bottom: '',
    logo: false,
    header_type: 1,
    header_img: [],
    header_all_page: false,
    header_due: false,
    sl_no: false,
    item_desc: false,
    tax_detail: false,
    tax_type: 0,
    name_type: 'N',
    footer_text: ''
  }
  ledger_name: any;
  ledger_accgrp_id: any;
  ledger_branch_id: any;
  ledger_alias: any;
  ledger_code: any;
  ledger_email: any;
  ledger_mobile: any;
  ledger_due: any;
  ledger_tin: any;
  ledger_address: any;
  ledger_notes: any;
  ledgersel: any;
  selData: any = { ledger_id: null, error: null, ledger_name: null, message: null, };
  group_all: string[];
  ledger_all: string[];
  branch_all: string[];
  leadger: any;
  private modalRef: NgbModalRef;
  closeResult: string;  
  customer_type = false;
  printError: any;
  searchleadger: any;
  supplier = false;
  taxCats = [];
  supp_all = [];
  godowns: any;
  priceGroupList = [];
  ledger_pricegrp_id = 1;
  country_dec: string;
  branch_name: any;
  tempImgUrl: any;
  logoImgUrl: string;
  vans: any;
  allvans: any;
  usr_block_pre_date_select: any;
  minDate: Date;

  constructor(private apiService: ApiService, private coreService: CoreService, private translate: TranslateService,private modalService: NgbModal) { }

  ngOnInit() {
    this.branch_name = this.coreService.getUserData('branch_name');
    this.translate.get(['Common.create', 'Common.update']).subscribe((res: string) => {
      this.langText.create = res['Common.create'];
      this.langText.update = res['Common.update'];
      this.curForm = this.langText.create;
    });
    this.getNextExJournaltId();
    this.getAllAccount();
    // this.getAllNonAccountLedgers();
    this.getAllNoncashLedgers();
    
    this.getInfo(this.curpage);
    this.getAllTaxCategories();
    this.up = this.coreService.getUserPrivilage();
    this.cmpny = this.coreService.getUserData('cmpny');
    this.userType = this.coreService.getUserData('user_type');
    //irfan
    this.getAllGroup();
    this.listGodowns();
    this.apiService.getAllPriceGroup().subscribe((res) => {
      this.priceGroupList = res.data;
    });

    this.country_dec=this.coreService.setDecimalLength();
    this.getTemplete();
    this.searchAllVans('');
    this.usr_block_pre_date_select = this.coreService.getUserData("usr_block_pre_date_select");
    if(this.usr_block_pre_date_select){
      this.minDate= new Date();
    }else{
      this.minDate= new Date(this.coreService.getUserData("branch_open_date"));
    }


  }

  listGodowns() {
    let searchval = new FormData();
    this.apiService.getGodownList(searchval).subscribe((res) => {
      this.godowns = res['data'];

    });
  }
  
  getNextExJournaltId() {
    this.apiService.getNextExJournaltId().subscribe((res) => {
      this.nextId = res.data.ref_no;
    });
  }
  
  getAllTaxCategories() {
    this.apiService.getAllTaxCategories().subscribe((res) => {
      this.taxCats = res.data;
      var idx = this.taxCats.findIndex(p => p.taxcat_tax_per==0);
      this.taxCats.splice(idx,1);  
    });
  }
  getAccLedgerBalance() {
    this.accLoading = true;
    this.apiService.getLedgerBalance({ ledger_id: this.pay.pay_acc.ledger_id }).subscribe((res) => {
      this.accLoading = false;
      this.pay.pay_balance = res.data.bal_without_sign;
      this.pay.pay_bal_sign = res.data.bal_sign;
    });
  }

  getLedgerBalance(i) {
    this.pay.ledgers[i].accLoading = true;
    this.apiService.getLedgerBalance({ ledger_id: this.pay.ledgers[i].ledger.ledger_id }).subscribe((res) => {
      this.pay.ledgers[i].accLoading = false;
      this.pay.ledgers[i].pay_balance = res.data.bal_without_sign;
      this.pay.ledgers[i].pay_bal_sign = res.data.bal_sign;
    });
  }
  
  getTemplete() {

    this.apiService.getSettings().subscribe((res) => {

      this.savedTemplate = {
        title_text: res.data.prnt_title_text,
        color: '#000',
        email_color: '#000',
        font_size: '12',
        orientation: "P",
        paper: 'A4-P',
        m_left: '',
        m_top: '',
        m_right: '',
        m_bottom: '',
        logo: res.data.prnt_header_logo,
        header_type: res.data.prnt_header_type,
        header_img: [],
        header_all_page: false,
        header_due: false,
        sl_no: false,
        item_desc: false,
        tax_detail: false,
        tax_type: 0,
        name_type: 'N',
        footer_text: res.data.prnt_display_footer_text
      }
      
      if (res.data.prnt_header_logo) {
        this.logoImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_logo_img_path;
      } else {
        this.logoImgUrl = '';
      }

      if (res.data.prnt_header_type == 2) {
        this.tempImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_img_path;
      } else {
        this.tempImgUrl = '';
      }

    });
  }
  getInfo(pageNo) {
    this.pageLoadingImg = true;
    this.apiService.getAllExpenceTaxJournal('',pageNo, this.perpage).subscribe((res) => {
      this.info = res.data.data;
      this.pageLoadingImg = false;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
    });
  }

  searchInp(e) {
    this.pageLoadingImg = true;

    const searchval = new FormData();
    searchval.append('search', e);
  //  searchval.append('is_exp_tax_journal', '1');
    this.apiService.getAllExpenceTaxJournal(searchval,1,this.perpage).subscribe((res) => {
      this.info = res.data.data;

      console.log("dddddddddd");
      console.log(this.info);
      this.pageLoadingImg = false;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
     });
  }

  getAllAccount() {
    this.apiService.getAllAccount().subscribe((res) => {
      this.account = res.data;
    });
  }
  getAllNonAccountLedgers() {
    this.apiService.getAllNonAccountLedgers().subscribe((res) => {
      this.nonAccount = res.data;
    });
  }
  // addMore() {
  //   this.pay.ledgers.push({
  //     sub_id: '',
  //     ledger: { ledger_id : 0 },
  //     amount: 0.00,
  //     narration: '',
  //     vatIncExc: 0,
  //     vat_per: 0,
  //     vat_amount: 0,
  //     actlAmount: 0,
  //     round_off,
  //     fnlAmount: 0,
  //     pay_balance: 0,
  //     pay_bal_sign: '',
  //     accLoading: false
  //   });
  // }
  remove(index) {
    this.pay.ledgers.splice(index, 1);
    this.updateTotoal();
  }
  createPay(){
    this.curForm = this.langText.create;
    this.nextId = '';
    this.getNextExJournaltId();
    this.clearForm();
  }
  clearForm() {
    this.pay = {
      pay_no: '',
      pay_date: new Date(),
      pay_acc: { ledger_id : 0 },
      note: '',
      ttlAmount: 0,
      grandTtlAmount: 0,
      ttlVatAmount: 0,
      pay_balance: 0,
      pay_bal_sign: '',
      sup_name:'',
      sup_tax_no:'',
      sup_inv_no:'',
      qty:'',
      van_id:0,
      desc:'',
      ttl_round_off:0,
      godown_id: 0,
      ledgers: [{
        sub_id: '',
        ledger: { ledger_id : 0 },
        amount: '',
        narration: '',
        vatIncExc: 0,
        vat_per: 15,
        vat_amount: 0,
        actlAmount: 0,
        round_off:0,
        fnlAmount: 0,
        pay_balance: 0,
        pay_bal_sign: '',
        accLoading: false
      }]
    };
  }

  updateRoundSign(index) {
    this.pay.ledgers[index].round_off = -1 * Number(this.pay.ledgers[index].round_off);
    this.vatCalculate(index);

  }
  vatCalculate(index) {
    this.pay.ledgers[index].amount = this.pay.ledgers[index].amount || 0;
    if (this.pay.ledgers[index].vatIncExc == 0) {
      // this.pay.ledgers[index].fnlAmount = this.pay.ledgers[index].amount;
      this.pay.ledgers[index].vat_amount = (this.pay.ledgers[index].amount / 100 ) * this.pay.ledgers[index].vat_per;
      this.pay.ledgers[index].fnlAmount = Number(this.pay.ledgers[index].amount) + Number(this.pay.ledgers[index].vat_amount) + Number(this.pay.ledgers[index].round_off);
      this.pay.ledgers[index].actlAmount = this.pay.ledgers[index].amount;
    } else {
      this.pay.ledgers[index].actlAmount = this.pay.ledgers[index].amount / ( 1 + (this.pay.ledgers[index].vat_per / 100));
      this.pay.ledgers[index].vat_amount = (this.pay.ledgers[index].actlAmount  / 100 ) * this.pay.ledgers[index].vat_per;
      this.pay.ledgers[index].fnlAmount = Number(this.pay.ledgers[index].amount) + Number(this.pay.ledgers[index].round_off);
    }
    this.updateTotoal();
    // alert(this.pay.ledgers[index].amount);

    // alert(this.pay.ledgers[index].vatIncExc);
    // alert(this.pay.ledgers[index].vat_per);
  }

  // checkTabClicked(keycode) {
  //   console.log(keycode);
  //   if (keycode.keyCode === 9) {
  //     this.addMore();
  //   }
  // }

  updateTotoal(){
    this.pay.ttlAmount = 0;
    this.pay.ttlVatAmount = 0;
    this.pay.grandTtlAmount = 0;
    this.pay.ttl_round_off = 0;
    this.pay.ledgers.forEach(element => {
      this.pay.grandTtlAmount += Number(element.fnlAmount);
      this.pay.ttlVatAmount += Number(element.vat_amount);
      this.pay.ttlAmount += Number(element.actlAmount);
      this.pay.ttl_round_off += Number(element.round_off);
    });
  }

  voidJournal(id,index){
    // const confrm = confirm('Do you wish to continue?');
    if (confirm('Are you sure to void this record ?')) {
      this.info[index].void_in_progress = true;
      this.apiService.voidJournal({jn_no: id}).subscribe((res) => {
        // console.log(this.staff);
        this.isSubmitInProg = false;
        if (res.error != null) {
          this.resultobj = res.error;
        } else {
          this.getInfo(this.curpage);
          $('.close').click();

          this.coreService.showMessage(res.message);
        }

      });
    }
  }

  editVoucher(jn_no){
    this.nextId = '';
    this.curForm = this.langText.update;
    this.clearForm();
    this.resultobj = [];
    this.apiService.getExJournal({rec_no: jn_no}).subscribe((res) => {

      console.log("dsssssssss");
      console.log(res);
        this.nextId = res.jn_no;
        const result = this.nonAccount.filter(acc => acc.ledger_id == res.jn_acc_ledger_id);
        this.pay.pay_no = res.jn_no;
        this.pay.pay_date = new Date(res.jn_datetime);
        this.pay.pay_acc =result[0];
        this.getAccLedgerBalance();
        this.pay.note = res.jn_note;
        this.pay.sup_name = res.etp_supplier_name;
        this.pay.sup_tax_no = res.etp_supplier_tax_no;
        this.pay.sup_inv_no = res.etp_supplier_inv_no;
        this.pay.qty = res.etp_qty;
        this.pay.desc = res.etp_desc;
        this.pay.godown_id = res.godown_id;
        this.pay.ledgers = [];
        res.particulars.forEach((element,i) => {
          const result = this.nonAccount.filter(nonacc => nonacc.ledger_id == element.ledger_id);
          var tmpRow = {
            sub_id: element.jnsub_id,
            ledger: result[0],
            amount: (element.jnsub_vat_inc == 0) ? element.jnsub_ttl_amount :(element.jnsub_ttl_amount+element.jnsub_vat_amt),
            narration: element.jnsub_narration,
            vatIncExc: element.jnsub_vat_inc,
            vat_per: element.jnsub_vat_per,
            vat_amount: element.jnsub_vat_amt,
            actlAmount: element.jnsub_amount - element.jnsub_vat_amt,
            // be care fully check if tax include exclud e option integrating
            round_off: element.jnsub_roundoff,
            fnlAmount: element.jnsub_amount,
            pay_balance: 0,
            pay_bal_sign: '',
            accLoading: true
          };
          this.pay.van_id = element.van_id;
          this.pay.ledgers.push(tmpRow);
          this.getLedgerBalance(i);
        });
        this.vatCalculate(0);
        this.updateTotoal();
    });
  }

  validateAndSubmit() {
    this.resultobj = [];

    if (this.pay.pay_date == undefined) {
      this.resultobj.pay_date = "t";
      this.resultobj.push({ 'pay_date': 'Required' });
    }
    else {
      if (this.pay.pay_date > new Date()) {
        this.resultobj.pay_date = "t";
        this.resultobj.push({ 'pay_date': 'Required' });
      } 
    }
    if (this.resultobj.length <= 0) {

    if (confirm('Do you wish to continue?')) {
      this.isSubmitInProg = true;
      let callFunction = '';
      if (this.curForm === this.langText.create) {
        callFunction = 'createExpanseTaxJournal';
      }
      if (this.curForm === this.langText.update) {
        callFunction = 'updateExpanseTaxJournal';
      }
      this.apiService[callFunction](this.pay).subscribe((res) => {
        // console.log(this.staff);
        this.isSubmitInProg = false;
        if (res.error != null) {
          this.resultobj = res.error;
        } else {
          this.closeModal.nativeElement.click();
          this.getInfo(this.curpage);
          this.coreService.showMessage(res.message);
          this.clearForm();
          this.getNextExJournaltId();
          this.resultobj = {};
        }

      });
    }
  }
  }

  viewJournal(jn_no){
    this.payPriviewLoader = true;
    this.apiService.getTaxJournal({jn_no: jn_no}).subscribe((res) => {
      this.payPriviewLoader = false;
      this.printPreview = res.data;
      console.log(this.printPreview);
    });
  }

  //irfan
  removeName() {
    this.ledger_all = [];
    $('.prev-list').hide();
  }

  getAllGroup() {
    this.apiService.readAllGrp().subscribe((res: Group[]) => {
      // console.log(res['data']);
      const LedgGroup = res['data'].filter((group) => group.accgrp_id !== 21);
      this.group_all = LedgGroup;
    });
  }
  openModal(content: any, size) {

    this.modalRef = this.modalService.open(content, { size: size, backdrop: 'static' });
    this.modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }

  searchVans(search: string) {
    const searchval = new FormData();
    searchval.append('van_name', search);
    this.apiService.searchVans(searchval).subscribe((res) => {
      
      this.vans = res['data'];
    });
  
  }
  
  searchAllVans(search: string) {
    const searchval = new FormData();
    searchval.append('van_name', search);
    this.apiService.searchVans(searchval).subscribe((res) => {
      
      this.allvans = res['data'];
      this.vans = res['data'];
    });
  
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  validateAndSubmit1(){
    this.isSubmitInProg1 = true;
    let callFunction = 'createCustomer';
    
    if(this.userType =='ADMIN'){
      this.customer.usr_type = 1;
    }else{
      this.customer.usr_type = 0;
    
    }
    this.customer.is_supplier = this.supplier;
    this.apiService[callFunction](this.customer).subscribe((res) => {
      // console.log(this.staff);
      this.isSubmitInProg1 = false;
      if (res.error != null) {
        this.resultobj = res.error;
      } else {
        
        this.coreService.showMessage(res.message);
        // this.clearForm();
        this.resultobj = {};
        this.modalRef.close();
        this.clearCustForm();
        this.supplier = false;
        this.customer_type = false;
        this.getAllNonAccountLedgers();
        this.getAllNoncashLedgers();
      }

    });
  }
  createSupplier(formgroup: { value: any; }) {
    this.validatesupplier(formgroup.value);
    if (this.printError.length <= 0) {
      this.pageLoadingImg1 = true;

    this.apiService.createSupplier(formgroup.value).subscribe((res) => {
      this.pageLoadingImg1=false;

      if (res.error != null) {
        this.resultobj = res.error;
        this.resultobj = this.coreService.getValidation(this.resultobj);
      } else {
        this.coreService.createfunct(formgroup, this.resultobj, res.message);
        this.resultobj = {};
        this.modalRef.close();
        this.supplier = false;
        this.getAllNonAccountLedgers();
        this.getAllNoncashLedgers();
      }

    });
  }
  }
  validatesupplier(list) {
   
    this.printError = [];
    if (!list.supp_name) {
      this.printError.push({ 'supp_name': 'Required' });
      $('.supp_name').css("border", "1px solid red");
      $(".supp_name_err").text("Supplier name required");
    } else {
      $('.supp_name').css("border", "1px solid #dedede");
      $(".supp_name_err").text("");
    }
}
createLedger(formgroup: { value: any; }) {
  this.pageLoadingImg1 = true;
  this.apiService.createLedgerPopup(formgroup.value).subscribe((res: Ledger) => {
    this.pageLoadingImg1 = false;

    if (res.error != null) {
      this.resultobj = res.error;
      this.resultobj = this.coreService.getValidation(this.resultobj);
    }
    else {
      this.coreService.createfunct(formgroup, this.resultobj, res.message);
      this.resultobj = {};
      this.modalRef.close();
      this.getAllNonAccountLedgers();
      this.getAllNoncashLedgers();
    }

  });
}
searchLedger(search: string) {
  let searchval = new FormData();
  searchval.append("search", search);
  this.apiService.searchLedgerList(searchval, 1).subscribe((res) => {
    this.searchleadger = res['data']['data'];
    // console.log(this.searchleadger);
  });
  $('.prev-list').show();
}
clearCustForm() {
  this.customer = {
    is_supplier:false,
    id: '',
    ledger_id: '',
    name: '',
    alias: '',
    code: '',
    email : '',
    mobile: '',
    vat_no: '',
    op_bal: 0,
    op_bal_type: true,
    brch_id:0,
    usr_type:0,
    add: {
      addr: '',
      zip: '',
      city: '',
      country: '',
      state: '',
      state_code: '',
      fax: ''
    },
    del: {
      addr: '',
      zip: '',
      city: '',
      country: '',
      state: '',
      state_code: '',
      fax: ''
    },
    due_day: '',
    van_line: 0,
    price_group: 1,
    note: ''
  };
}

searchSuppliers(search: any) {
  let searchval = new FormData();
  searchval.append("supp_name", search);
  this.apiService.gettaxSuppByName(searchval).subscribe((res) => {
    this.supp_all = res['data'];
  });
}

searchTaxSubByTaxNo(search: any) {
  let searchval = new FormData();
  searchval.append("vat_no", search);
  this.apiService.gettaxSuppByVatNo(searchval).subscribe((res) => {
    this.supp_all = res['data'];
    if(res['data'] && res['data'].length > 0 ){
      this.pay.sup_name= res['data'][0].etp_supplier_name;
    }else{
      this.pay.sup_name = '';
    }
  });
}

updateSuppTax(supp_name){
  // console.log(supp_name);
  const selSupp = this.supp_all.filter(supp => supp.etp_supplier_name == supp_name);
  if(selSupp.length > 0){
    this.pay.sup_tax_no = selSupp[0].etp_supplier_tax_no;
  }
  // console.log(selSupp);
}

getAllNoncashLedgers() {
  this.apiService.getAllNonCashLedgers().subscribe((res) => {
    this.nonAccount = res.data;
  });
}


}
