import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import * as XLSX from 'xlsx'; 
@Component({
  selector: 'app-rate-edit-log',
  templateUrl: './rate-edit-log.component.html',
  styleUrls: ['./rate-edit-log.component.css']
})
export class RateEditLogComponent implements OnInit {
  fileName= 'rate_edit_log.xlsx';
  editLog: any;
  pageLoadingImg: boolean;
  pageFrom: any;
  curpage: any;
  lastpage: any;
  pgend: any;
  pgstart: any;
  branch_display_name: any;
  branch_display_code: any;
  branch_name: any;
  exportLoader: boolean;
  ExpeditLog: any;
  srchVal: any;

  constructor(private apiService: ApiService, private coreService: CoreService) { }

  ngOnInit() {

    let searchval = new FormData();
    this.pageLoadingImg = true;
    this.apiService.rateEditLog(searchval,1).subscribe((res) => {
      this.pageLoadingImg = false;
     
      this.editLog = res['data']['data']['data'];
      this.pageFrom =  res['data']['data']['from'];
      this.curpage =  res['data']['data']['current_page'];
      this.lastpage =  res['data']['data']['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
  
    });
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name= this.coreService.getUserData('branch_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  pageNext(formdata: { value: any; },pageno: any){
    this.pageLoadingImg = true;
    let searchval = new FormData();
   if(this.srchVal){
    searchval.append('search',this.srchVal);
   }
    
    this.apiService.rateEditLog(searchval,pageno).subscribe((res) => {
      this.pageLoadingImg = false;
     
      this.editLog = res['data']['data']['data'];
      this.pageFrom =  res['data']['data']['from'];
      this.curpage =  res['data']['data']['current_page'];
      this.lastpage =  res['data']['data']['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
  
    })

  }

  exportEXCL(formdata: { value: any; }){
    this.exportLoader = true;
    let searchval = new FormData();
    searchval.append('export','export');
    if(this.srchVal){
      searchval.append('search',this.srchVal);
    }
    
    this.apiService.rateEditLog(searchval,1).subscribe((res) => {
     
     
      this.ExpeditLog = res['data']['data'];
      setTimeout(() => {
        this.exportexcel();
    }, 3000);
     
    });
  }

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
  
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;
 
  }
  searchInp(e){
    let searchval = new FormData();
    searchval.append('search',e);
    this.apiService.rateEditLog(searchval,1).subscribe((res) => {
      this.pageLoadingImg = false;
     
      this.editLog = res['data']['data']['data'];
      this.pageFrom =  res['data']['data']['from'];
      this.curpage =  res['data']['data']['current_page'];
      this.lastpage =  res['data']['data']['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
  
    })

  }
}
