import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-dasboard-settings',
  templateUrl: './dasboard-settings.component.html',
  styleUrls: ['./dasboard-settings.component.css']
})
export class DasboardSettingsComponent implements OnInit {
  dashboardTypes = [
    { id: 1, name: 'Wholesale Dashboard' },
    { id: 2, name: 'Retail Dashboard' }
  ];
  admin_dashboardTypes = [
    { id: 1, name: 'Wholesale Dashboard' },
    { id: 2, name: 'Retail Dashboard' },
    { id: 3, name: 'Admin Dashboard' },
  ];

  totlQtycard = [
    { id: 0, name: 'Hide' },
    { id: 1, name: 'Dispaly' }
  ];
  dashboard_type = 1;
  admin_dashboard_type = 1;
  pageLoadingImg: boolean;
  settings: any;
  qty_disply: any;
  userType: any;
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService) { }

  ngOnInit() {
    this.userType = this.coreService.getUserData('user_type');
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.getDasboardSetting();
  }
  getDasboardSetting() {

    this.apiService.getDasboardSetting().subscribe((res) => {
      if (res['data']) {
        this.settings = res['data'];
        this.dashboard_type = this.settings.ds_type;
        this.admin_dashboard_type = this.settings.ds_admin_type;
        this.qty_disply = this.settings.ds_total_qty_disply;
      } else {
        this.dashboard_type = 1;
        this.admin_dashboard_type = 1;
      }
    });

  }
  update(formdata: { value: any; }) {
    this.pageLoadingImg = true;
    let fn = 'updateDasboardSetting';
    if(this.userType=='ADMIN'){
      fn = 'updateAdminDasboardSetting';
    }
    this.apiService[fn](formdata.value).subscribe((res) => {
      this.pageLoadingImg = false;
      if (res.data) {
        this.coreService.showMessage('Setting Saved');

      }
    }, (error) => {
      this.pageLoadingImg = false;
      // console.log(error);
    });
  }

  langChange(){
    this.translate.get(['Common.wholesale_dashbrd', 'Common.retail_dashbrd','Common.Disable','Common.Enable']).subscribe((res: string) => {    
     

      this.dashboardTypes = [
        { id: 1, name: res['Common.wholesale_dashbrd']},
        { id: 2, name: res['Common.retail_dashbrd'] },
  
      ];
      this.totlQtycard = [
        { id: 0, name: res['Common.Disable']},
        { id: 1, name: res['Common.Enable'] },
      ];
      
    });

  }

}
