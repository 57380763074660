import { CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as XLSX from 'xlsx'; 
import { ExcelService } from '../../../../../service/excel.service';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
@Component({
  selector: 'app-rep-income-expense',
  templateUrl: './rep-income-expense.component.html',
  styleUrls: ['./rep-income-expense.component.css']
})
export class RepIncomeExpenseComponent implements OnInit {
  filterTypes: { id: string; name: any; }[];
  pageLoadingImg: boolean;
  report: any;
  period_type: string;
  exportLoader: boolean;
  fileName= 'income_and_expense_report.xlsx';
  branch_display_name: any;
  branch_address: any;
  country_dec: number=2;
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }

  ngOnInit() {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();

    this.period_type='t';
    let searchval =new FormData();
    searchval.append('period_type','t');
    this.apiService.incomeExpenseReport(searchval).subscribe((res) => {
      this.report=res.data;
      this.pageLoadingImg = false;
  
    });
    this.country_dec=this.coreService.setDecimalLength();
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_name= this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');
  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','Common.this_month']).subscribe((res: string) => {    
     
      
      this.filterTypes = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'tm', name: res['Common.this_month'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
      
    });
  }

  incomeExpenseReport(formdata: { value: any; }){
  

    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.pageLoadingImg = true;

  this.apiService.incomeExpenseReport(formdata.value).subscribe((res) => {
    this.report=res.data;
    this.pageLoadingImg = false;

  });
  }

  exportEXCL(){
    this.exportLoader = true;
    this.exportexcel();
  }
  exportexcel(): void 
  {
     /* table id is passed over here */   
     let element = document.getElementById('export-group'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.fileName);
     this.exportLoader = false;
    
  }

}
