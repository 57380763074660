import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ProductionService } from '../../../../../service/production.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../../../service/api.service';
import { CoreService } from '../../../../../service/core.service';
import { DatePipe } from '@angular/common';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { log } from 'util';


@Component({
  selector: 'app-sales-return',   
  templateUrl: './sales-return.component.html',
  styleUrls: ['./sales-return.component.css']
})

export class SalesReturnComponent implements OnInit {

  @ViewChild('closeModal', { static: false }) closeModal: ElementRef;
  type_id: any;
  ret_godown_id: any;
  gd_id: any;
  cust_id: any;
  total_amount: any;
  base_unit_code: any;
  purchase_rate: any;
  total_vat = 0;
  net_amount = 0;
  sub_qty: any;
  total_qty: any;
  errObjArr = 0;
  vantran_purch_price: any;
  printError: any;
  display_qty: any;
  vantransub_qty: any;
  box: any;
  rate: any;
  vat: any;
  unit_qty: any;
  sl_no: any;
  vantran_purch_amount: any;
  item_amount: any;

  selectedItems: any;
  rtnId: any;
  ret_date = new Date();
  step3 = false;
  step2 = false;
  step1 = false;
  searchExisting = true;
  calculationDisplay = true;
  netcost: any;
  prodform_id: any;
  ttlCommAmount: any;
  output_qty: number;
  prdAll = [];
  filterProdAll = [];
  selProdIds: any = {};
  formula_all: any;
  prdIngAll = [];
  filterIngrdProd = [];
  selIngProdIds: any = {};

  staffs = [];
  filterStaffs = [];
  selStaffs: any = {};

  commAll = 0;
  comsnCommAmnt = 0;
  resultobj: any = {};
  balance = 0;
  prodFormula = {
    ret_date: new Date(),
    productnId: 0,
    productnDate: 0,
    formula_id: '',
    formula_name: '',
    productOut: [],
    productIngd: [],
    isProdCommsn: false,
    // 0 for all and 1 for individual productIngd: [{
    staffs: [],
    insp_staff: null,
    ttlComm: 0,
    ttlPurchase: 0,
    miscExp: 0,
  };
  formula_list = [];
  info = [];
  isSubmitInProg = false;
  pageLoadingImg = false;

  curpage = 1;
  lastpage = 1;
  perpage = 5;
  totalItem = 0;
  godown_all = [];
  curForm = 'Create';
  // payment_types = [];
  // customer_types = [];
  searchStkBatch: any;
  ledger: any;

  salestatus = 1;

  base_rate: any;
  // sale_price: any;

  prd_barcode: any;
  headerSalesEinvoiceLink: any;

  prd_units: any;
  return_qty: any;
  default_unit: any;
  nonAccount: any;

  ledgerLoading = false;
  accLoading = false;
  invLoading = false;
  pendingInvs = JSON.stringify([]);
  rowLoadingImg: any;
  nextId = '';
  total_sold = 0;
  total_retuned = 0;
  max_returnable = 0;
  prd_id: any;
  from: any;
  typeflag: number;
  defLedger: any;
  cash_in_account: any;
  showNew: boolean;
  taxCategories = [];
  prd_tax_cat_id = 0;
  purchaseItems: any[];
  saleData: { sales_id: any; sales_agent_id:any; sales_inv_no: any; error: any; message: any; data: any; sales_sub: any; };
  showdata: boolean;
  cust_name: any;
  sales_inv_no: string;
  tempdata = [];
  returndata = [];
  qty_rtn: any;
  rate_rtn: any;
  tax_rate_rtn: any;
  total_disc: number;
  total_serv_vat: any;
  total_disc_canc: any;
  total_net: any;
  sum_amount: any;
  tax_amount: number;
  sales_cust_address: any;
  showreturn: boolean;
  sold_unit: any;
  maximumu_rtn_qty: any;

  db_rate_rtn: any;
  rm_amount: number;
  rm_tax_amount: number;
  swap: any;
  saleprivewLoadingImg: boolean;
  salesRetPreview: any;
  isVansale: any;
  branch_display_name: any;
  branch_name: any;
  branch_display_code: any;
  branch_address: any;
  img_url: any;
  branch_phone: any;
  branch_mob: any;
  branch_tin: any;
  branch_reg_no: any;
  base_url: string;
  purchpageLoadingImg: boolean;
  qr_inv: any;
  cmpny: any;
  tempImgUrl: string;
  sales_print_name: any;
  sr_print_style: any;
  sr_print_style_tmp: any;

  savedTemplate = {
    
    logo: false,
    header_type: 1,
    header_height: 35,
    footer_height: 32,
    page_no_display: 0,
    left_h: 92.6,
    botom_h: 26.4,
    page_format: ''
  }
  filter_types = [
    { id: 1, name: 'Reported/Cleared'},
    { id: 2, name: 'Failed/Pending'},
    { id: 3, name: 'All'}
    
  ];

  payment_types = [
    { type_id: 0, name:  'Cash' },
    { type_id: 1, name:  'Credit',disabled:true },
    { type_id: 2, name:  'Bank' },
    
  ];
  customer_types = [
    { c_id: 1, name:  'New' },
    { c_id: 2, name: 'Registered' },
    
  ];
  country_dec: string;
  footImgUrl: string;
  logoImgUrl: string;
  minDate:Date;
  usr_block_pre_date_select: any;
  inv_types = [
    { id: 1, name: 'Tax Invoice' },
    { id: 2, name: 'Simplified Tax Invoice' },
  ];
  eInvoiceSupport: boolean=false;
  _tax_invoice = 'Tax Invoice';
  _simplified_tax_invoice = 'Simplified Tax Invoice';
  _invoice = 'Tax Invoice';

  // "b2BRequirements": {
  //   "isSelfBilling": false,
  //   "isThirdParty": false,
  //   "isSummaryPresent": false,
  //   "isExport": false,
  //   "isNominal": false
  // },
  // "b2CRequirements": {
  //   "isThirdParty": false,
  //   "isSummaryPresent": false,
  //   "isNominal": false
  // }
  // NNPNESB
  // - 01 for tax invoice
  // - 02 for simplified tax invoice
  // P (position 3) = 3rd Party invoice 
  // transaction, 0 for false, 1 for true
  // N (position 4) = Nominal invoice 
  // transaction, 0 for false, 1 for true
  // ubl:Invoice / ubl:Invoice / cbc:InvoiceTypeCode /@name
  // Version 1.1 40 / 57
  // E (position 5) = Exports invoice 
  // transaction, 0 for false, 1 for true
  // S (position 6) = Summary invoice 
  // transaction, 0 for false, 1 for true 
  // B (position 7) = Self billed invoice
  _Exports = 'Exports';
  _self_billing = 'Self Billing';
  _none = 'None';
  _third_party = 'Third Party';
  _nominal = 'Nominal';
  _summary = 'Summary';
  inv_sub_types = [
    { id: '00000', name: 'None' },
    { id: '10000', name: 'Third Party' },
    { id: '01000', name: 'Nominal' },
    { id: '00010', name: 'Summary' },
    { id: '00100', name: 'Exports' },
    { id: '00001', name: 'Self Billing' }
  ];
  inv_sub_type = '00000';
  xmlPath = '';
  branch_code = '';
  mode = {
    1: "production",
    2: "simulation",
    3: "development",
  }
  branch_e_invoice_enabled = false;
  inv_type = 1;
  einv_simplfied_print_style = 1;
  invoiceId: any;
  invoice_type: any;
  einvoice_err: any;
  addAllitemerror: string;
  publicPath = '';
  constructor(private productionService: ProductionService, private datePipe: DatePipe, private apiService: ApiService,
    private coreService: CoreService,private translate: TranslateService,private ActiveRoute: ActivatedRoute,) { }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.branch_e_invoice_enabled = this.coreService.getUserData('is_e_invoice');
    this.headerSalesEinvoiceLink = this.ActiveRoute.snapshot.paramMap.get('einvoice_pending');

    if (this.headerSalesEinvoiceLink&&this.headerSalesEinvoiceLink==1){
      this.invoice_type=2
    }else{
      this.invoice_type=3;
    }
    this.apiService.getClientSettingBykey({ key: 'enable_e_invoice' }).subscribe((res) => {
      if (res['data']) {
        this.eInvoiceSupport = ( (res['data']['cs_value'] ? true : false) && this.branch_e_invoice_enabled) ? true : false;
        this.inv_types = [
          { id: 1, name: this._tax_invoice},
          { id: 2, name: this._simplified_tax_invoice},
        ];
      }

      this.getInfo(this.curpage);
    });
    this.country_dec=this.coreService.setDecimalLength();
    this.cmpny = this.coreService.getUserData('cmpny');
    this.branch_code = this.coreService.getUserData('branch_code');
    this.publicPath = (this.apiService.PHP_API_SERVER.indexOf('127.0.0.1') !== -1) ? '' : '/public';
    this.xmlPath = this.apiService.PHP_API_SERVER + this.publicPath  + '/xml/' + this.cmpny + '/' + this.branch_code + '/' ;
    this.usr_block_pre_date_select = this.coreService.getUserData("usr_block_pre_date_select");
    if(this.usr_block_pre_date_select){
      this.minDate= new Date();
    }else{
      this.minDate= new Date(this.coreService.getUserData("branch_open_date"));
    }
  

    this.getReturnId();
    // this.setPayTypes();
    this.getTemplete();

    this.listDefaultLedger();
    this.resetForm();
    this.selectedItems = [];
    this.apiService.getAllTaxCategory().subscribe((resp) => {
      this.taxCategories = resp.data;
    });

    this.showreturn = false;
    this.ret_date = new Date();

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name = this.coreService.getUserData('branch_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');
    this.img_url = this.coreService.getUserData('img_url');
    this.branch_phone = this.coreService.getUserData('branch_phone');
    this.branch_mob = this.coreService.getUserData('branch_mob');
    this.branch_tin = this.coreService.getUserData('branch_tin');
    this.branch_reg_no = this.coreService.getUserData('branch_reg_no');
    this.base_url = location.origin;
    this.sales_print_name=1;
    this.getSalesSetting();
  
  }
  getSalesSetting() {
    this.apiService.getSalesSetting().subscribe((res) => {
      if (res['data']) {        
        this.sales_print_name=res['data'].sales_print_name;
        this.sr_print_style=res['data'].sale_rtn_print_style;
        this.sr_print_style_tmp = res['data'].sale_rtn_print_style;
        this.einv_simplfied_print_style = res['data'].einv_simplfied_print_style;
      } else {
        this.sales_print_name=1;
        this.sr_print_style=1;
        this.sr_print_style_tmp = 1;
        this.einv_simplfied_print_style = 1;
      }
    });
  }

  getTemplete() {

    this.apiService.getSettings().subscribe((res) => {
      // console.log(res);

      this.savedTemplate = {
        
        logo: res.data.prnt_header_logo,
        header_type: res.data.prnt_header_type,
        header_height: res.data.prnt_nt_header_height,
        footer_height: res.data.prnt_nt_footer_height,
        page_no_display: res.data.prnt_nt_display_page_no,
        left_h: res.data.prnt_nt_left_space,
        botom_h: res.data.prnt_nt_bottom_space,
        page_format: res.data.prnt_nt_page_no_disply_frmt
      }
      if (res.data.prnt_header_logo) {
      this.logoImgUrl = this.apiService.PHP_API_SERVER+'/'+res.data.prnt_header_logo_img_path;
    } else {
      this.logoImgUrl = '';
    }
      
      if (res.data.prnt_header_type == 2) {
        this.tempImgUrl = this.apiService.PHP_API_SERVER+'/'+res.data.prnt_header_img_path;
        this.footImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_footer_img_path;
      } else {
        this.tempImgUrl = '';
        this.footImgUrl = '';
      }

    });
  }

  addQuantiy(item, i) {
    this.tempdata = [];

    this.prd_tax_cat_id = item.salesub_taxcat_id;
    this.tempdata.push(item);
    this.maximumu_rtn_qty = this.qty_rtn = item.salesub_rem_qty;
    this.sold_unit = item.salesub_unit_id;
    this.default_unit = { "unit_code": item.unit_code, "unit_name": item.unit_name, "sur_unit_id": item.salesub_unit_id, "unit_base_qty": item.unit_base_qty };


    this.showreturn = false;
    this.prd_units = item.units;


    this.db_rate_rtn = item.salesub_rate;

    this.rate_rtn = item.salesub_rate;
    this.tax_rate_rtn = item.salesub_tax_per;

  }

  appendItem(result) {
    this.errObjArr = 0;

    if (this.qty_rtn == "" || this.qty_rtn == undefined) {

      $('#qty_rtn').css("border", "1px solid red");
      this.errObjArr = 1;

    } else {
      $('#qty_rtn').css("border", "1px solid #dedede");

      if (Number(this.qty_rtn) > Number(result.salesub_rem_qty)) {
        $('#qty_rtn').css("border", "1px solid red");
        this.errObjArr = 5;
      }

      if (this.qty_rtn > 0) {

      }
      else {
        $('#qty_rtn').css("border", "1px solid red");
        this.errObjArr = 3;
      }



    }


    if ($('#rate_rtn').val() == "" || $('#rate_rtn').val() == undefined) {
      $('#rate_rtn').css("border", "1px solid red");
      this.errObjArr = 4;
    } else {
      $('#rate_rtn').css("border", "1px solid #dedede");
    }


    if ($('#tax_rate_rtn').val() == "" || $('#tax_rate_rtn').val() == undefined) {
      $('#tax_rate_rtn').css("border", "1px solid red");
      this.errObjArr = 6;
    } else {
      $('#tax_rate_rtn').css("border", "1px solid #dedede");
    }

    if (this.errObjArr > 0) {
    }
    else {

      this.saleData.sales_sub.forEach((value, i) => {
        if (value.salesub_id == result.salesub_id) {

          this.saleData.sales_sub[i].salesub_rem_qty -= this.qty_rtn;

          if (this.saleData.sales_sub[i].salesub_rem_qty == 0) {
            //this.saleData.sales_sub.splice(i, 1);
            this.saleData.sales_sub[i].salesub_flags = 0
          }
          else {
            this.saleData.sales_sub[i].salesub_flags = 0
          }

        }
      });
      this.tempdata = [];
      result['sl_no'] = this.returndata.length + 1;
      result['qty_rtn'] = this.qty_rtn;
      result['rate_rtn'] = this.rate_rtn;
      result['tax_rate_rtn'] = this.tax_rate_rtn;
      result['rtn_unit_id'] = this.default_unit.sur_unit_id;
      result['prd_tax_cat_id'] = this.prd_tax_cat_id;
      this.returndata.push(result);
      this.showreturn = true;
      this.sum_amount = this.qty_rtn * this.rate_rtn;
      this.tax_amount = (this.qty_rtn * this.rate_rtn) * (this.tax_rate_rtn / 100);

      this.total_amount = this.total_amount + this.sum_amount;
      this.total_vat = this.total_vat + this.tax_amount;
      this.total_net = this.total_amount + this.total_vat - this.total_disc;
    }


  }

  updateNetAmnt(disc) {

    this.total_net = this.total_amount + this.total_vat - disc;

  }


  removeItem() {

    this.tempdata = [];
    this.showreturn = true;
  }

  sale_search(search: string) {
    this.resetForm();
    this.pageLoadingImg = true;
    const searchval = new FormData();
    searchval.append('sales_inv_no', search);
    this.apiService.getSalesInvData(searchval).subscribe((res) => {
      this.pageLoadingImg = false;

      if (res.error != null) {
        this.resultobj = res.error;
        this.purchaseItems = [];
        this.saleData = {
          sales_id: null,
          sales_agent_id:null,
          sales_inv_no: null,
          error: null,
          message: null,
          data: null,
          sales_sub: []

        };
        this.resultobj = this.coreService.getValidation(this.resultobj);
      } else {
        this.showdata = true;
        this.resultobj = {};
        this.purchaseItems = res.data;
        this.saleData = res.data;
        this.isVansale = res.data.van_sale;
this.einvoice_err=res.data.einc_err
        this.cust_name = res.data.sales_cust_name;
        this.sales_cust_address = res.data.sales_cust_address;
        this.balance = res.data.cust_balance;
        this.type_id = res.data.sales_pay_type;
        this.inv_type = res.data.sales_inv_type;
        this.inv_sub_type = res.data.zatca_inv_sub_type;
        this.changePayType(this.type_id);

        this.cust_id = 1;
        if ((res.data.sales_cust_id > 0)) {
          this.cust_id = 2;
        }
        // this.cash_in_account.push({'ledger_id':res.data.sales_acc_ledger_id,'ledger_name':res.data.sales_acc_ledger_name});
        this.cash_in_account = res.data.sales_acc_ledger_id;

        this.total_disc = 0;
        if (res.data.sales_discount > 0) {
          this.total_disc = res.data.sales_discount;
          this.total_net = -1 * res.data.sales_discount;
        }

      }

      this.ret_date = new Date();

    });
  }

  resetForm() {
    this.purchaseItems = [];
    this.addAllitemerror='';
    this.tempdata = [];
    this.returndata = [];
    this.saleData = {
      sales_id: null,
      sales_agent_id:null,
      sales_inv_no: null,
      error: null,
      message: null,
      data: null,
      sales_sub: []

    };


    this.cust_name = '';
    this.balance = 0;
    this.type_id = '';
    this.cust_id = 0;
    this.ret_date = new Date();

    this.total_amount = 0;
    this.total_vat = 0;
    this.total_disc = 0
    this.total_serv_vat = 0
    this.total_disc_canc = 0
    this.total_net = 0;

  }

  clcikClose() {
    $("#clickclose").click();
    this.sales_inv_no = '';
  }

  clearForm() {
    this.sales_inv_no = '';
    this.selectedItems = [];
    this.total_amount = this.total_vat = this.net_amount = 0;
    this.prd_id = '';
    this.prd_barcode = '';
    this.return_qty = '';
    this.default_unit = [];
    this.rate = 0;
    this.vat = 0;
    this.cash_in_account = 0;

    this.total_amount = 0;
    this.total_vat = 0;
    this.total_disc = 0
    this.total_serv_vat = 0
    this.total_disc_canc = 0
    this.total_net = 0;


    this.ret_date = new Date();
  }




  getReturnId() {
    let searchval = new FormData();
    this.apiService.geSalesReturnId(searchval).subscribe((res) => {
      this.rtnId = res['data']['ret_id'];
    });
  }
  setPayTypes() {

    // this.type_id = 2;
    // this.cust_id = 2;

    this.payment_types.push({ "type_id": 0, 'name': 'Cash' },
      { "type_id": 1, 'name': 'Credit', disabled: true },
      { "type_id": 2, 'name': 'Bank' }
    );

    this.customer_types.push({ "c_id": 1, 'name': 'New' },
      { "c_id": 2, 'name': 'Registered' },
    );
    this.typeflag = 1;

  }

  langChange(){
    this.translate.get(['Common.cash', 'HOME.credit','HOME.Bank','Common.new','Common.registerd',
    'Common.tax_invoice', 'Common.simplified_tax_invoice','Common.none','Branch.third_party','Branch.nominal','Branch.exports','Common.summary','Branch.self_billing'
    ]).subscribe((res: string) => {    
     
      
      this.payment_types = [
        { type_id: 0, name:  res['Common.cash'] },
        { type_id: 1, name:  res['HOME.credit'],disabled: true },
        { type_id: 2, name:  res['HOME.Bank'] },
        
      ];

      this.customer_types = [
        { c_id: 1, name:  res['Common.new'] },
        { c_id: 2, name:  res['Common.registerd'] },
        
      ];

      this._tax_invoice = res['Common.tax_invoice'];
      this._simplified_tax_invoice = res['Common.simplified_tax_invoice'];
      this.inv_types = [
        { id: 1, name: this._tax_invoice},
        { id: 2, name: this._simplified_tax_invoice},
      ];

      this._Exports = res['Branch.exports'];
      this._self_billing = res['Branch.self_billing'] ;
      this._none = res['Common.none'];
      this._third_party = res['Branch.third_party'];
      this._nominal = res['Branch.nominal'];
      this._summary =  res['Common.summary'];
      this.inv_sub_types = [
        { id: '00000', name: this._none},
        { id: '10000', name: this._third_party },
        { id: '01000', name: this._nominal },
        { id: '00010', name: this._summary }
      ];
      if (this.inv_type == 1) {
        this.inv_sub_types.push({ id: '00100', name:  this._Exports });
        this.inv_sub_types.push({ id: '00001', name: this._self_billing});
      }
    });

  }


  changeCustomer(cust_id) {


    if (cust_id == 1) {
      this.showNew = true;
    } else {
      this.showNew = false;
    }
  }


  ngOnDestroy() {
    $('.close').click();

  }






  // pageNext(formdata: { value: any; }, pageno: any) {

  //   this.pageLoadingImg = true;
  //   this.apiService.salesReturnList(pageno).subscribe((res: any) => {
  //     this.pageLoadingImg = false;
  //   });

  // }

  getsalesReturnPrev(prdn_id, data= null) {
    this.saleprivewLoadingImg = true;
    this.saleprivewLoadingImg = true;
      if(data && data.is_e_inv && data.is_simplified == 1 && this.sr_print_style_tmp == 1000){
        if(this.einv_simplfied_print_style == 1){
          this.sr_print_style = 1001;
        } else {
          this.sr_print_style = 1002;
        }
      } else{
        this.sr_print_style = this.sr_print_style_tmp;
      }
    let searchval = new FormData();
    searchval.append("ref_no", prdn_id);
    this.apiService.getsalesReturnPrev(searchval).subscribe((res) => {
      this.saleprivewLoadingImg = false;
      this.salesRetPreview = res;
      this.salesRetPreview.total_vat = Number(res.total_vat);
      this.salesRetPreview.total_amt = Number(res.total_amt);


      // let link = 'http://127.0.0.1:8000/';
      // if (this.apiService.PHP_API_SERVER == "https://bquickerp.com/backend") {
      //   link = 'https://bquickerp.com/';
      // }
      // if (this.apiService.PHP_API_SERVER == "https://alhazmicloud.com/backend") {
      //   link = 'https://alhazmicloud.com/';
      // }
      // if (this.apiService.PHP_API_SERVER == "https://fujishka.vip//backend" || this.apiService.PHP_API_SERVER == "https://fujishka.vip/backend") {
      //   link = 'https://fujishka.vip/';
      // }
      // if (this.apiService.PHP_API_SERVER == "http://bquickerp.in/backend") {
      //   link = 'https://bquickerp.in/';
      // }

      // this.qr_inv = link + this.salesRetPreview.qr_link;
      this.qr_inv = this.salesRetPreview.qr_link;

      this.apiService.getSettings().subscribe((res) => {
        if (res.data.prnt_header_type == 2) {
          this.tempImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_img_path;
          this.footImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_footer_img_path;
        } else {
          this.tempImgUrl = '';
          this.footImgUrl = '';
        }
  
      });





      //this.godown_all.push({ "gd_id": 0, 'gd_name': 'Shop' });

    });

  }

  searchInp(key) {
    let searchval = new FormData();
    searchval.append("keyword", key);
    this.formula_list = [];

    if(this.invoice_type &&this.eInvoiceSupport){
      searchval.append("invoiceType_id",this.invoice_type);
    }

    this.pageLoadingImg = true;
    this.apiService.salesReturnList(searchval, 1).subscribe((res) => {
      this.formula_list = res.data.data;
      this.pageLoadingImg = false;
      this.from = res.data.from;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
    });


  }

  productionPreview(prdn_id) {

    let searchval = new FormData();
    searchval.append("prdn_id", prdn_id);
    this.apiService.productionPreview(searchval).subscribe((res) => {
      this.prodFormula = res['data'];

      //this.godown_all.push({ "gd_id": 0, 'gd_name': 'Shop' });

    });

  }



  changeUnit(qty, index) {
    //this.prodFormula.productIngd[index]['product']['shop'] = this.prodFormula.productIngd[index]['product']['shop_available'] * qty;
    this.prodFormula.productIngd[index]['qty'] *= qty;
  }
  getInfo(pageNo) {
    this.pageLoadingImg = true;
    const searchval = new FormData();
    if(this.invoice_type&&this.eInvoiceSupport){
      searchval.append("invoiceType_id",this.invoice_type);
    }

    this.apiService.salesReturnList(searchval, pageNo).subscribe((res) => {
      this.formula_list = res.data.data;
      this.pageLoadingImg = false;
      this.from = res.data.from;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
      this.headerSalesEinvoiceLink=null;
    });
  }


  
Select_invoiceType(id){

   
  if (id){
    this.invoiceId=id;
    let searchval = new FormData();
    searchval.append("invoiceType_id",this.invoiceId);
    this.pageLoadingImg = true;
    this.apiService.salesReturnList(searchval, 1).subscribe((res) => {
      this.formula_list = res.data.data;
      this.pageLoadingImg = false;
      this.from = res.data.from;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
    });

  }
}

  searchProducts(search: string) {
    const searchval = new FormData();
    searchval.append('keyword', search);
    this.apiService.searchSoldProducts(searchval).subscribe((res) => {
      this.prdAll = res['data'];
      this.filterProducts();
    });
  }



  filterProducts() {
    this.filterProdAll = this.prdAll.filter(e => {
      if (Object.values(this.selProdIds).indexOf(e.prd_id) < 0) {
        return true;
      }
    });
  }

  addMore() {
    this.prodFormula.productOut.push({
      product: null,
      qty: 0,
      qty_expected: 0,
      percntg: 0,
      qty_received: 0,
      rate_expected: 0,
      net_rate: 0,
      exp_date: 0,
      manf_date: 0,
      batch_code: ''
    });
  }





  addMoreIngrd() {
    this.prodFormula.productIngd.push({
      product: null,
      qty_show: 0,
      qty: 0,
      amount: 0,
    });
  }

  removeIngd(index) {
    if (this.prodFormula.productIngd.length > 1) {
      this.prodFormula.productIngd.splice(index, 1);
    } else {
      // error atleast 1 item required
    }
    delete this.selIngProdIds[index];

  }

  commissionChk(value) {
    if (value === 0) {
      this.prodFormula.staffs = [];
      this.prodFormula.ttlComm = 0;
    }
  }

  setStaff(staffId, index) {
    if (staffId == null) {
      delete this.selStaffs[index];
    } else {
      this.selStaffs[index] = staffId;
    }
    this.filterStaff();
  }

  filterStaff() {
    this.filterStaffs = this.staffs.filter(e => {
      if (Object.values(this.selStaffs).indexOf(e.ledger_id) < 0) {
        return true;
      }
    });
  }

  getAllSatff() {
    this.apiService.listAllStaff().subscribe((res) => {
      this.staffs = res.data;
      this.filterStaff();
    });
  }

  addMoreStaff() {
    this.prodFormula.staffs.push({
      staff: null,
      comm: 0,
    });
  }

  removeStaff(index) {

    this.prodFormula.staffs.splice(index, 1);
    delete this.selStaffs[index];
    this.filterStaff();
    this.updateCommsnTtl();
  }

  showAllStaff() {
    this.selStaffs = {};
    this.prodFormula.staffs = [];
    this.staffs.forEach((element, index) => {
      this.prodFormula.staffs.push({
        staff: element,
        comm: 0,
      });
      this.setStaff(element.ledger_id, index);
    });
    this.updateCommsnTtl();
  }

  showIndivStaff() {
    this.selStaffs = {};
    this.filterStaff();
    this.prodFormula.staffs = [{
      staff: {},
      comm: 0,
    }];
  }
  updateOutPutProducts(qty, index) {

  }
  updateCommsnTtl() {
    this.prodFormula.ttlComm = 0;
    this.prodFormula.staffs.forEach(element => {
      if (parseFloat(element.comm.toString()) > 0) {
        this.prodFormula.ttlComm += parseFloat(element.comm.toString());
      }

    });
  }
  updatePurchaseTtl() {
    this.prodFormula.ttlPurchase = 0;
    this.prodFormula.productIngd.forEach(element => {
      this.prodFormula.ttlPurchase += element.amount;
    });
  }

  setIndvComsn() {

    this.prodFormula.staffs.forEach((element, index) => {
      this.prodFormula.ttlComm += parseFloat(element.comm.toString());
      this.prodFormula.staffs[index].comm = this.comsnCommAmnt;
    });
    this.updateCommsnTtl();
  }
  validateAndSubmit() {
    this.isSubmitInProg = true;
    let callFunction = '';
    if (this.curForm === 'Create') {
      callFunction = 'createProduction';
    }
    if (this.curForm === 'Update') {
      callFunction = 'updateProduction';
    }

    this.prodFormula.productOut.forEach((element, index) => {
      this.prodFormula.productOut[index]['rate_expected'] = ((this.prodFormula.ttlComm * 1) + (this.prodFormula.ttlPurchase) + (this.prodFormula.miscExp * 1)) * (this.prodFormula.productOut[index].percntg / (100 * this.prodFormula.productOut[index].qty_expected));
      this.prodFormula.productOut[index]['net_rate'] = ((this.prodFormula.ttlComm * 1) + (this.prodFormula.ttlPurchase) + (this.prodFormula.miscExp * 1)) * (this.prodFormula.productOut[index].percntg / (100 * this.prodFormula.productOut[index].qty_received));
    });

    this.productionService[callFunction](this.prodFormula).subscribe((res) => {

      this.isSubmitInProg = false;
      if (res.error != null) {
        this.resultobj = res.error;
      } else {

        this.prodFormula.productnId = res.preview.prdn_id;
        this.prodFormula.productnDate = res.preview.prdn_date;
        this.getInfo(this.curpage);
        this.coreService.showMessage(res.message);

        this.resultobj = {};
      }

    });
  }



  updateTaxPerc(taxCatId) {
    const selTax = this.taxCategories.filter((taxCat) => taxCat.taxcat_id === taxCatId);
    this.tax_rate_rtn = selTax[0].taxcat_tax_per;
  }





  validateReturn(formData) {


    this.printError = [];
    if (formData.ret_date == "" || formData.ret_date == undefined) {
      $('#ret_date').css("border", "1px solid red");
      this.printError.push({ 'fieldErr': 'Required' });
    }
    else {
      $('#ret_date').css("border", "1px solid #dedede");
    }


    if (formData.ret_pay_type != 1) {
      if (formData.cash_in_account_ledger == "" || formData.cash_in_account_ledger == undefined) {

        $('#cash_in_account_ledger').css("border", "1px solid red");
        this.printError.push({ 'fieldErr': 'Required' });

      }
      else {
        $('#cash_in_account_ledger').css("border", "1px solid #dedede");
      }
    }




  }
  changeType(type) {
    this.defLedger = [];
    this.cash_in_account = '';


    if (type == 2) {
      this.getAllBankLedgers();
    } else {
      this.listDefaultLedger();

    }

    if (type == 1 || type == 3) {
      this.cust_id = 1;
      this.typeflag = 0;

    } else {
      this.cust_id = 2;
      this.typeflag = 1;
    }

    if (this.cust_id == 1) {
      this.showNew = true;
    } else {
      this.showNew = false;
    }



  }

  salesItemReturn(formData) {
    if (this.returndata.length == 0) {
      alert("Please add item to continue");
    }
    else {
      var confrm = confirm("Are you sure to return the items?");
      if (confrm) {
        this.validateReturn(formData.value);

        if (this.printError.length <= 0) {

          this.purchpageLoadingImg = true;



          formData.value.items = this.returndata;
          formData.value.total_amount = this.total_amount;
          formData.value.total_vat = this.total_vat;
          formData.value.total_disc = this.total_disc;
          formData.value.net_amount = this.total_net;

          this.apiService.salesItemReturnWithInv(formData.value).subscribe((res) => {
            let isError = false;
            if(this.eInvoiceSupport){
              if(res.error && res.error['error']){
                this.coreService.showMessage(res.error['error'], 4000);
                isError = true;
                this.purchpageLoadingImg = false;
              } 
            }
    
            if(!isError){
              this.purchpageLoadingImg = false;

              this.coreService.createfunct(formData, this.resultobj, res.message);


              this.ngOnInit();
            }
            // this.resetForm();

            this.apiService.updateEinvoiceNotificationChange();
          });
          this.apiService.updatebatchExNotification();
         
        }
        else {


        }
      }
    }

  }
  deleteItem(data: any) {
    var rmvitem = confirm("Are you sure you want to remove this item from list ? ");
    if (rmvitem) {

      var i;
      
      for (i = this.returndata.length-1; i >= 0; --i) {
        if (this.returndata[i].salesub_id == data.salesub_id) {

          this.rm_amount = this.returndata[i].qty_rtn * this.returndata[i].rate_rtn;
          this.rm_tax_amount = this.rm_amount * (this.returndata[i].tax_rate_rtn / 100);
          this.total_amount -= this.rm_amount;
          this.total_vat -= this.rm_tax_amount;
          this.total_net -= (this.rm_amount + this.rm_tax_amount);

          this.returndata.splice(i, 1);


          this.saleData.sales_sub.forEach((value, k) => {



            if (value.salesub_id == data.salesub_id) {
              this.saleData.sales_sub[k].salesub_flags = 1

              this.saleData.sales_sub[k].salesub_rem_qty = Number(this.saleData.sales_sub[k].salesub_rem_qty * 1) + Number(data.qty_rtn * 1);
            }
          });



        }
      }

    }    
  }

  findTotalSum() {
    this.total_amount = 0;
    this.total_vat = 0;
    this.selectedItems.forEach(element => {

      this.total_amount += Number(element.purchase_amount);
      this.total_vat += Number(element.vat_amount);

    });
    this.net_amount = this.total_amount + this.total_vat;
  }



  convertRate(unit_id, result) {

    if (unit_id.sold_qunatity == 'invalid') {
      alert('Sold Quantity is less than selected ');
    }
    else {
      this.rate_rtn = unit_id.sur_unit_rate;
      this.qty_rtn = unit_id.rem_qunatity;
      this.maximumu_rtn_qty = this.qty_rtn;


      this.saleData.sales_sub.forEach((value, i) => {
        if (value.salesub_id == result.salesub_id) {
          this.saleData.sales_sub[i].salesub_qty = unit_id.sold_qunatity;
          this.saleData.sales_sub[i].salesub_rem_qty = unit_id.rem_qunatity;
          this.saleData.sales_sub[i].unit_display = unit_id.unit_display;
          this.saleData.sales_sub[i].salesub_rate = this.db_rate_rtn / this.qty_rtn;

        }
      });
    }
  }


  listDefaultLedger() {
    this.apiService.getAllAccount().subscribe((res) => {
      this.defLedger = res['data'];
    });

  }

  getAllBankLedgers() {
    this.apiService.getAllBankLedgers().subscribe((res) => {
      this.defLedger = res['data'];
    });

  }

  changePayType(id) {
    if (id == 2) {
      this.getAllBankLedgers();
    } else {
      this.listDefaultLedger();

    }
  }

  updatePayment() {
    this.inv_sub_types = [
      { id: '00000', name: this._none},
      { id: '10000', name: this._third_party },
      { id: '01000', name: this._nominal },
      { id: '00010', name: this._summary }
    ];
    if (this.inv_type == 1) {
      this.inv_sub_types.push({ id: '00100', name:  this._Exports });
      this.inv_sub_types.push({ id: '00001', name: this._self_billing});
    }
  }

  eInvoiceResubmit(refno,i){
    if(confirm('Do you wish to continue?')){
      let tmp = {
        type: 'salesReturn',
        ref_no : refno
      }
      this.formula_list[i].resubmitLoadingImg = true;
      this.apiService.eInvoiceResubmit(tmp).subscribe((res) => {
        this.formula_list[i].resubmitLoadingImg = false;
        this.coreService.showMessage(res.msg, 4000);
        if (res.status) {
          this.getInfo(this.curpage);
        }
        this.apiService.updateEinvoiceNotificationChange();
      });
    }
  }

  addAllItem(saleItems){
    this.sum_amount=0;
    this.tax_amount=0;
    this.total_amount=0;
    this.total_vat=0;
    var returnItemsQuantity=0;
saleItems.forEach((value, i) => {

  
if(value.salesub_rem_qty !=0){
  returnItemsQuantity +=value.salesub_rem_qty;
  this.saleData.sales_sub[i].salesub_flags = 0
  this.default_unit = { "unit_code": value.unit_code, "unit_name": value.unit_name, "sur_unit_id": value.salesub_unit_id, "unit_base_qty": value.unit_base_qty };

  this.showreturn = false;
  this.prd_units = value.units;
  const returnItem = {
    ...value,
    sl_no:this.returndata.length + 1,
    qty_rtn: value.salesub_rem_qty,
    rate_rtn: value.salesub_rate,
    tax_rate_rtn: value.salesub_tax_per,
    rtn_unit_id: this.default_unit.sur_unit_id,
    prd_tax_cat_id: value.salesub_taxcat_id
  };
  this.returndata.push(returnItem);
  this.showreturn = true;
  this.sum_amount += Number(value.salesub_rem_qty) *  Number(value.salesub_rate);
  this.tax_amount += (Number(value.salesub_rem_qty) * Number(value.salesub_rate)) * (Number(value.salesub_tax_per) / 100);

  this.saleData.sales_sub[i].salesub_rem_qty =0;
}
});
this.total_amount=this.sum_amount;
this.total_vat =this.tax_amount;
this.total_net = this.total_amount + this.total_vat - this.total_disc;
if(returnItemsQuantity==0){
  this.addAllitemerror = 'Cannot return items because all returnable quantities are zero.';
}else{
  this.addAllitemerror=''
}
}




}