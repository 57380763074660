import { CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-wallet-tracking-rep',
  templateUrl: './wallet-tracking-rep.component.html',
  styleUrls: ['./wallet-tracking-rep.component.css']
})
export class WalletTrackingRepComponent implements OnInit {
  search_txt: "";
  pageLoadingImg: boolean;
  ledgers: any;
  branch_display_name: any;
  branch_name: any;
  branch_display_code: any;
  country_dec: string;
  branch_address: any;
  cur_lang: any;
  voucherDetails: any;
  bal: any;
  logoImgUrl: any = '';
  filterTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];
  savedTemplate = {
    title_text: '',
    color: '#000',
    email_color: '#000',
    font_size: '12',
    orientation: "P",
    paper: 'A4-P',
    m_left: '',
    m_top: '',
    m_right: '',
    m_bottom: '',
    logo: false,
    header_type: 1,
    header_img: [],
    header_all_page: false,
    header_due: false,
    sl_no: false,
    item_desc: false,
    tax_detail: false,
    tax_type: 0,
    name_type: 'N',
    footer_text: ''
  }
  tempImgUrl: string;
  total_walletAmount: any;
  exportLoader: boolean;
  fileName="payment.xlsx";
  fileName2="wallet.xlsx"
  ledger_id: any;
  ledger: any;
  total_walletAmounts: any;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      //  this.langChange();
     });
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name = this.coreService.getUserData('branch_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');
    this.country_dec=this.coreService.setDecimalLength();
    const searchval = new FormData();
    // this.getTemplete();

    this.apiService.walletTracking(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
     // console.log(res.data);
       this.ledgers =res.data;
       this.ledger_id =res.data.ledger_id;
      this.total_walletAmount = res.walletAmount;
      // this.total_cr_amount = res.total_cr_amount;
      //  this.total_dr_amount = res.total_dr_amount;
      // this.dayList = res.data.cashInHand;
      // this.acc = res.data.bankAccount;
   
    });


    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
  }

  filterSearch(formdata: { value: any; }) {
    this.search_txt="";
    this.pageLoadingImg = true;

    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.apiService.walletTracking(formdata.value, 1).subscribe((res: any) => {
      this.ledgers =res.data;
      this.ledger_id =res.data.ledger_id;
      this.total_walletAmount = res.walletAmount;
      console.log( this.ledgers);
      
      // this.total_supplier = res.total_supplier;
      // this.total_cr_amount = res.total_cr_amount;
      // this.total_dr_amount = res.total_dr_amount;
      this.pageLoadingImg = false;

    });
  }
  searchInp(e) {
    let searchval = new FormData();
    searchval.append("search", e);
    if(this.translate.currentLang =='Arabic'){
      searchval.append("with-alias", "1");
    }
   
   // console.log(this.search_txt);
    this.pageLoadingImg = true;
    this.apiService.searchSupplierpaymentDet(searchval, 1).subscribe((res) => {
      this.ledgers =res.data.data;
      console.log( this.ledgers);
      this.total_walletAmount = res.data.walletAmount;
      console.log(this.total_walletAmount);
      
      this.pageLoadingImg = false;
    });
    
  }

  paymentsdetail(id,formdata: { value: any; }) {


    formdata.value.ledger_id=id;
    formdata.value.ledger_sel_id=id;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.apiService.paymentwalletSupplierDet(formdata.value).subscribe((res) => {
    //   this.payPriviewLoader = false;
     this.voucherDetails = res.data;   
     this.bal = 0;  
     console.log(' this.bal', this.bal);
     console.log();
     
     
     if (this.voucherDetails.wallet.length > 0) {
      for (let i = 0; i < this.voucherDetails.wallet.length; i++) {
        this.voucherDetails.wallet[i].balance = this.bal = this.bal + (this.voucherDetails.wallet[i].wllt_in - this.voucherDetails.wallet[i].wllt_out);
      }
      console.log(' this.voucherDetails.wallet[i].balance', this.voucherDetails.wallet.balance);
      
     }
    //  this.bal = 0 - res.data.op_balance;
    //       if (this.voucherDetails.vouchers.length > 0) {
    //         for (let i = 0; i < this.voucherDetails.vouchers.length; i++) {
    //           this.voucherDetails.vouchers[i].balance = this.bal = this.bal + (this.voucherDetails.vouchers[i].vch_in - this.voucherDetails.vouchers[i].vch_out);
    //         }
    //       }
    });
   
  }
  getTemplete() {

    this.apiService.getSettings().subscribe((res) => {

      this.savedTemplate = {
        title_text: res.data.prnt_title_text,
        color: '#000',
        email_color: '#000',
        font_size: '12',
        orientation: "P",
        paper: 'A4-P',
        m_left: '',
        m_top: '',
        m_right: '',
        m_bottom: '',
        logo: res.data.prnt_header_logo,
        header_type: res.data.prnt_header_type,
        header_img: [],
        header_all_page: false,
        header_due: false,
        sl_no: false,
        item_desc: false,
        tax_detail: false,
        tax_type: 0,
        name_type: 'N',
        footer_text: res.data.prnt_display_footer_text
      }
      if (res.data.prnt_header_logo) {
        this.logoImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_logo_img_path;
      } else {
        this.logoImgUrl = '';
      }

      if (res.data.prnt_header_type == 2) {
        this.tempImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_img_path;
      } else {
        this.tempImgUrl = '';
      }

    });
  }
  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }


  exportEXCL() {
   

    

          setTimeout(() => {
            this.exportexcel();
          }, 3000);
   

   
  }

  exportexcel2(): void {
    /* table id is passed over here */
    let element = document.getElementById('export-group3');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName2);
    this.exportLoader = false;

  }

  exportEXCL2() {
    this.exportLoader = true;
    
     

          setTimeout(() => {
            this.exportexcel2();
          }, 3000);
    


   
  }
  
  generatePdf() {
  
    const heads = [
      this.translate.instant('Purchase.supplier'),
      this.translate.instant('HOME.Wallet_Amt'),
     
    ];

      const lblXPos = 10;
      const headerHeight = 15;
      const valueXPos = 55;
      const data = [];

      this.ledgers.forEach(item=>{
        data.push([
          (this.translate.currentLang == 'Arabic' && item.ledger_alias) ? item.ledger_alias : item.ledger_name,
          (item.walletAmount) 
      ]);
      
        
      })
  
      let doc = this.excelService.getPdfObj();

      var addressLines = this.branch_address.split('\r\n');
      var addressText = addressLines.join(', ');

      doc.setFont('Amiri');
      doc.setFontSize(12);
      doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);

      doc.setFont('Amiri');
      doc.setFontSize(9);
      doc.text(addressText, 71, headerHeight + 2);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 10);
      doc.setFontSize(10);
      doc.text(':  ' + `${this.translate.instant('Purchase.supplier_wallet_report')}`,  valueXPos, headerHeight + 10);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('HOME.total_wallet_amnt')}`, lblXPos, headerHeight + 15);
      doc.setFontSize(10);
       doc.text(':  ' + (this.total_walletAmount),  valueXPos, headerHeight + 15);
      // doc.text(':  ' + this.total_walletAmount,  valueXPos, headerHeight + 15);

      // doc.setFontSize(10);
      // doc.text(`${this.translate.instant('Table.total_dr_amount')}`, lblXPos, headerHeight + 20);
      // doc.setFontSize(10);
      // doc.text(':  ' + (this.total_dr_amount),  valueXPos, headerHeight + 20);

      // doc.setFontSize(10);
      // doc.text(`${this.translate.instant('Table.total_cr_amount')}`, lblXPos, headerHeight + 25);
      // doc.setFontSize(10);
      // doc.text(':  ' + (this.total_cr_amount),  valueXPos, headerHeight + 25);
      
      doc = this.excelService.addTableToPdf(doc, headerHeight + 20, lblXPos, heads, data, null);
  
     
      doc.save(`payment.pdf`);


    
  }




}
