import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Productionformula } from '../model/production/productionformula.model';
import { ApiService } from './api.service';
// import { CoreService } from '../../../../../service/core.service';

@Injectable({
  providedIn: 'root'
})
export class ProductionService {
  PHP_API_SERVER = environment.php_api_server + '/api/production_formula';
  PHP_API_PRODUCTION = environment.php_api_server + '/api/production';
  token = localStorage.getItem("auth_token");



  constructor(private httpClient: HttpClient, private apiService: ApiService) { }

  createProductFormula(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/add', data, {headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
      cmptoken: localStorage.getItem('cmptoken')

    }) });
  }

  getAllProductionFormula(curPage, perPage): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/list?page=' + curPage , {},{headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
      cmptoken: localStorage.getItem('cmptoken')
    }) });
  }

  getNewProduction(id,g_id): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/formula', { prodform_id: id,gd_id : g_id },{headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
      cmptoken: localStorage.getItem('cmptoken')
    }) });
  }

  getProductionFormula(id): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/item', { prodform_id: id },{headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
      cmptoken: localStorage.getItem('cmptoken')
    }) });
  }
  updateProductFormula(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/update', data, {headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
      cmptoken: localStorage.getItem('cmptoken')
    }) });
  }

  createProduction(data): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_PRODUCTION + '/add', data, {headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
      cmptoken: localStorage.getItem('cmptoken')
    }) });
  }

  updateProduction(data): Observable<any> {
    data.prdn_date = this.apiService.formatDate(data.prdn_date);
    return this.httpClient.post<any>(this.PHP_API_PRODUCTION + '/update', data, {headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
      cmptoken: localStorage.getItem('cmptoken')
    }) });
  }

  prodSumm(res: FormData,pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_PRODUCTION + '/production_report/summary?page=' + pageno, res,{headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
      cmptoken: localStorage.getItem('cmptoken')
    }) });
   }

  prodDet(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_PRODUCTION + '/production_report/details', res,{headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
      cmptoken: localStorage.getItem('cmptoken')
    }) });
   }
   prodIncr(res: FormData,pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_PRODUCTION + '/production_report/incredients?page=' + pageno, res,{headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
      cmptoken: localStorage.getItem('cmptoken')
    }) });
   }

   getProductionDet(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_PRODUCTION + '/get_production_details', res,{headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
      cmptoken: localStorage.getItem('cmptoken')
    }) });
   }


   getAllProductions(curPage, perPage): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_PRODUCTION + '/list?page=' + curPage + '&per_page=' + perPage, {},{headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
      cmptoken: localStorage.getItem('cmptoken')
    }) });
  }

  prodComm(res: FormData,pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_PRODUCTION + '/production_report/commission?page=' + pageno, res,{headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
      cmptoken: localStorage.getItem('cmptoken')
    }) });
   }

   prodProd(res: FormData,pageno: number): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_PRODUCTION + '/production_report/product?page=' + pageno, res,{headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
      cmptoken: localStorage.getItem('cmptoken')
    }) });
   }

   searchProductionFormula(res: FormData): Observable<any> {
    return this.httpClient.post<any>(this.PHP_API_SERVER + '/search_formula', res, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        cmptoken: localStorage.getItem('cmptoken')
      })
    });
  }


}
