import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../service/api.service';
import { CoreService } from '../../../../service/core.service';
import { FormControl } from '@angular/forms';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-purchase-return',
  templateUrl: './purchase-return.component.html',
  styleUrls: ['./purchase-return.component.css']
})
export class PurchaseReturnComponent implements OnInit {

  datevalue: any;
  purchret_refno: any;
  purchret_supp_inv_no: any;
  purchret_retid: any;
  purchret_supp_id: any;
  purchret_date: any;
  purchret_type: any;
  purchret_supp_retno: any;
  purchret_amount: any;
  gd_id: any;
  brcode: any;
  changeBox: number;
  vantran_godown_id: any;
  filter_types = [
    { id: 1, name: 'Reported/Cleared'},
    { id: 2, name: 'Failed/Pending'},
    { id: 3, name: 'All'}
    
  ];


  selecTed: any = { bs_prate: null };
  prd_id: any;
  tran_id: number;
  vantran_purch_price: number;
  prd_barcode: String;
  vantransub_qty: number;
  vantran_purch_amount: number;
  vantransub_unit_id: any;
  default_unit: any;
  total_qty = 0;
  NgForm: any;
  total_amount = 0;
  sel_gd_id = 0;
  tranArray: any;
  slno: number;
  prd_units = [];
  van_all = [];
  prd_all = [];
  selectedItems = [];
  resDup: any;
  resultobj: any = {};
  printError = [];
  vantran_date: any;
  prd_name_search: any;
  alias: any;
  vanlines: any[];
  VanLine: any[];
  preview_tran: any[];
  curpage: number;
  lastpage: number;
  // changeBox: boolean;
  pgstart: boolean;
  pgend: boolean;
  slnum: number;
  box = 0;
  preview_id = 0;
  preview_date: any[];
  preview_gd: any[];
  preview_van: any[];
  preview_prize: any[];
  preview_items: any[];
  prdSel: any;
  selunit: any;
  itemsreturn = [];
  avlqty: any;
  updatepurchasepricetot: any;
  item_barcode: any;
  purchaseretno: number;
  purch_note: number;
  bs_prate: any;
  purch_amount: any;
  purchData: any;
  pageLoadingImg:boolean;
  qr_inv: any;
  checkBatch =false;



  godown_all = [{ "gd_id": 0, 'gd_name': 'Shop' }];
  purchret_gd_id = 0;
  pay_type = [
    { id: 1, name: 'Credit' },
    { id: 2, name: 'Cash' },
    { id: 3, name: 'Bank' }
  ];

  pay_type_name={
    '1':'Credit',
    '2':'Cash',
    '3':'Bank',

  }
  gdid: number;
  errObjArr = {
    unitqtyErr: null, unitErr: null,
    prodErr: null, gdErr: null, pstkErr: null
  };

  ledger_all: string[];
  items = [];
  purchretsub_tax: any;
  netamount = 0;
  nettax = 0;
  netqty = 0;
  netprice = 0;
  netretamount = 0;
  purchret_discount = 0;
  gdwnid: any;
  punitname: any;
  punitqty: any;
  boxqty: any;
  purchretsub_tax_peritem: any;
  purchretsub_taxvalue_peritem: any;
  itemtype: string;
  baseunit: string;
  expiry: any;
  seltaxamount = 0;
  purchstock: any;
  availableQty: any;
  remeQty =0;
  existpurchaseprice: any;
  updatepurchaseprice: any;
  totbox: any;
  totprice: any;
  updatepurchasepricete: any;
  purchpricesingleorbox: any;
  purchpricesingleorbox_singlerate: any;
  prd_stock_statusUnchecked = "alias";
  prd_stock_statusChecked = "name";
  prd_stock_status = "name";
  retItems: any[];
  totalItem = 0;
  purchDataItems: string[];
  product_summary: any;
  elseBlocknostart: any;
  elseBlocknoend: any;
  searchStkBatch: any;
  purchDataRetAmount:any;
  gd_name:any;
  purch_acc_ledger_id:any;
  taxCategories = [];
  prd_tax_cat_id = 0;
  private modalRef: NgbModalRef;
  closeResult: string;
  //date = new FormControl(new Date());
  //serializedDate = new FormControl((new Date()).toISOString());
  date = new Date();
  up: any;
  defLedger: any;

  savedTemplate = {
    title_text: 'Purchase Return',
    color: '#000',
    email_color: '#000',
    font_size: '12',
    orientation: "P",
    paper: 'A4-P',
    m_left: '',
    m_top: '',
    m_right: '',
    m_bottom: '',
    logo: false,
    header_type: 1,
    header_img: [],
    header_all_page: false,
    header_due: false,
    sl_no: false,
    item_desc: false,
    tax_detail: false,
    tax_type: 0,
    name_type: 'N',
    footer_text: ''
  }
  logoImgUrl: string;
  tempImgUrl: string;
  inventoryType = [
    {id: 0, value: 'With Inventory'},
    {id: 1, value: 'Without inventory'},
  ];
  is_without_stock = 0; 
  sales_print_name: any;
  country_dec: string;
  batches = [];
  selBatch:any;
  saleData: { sales_id: any; sales_inv_no: any; error: any; message: any; data: any; items: any; };
  showdata: boolean;
  stkprdbc_all: any;
  errObjArr1 = 0;
  purchretsub_rate = 0;
  stock_id = 0;
  branch_stock_id= 0;
  ref_num_search = 0;
  purch_return= false;
  cur_lang: string;
  inv_types = [
    { id: 1, name: 'Tax Invoice' },
    { id: 2, name: 'Simplified Tax Invoice' },
  ];
  eInvoiceSupport: boolean=false;
  _tax_invoice = 'Tax Invoice';
  _simplified_tax_invoice = 'Simplified Tax Invoice';
  _invoice = 'Tax Invoice';

  // "b2BRequirements": {
  //   "isSelfBilling": false,
  //   "isThirdParty": false,
  //   "isSummaryPresent": false,
  //   "isExport": false,
  //   "isNominal": false
  // },
  // "b2CRequirements": {
  //   "isThirdParty": false,
  //   "isSummaryPresent": false,
  //   "isNominal": false
  // }
  // NNPNESB
  // - 01 for tax invoice
  // - 02 for simplified tax invoice
  // P (position 3) = 3rd Party invoice 
  // transaction, 0 for false, 1 for true
  // N (position 4) = Nominal invoice 
  // transaction, 0 for false, 1 for true
  // ubl:Invoice / ubl:Invoice / cbc:InvoiceTypeCode /@name
  // Version 1.1 40 / 57
  // E (position 5) = Exports invoice 
  // transaction, 0 for false, 1 for true
  // S (position 6) = Summary invoice 
  // transaction, 0 for false, 1 for true 
  // B (position 7) = Self billed invoice
  _Exports = 'Exports';
  _self_billing = 'Self Billing';
  _none = 'None';
  _third_party = 'Third Party';
  _nominal = 'Nominal';
  _summary = 'Summary';
  inv_sub_types = [
    { id: '00000', name: 'None' },
    { id: '10000', name: 'Third Party' },
    { id: '01000', name: 'Nominal' },
    { id: '00010', name: 'Summary' },
    { id: '00100', name: 'Exports' },
    { id: '00001', name: 'Self Billing' }
  ];
  inv_sub_type = '00000';
  xmlPath = '';
  branch_code = '';
  mode = {
    1: "production",
    2: "simulation",
    3: "development",
  }
  branch_e_invoice_enabled = false;
  inv_type = 1;
  cmpny: any;
  einv_simplfied_print_style = 1;
  invoiceId: any;
  invoice_type: any;
  item_disc: any=0;
  g_settings: any;
  generalSearch_type: any;
  headerSalesEinvoiceLink: any;
  draftList: any;
  draft_id: any
  purchret_note: any;
  publicPath = '';
  usr_block_pre_date_select: any;
  minDate: Date;
  constructor(private apiService: ApiService, private coreService: CoreService,
    private datePipe: DatePipe, private modalService: NgbModal,private translate: TranslateService,private ActiveRoute: ActivatedRoute) { }

  ngOnInit() {
    this.getGeneralSetting();
    this.cur_lang = sessionStorage.getItem("baseLang");
     this.aliasChecked()
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
      this.aliasChecked()
    })

    this.apiService.getClientSettingBykey({ key: 'Batch Support' }).subscribe((res) => {
      if (res['data']) {
        this.checkBatch = (res['data']['cs_value']) ? true : false;
      }
    });
    
    this.cmpny = this.coreService.getUserData('cmpny');
    this.branch_code = this.coreService.getUserData('branch_code');
    this.publicPath = (this.apiService.PHP_API_SERVER.indexOf('127.0.0.1') !== -1) ? '' : '/public';
    this.xmlPath = this.apiService.PHP_API_SERVER + this.publicPath + '/xml/' + this.cmpny + '/' + this.branch_code + '/' ;
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.headerSalesEinvoiceLink = this.ActiveRoute.snapshot.paramMap.get('einvoice_pending');
    if (this.headerSalesEinvoiceLink && this.headerSalesEinvoiceLink==1){
      this.invoice_type=2; 
    }else{
      this.invoice_type=3;
    }
    this.branch_e_invoice_enabled = this.coreService.getUserData('is_e_invoice');

    this.apiService.getClientSettingBykey({ key: 'enable_e_invoice' }).subscribe((res) => {
      if (res['data']) {
        this.eInvoiceSupport = ( (res['data']['cs_value'] ? true : false) && this.branch_e_invoice_enabled) ? true : false;
        this.inv_types = [
          { id: 1, name: this._tax_invoice},
          { id: 2, name: this._simplified_tax_invoice},
        ];
      }
      this.getReturnedList();
    });
    this.sales_print_name=1;
    this.up = this.coreService.getUserPrivilage();
    $("#purchase-ret").hide();
  
    this.getPurchaseReturn();

    this.getsaveTempPurchaseReturn();
    this.getTemplete();
    this.apiService.getAllTaxCategory().subscribe((resp) => {
      this.taxCategories = resp.data;
    });
    this.getSalesSetting();
    this.country_dec=this.coreService.setDecimalLength();

    this.usr_block_pre_date_select = this.coreService.getUserData("usr_block_pre_date_select");
    if(this.usr_block_pre_date_select){
      this.minDate= new Date();
    }else{
      this.minDate= new Date(this.coreService.getUserData("branch_open_date"));
    }


//  if (this.headerSalesEinvoiceLink && this.headerSalesEinvoiceLink==1){
//       this.invoice_type=2
//       this.Select_invoiceType(this.invoice_type);
//     }
  }

  aliasChecked() {
    if (this.cur_lang !== undefined && this.cur_lang !== null && this.cur_lang !== 'English') {
      this.prd_stock_status = this.prd_stock_statusUnchecked;
    } else {
      this.prd_stock_status = this.prd_stock_statusChecked;
    }
  }
  
  langChange(){
    this.translate.get(['Common.With_invtry', 'Common.Without_invtry','HOME.credit', 'Common.cash','Common.last_yr','HOME.Bank',
    'Common.tax_invoice', 'Common.simplified_tax_invoice','Common.none','Branch.third_party','Branch.nominal','Branch.exports','Common.summary','Branch.self_billing'
    ]).subscribe((res: string) => {    
     
      this.inventoryType = [
        {id: 0, value: res['Common.With_invtry']},
        {id: 1, value: res['Common.Without_invtry']},
      ];
      this.pay_type = [
        { id: 1, name: res['HOME.credit']},
        { id: 2, name: res['Common.cash'] },
        { id: 3, name: res['HOME.Bank'] }
      ];

      this._tax_invoice = res['Common.tax_invoice'];
      this._simplified_tax_invoice = res['Common.simplified_tax_invoice'];
      this.inv_types = [
        { id: 1, name: this._tax_invoice},
        { id: 2, name: this._simplified_tax_invoice},
      ];

      this._Exports = res['Branch.exports'];
      this._self_billing = res['Branch.self_billing'] ;
      this._none = res['Common.none'];
      this._third_party = res['Branch.third_party'];
      this._nominal = res['Branch.nominal'];
      this._summary =  res['Common.summary'];
      this.inv_sub_types = [
        { id: '00000', name: this._none},
        { id: '10000', name: this._third_party },
        { id: '01000', name: this._nominal },
        { id: '00010', name: this._summary }
      ];
      if (this.inv_type == 1) {
        this.inv_sub_types.push({ id: '00100', name:  this._Exports });
        this.inv_sub_types.push({ id: '00001', name: this._self_billing});
      }
     
    });

  }
  getSalesSetting() {

    this.apiService.getSalesSetting().subscribe((res) => {
      if (res['data']) {        
        this.sales_print_name=res['data'].sales_print_name;
        this.einv_simplfied_print_style = res['data'].einv_simplfied_print_style;
      } else {
        this.sales_print_name=1;
      }
    });
  }
  getTemplete() {

    this.apiService.getSettings().subscribe((res) => {

      this.savedTemplate = {
        title_text: res.data.prnt_title_text,
        color: '#000',
        email_color: '#000',
        font_size: '12',
        orientation: "P",
        paper: 'A4-P',
        m_left: '',
        m_top: '',
        m_right: '',
        m_bottom: '',
        logo: res.data.prnt_header_logo,
        header_type: res.data.prnt_header_type,
        header_img: [],
        header_all_page: false,
        header_due: false,
        sl_no: false,
        item_desc: false,
        tax_detail: false,
        tax_type: 0,
        name_type: 'N',
        footer_text: res.data.prnt_display_footer_text
      }
      if (res.data.prnt_header_logo) {
        this.logoImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_logo_img_path;
      } else {
        this.logoImgUrl = '';
      }

      if (res.data.prnt_header_type == 2) {
        this.tempImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_img_path;
      } else {
        this.tempImgUrl = '';
      }

    });
  }


  //form search  ledger
  searchLedger(search: string) {
    let searchval = new FormData();
    searchval.append("supp_name", search);
    // if(this.eInvoiceSupport){
    //   searchval.append('einvoice', '1');
    // }
    this.apiService.searchSupplier(searchval).subscribe((res) => {
      this.ledger_all = res['data'];

    });
  }
  searchLedgerAlias(search: string) {
    let searchval = new FormData();
    searchval.append("supp_name", search);
    searchval.append('with-alias','1'); 
    if(this.eInvoiceSupport){
      searchval.append('einvoice', '1');
    }
    this.apiService.searchSupplier(searchval).subscribe((res) => {
      this.ledger_all = res['data'];

    });
  }

  purchaseReturn(content) {
    this.inv_sub_type = '00000';
    this.inv_type = 1;
    this.selecTed = null;
    this.availableQty = 0;
    this.purchstock='';
    this.selunit = null;
    this.purchret_discount = 0;
    this.prdSel= null;
    this.modalRef = this.modalService.open(content, { size: 'xl',backdrop: 'static' });
    this.modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }
  changePayType(){
    this.searchDefaultLedger('');
  }
  searchDefaultLedger(search: string) {
    const searchval = new FormData();
    searchval.append('ledger_name', search);
    if(this.purchret_type==2){
      searchval.append('ledger_grp', '3');
    }else if(this.purchret_type==3){
      searchval.append('ledger_grp', '4');
    }
    
    this.apiService.searchLedgerByAccGroup(searchval).subscribe((res) => {
      this.defLedger = res['data'];
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  searchProducts(search: string, gdid: any) {
    this.prd_all = [];
    let searchval = new FormData();
    if (search) {
      searchval.append("keyword", search);
      searchval.append("gd_id", this.gdwnid);
      searchval.append("search_type", this.prd_stock_status);
    }
    this.apiService.getProdByNamePrret(searchval).subscribe((res) => {
      this.prd_all = res['data'];
    });
  }

  // search godown
  searchGodown(search: string) {
    let searchval = new FormData();
    searchval.append("gd_name", search);
    this.apiService.getGodownByName(searchval).subscribe((res) => {
      this.godown_all = res['data'];
      this.godown_all.push({ "gd_id": 0, 'gd_name': 'Shop' });

    });
  }


  resetItemsearch() {
    this.vantran_purch_price = 0;

    this.prd_barcode = '';
    this.default_unit = [];
    this.vantransub_qty = 0;
  }

  selItem(prdSel: any) {
    console.log("shameer test");
    console.log(prdSel);
    $('#purchret-item').show();
    this.selecTed = prdSel;
    console.log(prdSel);
    if (this.selecTed) {
      this.selBatch=0;
      this.selecTed.purchsub_id =0;
      this.batches = this.selecTed.batches;
      this.prd_tax_cat_id = this.selecTed.prd_tax_cat_id;
      this.seltaxamount = Number(this.selecTed.bs_prate) * Number(this.selecTed.prd_tax) / 100;
      this.boxqty = this.selecTed.avialble_qty;
      this.availableQty = this.selecTed.avialble_qty;
      this.selunit = this.selecTed.base_unit;

      if(this.selecTed.prd_default_unit_id){

        let searchval = new FormData();
        searchval.append("unit_id", this.selecTed.prd_default_unit_id);
       this.apiService.getUnit(searchval).subscribe((res) => {
      // this.godown_all = res['data'];
      // this.godown_all.push({ "gd_id": 0, 'gd_name': 'Shop' });
      // console.log("res");
      // console.log(res);
      this.selunit = res;

      this.selUnit(this.selunit);

    });
      

      }

      this.punitname = this.selecTed.base_unit.unit_code;
      this.punitqty = this.selecTed.base_unit.unit_base_qty;
      this.availableQty = Number(this.selecTed.avialble_qty) / Number(this.selecTed.base_unit.unit_base_qty);
      this.updatepurchaseprice = this.selecTed.bs_prate;
      this.updatepurchasepricetot = this.selecTed.bs_prate * this.selecTed.avialble_qty;
      $('#purchstock').focus();
      $('#purchstock').select();
      // this.selecTed = null;
    } else{
      $('#purchret-item').hide();
    }
  }

  appendItem(result) {
    this.errObjArr1 = 0;
    
    this.item_disc=this.item_disc?this.item_disc/this.punitqty:0;

    if (this.availableQty >result.purchsub_rem_qty) {

      $('#purchstock').css("border", "1px solid red");
      this.errObjArr1 = 1;

    } 
    if (this.errObjArr1 > 0) {
    }
    else {
    
      this.selecTed.avialble_qty = $('#purchstock').val();
   
    this.validateForm();
    
    if (this.gdwnid == undefined)
      this.gdwnid = 0;
    if (this.selecTed.batches.length > 0) {
      this.expiry = this.selecTed.batches[0].sb_expiry_date;
    }
    else {
      this.expiry = 'nil';
    }

    this.slno = (this.items.length) + 1;
    this.calcUnit();
    //  rate calculation
    this.purchpricesingleorbox = $('#purchprice').val();
    if (this.itemtype == 'piece') {
      this.selecTed.bs_prate = this.purchpricesingleorbox;
    } else {
      this.selecTed.bs_prate = Number(this.purchpricesingleorbox) / Number(this.punitqty);
    }
    // end rate calc
    //  cal tax
    this.purchretsub_tax = 0;
    this.purchretsub_tax_peritem = 0;
    // tax percentage of single item
    // this.purchretsub_tax_peritem = $('#purchtaxper').val();
    const selTax = this.taxCategories.filter((taxCat) => taxCat.taxcat_id === this.prd_tax_cat_id);
    this.selecTed.prd_tax = this.purchretsub_tax_peritem = selTax[0].taxcat_tax_per;
    // tax value of single item

    if(this.selecTed.purchsub_id > 0){
    
      
      this.purchretsub_rate = this.updatepurchaseprice/this.selunit.unit_base_qty;
      this.purchretsub_taxvalue_peritem = Number(this.selecTed.purchsub_rate) * (Number(this.purchretsub_tax_peritem) / 100);
      this.stock_id =this.selecTed.purchsub_stock_id;
      this.branch_stock_id =this.selecTed.purchsub_branch_stock_id;
    }
    else{
      this.purchretsub_rate = this.updatepurchaseprice/this.selunit.unit_base_qty;
      this.purchretsub_taxvalue_peritem = Number(this.selecTed.bs_prate-this.item_disc) * (Number(this.purchretsub_tax_peritem) / 100);
      this.stock_id =this.selecTed.bs_stock_id;
      this.branch_stock_id =this.selecTed.branch_stock_id;
    }
    


    this.purchretsub_tax = Number(this.boxqty) * Number(this.purchretsub_taxvalue_peritem);
    console.log("this.purchretsub_tax");
    console.log(this.purchretsub_tax);
    //  end tax calc   
    // console.log(this.selecTed.prd_id);
    // console.log(this.punitname);
    // console.log(this.selecTed.bs_prate);   
    // console.log(this.selecTed.prd_tax);     
    this.resDup = this.coreService.checkDuplicateValPchseret(this.items, this.selecTed.prd_id, this.selecTed.bs_prate, this.punitname, this.selecTed.prd_tax);
    if (this.selecTed.prd_id != undefined && this.printError.length <= 0) {
      
     
      if (this.resDup == undefined) {
        this.items.push({
          "sl_no": this.slno, "purchretsub_prd_id": this.selecTed.prd_id, "itemname": this.selecTed.prd_name, "purchretsub_stock_id":  this.stock_id,
          "purchretsub_branch_stock_id": this.branch_stock_id, "purchretsub_batch_id": this.selBatch, "purchretsub_qty": this.boxqty,
          "purchretsub_rate": this.purchretsub_rate, 
          "purchretsub_disc": this.item_disc, 
          // "purchretsub_unit_id": this.selecTed.prd_base_unit_id,
          "purchretsub_unit_id": this.selunit.unit_id,
          "purchretsub_tax": this.purchretsub_tax, "purchretsub_tax_per": this.selecTed.prd_tax, "prd_barcode": this.selecTed.prd_barcode,
          "punitqty": this.punitqty, "punitname": this.punitname, "boxqty": this.boxqty,
          "purchretsub_tax_peritem": this.purchretsub_taxvalue_peritem,
          "itemtype": this.itemtype, "baseunit": this.baseunit, "totbox": this.totbox, "updatepurchaseprice": this.updatepurchaseprice * this.selecTed.avialble_qty,"purchretsub_taxcat_id":this.prd_tax_cat_id,
           "purchsub_id":this.selecTed.purchsub_id
        });
        this.itemsreturn.push({
          "sl_no": this.slno, "purchretsub_prd_id": this.selecTed.prd_id, "purchretsub_stock_id":  this.stock_id,
          "purchretsub_branch_stock_id": this.branch_stock_id, "purchretsub_batch_id": this.selBatch, "purchretsub_qty": this.boxqty,
          "purchretsub_rate": this.purchretsub_rate-this.item_disc, 
          "purchretsub_disc": this.item_disc, 
          // "purchretsub_unit_id": this.selecTed.prd_base_unit_id,
          "purchretsub_unit_id": this.selunit.unit_id,
          "purchretsub_tax": this.purchretsub_tax, "purchretsub_tax_per": this.selecTed.prd_tax, "purchretsub_expiry": this.expiry, "purchretsub_taxcat_id":this.prd_tax_cat_id,
          "purchsub_id":this.selecTed.purchsub_id
        });
      } else {
        // console.log('already exists');
        //items
        var existingArray = [];
        var slNo = this.coreService.findItemRet(this.items, this.selecTed.prd_id, this.selecTed.bs_prate, this.punitname, this.selecTed.prd_tax);
        // console.log(slNo);
        // console.log(this.updatepurchaseprice);
        //base unit
        //quantity
        this.items.find(x => x.sl_no === slNo).purchretsub_qty = Number(this.items.find(x => x.sl_no === slNo).purchretsub_qty) + Number(this.boxqty);
        this.itemsreturn.find(x => x.sl_no === slNo).purchretsub_qty = Number(this.itemsreturn.find(x => x.sl_no === slNo).purchretsub_qty) + Number(this.boxqty);
        //item price    
        this.items.find(x => x.sl_no === slNo).updatepurchaseprice = Number(this.items.find(x => x.sl_no === slNo).purchretsub_qty) * Number(this.selecTed.bs_prate);

        // tax amount
        console.log("before : "+this.items.find(x => x.sl_no === slNo).purchretsub_tax);

        console.log(this.purchretsub_tax);
        console.log("number: "+Number(this.purchretsub_tax));
        this.items.find(x => x.sl_no === slNo).purchretsub_tax = Number(this.items.find(x => x.sl_no === slNo).purchretsub_tax) + Number(this.purchretsub_tax);
        this.itemsreturn.find(x => x.sl_no === slNo).purchretsub_tax = Number(this.itemsreturn.find(x => x.sl_no === slNo).purchretsub_tax) + Number(this.purchretsub_tax);
        console.log("after : ");
        console.log(this.items);
        
        //der unit
        //box qty piece
        this.items.find(x => x.sl_no === slNo).boxqty = Number(this.items.find(x => x.sl_no === slNo).boxqty) + Number(this.boxqty);

        // box nos
        this.items.find(x => x.sl_no === slNo).totbox = Number(this.items.find(x => x.sl_no === slNo).totbox) + Number(this.totbox);

      }

      
    
     if(this.selecTed.purchsub_id > 0){
 
       this.saleData.items.forEach((value, i) => {
         if (value.purchsub_id == result.purchsub_id) {
   
           // this.saleData.items[i].salesub_rem_qty -= this.qty_rtn;
   
           if (this.saleData.items[i].salesub_rem_qty == 0) {
             //this.saleData.sales_sub.splice(i, 1);
             this.saleData.items[i].purchsub_flag = 0
           }
           else {
             this.saleData.items[i].purchsub_flag = 0
           }
   
         }
       });
 
     }

      // console.log(this.itemsreturn);
      this.listRefresh();
      this.refreshAll();

    setTimeout(() => {
            if (this.draft_id && this.draft_id .value != null) {
              this.purchaseSave(this.draft_id , 'auto');
            } else {
              $('#autosave').click();
            }
      }, 2000);
      
      this.selecTed = null;
      this.prdSel= null;
      this.availableQty = 0;
      this.purchstock='';
      this.selunit = null;
    }
    
    this.purch_return= false;

  }

  }

  // removeItem(data) {
  //   console.log("data");
  //   console.log(data);
  //   var remopstk = confirm("Remove this Item");
  //   if (remopstk) {
  //     var type = data.sl_no,
  //       i;
  //     for (i = this.items.length - 1; i >= 0; --i) {
  //       if (this.items[i].sl_no == type) {
  //         this.items.splice(i, 1);
  //       }
  //     }
  //     for (i = this.itemsreturn.length - 1; i >= 0; --i) {
  //       if (this.itemsreturn[i].sl_no == type) {
  //         this.itemsreturn.splice(i, 1);
  //       }
  //     }
  //   }

  //   this.saleData.items.forEach((value, k) => {



  //     if (value.purchsub_id == data.purchsub_id) {
  //       this.saleData.items[k].purchsub_flag = 1;

  //       // this.saleData.items[k].salesub_rem_qty = Number(this.saleData.items[k].salesub_rem_qty * 1) + Number(data.qty_rtn * 1);
  //     }
  //     console.log("data.purchsub_id");
  //     console.log(data.purchsub_id);

  //     console.log("value.purchsub_id");
  //     console.log(value.purchsub_id);

  //     console.log("this.saleData.items[k].purchsub_flag");
  //     console.log(console.log(this.saleData.items[k].purchsub_flag)); 
  //   });

   


  //   this.selecTed = {};
  //   this.listRefresh();
  //   this.refreshAll();

  // }
  removeItem(data) {
    console.log("data");
    console.log(data);

    const remopstk = confirm("Remove this Item");
    if (remopstk) {
        const type = data.sl_no;
        
        // Ensure items and itemsreturn arrays are defined
        if (this.items) {
            for (let i = this.items.length - 1; i >= 0; --i) {
                if (this.items[i].sl_no === type) {
                    this.items.splice(i, 1);
                }
            }
        }
        
        if (this.itemsreturn) {
            for (let i = this.itemsreturn.length - 1; i >= 0; --i) {
                if (this.itemsreturn[i].sl_no === type) {
                    this.itemsreturn.splice(i, 1);
                }
            }
        }
    }

    // Check if saleData and saleData.items are defined before iterating
    if (this.saleData && this.saleData.items) {
        this.saleData.items.forEach((value, k) => {
            if (value.purchsub_id === data.purchsub_id) {
                this.saleData.items[k].purchsub_flag = 1;

                // Uncomment this line if needed
                // this.saleData.items[k].salesub_rem_qty = Number(this.saleData.items[k].salesub_rem_qty * 1) + Number(data.qty_rtn * 1);
            }
            console.log("data.purchsub_id", data.purchsub_id);
            console.log("value.purchsub_id", value.purchsub_id);
            console.log("this.saleData.items[k].purchsub_flag", this.saleData.items[k].purchsub_flag);
        });
    }

    this.selecTed = {};
    this.listRefresh();
    this.refreshAll();
}

  getsaveTempPurchaseReturn() {

    this.apiService.getsaveTempPurchaseReturn('').subscribe((res) => {
      this.draftList = res;
      this.purchret_amount = Number(res.purchret_amount);
      // this.prch_tax = Number(res.purch_tax);

    });
  }
  removePurchaseFromDraft(purchret_id) {
    let searchval = new FormData();
    searchval.append("purchret_id", purchret_id);
    this.apiService.removeFromDraftReturn(searchval).subscribe((res) => {
      this.getsaveTempPurchaseReturn();
    });
  }
  removeFromDraftReturn(search) {

    var remopstk = confirm("Are you sure you want to delete this purchase from draft ? ");
    if (remopstk) {

      let searchval = new FormData();
      searchval.append("purchret_id", search);
      this.apiService.removeFromDraftReturn(searchval).subscribe((res) => {
        this.getsaveTempPurchaseReturn();

      });

    }
  }


  purchaseReturnSub(formdata: { value: any; }) {
    this.resultobj = [];

    if (formdata.value.purchret_date == undefined) {
      this.resultobj.purchret_date = "t";
      this.resultobj.push({ 'purchret_date': 'Required' });
    }
    else {
      if (formdata.value.purchret_date > new Date()) {
        this.resultobj.purchret_date = "t";
        this.resultobj.push({ 'purchret_date': 'Required' });
      } else {
        this.resultobj.purchret_date = "f";
      }
    }
  
    if (this.resultobj.length <= 0) {
    // var precnfrm = confirm("Are you sure?");
    if (confirm("Are you sure?")) {
      this.pageLoadingImg = true;
      formdata.value.item_name = undefined;
      formdata.value.itebar = undefined;
      formdata.value.itenam = undefined;
      formdata.value.purchrate = undefined;
      formdata.value.purchstock = undefined;
      formdata.value.purchret_qty = undefined;
      formdata.value.selunit = undefined;
      formdata.value.purchtaxper = undefined;
      formdata.value.purchtaxamount = undefined;
      formdata.value.purchprice = undefined;
      formdata.value.purchret_retid = undefined;
      formdata.value.item_barcode = undefined;
      formdata.value.prd_stock_status = undefined;
      formdata.value.items = this.itemsreturn;
      formdata.value.purchret_gd_id = this.gdwnid;
      formdata.value.purchret_date = (formdata.value.purchret_date) ?
      this.apiService.formatDate(formdata.value.purchret_date) : formdata.value.purchret_date;
      this.apiService.purchaseReturn(formdata.value).subscribe((res: any) => {

        let isError = false;
        if(this.eInvoiceSupport){
          if(res.error && res.error['error']){
            this.coreService.showMessage(res.error['error'], 4000);
            isError = true;
            this.pageLoadingImg = false;
          }
          if(res.message && res.message.preview){
            this.coreService.showMessage(res.message.msg, 4000);
          }
        }
      
        if (this.draft_id) {
          this.removePurchaseFromDraft(this.draft_id);
        }

        if(!isError){
          this.pageLoadingImg = false;
          if (res.error != null) {
            if (typeof res.is_cust_err !== 'undefined') {
              this.coreService.showMessage(res.error,6000);
            }else{
              this.resultobj = res.error;
              this.resultobj = this.coreService.getValidation(this.resultobj);
            }
            
          }
          else {
            this.resultobj = {};
            this.coreService.createfunct(formdata, this.resultobj, res.message);
            this.netamount = null;
            this.modalRef.close();
            this.items = [];
            this.itemsreturn = [];
            this.selecTed = {};
            this.netqty = 0;
            this.netprice = 0;
            this.netamount = 0;
            this.nettax = 0;
            this.netretamount = 0;
            this.vantransub_qty = 0;
            this.is_without_stock = 0; 
            this.prd_stock_statusUnchecked = "alias";
            this.prd_stock_statusChecked = "name";
            this.prd_stock_status = "name";
            this.getPurchaseReturn();
            this.getReturnedList();
            this.date = new Date();
          }
        }
        this.apiService.updateEinvoiceNotificationChange();
      });
      this.apiService.updatebatchExNotification();
    }
    this.ref_num_search = 0;
  }
  }

  selGd(gdid) {
    this.gdwnid = gdid;
    this.items = [];
    this.prd_all = [];
    this.prdSel = "Select Product";
    this.listRefresh();
    this.refreshAll();
    //}
  }

  RateChange(result){
    console.log("result");
    console.log(result);
    if(result.purchsub_id > 0)
    {

      this.selunit =result.purchased_unit;
      this.selecTed.units = result.units;
      this.selecTed.avialble_qty = $('#purchstock').val();
      this.availableQty = Number(this.selecTed.avialble_qty) * Number(result.punit_base_qty);

    }
   
  }

  selUnit(selunit) {

    console.log(this.selecTed);
    if (selunit) {

      if(this.selecTed.purchsub_id > 0)
      {

        console.log(selunit);
        this.availableQty = 0;
        this.punitname = selunit.unit_code;
        this.punitqty = selunit.unit_base_qty;
        this.selecTed.bs_prate =this.selecTed.purchsub_rate;
        this.selecTed.avialble_qty = $('#purchstock').val();
        console.log("this.selecTed.avialble_qty");
        console.log(this.selecTed.avialble_qty);
  
  
        this.availableQty = Number(this.selecTed.avialble_qty) * Number(selunit.unit_base_qty);
        console.log("this.availableQty");
        console.log(this.availableQty);
        //this.existpurchaseprice = $('#purchrate').val();
       
      }
      else{

        // console.log(selunit);
      this.availableQty = 0;
      this.punitname = selunit.unit_code;
      this.punitqty = selunit.unit_base_qty;


      this.availableQty = Number(this.selecTed.avialble_qty) / Number(selunit.unit_base_qty);
      //this.existpurchaseprice = $('#purchrate').val();
    

      }

      this.updatepurchaseprice = Number(this.punitqty) * Number(this.selecTed.bs_prate);
      // console.log(this.updatepurchaseprice);
      this.purchstock = $('#purchstock').val();
      this.updatepurchasepricetot = Number(this.updatepurchaseprice) * this.purchstock;
      $('#purchprice').val(this.updatepurchaseprice);
     
    }
  }

  calpurchPrice(inpvalue) {
    this.updatepurchaseprice = (Number(this.punitqty)) * (Number(inpvalue));
    this.updatepurchasepricetot = Number(this.updatepurchaseprice) * this.purchstock;

  }

  addPurch(inptext) {
    var purchtaxamount = (Number($('#purchrate').val()) * (inptext / 100)).toFixed(2);
    $('#purchtaxamount').val(purchtaxamount);
  }

  validateForm() {
    this.printError = [];
    var purchstock = $('#purchstock').val();
    if (purchstock == "" || purchstock == undefined) {
      this.errObjArr.pstkErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });
    }
    else {
      this.errObjArr.pstkErr = "f";
    }


  }
  calPrice() {
    this.totprice = 5;
  }

  calcUnit() {
    console.log("selected ");
    console.log(this.selecTed) ;
    if(this.selecTed.purchsub_id > 0)
    {
      this.baseunit = this.selecTed.baseunit.unit_code;
    }
    else{
      this.baseunit = this.selecTed.base_unit.unit_code;
    }
   
    this.avlqty = $('#purchstock').val();
    if (this.punitqty == 1) {
      this.itemtype = "piece";
      this.boxqty = this.avlqty;
      this.totbox = this.avlqty;
    } else {
      this.itemtype = "box";
      this.boxqty = this.avlqty * this.punitqty;
      this.totbox = this.avlqty;
    }

  }


  listRefresh() {
    this.netqty = 0;
    this.netprice = 0;
    this.netamount = 0;
    this.nettax = 0;
    this.netretamount = 0;
    for (var j = 0; j < this.items.length; j++) {
      this.netprice = Number(this.netprice) + Number(this.items[j]['purchretsub_rate']);
      this.netamount = Number(this.netamount) + (Number(this.items[j]['purchretsub_rate']-this.items[j]['purchretsub_disc']) * Number(this.items[j]['purchretsub_qty']));
      this.nettax = Number(this.nettax) + Number(this.items[j]['purchretsub_tax']);
      this.netqty = Number(this.netqty) + Number(this.items[j]['purchretsub_qty']);
    }
    this.netretamount = (Number(this.netamount) + Number(this.nettax) - Number(this.purchret_discount));
    if (this.items.length > 0) {
      $("#purchase-ret").show();
    } else {
      $("#purchase-ret").hide();
    }

  }


  refreshAll() {
    this.selecTed.units = [];
    this.selecTed = [];
    this.selunit = "Select unit";
    this.prd_all = [];
    this.prdSel = "Select product";
    this.punitname = undefined;
    this.punitqty = undefined;
    this.item_disc = 0;
    this.seltaxamount = 0;
    this.boxqty = 0;
    $('#purchstock').val('');
    $('#purchret-item').hide();
    this.selecTed.avialble_qty = '';
    this.godown_all = [{ "gd_id": 0, 'gd_name': 'Shop' }];
    // this.purchret_gd_id = 0;
  }

  barcodeSearch(search: string) {
    let searchval = new FormData();
    searchval.append("keyword", search);
    this.apiService.getPurchaseRetbyBcode(searchval).subscribe((res) => {

      if (res['data'][0]) {
        this.selecTed = res['data'][0];
        this.prd_tax_cat_id = this.selecTed.prd_tax_cat_id;
        this.seltaxamount = Number(this.selecTed.bs_prate) * Number(this.selecTed.prd_tax) / 100;
        this.boxqty = this.selecTed.avialble_qty;
        this.availableQty = this.selecTed.avialble_qty;
        this.selunit = this.selecTed.base_unit;

        this.punitname = this.selecTed.base_unit.unit_code;
        this.punitqty = this.selecTed.base_unit.unit_base_qty;
        this.availableQty = Number(this.selecTed.avialble_qty) / Number(this.selecTed.base_unit.unit_base_qty);
        this.updatepurchaseprice = this.selecTed.bs_prate;
        this.updatepurchasepricetot = this.selecTed.bs_prate * this.selecTed.avialble_qty;
        this.selecTed.purchsub_id =0;

        $('#purchret-item').show();
        $('#purchstock').focus();
        $('#purchstock').select();
        this.item_barcode = '';
      } else {
        $('#purchret-item').hide();
      }
    });
  }


  // purchase ret no. generation
  getPurchaseReturn() {
    let searchval = new FormData();
    this.apiService.getPurchaseReturn(searchval).subscribe((res) => {
      this.purchaseretno = res['data'].purch_ret_num;
    });
  }

  getReturnedList() {
    let searchval = new FormData();
    if(this.invoice_type &&this.eInvoiceSupport){
      searchval.append("invoiceType_id",this.invoice_type);
    }
   
    this.apiService.listPurchaseReturns(searchval, 1).subscribe((res: any) => {
      this.retItems = res['data']['data'];
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.headerSalesEinvoiceLink=null;
    });
  }
  changeBool() {
    if (this.changeBox == 1) {
      this.changeBox = 0;
    } else {
      this.changeBox = 1;
    }

  }
  purchaseSave(formdata: { value: any; }, savetype) {
    console.log(formdata.value);
    
    // this.updateTotalAmount();
   
    formdata.value.purchret_amount = this.netretamount;
    formdata.value.purchret_tax = this.nettax;
    formdata.value.purchret_discount = this.purchret_discount;




    formdata.value.items = this.items;
    this.apiService.saveTempPurchaseReutn(formdata.value).subscribe((res) => {
      this.draft_id = res;
      this.pageLoadingImg = false;
      this.getsaveTempPurchaseReturn();

      if (savetype == 'manual') {
        this.coreService.showMessage('Saved To Draft');
      }

    });
    
  }
  getTempPurchaseReturn(purchret_id) {
    let searchval = new FormData();
    console.log('draftList',this.draftList);
    
    searchval.append("purchret_id", purchret_id);

    this.apiService.getTempPurchaseReturn(searchval).subscribe((res: any) => {
      console.log(' res.items', res);
      this.items=[];
      this.ledger_all=res.supplier;
      this.purchret_supp_id=res.purchret_supp_id;
      this.defLedger=res.acc_ledger
      this.purch_acc_ledger_id=res.purchret_acc_ledg_id
      this.purchret_type= res.purchret_pay_type;
      this.gdwnid=res.purchret_gd_id
      this.purchret_supp_retno=res.purchret_no
      this.purchret_supp_inv_no=res.purchret_inv_no
      this.purchret_note=res.purchret_note
      this.purchret_discount=res.purchret_discount
      this.is_without_stock=res.purchret_is_without_stock
      console.log(res);
      console.log(res.supplier);
      
      console.log(this.purchret_supp_id);
      
      res.items.forEach((element, i) => {
        // res.data.items[i].purchretsub_qty = this.boxqty;
        // res.data.items[i].purch_item_total_without_item_disc = Number(element.purchaserateperbox);
        // res.data.items[i].purchsub_disc = 0;
        // res.data.items[i].bsunit_qty = 1;

     
        // if(element.purchsub_rebate_amount == undefined){
        //   res.data.items[i].purchsub_rebate_amount =0;
        // }
        // res.data.items[i].price_vat_ttl
        let tmp = <any>{};
        tmp.purchretsub_prd_id = element.purchretsub_prd_id;
        tmp.sl_no = i+1;
        tmp.itemname = element.prd_name;
        tmp.prd_barcode = element.prd_barcode;
        tmp.purchretsub_stock_id = element.purchretsub_stock_id;
        tmp.purchretsub_branch_stock_id = element.purchretsub_branch_stock_id;
        tmp.purchretsub_batch_id = element.purchretsub_batch_id;
        tmp.purchretsub_qty = element.purchretsub_qty;
        tmp.purchretsub_rate = element.purchretsub_rate;
        tmp.purchretsub_disc = element.purchretsub_disc;
        tmp.purchretsub_unit_id = element.purchretsub_unit_id;
        tmp.purchretsub_tax = element.purchretsub_tax;
        tmp.purchretsub_tax_per = element.purchretsub_tax_per;
        tmp.purchretsub_expiry = element.purchretsub_expiry;
        tmp.purchretsub_taxcat_id = element.purchretsub_taxcat_id;
        tmp.purchsub_id = element.purchretsub_id;
        tmp.punitqty = element.unit_base_qty;
        tmp.punitname = element.unit_code;
        tmp.boxqty = element.purchretsub_qty;
        tmp.purchretsub_tax_peritem =  Number(element.purchretsub_rate) * (Number(element.purchretsub_tax_per) / 100);
        tmp.itemtype = element.unit_name;
        tmp.baseunit = element.unit_code;
        tmp.totbox = element.purchretsub_rate;
        tmp.updatepurchaseprice = (element.purchretsub_rate)*(element.purchretsub_qty);
        this.items.push(tmp);
        // this.itemsreturn.push({
       
       
      
        //  
        //   
        // });
        let tmp2 = <any>{};
        tmp2.sl_no = i+1;
        tmp2.purchretsub_prd_id = element.purchretsub_prd_id;
        tmp2.purchretsub_stock_id = element.purchretsub_stock_id;
        tmp2.purchretsub_branch_stock_id = element.purchretsub_branch_stock_id;
        tmp2.purchretsub_batch_id = element.purchretsub_batch_id;
        tmp2.purchretsub_qty = element.purchretsub_qty;
        tmp2.purchretsub_rate = element.purchretsub_rate;
        tmp2.purchretsub_disc = element.purchretsub_disc;
        tmp2.purchretsub_unit_id = element.purchretsub_unit_id;
        tmp2.purchretsub_tax = element.purchretsub_tax;
        tmp2.purchretsub_tax_per = element.purchretsub_tax_per;
        tmp2.purchretsub_expiry = element.purchretsub_expiry;
        tmp2.purchretsub_taxcat_id = element.purchretsub_taxcat_id;
        tmp2.purchsub_id = element.purchretsub_id;
        this.itemsreturn.push(tmp2);
      });
     console.log(this.itemsreturn);
     console.log(this.items);
     
     
   this.listRefresh() 

      // this.items.forEach(row => {

      

      //   this.items.forEach((row, index) => {
      //     if(row.enable_item_serial_no == false){
      //       // row.Serial = [];
      //       this.items[index].Serial = [];
      //     }

          
      //   });

       
      // });


      this.draft_id = res.draft_id;
      // this.supp_id = Number(res.data.purch_supp_id);
      // this.purch_inv_no = res.data.purch_inv_no;
      // this.stkprdselbc = res.data.stkprdselbc;
      // this.purch_note = res.data.purch_note;
      // this.freight_type =res.data.freight_type;

      // this. getSupBalance(this.supp_id, null);

      // setTimeout(() => {
      //   this.round_sign = res.data.purch_round_sign ? res.data.purch_round_sign : 1;
      //   this.roundoff = '' + res.data.purch_roundoff ? res.data.purch_roundoff : 0;
      // }, 3000);
      // this.discount = res.data.purch_discount ? res.data.purch_discount : 0;


      // this.purch_pay_type = Number(res.data.purch_pay_type);
      // if (res.data.purch_inv_date) {
      //   this.purch_inv_date = new Date(res.data.purch_inv_date);
      // } else {
      //   this.purch_inv_date = '';
      // }
      // this.date = new Date(res.data.purch_date);

      $('.purchase-ret').show();
      $('.save').show();
      $('.box').show();
      this.changeBool();
     
      this.refreshAll();
      $('.purchase-ret').show();

      var scrollBottom = $('.table-responsive').height();
      $('.scroll-table').scrollTop(300);

    });
    

  }

  
Select_invoiceType(id){

   
  if (id){
    this.invoiceId=id;
    let searchval = new FormData();
    searchval.append("invoiceType_id",this.invoiceId);
    this.apiService.listPurchaseReturns(searchval, 1).subscribe((res: any) => {
      this.retItems = res['data']['data'];
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });

  }
}

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  pageNext(formdata: { value: any; }, pageno: any) {
    let searchval = new FormData();
    if(this.invoice_type &&this.eInvoiceSupport){
      searchval.append("invoiceType_id",this.invoice_type);
    }
    this.apiService.listPurchaseReturns(searchval, pageno).subscribe((res: any) => {
      this.retItems = res['data']['data'];
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });

  }

  getPreview(id: any,gd_name) {
    const searchval = new FormData();
    searchval.append('purchret_id', id);
    this.apiService.getpurchaseRetDetails(searchval).subscribe((res) => {
      this.purchData = res['data'][0];
      this.purchDataItems = res['data'][0]['items']; 
      if(!res['data'][0].purchret_frieght)
      res['data'][0].purchret_frieght = 0;
      if(!res['data'][0].purchret_discount)
      res['data'][0].purchret_discount = 0;
      if(!res['data'][0].purchret_discount)
      res['data'][0].purchret_discount = 0; 
      this.gd_name = gd_name;

      if(!this.gd_name)
      this.gd_name = 'Shop';

      this.purchDataRetAmount = Number(res['data'][0].purchret_amount);
      this.purchData.purchret_amount = Number(res['data'][0].purchret_amount)-Number(res['data'][0].purchret_tax)
      +Number(res['data'][0].purchret_discount);
      this.qr_inv = res['data'][0].qr_link;
    });

  }

  purchase_search(search: string) {
   
    this.purch_return =true;
    this.pageLoadingImg = true;
    const searchval = new FormData();
    searchval.append('purch_id', search);
    this.apiService.getpurchaseDetails(searchval).subscribe((res) => {
      this.pageLoadingImg = false;

      console.log(res);

      if (res.error != null) {
        this.resultobj = res.error;
       
        this.saleData = {
          sales_id: null,
          sales_inv_no: null,
          error: null,
          message: null,
          data: null,
          items: []

        };
        this.resultobj = this.coreService.getValidation(this.resultobj);
      }

      else {
         this.showdata = true;
        this.resultobj = {};
        // this.purchaseItems = res.data;
        this.saleData = res.data;
        this.purchret_supp_id =res.data.purch_supp_id;
       // alert(res.data.items[0].purchsub_gd_id);
        this.searchLedger(res.data.supp_name);
        this.purchret_type =res.data.purch_pay_type;
        this.purch_acc_ledger_id =res.data.purch_acc_ledger_id;
        this.searchDefaultLedger(res.data.purch_acc_ledger_id);
        this.purchret_gd_id =res.data.items[0].purchsub_gd_id;
        this.purchret_supp_inv_no =res.data.purch_inv_no;
        if(res.data['cash_account'][0]){
          this.searchDefaultLedger(res.data['cash_account'][0].ledger_name);
        }
      
        console.log("fsddddddddddd");
        // console.log(res.data.items[0]['godown'].gd_name);
        if(res.data.items[0]['godown']){
          this.searchGodown(res.data.items[0]['godown'].gd_name);
        }
      
       
        this.ref_num_search = 1;
      }

    });
  }

  addQuantiy(item, i) {

    this.purch_return= true;
    $('#purchret-item').show();
    console.log("item");
   console.log(item);

   let searchval = new FormData();
    searchval.append("prd_id", item.purchsub_prd_id);
    // console.log(this.selecTed);
    // console.log(data);
    this.selunit =item.purchased_unit;
    this.apiService.getPurchaseProduct(searchval).subscribe((res) => {
      this.stkprdbc_all = res['data'];
      // console.log("this.stkprdbc_all");
      // console.log(this.stkprdbc_all.batches);
      this.selecTed.units = this.stkprdbc_all.units;
      this.selecTed.batches = this.stkprdbc_all.batches;
      this.batches = this.selecTed.batches;
      
    });
   this.selecTed =item;
   this.purchstock =item.purchased_qty;
  
   this.bs_prate =item.purchased_rate;
   this.updatepurchaseprice =item.purchased_rate;
   this.prd_tax_cat_id =item.purchsub_taxcat_id;

  //  this.batches = this.selecTed.batches
  //     this.prd_tax_cat_id = this.selecTed.prd_tax_cat_id;
  //     this.seltaxamount = Number(this.selecTed.bs_prate) * Number(this.selecTed.prd_tax) / 100;
        // this.boxqty = this.selecTed.purchsub_qty;
       this.availableQty = this.selecTed.purchsub_qty;
       this.remeQty = this.selecTed.purchsub_rem_qty;
      // this.selunit = this.selecTed.base_unit;

      // this.punitname = this.selecTed.baseunit.unit_code;
      this.punitname =this.selecTed.punit_code;
      //this.punitqty = this.selecTed.baseunit.unit_base_qty;
      this.punitqty = this.selecTed.punit_base_qty;
  //    this.availableQty = Number(this.selecTed.avialble_qty) / Number(this.selecTed.base_unit.unit_base_qty);
  //     this.updatepurchaseprice = this.selecTed.bs_prate;
  //     this.updatepurchasepricetot = this.selecTed.bs_prate * this.selecTed.avialble_qty;

  }

  removeItemGrid() {

    $('#purchret-item').hide();
  }

  clsmodl(){
    this.listRefresh();
    this.refreshAll();
  }

  updatePayment() {
    this.inv_sub_types = [
      { id: '00000', name: this._none},
      { id: '10000', name: this._third_party },
      { id: '01000', name: this._nominal },
      { id: '00010', name: this._summary }
    ];
    if (this.inv_type == 1) {
      this.inv_sub_types.push({ id: '00100', name:  this._Exports });
      this.inv_sub_types.push({ id: '00001', name: this._self_billing});
    }
  }

  eInvoiceResubmit(refno,i){
    if(confirm('Do you wish to continue?')){
      let tmp = {
        type: 'purchaseReturn',
        ref_no : refno
      }
      this.retItems[i].resubmitLoadingImg = true;
      this.apiService.eInvoiceResubmit(tmp).subscribe((res) => {
        this.retItems[i].resubmitLoadingImg = false;
        this.coreService.showMessage(res.msg, 4000);
        if (res.status) {
          this.getReturnedList();
          this.apiService.updateEinvoiceNotificationChange();
        }
      });
    }
  }
  
  getGeneralSetting() {

    this.apiService.getGeneralSetting().subscribe((res) => {

      if(res['data']){
        this.g_settings = res['data'];
        this.generalSearch_type = this.g_settings.g_s_search_type;
      }else{
        this.generalSearch_type=0;
      }
      
    });

  }


}
