import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { ProductionService } from '../../../../../service/production.service';
import * as XLSX from 'xlsx'; 
import { StockComponent } from 'src/app/view/stock/stock.component';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-rep-stock-expiry',
  templateUrl: './rep-stock-expiry.component.html',
  styleUrls: ['./rep-stock-expiry.component.css']
})
export class RepStockExpiryComponent implements OnInit {
  @ViewChild('exportall',{static: true}) reportContent: ElementRef;
  fileName= 'stock_expiry.xlsx';
  pageLoadingImg: boolean;
  curpage: any;
  lastpage: any;
  pgstart: boolean;
  pgend: boolean;
  datas: string[];
  elseBlocknostart: any;
  elseBlocknoend: any;
  date1: any;
  date2: any;
  period_type: string;
  prd_name: string;
  pid1: number;
  pid2: number;
  categories: string[];
  prd_formula: string;
  pcost1: any;
  pcost2: any;
  prate1: any;
  prate2: any;
  pamount1: any;
  pamount2: any;
  pcomm1: any;
  pcomm2: any;
  pmisc1: any;
  pmisc2: any;
  prod_id: any;
  prod_cost: any;
  prod_rate: any;
  prod_amount: any;
  prod_comm: any;
  prod_misc: any;
  prod_cat_id: any;
  tableCont: boolean;
  values: string[];
  isnoData : boolean;
  usr_id:any;
  filter:any;
  stkprd_all:any;
  status_id:any;
  sTatus:any;mfg1:any;mfg2:any;exp1:any;exp2:any;prd_id:any;prd_barcode;period_mfg:any;period_exp:any;prd_batchcode:any;
  filter_branch_id: number;
  branch_all: any;
  up: any;
  country_dec: string;
  exportLoader: boolean;
  Exprodsumms: any;
  cur_lang: string;
  branch_display_name:any;
  branch_display_code:any;
  branch_address:any;
  userType: any;

  slNo: number;
  godown_all: any;
  constructor(private productionService: ProductionService, private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe,private datePipe: DatePipe) { }

  ngOnInit() {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
   this.branch_display_code= this.coreService.getUserData('branch_code');
   this.branch_address = this.coreService.getUserData('branch_address');

    this.country_dec=this.coreService.setDecimalLength();
    this.up = this.coreService.getUserPrivilage();
    this.filter_branch_id = 1;
    this.getAllBranch();
   
    this.readProdSumm(1);  
    this.sTatus = [
      { id: 1, name: 'Expired' },
      { id: 2, name: 'Not Expired' }  
    ];  
    this.searchGdwn('');
    this.userType = this.coreService.getUserData('user_type');
    this.cur_lang = sessionStorage.getItem("baseLang");
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
  }
  langChange(){
    this.translate.get(['Common.Expired','Common.Not_Expired',
  
  ]).subscribe((res: string) => {

   
      this.sTatus = [
        { id: 1, name: res['Common.Expired'] },
        { id: 2, name: res['Common.Not_Expired']}
      ];
    });

  }
  exportexcel(): void 
  {
     /* table id is passed over here */   
     let element = document.getElementById('export-group'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.fileName);
     this.exportLoader = false;
    
  }


  exportEXCL(formdata: { value: any; }){
    
  this.exportLoader = true;
    // debugger;
    formdata.value.export = 'export';
    

    this.apiService.expSumm(formdata.value, 1).subscribe((res:any) => {
    
      
       


      formdata.value.export = '';

      this.Exprodsumms = res['data'];
     
 
 
    
   
    setTimeout(() => {
      this.exportexcel(); 
  }, 3000);
  

  });



  }
  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res) => {
      this.branch_all = res['data'];
      // this.filter_branch_id = res['selected'];
    });
  }

  searchGdwn(search: string) {
    let searchval = new FormData();
    searchval.append("gd_name", search);
    this.apiService.getGodownByName(searchval).subscribe((res) => {
      this.godown_all = res['data'];
      this.godown_all.push({ "gd_id": 0, 'gd_name': 'Shop' });
    });

  }
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
    });
  }
  readProdSumm(pageno) {
    this.pageLoadingImg = true;
    this.isnoData = false;
    let searchval = new FormData();
    this.apiService.expSumm(searchval, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.datas = res['data']['data'];
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      if(this.datas.length==0)
      this.isnoData = true;

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });


  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;
  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  pageNext(formdata: { value: any; }, pageno: any) {
    formdata.value.filter = 1;
  
    this.pageLoadingImg = true;
    this.isnoData = false;
    if (formdata.value.period_mfg) {
      formdata.value.mfg1 = (formdata.value.mfg1) ? this.apiService.formatDate(formdata.value.mfg1) : formdata.value.mfg1;
      if(formdata.value.period_mfg === 'between') {
        formdata.value.mfg2 = (formdata.value.mfg2) ? this.apiService.formatDate(formdata.value.mfg2) : formdata.value.mfg2;
      }
    }
    if (formdata.value.period_exp) {
      formdata.value.exp1 = (formdata.value.exp1) ? this.apiService.formatDate(formdata.value.exp1) : formdata.value.exp1;
      if(formdata.value.period_exp === 'between') {
        formdata.value.exp2 = (formdata.value.exp2) ? this.apiService.formatDate(formdata.value.exp2) : formdata.value.exp2;
      }
    }
    this.apiService.expSumm(formdata.value, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.datas = res['data']['data'];      
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      if(this.datas.length==0)
      this.isnoData = true;

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);

    });
  }

  filterDate = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];
  // filter  types
  filterTypes = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' }
  ];



  //form search  for category
  searchCat(search: string) {
    let searchval = new FormData();
    searchval.append("cat_name", search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.categories = res['data'];
    });
  }

  //form search
  searchUser(search: string) {
    let searchval = new FormData();
    searchval.append("usr_name", search);
    this.apiService.getUserByName(searchval).subscribe((res) => {
      this.values = res['data'];
    });
  }

        //product form search 
        searchStkPrd(search: string)
          {      
              let searchval = new FormData();
              if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
                searchval.append('alias_search', '1');
              }      
                searchval.append("keyword", search);    
                this.apiService.getPrdRepkbyName(searchval).subscribe((res) => {
                  this.stkprd_all = res['data'];              
                      
                });
          } 
  

          generatePdf(formdata: { value: any; }) {

            this.exportLoader = true;
    // debugger;
    formdata.value.export = 'export';

    this.apiService.expSumm(formdata.value, 1).subscribe((res:any) => {

      formdata.value.export = '';

      this.Exprodsumms = res['data'];

      const heads = [
        this.translate.instant('Common.prd_name'),
        this.translate.instant('Common.itm_code'),
        this.translate.instant('Table.barcode'),
        this.translate.instant('Purchase.batch') + '\n' + this.translate.instant('Table.code'),
        this.translate.instant('Purchase.mfg') + '\n' + this.translate.instant('Table.date'),
        this.translate.instant('Purchase.exp') + '\n' + this.translate.instant('Table.date'),
        this.translate.instant('Common.validity'),
        this.translate.instant('Table.status')
      ];
        
        const lblXPos = 10;
        const headerHeight = 15;
        const valueXPos = 35;
        const data = [];
  
        this.Exprodsumms.forEach(item=>{
          const arr=[
            item.product,
            item.itemcode,
            item.barcode,
            item.validity + ' ' + 'Days'
          ]

          if(item.batchcode==null){
            arr.splice(3,0, 'NA')
          } else {
            arr.splice(3,0, item.batchcode)
          }

          if(item.mfg==null){
            arr.splice(4,0, 'NA')
          } else {
            arr.splice(4,0, this.datePipe.transform(item.mfg, 'dd/MM/yyyy'))
          }

          if(item.exp==null){
            arr.splice(5,0, 'NA')
          } else {
            arr.splice(5,0, this.datePipe.transform(item.exp, 'dd/MM/yyyy'))
          }

          if(item.expiry==0){
            arr.splice(7,0, 'Not Expired')
          } else {
            arr.splice(7,0, 'Expired')
          }

          data.push(arr)
        })
    
        let doc = this.excelService.getPdfObj();
  
        var addressLines = this.branch_address.split('\r\n');
        var addressText = addressLines.join(', ');
  
        doc.setFont('Amiri');
        doc.setFontSize(12);
        doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
  
        doc.setFont('Amiri');
        doc.setFontSize(9);
        doc.text(addressText, 71, headerHeight + 2);
  
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 10);
        doc.setFontSize(10);
        doc.text(':  ' + `${this.translate.instant('Common.stock_expiration_summary')}`,  valueXPos, headerHeight + 10);
  
        
        doc = this.excelService.addTableToPdf(doc, headerHeight + 15, lblXPos, heads, data, null);
    
       
        doc.save(`Stock Expiration Summary.pdf`);
  
        this.exportLoader = false;

  });
  
            
          }

}
