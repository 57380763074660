import { Component, OnInit } from '@angular/core';

import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import html2canvas from 'html2canvas';
import * as html2pdf from "html2pdf.js";
// import * as jspdf from 'jspdf';
import { ExcelService } from '../../../../../service/excel.service';
import { TranslateService } from '@ngx-translate/core';
import * as XLSX from 'xlsx'; 
import { LangChangeEvent } from '@ngx-translate/core';
import { CurrencyPipe, DatePipe } from '@angular/common';


@Component({
  selector: 'app-verification-of-balance',
  templateUrl: './verification-of-balance.component.html',
  styleUrls: ['./verification-of-balance.component.css']
})
export class VerificationOfBalanceComponent implements OnInit {

  fileName= 'verification_balance.xlsx';


  resultobj: any;
  elseBlocknostart: any;
  elseBlocknoend: any;
  date1: any;
  date2: any;
  inv_filter: any;
  inv_val: any;
  inv_val1: any;
  purch_price: any;
  price1: any;
  price2: any;
  purch_discount: any;
  discount1: any;
  discount2: any;
  added_by: any;
  vch_type: any;
  disp_type = '1';
  vch_filter: any;
  ret_ref_filter: any;



  dayList: string[];
  curpage: number;
  lastpage: number;
  pgstart: boolean;
  pgend: boolean;
  slnum: number;
  ModelData: boolean;
  // total_purchase:number;
  total_return: any;
  total_purchase_amount: any;

  pageLoadingImg: boolean;
  report_type: string;
  is_year_rep: any;
  filter_types: any;
  filter_refnum: any;
  filter_price: any;
  filter_discount: any;
  // filter_paytype:any;
  // filter_purchtypes:any;
  selectType: any;
  // selectRefNo:any;
  selectPrice: any;
  // selectDiscount:any;



  filterTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];

  // filter  types
  sortType = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' },
  ];

  filter_paytype = [
    { id: '1', name: 'Credit' },
    { id: '2', name: 'Cash' },

  ];

  display_layout = [
    { id: 1, name: 'Normal' },
    { id: 2, name: 'Table' },

  ];

  filter_purchtypes = [
    { id: '1', name: 'Summary' },
    { id: '2', name: 'Group wise' },
    { id: '3', name: 'Detailed' },

  ];
  filterPurchrate = [
    { id: 'last', name: 'Last Purchase Rate' },
    { id: 'avg', name: 'Avg. Purchase Rate' },
    { id: 'avg_by_date', name: 'Avg. Purchase Rate By Date' }
  ];
  repDate: any;
  voucherType: any;
  ledger_all: any;
  users: any;
  show_notes: any;
  dispnote: any;
  debitAmt: any;
  crdAmt: any;
  group_all: any;
  ledgerList: any;
  total_op_debit: any;
  total_op_credit: any;
  total_cur_debit: any;
  total_cur_credit: any;
  total_clos_debit: any;
  total_clos_credit: any;
  reportData: any;
  diffInOpengBal: any;

  math = Math;
  disp_purch_rate = 'last';
  period_type = '';
  ExpledgerList: any;
  ExpdiffInOpengBal: any;
  Exptotal_debit: any;
  Exptotal_credit: any;
  branch_display_name: any;
  branch_name: any;
  branch_address:any;
  branch_display_code: any;
  branch_all: any;
  userType: any;
  exportLoader: boolean;
  disp_layout = 1;
  display_subtotal = true;
  country_dec: string;
  head: any;
  lang_filter: any;
  lang_filter1: any;
  lang_ledger_filter:any;
  op_debit: any;
  op_credit: any;
  cur_debit: any;
  cur_credit: any;
  clos_debit: any;
  clos_credit: any;
  currentDateTime: string;

  constructor(private apiService: ApiService, private coreService: CoreService, private excelService: ExcelService, private translate: TranslateService,private currencyPipe: CurrencyPipe) { }

  ngOnInit() {
    this.updateDateTime();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.userType = this.coreService.getUserData('user_type');
    this.getAllBranch();
    let searchval = new FormData();
    this.getVeificationOfBalanceData(searchval, 1);
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name= this.coreService.getUserData('branch_name');
   this.branch_display_code= this.coreService.getUserData('branch_code');
   this.branch_address= this.coreService.getUserData('branch_address');
   this.country_dec=this.coreService.setDecimalLength();
  }
  ngOnDestroy() {
    $('.close').click();

  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','Common.last_purchase_rate','Common.average_purchase_rate','Common.avg_purchase_rate_by_date','Common.normal','Table.table','Common.summary','ACCOUNTS.Group_wise','Common.detailed']).subscribe((res: string) => {    
     
      
      this.filterTypes = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];

      this.filterPurchrate = [
        { id: 'last', name: res['Common.last_purchase_rate'] },
        { id: 'avg', name: res['Common.average_purchase_rate'] },
        { id: 'avg_by_date', name: res['Common.avg_purchase_rate_by_date'] }
      ];

      this.display_layout = [
        { id: 1, name:  res['Common.normal'] },
        { id: 2, name:  res['Table.table'] },
    
      ];

      this.filter_purchtypes = [
        { id: '1', name: res['Common.summary'] },
        { id: '2', name: res['ACCOUNTS.Group_wise'] },
        { id: '3', name: res['Common.detailed'] },
    
      ];
    });

  }

  getVeificationOfBalanceData(searchval, pageNo) {
    this.pageLoadingImg = true;
    this.apiService.verificationOfBalanceReport(searchval, pageNo).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.ledgerList = res.data.data;
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.total_op_debit = res.info.total_op_debit;
      this.total_op_credit = res.info.total_op_credit;
      this.total_cur_debit = res.info.total_cur_debit;
      this.total_cur_credit = res.info.total_cur_credit;
      this.total_clos_debit = res.info.total_clos_debit;
      this.total_clos_credit = res.info.total_clos_credit;
      this.diffInOpengBal = (res.info.opening_bal) ? res.info.opening_bal : 0;
      this.repDate = res.info.Date;
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;
    });
  }


  pageNext(formdata: { value: any; }, pageno: any) {




    $('.resultdata').empty();
    this.pageLoadingImg = true;

    let searchval = new FormData();
    searchval.append("pageno", pageno);

    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.getVeificationOfBalanceData(formdata.value, pageno);

    // this.apiService.trialBalanceReport(formdata.value, pageno).subscribe((res: any) => {

    //   this.pageLoadingImg = false;
    //   this.ledgerList = res.data.data;
    //   this.curpage = res['data']['current_page'];
    //   this.lastpage = res['data']['last_page'];
    //   this.diffInOpengBal = (res.info.opening_bal) ? res.info.opening_bal : 0;
    //   this.total_debit = res.info.total_debit;
    //   this.total_credit = res.info.total_credit;
    //   this.repDate = res.info.Date;



    //   this.pgEnd(this.curpage, this.lastpage);
    //   this.pgStart(this.curpage);
    //   this.slnum = 0;


    //   this.repDate = res.info.Date;
    //   // this.dispnote = res.data.show_notes;
    //   // this.debitAmt = res.data.debit_amnt;
    //   // this.crdAmt = res.data.credit_amnt;



    // });
  }




  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res) => {
      this.branch_all = res['data'];

      // console.log(  this.branch_all );
    
    });
  }
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
    
    });
  }



  //form search
  searchUser(search: string) {
    let searchval = new FormData();
    searchval.append("usr_name", search);
    this.apiService.getUserByName(searchval).subscribe((res) => {
      this.users = res['data'];
    });
  }



  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  grpSummaryexport() {

  }

  openPDF() {
    $("#contentToConvert").show();

    var data = document.getElementById('contentToConvert');
    html2canvas(data).then(canvas => {
      // Few necessary setting options  
      var imgWidth = 208;
      //208  
      var pageHeight = 495;
      var imgHeight = canvas.height * imgWidth / canvas.width;

      const contentDataURL = canvas.toDataURL('image/jpeg', 1.0)
      // let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF         
      // var position = 0;
      // pdf.addImage(contentDataURL, 'JPG', 0, position, imgWidth, imgHeight);

      // pdf.save('Account Group Summary Report.pdf'); // Generated PDF         

    });

    $("#contentToConvert").hide();


  }



  exportAsXLSX(formdata: { value: any; }): void {

    this.apiService.groupSummaryToatalReport(formdata.value).subscribe((res: any) => {
      this.reportData = res['data'];
      // console.log(this.reportData);
    });

    this.excelService.exportAsExcelFile(this.reportData, 'Account Group Summary Report');
  }



  exportexcel(): void {
    this.exportLoader = true;

    /* table id is passed over here */
    let element = document.getElementById('edit-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }


  // export(formdata: { value: any; }) {

  //   // debugger;
  //   formdata.value.export = 'export';
  //   if (formdata.value.period_type === 'c') {
  //     formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
  //     formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
  //   }

  //   this.apiService.trialBalanceReport(formdata.value, 0).subscribe((res: any) => {

  //     this.pageLoadingImg = false;
  //     this.ExpledgerList = res.data.data;
  //     this.ExpdiffInOpengBal = (res.info.opening_bal) ? res.info.opening_bal : 0;
  //     this.Exptotal_debit = res.info.total_debit;
  //     this.Exptotal_credit = res.info.total_credit;
  //     this.repDate = res.info.Date;



  //     this.pgEnd(this.curpage, this.lastpage);
  //     this.pgStart(this.curpage);
  //     this.slnum = 0;


  //     this.repDate = res.info.Date;
  //     // this.dispnote = res.data.show_notes;
  //     // this.debitAmt = res.data.debit_amnt;
  //     // this.crdAmt = res.data.credit_amnt;
  //     setTimeout(() => {
  //       this.exportexcel(); 
  //   }, 3000);


  //   });



  // }

  // pdf download
  generatePdf() {

    this.exportLoader = true;

    const options = {
      filename: "Verification Of Balace.pdf",
      image: { type: "png", quality: 0.98 },
      html2canvas: { scale: 2,y: 0,  scrollY: 0, allowTaint: false, useCORS: true },
      jsPDF: { orientation: "p",  unit: "mm", format: "a4", precision: 5 },
      pagebreak: { after: ".page-break" },
    };

    const content = document.getElementById("pdfDownload");
    html2pdf().from(content).set(options).then(() =>{
      
    }).save()

    this.exportLoader = false;

  }

  updateDateTime() {
    const now = new Date();
    this.currentDateTime = now.toLocaleString();
  }

}
