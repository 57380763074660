import { Component, OnInit, ViewChild, ElementRef, Input, Output,EventEmitter} from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import {TranslateService,LangChangeEvent} from '@ngx-translate/core';
@Component({
  selector: 'app-invoice-due-popoup',
  templateUrl: './invoice-due-popoup.component.html',
  styleUrls: ['./invoice-due-popoup.component.css']
})
export class InvoiceDuePopoupComponent implements OnInit {
  @Input()ledger_id;
  @Input()chngecheck;
  @Input()list_sales;
  @Output() listUpdated = new EventEmitter();
  @ViewChild('closeModal', { static: false }) closeModal: ElementRef;
  resultobj: any = {};
  isEdit= false;
  rec = {
    rec_no: '',
    doc_no:'',
    rec_date: new Date(), 
    rec_acc: 0,
    rec_acc_balance: 0,
    rec_acc_bal_sign: '',
    rec_cust_ledger: 0,
    rec_cust_balance: 0,
    rec_cust_bal_sign: '',
    note: '',
    invs: [],
    ttl_amount: 0,
    ttl_wo_amount:0,
    ttl_input_amount: 0,
    ttl_due: 0,
    payType: 1,
    collected_by_agent: 0,
    balance_to_wallet:this.covertToPrice(0,2),
    is_advance : false
  };
  dueInv = [];
  pendingInvs = JSON.stringify([]);
  isRemoveLaoding:any = {};  
  is_advance = false;
  wallet = 0;
  account = [];
  isSubmitInProg = false;
  curForm = 'Create';
  langText = {
    create: '',
    update: ''
  };
  usr_hide_others_sales_data: any;
  pageLoadingImg = false;
  curpage = 1;
  lastpage = 1;
  perpage = 20;
  totalItem = 0;
  walletPageLoadingImg = false;
  wallet_info = [];
  wallet_perpage = 20;
  wallet_curpage = 1;
  wallet_lastpage = 1;
  wallet_totalItem = 0;
  info = [];
  nonAccount = [];
  nextId = '';
  ledgerLoading = false;
  ledger_name: any;
  ledger_alias: any;
  bal_amt: any;
  bal_inv_amt: any;
  total_amnt_due: void;
  invLoading = false;
  total_inv_amnt: number;
  lstbalAmt = 0;
  country_dec_exp: any;
  sales_agents: any;
  accLoading = false;
  recPriviewLoader: boolean;
  recWalletPriviewLoader: boolean;
  printWalletPreview: any;
  printPreview: any;
  up: any;
  savedTemplate = {
    title_text: '',
    color: '#000',
    email_color: '#000',
    font_size: '12',
    orientation: "P",
    paper: 'A4-P',
    m_left: '',
    m_top: '',
    m_right: '',
    m_bottom: '',
    logo: false,
    header_type: 1,
    header_img: [],
    header_all_page: false,
    header_due: false,
    sl_no: false,
    item_desc: false,
    tax_detail: false,
    tax_type: 0,
    name_type: 'N',
    footer_text: ''
  }
  logoImgUrl: string;
  tempImgUrl: string;
  footImgUrl: string;
  branch_display_name: any;
  branch_code: any;
  branch_addrss: any;
  country_dec: string;
  cmpny1:any;
  usr_block_pre_date_select: any;
  minDate: Date;
  constructor(private apiService: ApiService, private coreService: CoreService, private translate: TranslateService) { }

  ngOnChanges(changes) {
    console.log('this.chngecheck',this.chngecheck);
    
    if (changes['chngecheck'] && this.chngecheck) {
      console.log('ledger_id',this.ledger_id);
      console.log('ledger_idthis.list_sales',this.list_sales);
      console.log('this.chngecheck',this.chngecheck);
      
        const searchval = new FormData();
        searchval.append('ledger_id', this.ledger_id);
        this.apiService.getCustomerLedgres(searchval).subscribe((res) => {
          this.nonAccount = res.data;
          this.rec.rec_cust_ledger=this.ledger_id
          this.getBalance()
        });
     
      // console.log('in child 2');
      // console.log(this.saleQtnData);
      // this.hide_prt_salesman = this.sales_settings.ss_hide_prt_salesman;
    }

   
  }
  ngOnInit() {
    this.usr_hide_others_sales_data = this.coreService.getUserData('usr_hide_othr_sales_data');
    this.getPaidByWalletInfo(this.wallet_curpage)
    this.getNextReceiptId();
    this.getAllAccount();
    this.getCustomerLedgres();
    this.getInfo(this.curpage);
    this.getPaidByWalletInfo(this.wallet_curpage)
    this.up = this.coreService.getUserPrivilage();
    this.getTemplete();
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_code = this.coreService.getUserData('branch_code');
    this.branch_addrss = this.coreService.getUserData('branch_address');
    this.getAllAgents();

    this.country_dec=this.coreService.setDecimalLength();
    this.cmpny1 = this.coreService.getUserData('cmpny');
    this.usr_block_pre_date_select = this.coreService.getUserData("usr_block_pre_date_select");
    if(this.usr_block_pre_date_select){
      this.minDate= new Date();
    }else{
      this.minDate= new Date(this.coreService.getUserData("branch_open_date"));
    }

  }
  getNextReceiptId() {
    this.apiService.getNextReceiptId().subscribe((res) => {
      this.nextId = res.data.ref_no;
    });
  }
  getTemplete() {

    this.apiService.getSettings().subscribe((res) => {

      this.savedTemplate = {
        title_text: res.data.prnt_title_text,
        color: '#000',
        email_color: '#000',
        font_size: '12',
        orientation: "P",
        paper: 'A4-P',
        m_left: '',
        m_top: '',
        m_right: '',
        m_bottom: '',
        logo: res.data.prnt_header_logo,
        header_type: res.data.prnt_header_type,
        header_img: [],
        header_all_page: false,
        header_due: false,
        sl_no: false,
        item_desc: false,
        tax_detail: false,
        tax_type: 0,
        name_type: 'N',
        footer_text: res.data.prnt_display_footer_text
      }
      if (res.data.prnt_header_logo) {
        this.logoImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_logo_img_path;
      } else {
        this.logoImgUrl = '';
      }

      if (res.data.prnt_header_type == 2) {
        this.tempImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_img_path;
      } else {
        this.tempImgUrl = '';
      }
      this.footImgUrl=this.apiService.PHP_API_SERVER + '/' + res.data.prnt_reciept_footer_img;

    });
  }

  getAllAccount() {
    this.apiService.getAllAccount().subscribe((res) => {
      this.account = res.data;
    });
  }
  getCustomerLedgres() {
    this.apiService.getCustomerLedgres('').subscribe((res) => {
      this.nonAccount = res.data;
    });
  }
  searchNonAccount(search: string){
    const searchval = new FormData();
    searchval.append('search', search);
    this.apiService.getCustomerLedgres(searchval).subscribe((res) => {
      this.nonAccount = res.data;
    });
  }
  getAllAgents() {
    this.apiService.getAllAgents().subscribe((res) => {
      this.sales_agents = res['data'];
    });
  }
  searchAgents(search: string) {
    const searchval = new FormData();
    searchval.append('ledger_name', search);
    this.apiService.searchAgents(searchval).subscribe((res) => {
      this.sales_agents = res['data'];
    });
  }
  getLedgerBalance() {
    this.accLoading = true;
    this.apiService.getLedgerBalance({ ledger_id: this.rec.rec_acc }).subscribe((res) => {
      this.accLoading = false;
      this.rec.rec_acc_balance = res.data.bal_without_sign;
      this.rec.rec_acc_bal_sign = res.data.bal_sign;
    });
    // this.ShowInvDetails();
  }
  getWalletclearData(){
    this.clearData();
    // wallet amount comes here
    this.rec.ttl_input_amount =  this.wallet;
    // this.updatePrice();
  }

  clearData(){
    this.dueInv = JSON.parse(this.pendingInvs);
    this.rec.invs = [];
    this.rec.ttl_input_amount = 0;
    // this.rec.balance_to_wallet = this.covertToPrice(0,2);
    // this.updateTotal();
    this.isRemoveLaoding = {};
    this.is_advance = false;
  }
  getPaidByWalletInfo(pageNo) {
    let searchval = new FormData();   
    if(this.usr_hide_others_sales_data){ 
      let hide_other='hide';
      searchval.append("hide_others", hide_other);
    }
      this.walletPageLoadingImg = true;
      this.apiService.getAllWalletDueReceipt(searchval,pageNo, this.wallet_perpage).subscribe((res) => {
        this.wallet_info = res.data.data;
        this.walletPageLoadingImg = false;
        this.wallet_curpage = res.data.current_page;
        this.wallet_lastpage = res.data.last_page;
        this.wallet_totalItem = res.data.total;
      });
    
  }
  getInfo(pageNo) {
    let searchval = new FormData();   
    if(this.usr_hide_others_sales_data){ 
      let hide_other='hide';
      searchval.append("hide_others", hide_other);
    }
    this.pageLoadingImg = true;
    this.apiService.getAllDueReceipt(searchval,pageNo, this.perpage).subscribe((res) => {
      this.info = res.data.data;
      this.pageLoadingImg = false;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.totalItem = res.data.total;
    });
  }
  public covertToPrice(num, decimals){ 
    return num.toLocaleString('en-US', {
        minimumFractionDigits: this.country_dec_exp,      
        maximumFractionDigits: this.country_dec_exp,
    });
  }
  public dayBetweenDates(sdate, edate = new Date()) {
    // Get 1 day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;
    // Convert both dates to milliseconds
    const sdateMs = new Date(sdate).getTime();
    const edateMs = new Date(edate).getTime();
    // Calculate the difference in milliseconds
    const differenceMs = edateMs - sdateMs;
    // Convert back to days and return
    return Math.round(differenceMs / oneDay);
  }
  public checkDueExp(dueDate) {
    const duedateMs = new Date(dueDate).getTime();
    const crrdateMs = new Date().getTime();
    const differenceMs = duedateMs - crrdateMs;
    if (differenceMs < 0) {
      return true;
    } else {
      return false;
    }
  }
  viewReceipt(id){
    this.recPriviewLoader = true;
      this.apiService.getInvoiceDueReceipt({rec_no: id}).subscribe((res) => {
        this.recPriviewLoader = false;
        this.printPreview = res;
      });
  }
  viewWalletReceipt(id){
    this.recWalletPriviewLoader = true;
      this.apiService.getInvoiceDueReceiptByWallet({rec_no: id}).subscribe((res) => {
        this.recWalletPriviewLoader = false;
        this.printWalletPreview = res;
      });
  }
  addInvoice(indx) {
    // this.dueInv[indx].rec_amt = this.dueInv[indx].salesduesub_inv_balance;    
    if(this.rec.payType==2){
      // if(this.rec.balance_to_wallet>0){
      //   if(this.dueInv[indx].salesduesub_inv_balance>this.rec.balance_to_wallet){
      //     this.coreService.showMessage("Invoice amount greater-than available balance");
      //     return false;
      //   }
      // }else{
      //   if(this.dueInv[indx].salesduesub_inv_balance>this.wallet){
      //     this.coreService.showMessage("Insufficient wallet balance");
      //     return false;
      //   }
      // }
      if(!this.wallet){
        this.coreService.showMessage("Insufficient wallet balance");
        return false;
      }
    }
    
    
    this.rec.invs.push(this.dueInv[indx]);
    console.log(this.rec.invs);
    this.dueInv.splice(indx, 1);
    this.updateTotal();
  }

  
  updateTotal() {
    if(!this.isEdit){
      this.rec.ttl_amount = 0;
      this.rec.ttl_wo_amount = 0;
    console.log(" this.rec.invs");
    console.log(this.rec.invs);
    this.rec.invs.forEach((element, indx) => {
      if(!isNaN(element.rec_amt)){
        this.rec.ttl_amount += Number(element.rec_amt); 
      }else{
        this.rec.ttl_amount +=Number(element.rec_amt.replace(/[^0-9.]/g, ''));
      }
      element.write_off = (element.write_off) ? element.write_off : 0;
      if(!isNaN(element.write_off)){
        this.rec.ttl_wo_amount += Number(element.write_off); 
      }else{
        this.rec.ttl_wo_amount +=Number(element.write_off.replace(/[^0-9.]/g, ''));
      }      
    });
    this.rec.ttl_due = this.rec.rec_cust_balance - this.rec.ttl_amount;
    console.log('rec.ttl_input_amount : ' + this.rec.ttl_input_amount);
    this.rec.balance_to_wallet = (this.rec.ttl_input_amount == 0) ? this.covertToPrice(0,2) : this.covertToPrice((this.rec.ttl_input_amount-this.rec.ttl_amount),2);
    // this.rec.balance_to_wallet = (this.rec.ttl_input_amount == 0) ? this.rec.ttl_amount : this.rec.ttl_input_amount;
  }
  }

  updatePrice() {
    if(this.is_advance){
      this.rec.invs = [];
      this.dueInv = JSON.parse(this.pendingInvs);
      this.updateTotal();
      this.isRemoveLaoding = {};
    }else{
      this.dueInv = JSON.parse(this.pendingInvs);
      this.rec.invs = [];
      if (Number(this.rec.ttl_input_amount) !== 0) {
        let balAmt = this.rec.ttl_input_amount;
        const addInx = [];
        this.dueInv.forEach((element, indx) => {
          if (balAmt > 0 ) {
            const remBal = Number(balAmt) - Number(element.salesduesub_inv_balance);
            if (remBal < 0) {
              this.dueInv[indx].rec_amt = this.lstbalAmt = Number(balAmt);
              console.log('main loop balance amut' +this.lstbalAmt);
              this.rec.balance_to_wallet = this.covertToPrice(0,2);
            } else {
              this.lstbalAmt = Number(balAmt);
              this.rec.balance_to_wallet = this.covertToPrice(remBal,2);
              console.log('main loop balance amt 2 ' +this.lstbalAmt);
            }
            addInx.push(indx);
            balAmt = Number(remBal);
          }
        });
        addInx.forEach((indx, i) => {
          this.rec.invs.push(this.dueInv[indx - i]);
          this.dueInv.splice(indx - i, 1);
        });
        this.updateTotal();
      }
    }
  }

  ShowInvDetails() {
    if(!this.isEdit ){
    this.rec.invs = [];
    }
    this.pendingInvs = JSON.stringify([]);
    if (this.rec.rec_cust_ledger !== 0 ) {
      this.invLoading = true;
      this.apiService.getCustomerInvoice({ cust_ledger_id: this.rec.rec_cust_ledger }).subscribe((res) => {
        this.invLoading = false;
        this.pendingInvs = JSON.stringify(res.data);
        this.dueInv = res.data;
        this.total_inv_amnt = 0;
        this.dueInv.forEach((element, indx) => {
          this.total_inv_amnt += Number(element.salesduesub_inv_amount);
        });
        this.updateTotal();
        console.log('pay type ' +this.rec.payType );
        if(this.rec.payType == 0 || this.rec.payType ==2){
          console.log('in if paytype');
          this.updatePrice();
        }
        // this.rec.invs = res.data;
      });
    }
  }
  
  getBalance() {
    if(!this.isEdit ){
    this.clearData();
    }
    this.rec.payType = 1;
    this.ledgerLoading = true;
    if(!this.rec.rec_cust_ledger) return;
    this.apiService.getCustomerBalance({ cust_ledger_id: this.rec.rec_cust_ledger }).subscribe((res) => {
      this.ledgerLoading = false;
      this.rec.rec_cust_balance = res.data.balance;
      this.wallet = res.data.wallet;
      this.rec.rec_cust_bal_sign = res.data.bal_sign;
      this.ledger_name=res.data.ledger_name;
      this.ledger_alias=res.data.ledger_alias;
      this.bal_amt=res.data.total_due_amnt;
      this.bal_inv_amt=res.data.total_inv_amnt;
      this.total_amnt_due=res.data.total_amount_due;
      if(!this.isEdit ){
        this.rec.collected_by_agent=res.data.agent;
      }
     
      this.ShowInvDetails();
     
    });

    // this.apiService.getLedgerBalance({ ledger_id: this.rec.rec_cust_ledger }).subscribe((res) => {
    //   this.ledgerLoading = false;
    //   this.rec.rec_cust_balance = res.data.bal_without_sign;
    //   this.rec.rec_cust_bal_sign = res.data.bal_sign;
    // });
  }
  clearForm() {
    this.rec = {
      rec_no: '',
      doc_no:'',
      rec_date: new Date(),
      rec_acc: 0,
      rec_acc_balance: 0,
      rec_acc_bal_sign: '',
      rec_cust_ledger: 0,
      rec_cust_balance: 0,
      rec_cust_bal_sign: '',
      note: '',
      invs: [],
      ttl_amount: 0,
      ttl_wo_amount: 0,
      ttl_input_amount: 0,
      ttl_due: 0,
      payType: 1,
      collected_by_agent: 0,
      balance_to_wallet:this.covertToPrice(0,2),
      is_advance : false
    };
    this.dueInv = [];
    this.rec.invs = [];
    this.isRemoveLaoding = {};
    this.is_advance = false;
    this.isEdit =false;
    this.wallet = 0;
    this.curForm = this.langText.create;
    this.getNextReceiptId();

  }
  
  validateAndSubmit() {
    // const confrm = confirm('Do you wish to continue?');
    
    if(!this.isEdit){

      if(this.rec.payType==1 && (!this.rec.ttl_amount && !this.rec.ttl_wo_amount)){
        return false;
      }
      if(this.rec.payType==2){  
        this.rec.doc_no=null;    
        if (this.rec.ttl_amount > this.wallet ) {
          this.coreService.showMessage('Total amount should be lessthan or equal to wallet balance');
          this.isSubmitInProg = false;
          return false;
        }
      }
      if(!this.is_advance && !this.rec.invs.length){
        this.coreService.showMessage('No invoice selected');
        this.isSubmitInProg = false;
        return false;
      } 
      if(this.is_advance && (this.rec.balance_to_wallet<=0 || !this.rec.balance_to_wallet)){
        this.coreService.showMessage('Enter advance amount');
        this.isSubmitInProg = false;
        return false;
      } 

    }
 
    if (confirm('Do you wish to continue?')) {
      this.isSubmitInProg = true;
      // let callFunction = '';
      this.rec.is_advance = this.is_advance;
      // console.log('this.curForm1',this.curForm);
      // console.log(' this.langText', this.langText.create);
      
      // if (this.curForm == this.langText.create) {
      //   console.log('jii');
        
      //   callFunction = 'createDueReceipt';
      // }
      // if (this.curForm == this.langText.update) {
      //   callFunction = 'updateDueReceipt';
      // }
      // if (this.rec.ttl_input_amount > this.rec.rec_cust_balance ) {
      //   this.resultobj = {ttl_input_amount: 'Invalid Amount'};
      //   this.coreService.showMessage('Invalid Input Amount');
      //   this.isSubmitInProg = false;
      // } else {
        this.apiService.createDueReceipt(this.rec).subscribe((res) => {
          // console.log(this.staff);
          this.isSubmitInProg = false;
          if (res.error != null) {
            this.resultobj = res.error;
            if(this.resultobj.doc_no){
              alert(this.resultobj.doc_no);
            }
            if(res.message) {
              this.coreService.showMessage(res.message);
            }
          } else {
            this.closeModal.nativeElement.click();
            this.getInfo(this.curpage);
            this.getPaidByWalletInfo(this.wallet_curpage);
            this.listUpdated.emit();
            this.coreService.showMessage(res.message);
            this.clearForm();
            this.resultobj = {};
            this.getNextReceiptId();
            this.getBalance();
          }

        });
      // }
    }
  }
  
  

}
