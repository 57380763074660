import { Pipe, PipeTransform } from '@angular/core';
import { CoreService } from './service/core.service';

@Pipe({
  name: 'customCurrency'
})

export class CustomCurrencyPipe implements PipeTransform {
  cmpny:any;
  truncatedValue: any;
  constructor(private coreService: CoreService) {
    this.cmpny = this.coreService.getUserData('cmpny');
   }
 
  transform(value: any, decimalPlaces: number=2): any {
    if (!value) return value;

    // Multiplying and truncating to the given number of decimal places
    const factor = Math.pow(10, decimalPlaces);
   
  //  if(this.cmpny=='SAAD'||this.cmpny=='FJK'){
    this.truncatedValue = (decimalPlaces>2)?Math.round(value * factor) / factor:value;
  //  } else{
  //   this.truncatedValue=(decimalPlaces>2)?Math.floor(value * factor) / factor:value;
  //  }
   
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces
    }).format(this.truncatedValue);
    // return truncatedValue.toFixed(decimalPlaces);
    
  }


}
