import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import * as XLSX from 'xlsx';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-rep-stock-verification-inv-based',
  templateUrl: './rep-stock-verification-inv-based.component.html',
  styleUrls: ['./rep-stock-verification-inv-based.component.css']
})
export class RepStockVerificationInvBasedComponent implements OnInit {

  pageLoadingImg: boolean;

  fileName = 'stock_verification_inv.xlsx';


  periodTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];

  sortType = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' },
  ];
  repDate: any;
  summary: any;
  pageFrom: any;
  curpage: any;
  lastpage: any;
  period_type: string;
  tot_items: any;
  tot_req_qty: any;
  total_vrfy_qty: any;
  pgend: boolean;
  pgstart: boolean;
  userType: any;
  godowns: any;
  users: any;
  products: any[];
  catagories: any;
  mfs: any;
  Expsummary: any;
  Exptot_items: any;
  Exptot_req_qty: any;
  Exptotal_vrfy_qty: any;
  exportLoader: boolean;
  branch_display_name: any;
  branch_display_code: any;
  branch_all: any;
  branch_address:any;
  country_dec: string;
  cur_lang: string;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }

  ngOnInit() {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.cur_lang = sessionStorage.getItem("baseLang")
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    this.pageLoadingImg = true;

    let searchval = new FormData();
    this.summary = [];
    this.period_type = 't';
    searchval.append("period_type", this.period_type);

    this.apiService.stockVerificationInvBasedRep(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.repDate = res.data.Date;
      this.summary = res['data']['data']['data'];
      this.pageFrom = res['data']['data']['from'];
      this.curpage = res['data']['data']['current_page'];
      this.lastpage = res['data']['data']['last_page'];

      this.tot_items = res['data']['total']['tot_prods'];
      this.tot_req_qty = res['data']['total']['tot_req_qty'];
      this.total_vrfy_qty = res['data']['total']['total_vrfy_qty'];


      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });

    this.userType = this.coreService.getUserData('user_type');
    this.getGodownList();
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');
  }
  langChange(){
    this.translate.get(['Common.today','Common.last_day','Common.last_week','Common.this_month','Common.last_yr','Common.cust_date','Common.all',]).subscribe((res: string) => {

     this.periodTypes = [
        { id: 't', name:res['Common.today'] },
        { id: 'ld', name:res['Common.last_day'] },
        { id: 'lw', name:res['Common.last_week'] },
        { id: 'lm', name:res['Common.this_month'] },
        { id: 'ly', name:res['Common.last_yr'] },
        { id: 'c', name:res['Common.cust_date'] },
        { id: '', name:res['Common.all'] }
      ];
    
    });

  }
  getGodownList() {
    let searchval = new FormData();

    this.apiService.getGodownList(searchval).subscribe((res) => {
      this.godowns = res['data'];
      this.godowns.push({ "gd_id": '', 'gd_name': 'All' });

    });
  }



  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }


  pageNext(formdata: { value: any; }, pageno: any) {

    this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    formdata.value.export = '';

    this.summary = [];


    this.apiService.stockVerificationInvBasedRep(formdata.value, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.repDate = res.data.Date;
      this.summary = res['data']['data']['data'];
      this.pageFrom = res['data']['data']['from'];
      this.curpage = res['data']['data']['current_page'];
      this.lastpage = res['data']['data']['last_page'];

      this.tot_items = res['data']['total']['tot_prods'];
      this.tot_req_qty = res['data']['total']['tot_req_qty'];
      this.total_vrfy_qty = res['data']['total']['total_vrfy_qty'];


      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });
  }


  searchUser(search: string) {
    let searchval = new FormData();
    searchval.append("usr_name", search);
    this.apiService.getUserByName(searchval).subscribe((res) => {
      this.users = res['data'];
    });
  }

  // search product
  selectProd(name: string) {

    const searchval = new FormData();
    searchval.append('prod_name', name);
    if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      searchval.append('alias_search', '1');
    } 
    this.apiService.getProdByName(searchval).subscribe((res) => {
      this.products = res.data;
    });
  }

  searchCat(search: string) {
    const searchval = new FormData();
    searchval.append('cat_name', search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.catagories = res['data'];
    });

  }

  searchManf(search: string) {
    let searchval = new FormData();
    searchval.append("manftr_comp_name", search);
    this.apiService.getManfbyName(searchval).subscribe((res) => {
      this.mfs = res['data'];
    });
  }


  exportEXCL(formdata: { value: any; }, pageno = 1) {
    formdata.value.export = 'export';
    this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.Expsummary = [];
    this.exportLoader = true;

    this.apiService.stockVerificationInvBasedRep(formdata.value, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.repDate = res.data.Date;
      this.Expsummary = res['data']['data']['data'];

      this.Exptot_items = res['data']['total']['tot_prods'];
      this.Exptot_req_qty = res['data']['total']['tot_req_qty'];
      this.Exptotal_vrfy_qty = res['data']['total']['total_vrfy_qty'];

      setTimeout(() => {
        this.exportexcel();
      }, 3000);

    });
  }

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }

  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
    });
  }

  // pdf download
  generatePdf(formdata: { value: any; }) {

    formdata.value.export = 'export';
    this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.Expsummary = [];
    this.exportLoader = true;

    this.apiService.stockVerificationInvBasedRep(formdata.value, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.repDate = res.data.Date;
      this.Expsummary = res['data']['data']['data'];

      this.Exptot_items = res['data']['total']['tot_prods'];
      this.Exptot_req_qty = res['data']['total']['tot_req_qty'];
      this.Exptotal_vrfy_qty = res['data']['total']['total_vrfy_qty'];

      const heads = [
        this.translate.instant('Common.prd_name'),
        this.translate.instant('Purchase.refno'),
        this.translate.instant('HOME.verification_date'),
        this.translate.instant('HOME.required_qty'),
        this.translate.instant('HOME.verified_qty'),
        this.translate.instant('HOME.missing_qty'),
        this.translate.instant('HOME.excess_qty')
      ];

        const lblXPos = 10;
        const headerHeight = 15;
        const valueXPos = 55;
        const data = [];
  
        this.Expsummary.forEach(item=>{
          const arr=[
            item.prd_name,
            item.stkvrfy_ref_id,
            item.stkvrfy_date,
            this.currencyPipe.transform(item.stkvrfy_req_qty, '', '', '1.1-1'),
            this.currencyPipe.transform(item.stkvrfy_existing_qty, '', '', '1.1-1')
          ]

          if(item.stkvrfy_add_missing){
            arr.splice(5,0, this.currencyPipe.transform(item.stkvrfy_existing_qty - item.stkvrfy_req_qty, '', '', '1.1-1'))
          }

          if(item.stkvrfy_add_excess){
            arr.splice(5,0, this.currencyPipe.transform(item.stkvrfy_existing_qty - item.stkvrfy_req_qty, '', '', '1.1-1'))
          }
          
          data.push(arr)
        })
    
        let doc = this.excelService.getPdfObj();
  
        var addressLines = this.branch_address.split('\r\n');
        var addressText = addressLines.join(', ');
  
        doc.setFont('Amiri');
        doc.setFontSize(12);
        doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
  
        doc.setFont('Amiri');
        doc.setFontSize(9);
        doc.text(addressText, 71, headerHeight + 2);
  
        if(this.repDate){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
          doc.setFontSize(10);
          doc.text(':  ' + this.repDate.date1 + '   to   ' + this.repDate.date2  ,  valueXPos, headerHeight + 10);
        }
  
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
        doc.setFontSize(10);
        doc.text(':  ' + `${this.translate.instant('HOME.stock_verification_inv_based')}`,  valueXPos, headerHeight + 15);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Table.totl_item')}`, lblXPos, headerHeight + 20);
        doc.setFontSize(10);
        doc.text(':  ' + this.tot_items,  valueXPos, headerHeight + 20);
  
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.total_required_qty')}`, lblXPos, headerHeight + 25);
        doc.setFontSize(10);
        doc.text(':  ' + this.currencyPipe.transform(this.tot_req_qty, '', '', '1.1-1'),  valueXPos, headerHeight + 25);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.total_verified_qty')}`, lblXPos, headerHeight + 30);
        doc.setFontSize(10);
        doc.text(':  ' + this.currencyPipe.transform(this.total_vrfy_qty, '', '', '1.1-1'),  valueXPos, headerHeight + 30);
        
        doc = this.excelService.addTableToPdf(doc, headerHeight + 35, lblXPos, heads, data, null);
    
       
        doc.save(`Stock Verification Invoice Based.pdf`);

        this.exportLoader = false;

    });
  
  }

  
}
