import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../service/core.service';


@Component({
  selector: 'app-rep-van',
  templateUrl: './rep-van.component.html',
  styleUrls: ['./rep-van.component.css']
})
export class RepVanComponent implements OnInit {
  up: any;
  cmpny: any;

  constructor(private coreService: CoreService) { }

  ngOnInit() {
    this.up = this.coreService.getUserPrivilage();
    this.cmpny = this.coreService.getUserData('cmpny');
  }

}
