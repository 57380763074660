import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-qtn-template-three',
  templateUrl: './qtn-template-three.component.html',
  styleUrls: ['./qtn-template-three.component.css']
})
export class QtnTemplateThreeComponent implements OnInit {

  @Input()saleQtnData;
  @Input()print_style;
  @Input()savedTemplate;
  @Input()is_prew_description;
  @Input()is_prew_img;
  @Input()cmpny;
  @Input()previewLoader;
  @Input()logoImgUrl;
  @Input()tempImgUrl;
  @Input()sales_print_name;
  @Input()sales_settings;
  @Input()base_url;
  @Input()hide_prnt_alias;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes) {
    if (changes['saleQtnData'] && this.saleQtnData) {
      this.is_prew_img = this.saleQtnData['sales_qtn_sub'].filter(x => x.prd_img_url);
    }
  }

}
