import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ComfunctModule} from '../.././common/comfunct/comfunct.module';
import { MatDialogModule} from '@angular/material';
import {NgxPrintModule} from 'ngx-print';
// routing
import { PurchaseRoutingModule} from './purchase-routing.module';

// forms
import { FormsModule, ReactiveFormsModule} from '@angular/forms';

// share
import {ErpMaterialModule} from '../../material-module';
import { NgSelectModule } from '@ng-select/ng-select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSelectModule} from '@angular/material/select';

import {HttpClient} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

export function HttpLoaderFactory(httpClient: HttpClient) {
return new TranslateHttpLoader(httpClient); }
import { APP_BASE_HREF} from '@angular/common';
import { AppComponent } from '../../app.component';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';



// component
import { PurchaseDashComponent } from './purchase-dash/purchase-dash.component';
import { PurchaseComponent } from './purchase-dash/purchase/purchase.component';
import { PurchaseVoidComponent , AddNotePopup } from './purchase-dash/purchase-void/purchase-void.component';
import { NotesComponent } from './purchase-dash/common/notes/notes.component';
import { CommonComponent } from './purchase-dash/common/common.component';
import { PurchaseReturnComponent } from './purchase-dash/purchase-return/purchase-return.component';
import { PurchaseReturnVoidComponent } from './purchase-dash/purchase-return-void/purchase-return-void.component';
import { PurchaseQuotationComponent } from './purchase-dash/purchase-quotation/purchase-quotation.component';
import { AngularEditorModule } from '@kolkov/angular-editor';

import { QRCodeModule } from 'angularx-qrcode';
import { QuickProductModule } from '../../view/product/quick-product/quick-product.module';
import { PoGeneralPrintComponent } from './purchase-dash/print-templates/puchase-order/po-general-print/po-general-print.component';
import { PoTempOnePrintComponent } from './purchase-dash/print-templates/puchase-order/po-temp-one-print/po-temp-one-print.component';
import { QuickPurchaseComponent } from './purchase-dash/quick-purchase/quick-purchase.component';
import { DemoQuickPurchaseComponent } from './purchase-dash/demo-quick-purchase/demo-quick-purchase.component';
import { GoodsReceiptComponent } from './purchase-dash/goods-receipt/goods-receipt.component';
import { PoFixedHeaderFooterPrintComponent } from './purchase-dash/print-templates/puchase-order/po-fixed-header-footer-print/po-fixed-header-footer-print.component';

import { LedgerModule } from '../ledger/ledger.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { PoTemp2Component } from './purchase-dash/print-templates/puchase-order/po-temp2/po-temp2.component';

@NgModule({
  declarations: [
    PurchaseDashComponent,
    PurchaseComponent,
    PurchaseVoidComponent,
    CommonComponent,
    AddNotePopup,
    PurchaseReturnComponent,
    NotesComponent,
    PurchaseReturnVoidComponent,
    PurchaseQuotationComponent,
    PoGeneralPrintComponent,
    PoTempOnePrintComponent,
    QuickPurchaseComponent,
    DemoQuickPurchaseComponent,
    GoodsReceiptComponent,
    PoFixedHeaderFooterPrintComponent,
    PoTemp2Component
  ],
  entryComponents: [AddNotePopup,PurchaseVoidComponent ],
  imports: [
    CommonModule, 
    PurchaseRoutingModule,
    ErpMaterialModule,
    NgSelectModule,
    BrowserAnimationsModule,
    MatCheckboxModule,
    MatTabsModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule, 
    ComfunctModule,  
    MatDialogModule, 
    DateInputsModule,
    NgxPrintModule,
    AngularEditorModule,
    SharedModule,
    LedgerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    QRCodeModule,
    QuickProductModule
  ],
  providers: [{provide: APP_BASE_HREF, useValue : '/' },DatePipe], 
  bootstrap: [AppComponent],
  
})
export class PurchaseModule { }
